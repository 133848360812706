import React from "react";

function Button({ children, rest, outlined }) {
  return (
    <button
      type="button"
      {...rest}
      class={` ${
        outlined ? "border border-customBlue text-customBlue hover:bg-darkBlue hover:bg-opacity-10" : "text-white bg-customBlue hover:bg-darkBlue"
      }  font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
    >
      {children}
    </button>
  );
}

export default Button;
