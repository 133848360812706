import React from 'react'
import { Link } from 'react-router-dom'
import GroupImage from '../../../../../../../assets/Frame 249.png'
import Button from '../../../../../../../components/Buttons/Button'

const StarterPage = () => {
  return (
    <div className='p-3'>
        <header>
             <h3 className='text-md font-semibold'>Leaners Group</h3>
             <nav className='mt-1 flex items-center'>
                 <Link to={'/'} className='text-xs'>
                     Dashboard
                 </Link>
                 <span className='bi px-1 text-xs bi-chevron-right'></span>
                 <Link to={'/'} className='text-xs'>
                     Groups
                 </Link>
             </nav>
        </header>
        <section className='mt-5 mx-auto py-8 w-[700px] bg-lightBlue'>
              <div>
                 <img src={GroupImage} className='w-[380px] block mx-auto h-[300px]' />
                 <div>
                     <Button 
                        width={'w-[250px] mx-auto mt-3'} text={'Create Group'}
                        renderAs='link'
                        to='/admin/learning-path/learners-group/create'
                     />
                 </div>
              </div>
        </section>
    </div>
  )
}

export default StarterPage