import React from 'react';
import OverviewCard from '../../report-and-analysis/components/OverviewCard';
import { useQuery } from 'react-query';
import { dashboardApi } from '../../../../../api/dashboard';
import { formatCurrency } from '../../billing/site-admin/pages/summary/Summary';
import BadgeIcon from '../../../../../assets/Badge.png';
import BadgeIconOrange from '../../../../../assets/Bagde (5).png';
import BadgeIconGreen from '../../../../../assets/Badge (1).png';

const SiteAdminSummary = () => {
    
    const { data: dashboardSummary, isLoading } = useQuery(
        ["site admin dashboard summary"],
        () => { 
            return dashboardApi.getClientDashboardSummary({orgId: null});
        },
        {
            cacheTime: 100,
            staleTime: 100,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            select: (data) => data?.data,
      });

 const summary = (summary) => isLoading ? 0 : summary

  return (
    <>  
      <header className='grid pt-3 grid-cols-1 md:grid-cols-4 gap-5'>
           <OverviewCard 
             title={'Total Clients'}
             iconPath={BadgeIconGreen}
             py={'py-5'}
             total={formatCurrency(summary(dashboardSummary?.totalOrganizations), false)}
             headerTextSize='text-2xl'
             withNavigation
             path={'/admin/clients/view'}
           />
           <OverviewCard
             title={'Total Learners'}  
             total={formatCurrency(summary(dashboardSummary?.totalLearners), false)}
             iconPath={BadgeIconOrange}
             headerTextSize='text-2xl'
             py='py-5'
             text={
                <p className='flex justify-between text-xs'>
                   <span>+{summary(dashboardSummary?.todayTotalLearners)} today </span>
                   <span className='text-green-500'>
                     +{summary(dashboardSummary?.todayTotalLearnersPercentage)} today 
                  </span>
                </p>
               }
           />
           <OverviewCard
             title={'Active Learners'}
             total={formatCurrency(summary(dashboardSummary?.activeLearners),false)}
             iconPath={BadgeIcon}
             text={
                <p className='flex justify-between text-xs'>
                   <span>+{summary(dashboardSummary?.todayActiveLearners)} today </span>
                   <span className='text-green-500'>
                     +{summary(dashboardSummary?.todayActiveLearnersPercentage)} today 
                  </span>
                </p>
             }
             headerTextSize='text-2xl'
             py='py-5'
           />
           <OverviewCard
             title={'Revenue Generated ($)'}  
             total={formatCurrency(summary(dashboardSummary?.revenueGenerated))}
             iconPath={BadgeIconOrange}
             text={
                <p className='flex justify-between text-xs'>
                   <span>+{formatCurrency(summary(dashboardSummary?.todayRevenueGenerated))} today </span>
                   <span className='text-green-500'>
                     +{summary(dashboardSummary?.todayRevenueGeneratedPercentage)} today 
                  </span>
                </p>
             }
             headerTextSize='text-2xl'
             py='py-5'
             withNavigation
             path={'/admin/billing/summary'}
           />
        </header>
    </>
  )
}

export default SiteAdminSummary