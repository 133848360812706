"use client";

import { createContext, useState } from "react";

const RewardsContext = createContext({
  currentReward: {},
  setCurrentReward: () => {},
  currentCategory: {},
  setCurrentCategory: () => {},
});

const RewardsProvider = ({ children }) => {
  const [currentReward, setCurrentReward] = useState({});
  const [currentCategory, setCurrentCategory] = useState({});

  return (
    <RewardsContext.Provider
      value={{
        currentReward,
        setCurrentReward,
        currentCategory,
        setCurrentCategory,
      }}
    >
      {children}
    </RewardsContext.Provider>
  );
};

export { RewardsProvider, RewardsContext };
