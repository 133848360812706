import React from 'react'
import CenteredModal from '../../../../../../../../../components/Modal/CenteredModal'
import Table from '../../../../../../../../../components/table/Table'

const ViewQuestionsModal = ({open, setOpen, activeTemplate}) => {

    let columns = React.useMemo( () => [
        {
          title: "ID",
          selector: null,
          key: "id",
          render: ({ index }) =>
            index + 1
        },
        {
            title: "Question",
            selector: "",
            key: "",
            render: ({ row}) => {                      
                 return( 
                    <span className="">{row?.question?.text}</span>
                 )
             },
          },
        {
          title: "Type",
          selector: "",
          key: "",
          render: ({ row }) => {                      
               return( 
                  <span className="">{row?.question?.type}</span>
               )
           },
        }, 
    ])
 
    const tableProps = { isLoading: false, columns, data: activeTemplate?.questions }

  return (
    <>
      <CenteredModal
          title={
            <h5 className='text-md font-semibold bi bi-eye'> 
               &nbsp; View Feedback Questions 
           </h5>
          }
        open={open}
        setOpen={setOpen}
        style={{padding: 0}}
        width={'w-[600px] h-auto overflow-y-auto'}
        headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
        withCloseBtn
        closeButtonLabel={'Close'}
        btnBgColor={'bg-secondary'}
        onClose={ () => {
            setOpen(false)
        }}
      > 
        <div className='text-left'>
          <Table {...tableProps} /> 
        </div>
      </CenteredModal>
    </>
  )
}

export default ViewQuestionsModal