import React, { useState } from "react";
import EmailSettingStep1 from "../pages/EmailSettingStep1";
import EmailSettingStep2 from "../pages/EmailSettingStep2";
import EmailSettingStep3 from "../pages/EmailSettingStep3";



const MultiStepForm = () => {
  const [step, setStep] = useState(1); // Manage current step
  const [formData, setFormData] = useState({}); // Store form data across steps

  const nextStep = (data) => {
    setFormData({ ...formData, ...data }); // Merge new data with existing form data
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  switch (step) {
    case 1:
      return <EmailSettingStep1 nextStep={nextStep} />;
    case 2:
      return <EmailSettingStep2 nextStep={nextStep} prevStep={prevStep}  formData={formData}/>;    
    case 3:
      return <EmailSettingStep3 nextStep={nextStep} prevStep={prevStep}  formData={formData}/>;    
      default:
      return null;
  }
};

export default MultiStepForm;
