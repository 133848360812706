import React, { useEffect } from "react";
import { Ripple, initTE } from "tw-elements";

const Search = ({
  iconColor,
  value,
  placeholder,
  bgColor,
  onClick,
  onChange,
  showButton = true,
  className,
  width,
  mb = 'mb-3',
  ...rest
}) => {
  useEffect(() => {
    initTE({ Ripple });
  }, []);

  return (
    <div className={`${mb} ${width}`}>
      {/* <div className={className || 'mb-5'}> */}
      <div className="relative flex w-full flex-wrap items-stretch">
        <input
          type="search"
          className={`relative m-0 bg-white -mr-0.5 block min-w-0 flex-auto rounded-l border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.25rem] text-xs font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:outline-none ${className}`}
          placeholder={placeholder || "e.g learner-email@gmail.com or name.."}
          aria-label="Search"
          aria-describedby="button-addon1"
          onChange={onChange}
          value={value}
          {...rest}
        />
        {showButton && (
          <button
            onClick={onClick}
            className={`relative z-[2] flex items-center rounded-r ${
              bgColor || "bg-primary"
            } px-6 py-2.5 text-xs font-medium uppercase leading-tight ${
              iconColor || "text-white"
            } shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg`}
            type="button"
            id="button-addon1"
            data-te-ripple-init
            data-te-ripple-color="light"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="h-5 w-5"
            >
              <path
                fillRule="evenodd"
                d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export default Search;
