import createApiClient from "./api";

const client = createApiClient();

export const learnersAnalytics = {
  getLearners_SummaryAnalytics: (orgId) =>
    client
      .get(`/admin/learners/profile/analytics/summary?orgId=${orgId}&type=''`)
      .then(({ data }) => data),

  getCohort_SummaryAnalytics: (cohortId) =>
    client
      .get(`/admin/learners/cohort/analytics/summary?cohortId=${cohortId}`)
      .then(({ data }) => data),

  getPath_SummaryAnalytics: ({ cohortId, pathId }) =>
    client
      .get(
        `/admin/learners/learning-path/analytics/summary?cohortId=${cohortId}&learningPathId=${pathId}`
      )
      .then(({ data }) => data),

  getCourse_SummaryAnalytics: ({ cohortId, pathId, courseId }) =>
    client
      .get(
        `/admin/learners/course/analytics/summary?cohortId=${cohortId}&learningPathId=${pathId}&courseId=${courseId}`
      )
      .then(({ data }) => data),

  getCohort_CompletionAnalytics: ({ cohortId, orgId }) =>
    client
      .get(
        `/admin/learners/cohort/analytics/completion-rate?cohortId=${cohortId}&org=${orgId}&period=lastThirtyDays`
      )
      .then(({ data }) => data),

  getPath_CompletionAnalytics: ({ cohortId, orgId, learningPathId }) =>
    client
      .get(
        `/admin/learners/learning-path/analytics/completion-rate?cohortId=${cohortId}&org=${orgId}&learningPathId=${learningPathId}&period=lastThirtyDays`
      )
      .then(({ data }) => data),

  getCourse_CompletionAnalytics: ({ cohortId, learningPathId, courseId }) =>
    client
      .get(
        `/admin/learners/course/analytics/completion-rate?cohortId=${cohortId}&learningPathId=${learningPathId}&courseId=${courseId}&period=lastThirtyDays`
      )
      .then(({ data }) => data),

  getCohort_ListAnalytics: ({ cohortId, orgId }) =>
    client
      .get(
        `/admin/learners/cohort/analytics/completion-list?cohortId=${cohortId}&org=${orgId}&period=lastThirtyDays`
      )
      .then(({ data }) => data),

  getTopLearners: ({ cohortId, orgId }) =>
    client
      .get(
        `/admin/learners/analytics/top-learners?cohortId=${cohortId}&org=${orgId}&sort=desc`
      )
      .then(({ data }) => data),

  getBottomLearners: ({ cohortId, orgId }) =>
    client
      .get(
        `/admin/learners/analytics/top-learners?cohortId=${cohortId}&org=${orgId}&sort=asc`
      )
      .then(({ data }) => data),

  getCompletionListStatus: ({ cohortId, learningPathId }) =>
    client
      .get(
        `/admin/learners/cohort/analytics/completion-status?cohortId=${cohortId}&learningPathId=${learningPathId}`
      )
      .then(({ data }) => data),
};
