import React from "react";
import { Route, Routes } from "react-router";
import LearningPath from "./pages/LearningPath";
import LearnersGroupRoutes from "./pages/learnersGroup/LearnersGroupRoutes";
import CreatePath from "./pages/CreatePath";
import CreateProgram from "./pages/CreateProgram";
import EditPath from "./pages/editLearningPath/EditPath";

const PathRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LearningPath />} />
      <Route path="/learners-group/*" element={ <LearnersGroupRoutes /> } />
      <Route path="/create-path" element={<CreatePath />} />
      <Route path="/create-program" element={<CreateProgram />} />
      <Route path="/edit-program" element={<EditPath />} />
      <Route path="/edit-path" element={<EditPath />} />
    </Routes>
  );
};

export default PathRoutes;
