import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '../../../../../../../components/Buttons/Button'
import GroupCard from '../components/GroupCard'
import BlockChainImg from '../../../../../../../assets/Blockchain.png'
import Pagination from '../../../../../../../components/pagination/Pagination'
import Loader from '../../../../../../../components/loader/Loader'
import ViewUsers from '../components/ViewUsers'
import Table from '../../../../../../../components/table/Table'
import EmptyData from '../../../../../../../components/EmptyData'
import Search from '../../../../../../../components/Inputs/Search'

const ViewGroups = ({
     perPage, setPerPage, search, setSearch,
     currentPage, setCurrentPage, cohorts, 
     isLoading, refetch
  }) => {

  const [openModal, setOpenModal] = useState(false)
  const [activeCohortId, setActiveCohortId] = useState(null)

  useEffect( () =>  {
    refetch()
  } , [] )

      let columns = React.useMemo( () => [
        {
          title: "ID",
          selector: null,
          key: "id",
          render: ({ index }) =>
            index +
            1 +
            ((cohorts?.meta?.page || 1) - 1) * (cohorts?.meta?.perPage || perPage ),
        },
        {
            title: "Name",
            selector: "name",
            key: "name",
            render: ({ item, row }) => {                      
                return( 
                    <span className="">{row?.cohort?.name}</span>
                )
            },
          },
        {
          title: "Status",
          selector: null,
          key: null,
          render: ({ row }) => {                      
              return( 
                  <span className={`${row?.status === 'in progress' ? 'text-primary' : 'text-customBlue' }`}>
                          {row?.status}
                  </span>
              )
          },
        },
        {
          title: "Learners",
          selector: null,
          key: null,
          render: ({ row }) => {                      
              return( 
                  <span className="">{row?.numberOfParticipants}</span>
              )
          },
        },
        {
          title: "Progress",
          selector: "progress",
          key: "progress",
          render: ({ item }) => {                      
            return <span className={item > 50 ? 'text-green-500 pl-3' : 'pl-3 text-gray-800' }>
                   {item} %
                </span>;
          }
        },
        {
            title: "Team Enabled",
            selector: "teamEnabled",
            key: "teamEnabled",
            render: ({ row }) => {                      
              return <span 
                        className={ !row?.cohort?.teamEnabled ? 'bi bi-x-octagon text-red-500 pl-8' :
                                  'bi bi-check-circle text-green-500 pl-8'}
                      />
            }
          },
        {
          title: "Date Created",
          selector: null,
          key: null,
          render: ({ row }) => {           
            return <span className="">
                {`${ new Date(row?.cohort?.createdAt).toLocaleDateString('en-ng')}`}
            </span>;
          },
        },
        {
          title: "Last Updated",
          selector: null,
          key: null,
          render: ({ row }) => {           
            return <span className="">
                {`${ new Date(row?.cohort?.updatedAt).toLocaleDateString('en-ng')}`}
            </span>;
          },
        },
        {
          title: "Action",
          selector: null,
          key: null,
          render: ({row}) => {           
            return <button 
               className='bi bi-eye'
               onClick={ () => {
                  setActiveCohortId(row?.cohort?.id)
                  setOpenModal(true)
               } }
            /> 
               
          },
        },
      ],
      [1, 20, 20]
    );

  const tableProps = { isLoading, columns, data: cohorts?.data }

  return (
    <div className={`p-3 bg-lightBlue ${ (cohorts?.data?.length < 12 || isLoading) && 'h-full'}`}> 
           <ViewUsers 
              openModal={openModal}
              setOpenModal={setOpenModal} 
              activeCohortId={activeCohortId}
            />
           <header className='flex justify-between'>
              <div>
                <h3 className='text-md font-semibold'>Cohort Overview</h3>
                <nav className='mt-1 flex items-center'>
                    <Link to={'/admin/learning-path/learners-group/view'} className='text-xs'>
                        Dashboard
                    </Link>
                    <span className='bi px-1 text-xs bi-chevron-right'></span>
                    <Link to={'/admin/learning-path/learners-group/view'} className='text-xs'>
                        Cohorts
                    </Link>
                </nav>
              </div>
              <div>
                   <Button 
                         width={'w-[200px]'} 
                         style={{fontWeight: 600, marginTop: 10}}
                         renderAs='link' to='/admin/learning-path/learners-group/create' 
                         text={'Create New Cohort'} 
                         bgColor={'bg-customBlue'}
                    />
              </div>
        </header>
        <section className='mt-3 bg-lightBlue'>
              <section className='flex items-center justify-between bg-white py-1 px-2'>
                  <h3 className='text-sm'>
                   Total Cohorts ({cohorts?.meta?.total})
                  </h3>
                  <div className='w-[300px] mb-0 pt-2'>
                    <Search 
                          value={search}
                          className={'mn-0'}
                          placeholder="e.g access cohort"
                          onChange={ (e) => {
                                setCurrentPage(1)
                                setSearch(e.target.value)
                          } }
                      />
                </div>
              </section>
              <section className='mt-5 bg-white'>
                  {
                    isLoading ? <Loader /> :
                    cohorts?.data?.length ? <Table {...tableProps} />  :
                    <EmptyData text={'There is any cohort available yet.'} />
                  }
              </section>
                  <section className={`mt-5 bg-white px-3`}>
                     <Pagination
                       data={cohorts?.meta}
                       perPage={perPage}
                       pageNo={currentPage}
                       setPageNo={setCurrentPage}
                       setPerPage={setPerPage}
                     />
                  </section>
        </section>
    </div>
  )
}

export default ViewGroups