export const roles = [
  {
    role: "Admin",
    members: 5,
    createdAt: new Date("2024-03-27"),
    updatedAt: new Date("2024-03-27")
  },
  {
    role: "Manager",
    members: 10,
    createdAt: new Date("2024-03-27"),
    updatedAt: new Date("2024-03-27")
  },
  {
    role: "Employee",
    members: 100,
    createdAt: new Date("2024-03-27"),
    updatedAt: new Date("2024-03-27")
  },
  {
    role: "Supervisor",
    members: 8,
    createdAt: new Date("2024-03-27"),
    updatedAt: new Date("2024-03-27")
  },
  {
    role: "Customer Support",
    members: 20,
    createdAt: new Date("2024-03-27"),
    updatedAt: new Date("2024-03-27")
  },
  {
    role: "Sales Representative",
    members: 15,
    createdAt: new Date("2024-03-27"),
    updatedAt: new Date("2024-03-27")
  },
  {
    role: "Developer",
    members: 50,
    createdAt: new Date("2024-03-27"),
    updatedAt: new Date("2024-03-27")
  },
  {
    role: "Marketing Specialist",
    members: 12,
    createdAt: new Date("2024-03-27"),
    updatedAt: new Date("2024-03-27")
  },
  {
    role: "Consultant",
    members: 7,
    createdAt: new Date("2024-03-27"),
    updatedAt: new Date("2024-03-27")
  },
  {
    role: "Intern",
    members: 3,
    createdAt: new Date("2024-03-27"),
    updatedAt: new Date("2024-03-27")
  }
]

  export const courses = [
    {
        courseCode: 'CS101',
        name: 'Introduction to Computer Science',
        duration: 90,
        points: 4,
        learners: '250',
        creationDate: '2023-05-15',
        status: 'published'
    },
    {
        courseCode: 'ENG202',
        name: 'Advanced English Composition',
        duration: 120,
        points: 5,
        learners: '180',
        creationDate: '2023-07-20',
        status: 'published'
    },
    {
        courseCode: 'MATH301',
        name: 'Linear Algebra',
        duration: 90,
        points: 4,
        learners: '150',
        creationDate: '2023-06-10',
        status: 'published'
    },
    {
        courseCode: 'PHYS204',
        name: 'Quantum Mechanics',
        duration: 120,
        points: 5,
        learners: '120',
        creationDate: '2023-08-02',
        status: 'draft'
    },
    {
        courseCode: 'CHEM101',
        name: 'General Chemistry I',
        duration: 90,
        points: 4,
        learners: '200',
        creationDate: '2023-06-25',
        status: 'published'
    },
    {
        courseCode: 'HIST203',
        name: 'World History: Ancient Civilizations',
        duration: 120,
        points: 5,
        learners: '160',
        creationDate: '2023-09-05',
        status: 'published'
    },
    {
        courseCode: 'PSYC201',
        name: 'Introduction to Psychology',
        duration: 90,
        points: 4,
        learners: '180',
        creationDate: '2023-07-15',
        status: 'published'
    },
    {
        courseCode: 'ART101',
        name: 'Art History: Renaissance to Modern Art',
        duration: 120,
        points: 5,
        learners: '140',
        creationDate: '2023-08-20',
        status: 'draft'
    },
    {
        courseCode: 'ECON301',
        name: 'Microeconomics',
        duration: 90,
        points: 4,
        learners: '220',
        creationDate: '2023-06-30',
        status: 'published'
    },
    {
        courseCode: 'BIOL202',
        name: 'Cell Biology',
        duration: 120,
        points: 5,
        learners: '130',
        creationDate: '2023-09-10',
        status: 'published'
    },
    // Add more courses as needed
];
 