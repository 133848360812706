
export const createClientValidation = (values, type='create') => {
    const errors = {};
    if (!values.fname) {
        errors.fname = "Error! first name field is required.";
      } 
      else if (!values.lname) {
        errors.lname = "Error! last name field is required.";
      } 
     else if (!values.email) {
      errors.email = "email address is required.";
    } 
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    } 
    else if (!values.phone) {
      errors.phone = "Error! phone number field cannot be empty.";
    } 
    else if (!values.phone.toString().length > 13) {
      errors.phone = "Error! maximum phone number is 13 digits";
    } 
    else if (!values.ogName) {
        errors.ogName = "Error! company name field is required.";
    } 
    else if (!values.ogAddress) {
       errors.ogAddress = "Error! company address field is required.";
    } 
    if(type !== 'edit'){
      if (!values.role) {
        errors.role = "Error! select your role to continue.";
      } 
      else if(!values?.password){
         errors.password = 'Error! Password field is required.'
      }
      else if(values?.password.toString().length < 8 ){
          errors.password = 'Error! Password field should be more than 8 characters.'
       }
      else if(!values?.cpassword){
          errors.cpassword = 'Error! Confirm your password.'
       }
      else if(values?.password !== values?.cpassword){
          errors.password = 'Error! Password choosen are not the same.'
          errors.cpassword = 'Error! Password choosen are not the same.'
       }
    }
    return errors;
}