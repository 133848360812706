import React from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import CenteredModal from '../../../../../../../../components/Modal/CenteredModal';
import Button from '../../../../../../../../components/Buttons/Button';
import Counter from './Counter';
import ReactDatePicker from 'react-datepicker';
import { api } from '../../../../../../../../api/billing';

const CreateBundle = ({ openModal, setOpenModal, refetch, activeBundle, setActiveBundle }) => {

 const addBundleMutation = useMutation( activeBundle === null ? api.addBundle : api?.updateBundle, {
    onSuccess: (response) => {
        toast.remove();
        toast.success(response?.message);
        refetch()
        setOpenModal(false)
        setActiveBundle(null)
    },
    onMutate: () => {
        toast.loading("Loading...");
    },
    onError: ({ response }) => {
        toast.remove();
        toast.error(response.data?.message);
    },
   });
    
  const initialValues = {
    name: activeBundle === null ? "" : activeBundle?.name ,
    minimumCredit: activeBundle === null ? "1" : activeBundle?.minimumCredit,
    maximumCredit: activeBundle === null ? "1" : activeBundle?.maximumCredit,
    discount: activeBundle === null ? "1" : activeBundle?.discount,
    startDate: activeBundle === null ? new Date() : new Date(activeBundle?.startDate),
    endDate: activeBundle === null ? new Date() : new Date(activeBundle?.endDate),
  };

  const validate = (values) => {
   const errors = {}
   if(!values?.name){
     errors.name = 'Error! title is required.'
   }
   if(!values?.minimumCredit){
      errors.minimumCredit = 'Error! minimum credit is required.'
    }
    if(!values?.maximumCredit){
        errors.maximumCredit = 'Error! maximum credit is required.'
    }
    if(parseInt(values?.minimumCredit) > parseInt(values?.maximumCredit)){
        errors.minimumCredit = 'Error! minimum credit should be less than maximum credit.'
    }
    if(!values?.discount){
        errors.discount = 'Error! discount is required.'
    }

   return errors;
 };

 const createBundle = (values, actions) => {
   if(activeBundle === null) addBundleMutation.mutate(values)
    else addBundleMutation.mutate({ id: activeBundle?.id, data: values })
   actions.setSubmitting(false);
 };

  return (
    <CenteredModal 
            title={
            <h5 className='text-md font-semibold bi bi-plus-circle'>
                &nbsp; { activeBundle === null ? 'Create' : 'Edit' } Bundle  
            </h5>
            }
            open={openModal}
            setOpen={setOpenModal}
            style={{padding: 0}}
            width={'w-[450px] overflow-y-auto'}
            headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
            //btnBgColor={'bg-primary'}
       > 
         <div className='text-left p-5'>
         <Formik
          initialValues={initialValues}
          validate={validate}
          enableReinitialize={true}
          onSubmit={createBundle}
        >
          { ({values, setFieldValue, isValid}) => (
            <Form>
                <p className='text-xs text-gray-700 mt-1 pb-2 border-b border-gray-300'>
                   Add New Bundle
                </p>
                {!isValid ?
                <div>
                     <p className='text-xs text-red-500 mt-2'>
                       <ErrorMessage name='name' />
                     </p>
                     <p className='text-xs text-red-500 mt-2'>
                        <ErrorMessage name='minimumCredit' />
                    </p>
                    <p className='text-xs text-red-500 mt-2'>
                        <ErrorMessage name='maximumCredit' />
                    </p>
                    <p className='text-xs text-red-500 mt-2'>
                        <ErrorMessage name='discount' />
                    </p>
                    <p className='text-xs text-red-500 mt-2'>
                        <ErrorMessage name='startDate' />
                    </p>
                    <p className='text-xs text-red-500 mt-2'>
                        <ErrorMessage name='endDate' />
                    </p>
                </div> : null }
               <div className='mt-2'>
                  <label htmlFor="course-enrolled" className='text-xs'>
                     Title
                  </label>
                  <Field name='name' className='input-md' />
               </div>
               <section className='grid grid-cols-3 gap-5'>
                <div className='mt-3'>
                    <label htmlFor="course-enrolled" className='text-xs'>
                        Minimum credit 
                    </label>
                    <Counter
                        setFieldValue={setFieldValue}
                        values={values}
                        field='minimumCredit'
                        value={parseInt(values?.minimumCredit)}
                    />
                </div>
                <div className='mt-3'>
                    <label htmlFor="course-enrolled" className='text-xs'>
                        Maximum credit 
                    </label>
                    <Counter
                        setFieldValue={setFieldValue}
                        values={values}
                        field='maximumCredit'
                        value={parseInt(values?.maximumCredit)}
                    />
                </div>
                <div className='mt-3'>
                    <label htmlFor="course-enrolled" className='text-xs'>
                        Discount (%)
                    </label>
                    <Counter
                        setFieldValue={setFieldValue}
                        values={values}
                        field='discount'
                        value={parseInt(values?.discount)}
                    />
                </div>
               </section>
                <section className='grid grid-cols-2 gap-5'>
                 <div>
                    <label htmlFor="course-enrolled" className='text-xs'>
                        Start Date
                    </label>
                    <ReactDatePicker
                        onChange={ date => setFieldValue("startDate", date.toISOString()) }
                        selected={values.startDate}
                        dateFormat="dd/MM/yyyy"
                        className="primary-form-input w-full"
                        maxDate={values.endDate}
                    />
                </div>
                <div>
                    <label htmlFor="course-enrolled" className='text-xs'>
                        End Date
                    </label>
                    <ReactDatePicker
                        onChange={ date => setFieldValue("endDate", date.toISOString()) }
                        selected={values.endDate}
                        dateFormat="dd/MM/yyyy"
                        minDate={values.startDate}
                        className="primary-form-input w-full"
                    />
                </div>
                </section>
               <Button 
                  bgColor={'bg-darkBlue'} width={'w-[60%] mt-3 mx-auto'}
                  text={'Save'}
                  btn_type='submit'
                />
            </Form>
         )}
      </Formik>
   </div>
   </CenteredModal>
        
  )
}

export default CreateBundle