import React, { useState } from 'react'
import Button from '../../../../../../../../../components/Buttons/Button'
import CustomDropdown from '../../../../../../../../../components/dropdown/CustomDropdown'
import CompanyRoleButton from '../../../../../../../../Auth/components/CompanyRoleButton'
import { api } from '../../../../../../../../../api/teams'
import { useMutation, useQuery } from 'react-query'
import CreateTeams from './CreateTeams'
import Counter from '../../../../../../learners/teams/pages/createTeam/components/Counter'
import toast from 'react-hot-toast'
import { errorResponse } from '../../../../../../../../../components/utils/errorResponse'

const AddTeams = ({
        setCurrentStep, teams, setTeams, team, setTeam, enableLeaderBoard,
        setEnableLeaderBoard , mapLearnerMethod, setMapLearnerMethod,
        increment, decrement, value,  cohortId, selectedTeams, setSelectedTeams    
}) => {

  const [selectionType, setSelectionType] = useState(false)
  const orgId = JSON.parse(sessionStorage.getItem('user'))?.org?.id
  const { data, isLoading, } = useQuery(
   ["view teams"],
   () => {
     return api.getTeams({ perPage: 10000, currentPage:1, orgId });
   },
   {
     cacheTime: 100,
     staleTime: 100,
     refetchOnWindowFocus: false,
     refetchOnMount:false,
     select: (data) => data?.data,
   })

   const addTeamsMutation = useMutation(api.createOrganisationTeams, {
      onSuccess: (data) => {
          toast.remove()
          toast.success('Teams Created successfully!')
          if(data?.data?.length) setSelectedTeams(data?.data)
      },
      onError: error => errorResponse(error),
      onMutate: () => {
          toast.loading('Loading...')
      }
    })

    const addLearnersToTeamsMutation = useMutation(api.assignOrganisationTeamsToCohort, {
      onSuccess: () => {
          toast.remove()
          toast.success('Teams Settings Applied successfully!')
          setCurrentStep(2)
      },
      onError: error => errorResponse(error),
      onMutate: () => {
          toast.loading('Loading...')
      }
    })

   const createTeams = () => {
      const hasEmptyName = teams.some( team =>  team.name === '');
      if(hasEmptyName){
        toast.error('Error! Complete Adding Name To Your Teams')
      }
      else addTeamsMutation.mutate({ orgId, payload: {
         cohortId,
         teams: teams?.map( team => {
           return { name: team.name, leaderboardEnabled: enableLeaderBoard }
         })
      }})
   }

   const addTeams = () => {

      if(!selectionType){
        if(!team?.length) toast.error('Error! Select teams to proceed')
        else{
         const payload = {
            orgId, payload: {
              teams: team?.map( team => {
               return {
                  id: team?.value, cohortId,
                  assignType: mapLearnerMethod === 1 ? "auto" : "manual",
                  numberOfParticipants: mapLearnerMethod === 1 ? value : null
              }
            })
           }
         }
         addLearnersToTeamsMutation.mutate(payload)
       }
      }
      else {
         if(!selectedTeams?.length) toast.error('Error! create teams to proceed.')
         else{
            const payload = {
               orgId, payload: {
                 teams: selectedTeams?.map( team => {
                  return {
                      id: team?.id,
                      assignType: mapLearnerMethod === 1 ? "auto" : "manual",
                      cohortId,
                      numberOfParticipants: mapLearnerMethod === 1 ? value : null
                  }
                })
             }
            }
          addLearnersToTeamsMutation.mutate(payload)
         }
      }
   }

  return (
    <>
       <div className={`w-[500px] mx-auto shadow-2xl p-4 rounded-lg h-[auto] bg-white mt-3`}>
              <header className='flex justify-between items-center'>
                   <div>
                      <h1 className='text-md font-semibold mt-1'>
                        Step 1 <span className='bi bi-arrow-right'></span> &nbsp;
                        Add Teams 
                      </h1>
                      <p className='text-xs mb-2 text-gray-700'>
                         add teams to your cohort
                      </p>
                   </div>
              </header>
             <div className='border-t border-gray-300 mt-3 pt-3'>
                <div className='mb-4 mt-2 flex items-center'>
                <label className='text-xs pb-0 -mt-1 mr-3'> Select Or Create Teams </label>
                        <h3 className='flex text-sm items-center'>
                        <CompanyRoleButton 
                           text={'Select'} 
                           active={selectionType === false}
                           onClick={ () => setSelectionType(false) }
                        /> 
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <CompanyRoleButton 
                           text={'Create Team'}
                           active={selectionType}
                           onClick={ () => {
                              setSelectionType(true) 
                           }}
                        />
                        </h3>
                </div>
                { selectionType === false ?
                   <CustomDropdown
                   value={team}
                    options={ isLoading ? [] : 
                      data?.length ? data?.map( team => {
                        return { label: team?.team?.name , value: team?.team?.id }
                      } ) : []
                    }
                    placeholder={'Select Existing Team..'}
                    onChange={ value => {
                     setTeam(value)
                    }}
                    multiSelect
                    width={'w-full mt-2'}
                 />
                  : <CreateTeams inputWidth={250} teams={teams} setTeams={setTeams} />
                 }
                 { selectionType && <div className='flex justify-end mt-2'>
                     <Button 
                       width={'w-[150px]'} bgColor={'bg-secondary'}
                       text={'Create team'}
                       onClick={createTeams}
                     />
                  </div>}
                 <section className='mt-5'>
                     <h3 className='flex text-xs items-center'>
                        Enable Team In Leader Board &nbsp;&nbsp;
                        <CompanyRoleButton 
                           text={'Enable'} 
                           active={enableLeaderBoard}
                           onClick={ () => setEnableLeaderBoard(true) }
                        /> 
                        &nbsp;&nbsp;
                        <CompanyRoleButton 
                           text={'Disable'}
                           active={!enableLeaderBoard}
                           onClick={ () => setEnableLeaderBoard(false) }
                        />
                     </h3>
               </section>
               <h2 className='mt-5 mb-0 text-sm'>Apply Settings</h2>
            <section>
             <p className='text-xs mb-3'>Assign learners to team automatically in batches or manually</p>
             <h3 className='flex text-sm items-center'>
                <CompanyRoleButton 
                   text={'Automatic'} 
                   active={ mapLearnerMethod === 1}
                   onClick={ () => setMapLearnerMethod(1) }
                /> 
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <CompanyRoleButton 
                    text={'Manual'}
                    active={mapLearnerMethod === 2}
                    onClick={ () => {
                        setMapLearnerMethod(2)
                        //setOpenModal(true)
                    } }
                />
                {
                  mapLearnerMethod === 1 ? 
                  <>
                     <span className='text-xs ml-5 mr-3'>Batch Of</span>
                     <Counter value={value} increment={increment} decrement={decrement} />
                  </>
                  : null
                }
             </h3>
        </section>
                  <div className='flex mt-5 justify-end'>
                      <Button 
                        width={'w-[160px]'} 
                        text={'Add Teams To Cohort'}
                        bgColor={'bg-customBlue'}
                        onClick={ addTeams }
                      />  
                  </div>    
             </div>
         </div>
    </>
  )
}

export default AddTeams