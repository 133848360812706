import React from "react";

const StepButton = ({ index, title, completed, onClick, activeScreen }) => {
  return (
    <>
      <button className="flex items-center mr-12" onClick={onClick}>
        {completed ? (
          <span className="font-semibold h-[25px] bg-primary text-white w-[25px] bi bi-check text-[14px] flex items-center justify-center rounded-full border border-2 border-primary"></span>
        ) : (
          <span className="font-semibold h-[25px] w-[25px] text-[11px] flex items-center justify-center rounded-full border border-2 border-primary">
            {index}
          </span>
        )}
        <span
          className={`${
            activeScreen >= index && "text-primary"
          } underline ml-2  text-sm font-semibold`}
        >
          {title}
        </span>
      </button>
    </>
  );
};

export default StepButton;
