import React, {useState} from 'react'
import CenteredModal from './../../../../../../components/Modal/CenteredModal';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { createClientValidation } from '../utils/validations';
import { useQuery, useMutation } from 'react-query';
import { api } from '../../../../../../api/clients';
import toast from 'react-hot-toast';
import URL from '../../../../../../url/index';

const EditClientModal = ({ openModal, setOpenModal, refetch, type, activeClient }) => {

const { data: client, isLoading, } = useQuery(
    ["get client details", { id: activeClient?.id, type: 'profile' }],
    ({ queryKey }) => {
        return activeClient !== null && api.getClientSummary(queryKey[1]);
    } 
);

const editClientMutation = useMutation(api.editClient, {
    onMutate: () => {
      toast.loading('Loading...')
    },
    onError: (error) => {
        toast.remove()
        toast.error(error?.response?.data?.message);
    },
    onSuccess: response => {
       toast.remove()
       toast.success('Client account updated successfully!')
       refetch()
       setOpenModal(false)
    } 
 })
const getData = data => isLoading ? '' : data  
const getName = () => {
  const name = client?.data?.rootAccount?.name
  if(client?.data?.rootAccount) return  name?.split(" ")
  else return null
}

 const initialValues = {
    phone: getData(client?.data?.organization?.phoneNumber), 
    ogName: getData(client?.data?.organization?.name), 
    ogAddress: getData(client?.data?.organization?.address),
    role: getData(client?.data?.organization?.domain),
    email:  getData(client?.data?.rootAccount?.email),
    lname: (isLoading || activeClient === null || getName() === null ) ? '' : getName()[getName()?.length - 1] ,
    fname: (isLoading || activeClient === null || getName() === null) ? '' : getName()?.slice(0, -1)?.join(" "), 
    paymentType: getData(client?.data?.organization?.paymentType)
 }

 const validate = (values) => createClientValidation(values,'edit')
 
   const editClient = (values, actions) => {
      
    const payload = {
        firstName: values?.fname,
        lastName: values?.lname,
        email: values?.email,
        organizationName: values?.ogName,
        organizationAddress: values?.ogAddress,
        organizationEmail: values?.email,
        domain: values?.role,
        phoneNumber: values?.phone,
        paymentType: values?.paymentType,
        baseUrl: URL
    }
    editClientMutation.mutate({ data:payload, id: activeClient?.id})
   }

  return (
    <> 
      <CenteredModal 
        title={<h5 className='text-md font-semibold bi bi-pencil-square'> &nbsp; Edit Client </h5> }
        open={openModal}
        setOpen={setOpenModal}
        style={{padding: 0}}
        width={'w-[550px] h-[500px] overflow-y-auto'}
        headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
        withCloseBtn
        closeButtonLabel={'Edit Client Details'}
        btnBgColor={'bg-primary'}
        onClose={ () => {
           document.querySelector('#create-client-submit-btn').click()
        }}
      >
         <section className='text-left pb-3 px-5'>
            <Formik
                    initialValues={initialValues}
                    validate={validate}
                    enableReinitialize={true}
                    onSubmit={editClient}
                >
                {({ values, setFieldError, setFieldValue, isValid }) => (
                    <Form>
                         <h3 className='text-sm text-primary pt-2'>
                            Please fill the required fields marked with *
                         </h3>
                         <section className='mb-3'>
                            <label htmlFor="fname" className='text-xs'>
                                First Name <span className='text-red-500 text-md font-semibold'>*</span>
                            </label>
                            <Field 
                              name='fname' className='input-md mt-1' 
                              placeholder='clients first name...'
                            />
                            <ErrorMessage 
                                    name='fname' className='py-1 text-xs text-red-500'
                                    component={'p'} 
                            />
                        </section>
                        <section className='mb-3'>
                            <label htmlFor="lname" className='text-xs'>
                                Last Name <span className='text-red-500 text-md font-semibold'>*</span>
                            </label>
                            <Field name='lname' className='input-md mt-1' placeholder='clients last name...' />
                            <ErrorMessage 
                                name='lname' className='py-1 text-xs text-red-500'
                                component={'p'} 
                            />
                        </section>
                        <section className='mb-3'>
                            <label htmlFor="email" className='text-xs'>
                                Email Address <span className='text-red-500 text-md font-semibold'>*</span>
                            </label>
                            <Field type='email' name='email' className='input-md mt-1' placeholder='email address...' />
                            <ErrorMessage 
                                name='email' className='py-1 text-xs text-red-500'
                                component={'p'} 
                        />
                        </section>
                        <section className='mb-3'>
                            <label htmlFor="phone" className='text-xs'>
                                Phone Number <span className='text-red-500 text-md font-semibold'>*</span>
                            </label>
                            <Field name='phone' className='input-md mt-1' placeholder='phone e.g 08034...56' />
                            <ErrorMessage 
                                name='phone' className='py-1 text-xs text-red-500'
                                component={'p'} 
                        />
                        </section>
                        <section className='mb-3'>
                            <label htmlFor="role" className='text-xs'>
                              Payment Type (client prepared method of payment)
                            <span className='text-red-500 text-md font-semibold'>*</span>
                            </label>
                            <Field 
                                type='text' as ='select'
                                name='paymentType' className='input-md mt-1' 
                            >
                                <option value="pre-paid">Pre Paid</option>
                                <option value="post-paid">Post Paid</option>
                            </Field>
                            <ErrorMessage 
                              name='paymentType' className='py-1 text-xs text-red-500'
                              component={'p'} 
                           />
                        </section>
                        <section className='mb-3'>
                            <label htmlFor="ogName" className='text-xs'>
                               Organisation Name <span className='text-red-500 text-md font-semibold'>*</span>
                            </label>
                            <Field name='ogName' className='input-md mt-1' placeholder='organisation name...' />
                            <ErrorMessage 
                                name='ogName' className='py-1 text-xs text-red-500'
                                component={'p'} 
                        />
                        </section>
                        <section className='mb-3'>
                            <label htmlFor="ogAddress" className='text-xs'>
                            Organisation Address <span className='text-red-500 text-md font-semibold'>*</span>
                            </label>
                            <Field
                                as='textarea' name='ogAddress' className='input-md mt-1 h-[90px]'
                                placeholder='organisation address...' 
                            />
                            <ErrorMessage 
                                name='ogAddress' className='py-1 text-xs text-red-500'
                                component={'p'} 
                        />
                        </section>
                        <button type="submit" id='create-client-submit-btn'></button>
                    </Form>
                )}
           </Formik>
         </section>
      </CenteredModal>
    </>
  )
}

export default EditClientModal