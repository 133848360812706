import React from 'react'
import ProgressBar from '../../../components/ProgressBar'
import Loader from '../../../../../../../components/loader/Loader'
import { useQuery } from "react-query";
import { api } from "../../../../../../../api/report";

const CompletionSection = () => {

    const user = JSON.parse(sessionStorage.getItem('user'))

    const { data: courseCompletion, isLoading, refetch } = useQuery(
      ["completion rate", { id: user?.org?.id }],
      ({ queryKey }) => {
        return api.getCompletionSummary(queryKey[1]);
      },
      {
        cacheTime: 100,
        staleTime: 100,
        refetchOnWindowFocus: true,
        select: (data) => data?.data,
      }
    )

  if(isLoading) return <Loader />

  return (
    <div className='p-3'> {console.log(courseCompletion)}
        <h5 className='text-md font-medium'>Completions</h5>
        <p className='text-xs mb-3'>Completion target</p>
        <ProgressBar
            progress={ 
               (courseCompletion?.coursesCompleted / courseCompletion?.expectedNumberOfCourseCompletion) * 100 
              }
        />
        <p className='flex justify-between mt-3'>
            <span className='text-gray-500 font-medium text-xs'>
                +{courseCompletion?.numberOfCoursesCompletedToday} courses today
          </span>
            <span className='text-green-500 font-medium text-xs'>+
                {courseCompletion?.percentOfCoursesCompletedToday}</span>
        </p>
        <p className='flex justify-between mt-3'>
            <span className='text-gray-500 font-medium text-xs'>
                +{courseCompletion?.numberOfCoursesCompletedYesterday} courses yesterday
          </span>
            <span className='text-green-500 font-medium text-xs'>+
                {courseCompletion?.percentOfCoursesCompletedYesterday}</span>
        </p>
        <p className='text-xs text-gray-500 font-semibold mt-2 pt-2 border-t border-gray-200'>
           Learner completed  <span className='text-gray-900'>
             {courseCompletion?.numberOfCoursesCompletedToday}
            </span> courses today.
        </p>
        <p className='flex justify-between mt-3'>
            <span className='text-gray-500 font-medium text-xs flex items-center'>
                Completion &nbsp; <span className='text-primary text-md font-medium'> 
                {(courseCompletion?.coursesCompleted / courseCompletion?.expectedNumberOfCourseCompletion) * 100 }%
               </span>
            </span>
            <span className='text-gray-500 font-medium text-xs flex items-center'>
                Target &nbsp; <span className='text-green-500 text-md font-medium'> 100% </span>
            </span>
        </p>
    </div>
  )
}

export default CompletionSection