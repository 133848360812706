import React, { useState } from 'react';

const MultiSelectInput = ({ selectedValues, onValuesChange }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === ' ' && inputValue.trim() !== '') {
      onValuesChange([...selectedValues, inputValue.trim()]);
      setInputValue('');
    }
  };

  const handleRemoveValue = (value) => {
    const updatedValues = selectedValues.filter((selectedValue) => selectedValue !== value);
    onValuesChange(updatedValues);
  };

  return (
    <div className="border rounded-lg p-4 h-fit">
      <div className="space-y-2 space-x-2">
        {selectedValues.map((value) => (
          <span
            key={value}
            className="inline-flex items-center bg-[#FFF0F4] rounded-sm px-3 py-1"
          >
            {value}
            <button
              onClick={() => handleRemoveValue(value)}
              className="ml-1 text-primary "
            >
              &times;
            </button>
          </span>
        ))}
        <input
          type="text"
          placeholder="Input email address and press space to add"
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleInputKeyPress}
          className=" p-2 w-full focus:outline-none   focus:border-transparent"
        />
      </div>
    </div>
  );
};

export default MultiSelectInput;
