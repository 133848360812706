import React from 'react'

const DiscountCard = ({
     title, text, discountCode, discount, contentEditable = false, 
     coursesRequired, handleEdit 
  }) => {

  return (
    <div className='p-8 shadow-md rounded-md bg-lightOrange'>
        <h4 className={`text-sm font-semibold text-center ${
            contentEditable ? 'flex justify-between items-center pb-2' : ''
        }`}>
            {title} {
                contentEditable ? 
                  <div>
                    <span 
                        className='bi hover:cursor-pointer bi-pencil-square text-sm text-blue-500'
                        onClick={handleEdit}
                    />
                    <span className='bi bi-trash text-sm hidden text-red-500 ml-3' />
                  </div>
                : null
            }
        </h4>
        <p className='text-xs text-center'>{text} <br /> courses required:{' '}
          {coursesRequired}
          </p>
        <p className='text-gray-400 font-bold text-center pt-1 text-5xl'>{discount}%</p>
        <span className='text-xs text-center block mt-3'>Discount Code:</span> 
        <span className='text-xs text-center block'>{discountCode}</span>
    </div>
  )
}

export default DiscountCard