import createApiClient from "./api";

const client = createApiClient();

export const api = {

  getRoles: ({ orgId, search, perPage, currentPage }) => client
    .get(`admin/roles?orgId=${orgId}&perPage=${perPage}${
        search ? `&search=${search}` : "" }&page=${currentPage}`
    ).then(({ data }) => data),

  createRole: (payload) => client.post(`admin/roles`, payload).then(({ data }) => data),

  updateRole: (payload) => client.patch(`admin/roles/${payload.id}`, payload?.data).then(({ data }) => data),

  deleteRole: (payload) => client.delete(`admin/roles/${payload}`).then(({ data }) => data),

  getPermissions: () => client.get(`admin/permissions?perPage=10000`).then(({ data }) => data),

  addPermissionToARole: (payload) => client.post(`admin/roles/${payload?.id}`, payload?.data)
   .then(({ data }) => data),
};
