import React from 'react'
import LoginTemplate from './LoginTemplate'
import placeHolderImg from '../../../../../../../../../assets/bi_image-fill.png'

const LoginPanel = ({loginTemplate, setLoginTemplate, openModal}) => {
  return (
    <section className='relative ml-8'>
        <div className='absolute left-[-30px] top-[100px]' style={{zIndex: 1}}>
            { 
             loginTemplate?.type === 'image' ?
                <img 
                  style={{zIndex:1}}
                  src={loginTemplate?.bodyBackgroundImage} alt="placeholder" 
                  className='w-[260px] h-[260px]'
                />
             :
             (!loginTemplate.bodyBackgroundColor) ?
              <img 
                style={{zIndex:1}}
                src={placeHolderImg} alt="placeholder" 
                className='w-[260px] h-[260px]'
              /> :
              <div 
                  className='w-[260px] h-[260px] rounded-xl'
                  style={{background: loginTemplate.bodyBackgroundColor}}
              />
            }
        </div>
        <div style={{zIndex: openModal ? 1 : 100}} 
            className='bg-white absolute ml-16 rounded-xl p-4 w-[70%] mt-12 mx-auto shadow-lg'
        >
              <LoginTemplate
                 loginTemplate={loginTemplate}
                 setLoginTemplate= {setLoginTemplate}
              />
        </div>
    </section>
  )
}

export default LoginPanel