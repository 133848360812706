import React from 'react'
import { Route, Routes } from 'react-router-dom';
import ViewTeams from './pages/viewTeams/ViewTeams';
import CreateTeam from './pages/createTeam/CreateTeam';
import AssignLearners from './pages/assignLearners/AssignLearners';
import ViewLearners from './pages/viewLearners/ViewLearners';

const TeamsRoute = () => {
  return (
    <div>
    <Routes>
      <Route path="/" element={<ViewTeams />} />
      <Route path="/create" element={<CreateTeam />} />
      <Route path="/assign-learners/:id/:name" element={<AssignLearners />} />
      <Route path="/learners/:id/:name" element={<ViewLearners />} />
    </Routes>
    </div>
  )
}

export default TeamsRoute