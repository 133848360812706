import React from "react";
import { Routes, Route } from "react-router";
import EmailEditor from "./pages/EmailEditor";
import MultiStepForm from "./components/MultiStepForm";
import EmailTemplateSelection from "./pages/EmailTemplateSelection";
import EmailTemplate from "./pages/EmailTemplate";
import AllEmailTemplate from "./pages/AllEmailTemplate";
import EditTemplate from "./pages/EditTemplate";
import CreateEmailTemplate from "./pages/CreateEmailTemplate";

const EmailEditorRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<EmailEditor />} />
      <Route path="/email-configuration" element={<MultiStepForm />} />
      <Route path="/template-configuration" element={<EmailTemplate />} />
      <Route path="/template-onboarding" element={<EmailTemplateSelection />} />
      <Route path="/create-email" element={<CreateEmailTemplate />} />
      <Route path="/template-emails" element={<AllEmailTemplate/>} />
      <Route path="/edit-template/:templateId" element={<EditTemplate/>} />
    </Routes>
  );
};

export default EmailEditorRoutes;
