import React, {useMemo, useState, useEffect} from 'react'
import CenteredModal from '../../../../../../../../components/Modal/CenteredModal'
import { useQuery } from 'react-query';
import { api } from '../../../../../../../../api/teams';
import { v4 as uuid } from 'uuid';
import Search from '../../../../../../../../components/Inputs/Search';
import Loader from '../../../../../../../../components/loader/Loader';
import EmptyData from '../../../../../../../../components/EmptyData';
import Table from '../../../../../../../../components/table/Table';
import Pagination from '../../../../../../../../components/pagination/Pagination';

const AddLearners = ({ openModal, setOpenModal }) => {

  const user = JSON.parse(sessionStorage.getItem("user"));
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectAll, setSelectAll] = useState(false);

    const { data: learners, isLoading, refetch} = useQuery(
        ["Get Learners", { orgId: user?.org?.id, search, perPage, currentPage }],
        ({queryKey}) => {
          return api.getLearners({...queryKey[1]});
        },
        {
          cacheTime: 10000,
          staleTime: 10000,
          refetchOnWindowFocus: false,
        }
      );

    useEffect( () => {
       refetch()
    }, [search, currentPage, perPage, isLoading] )

    useEffect(() => {
        if (selectAll) {
          const btn = document.querySelector("#select-all-learners");
          if (btn) btn.click();
        }
      }, [learners?.data]);

      useEffect(() => {
        if(!isLoading){
            const selectedLearners = sessionStorage.getItem('learners')
            if(learners?.data && JSON.parse(selectedLearners)?.length){
                const selected = document.querySelectorAll('.checkbox-inner')
                selected.forEach( learner => {
                  learner.checked = JSON.parse(selectedLearners)?.
                                   findIndex( l => l?.user?.id === learner?.id ) !== -1
                } )
            }
        }
      }, [learners?.data, isLoading]); 

      useEffect(() => {
        if (sessionStorage.getItem("learners"))
          sessionStorage.removeItem("learners");
      }, []);

    let columns = useMemo(
        () => [
          {
            title: (
              <input
                //checked={selectAll}
                type="checkbox"
                id="select-all-learners"
                onClick={(event) => {
                  //setSelectAll(true)
                  const isChecked = event.target.checked;
                  document
                    .querySelectorAll(".learners-select-check")
                    .forEach((element) => {
                      element.checked = isChecked;
                      element.style.backgroundColor = isChecked ? "#ccc" : null;
                    });
                  setSelectAll(isChecked);
                  if (isChecked) {
                    if (sessionStorage.getItem("learners"))
                      sessionStorage.removeItem("learners");
                  }
                }}
                className="w-[18px] rounded-lg h-[18px]"
              />
            ),
            selector: null,
            key: "check",
            render: ({ index, row }) => {
              const learners = sessionStorage.getItem("learners");
              return (
                <input
                  type="checkbox"
                  id={row?.user?.id}
                  defaultChecked={
                    learners
                      ? 
                       JSON.parse(learners).findIndex( l => l?.user?.email === row?.user?.email) !== -1
                      : false
                  }
                  className={`w-[18px] checkbox-inner checked:bg-primary border checked:border-primary rounded-lg h-[18px] learners-select-check`}
                  onClick={(event) => {
                    const learners = sessionStorage.getItem("learners");
                    if (learners) {
                      if (event.target.checked) {
                        sessionStorage.setItem(
                          "learners",
                          JSON.stringify([...JSON.parse(learners), row])
                        );
                      } else {
                        sessionStorage.setItem(
                          "learners",
                          JSON.stringify(
                            [...JSON.parse(learners)].filter((learners) => {
                              return learners?.user?.email !== row?.user?.email;
                            })
                          )
                        );
                      }
                    } else sessionStorage.setItem("learners", JSON.stringify([row]));
                  }}
                  onChange={(event) => {
                    const selectAllBtn = document.querySelector(
                      "#select-all-learners"
                    );
                    if (selectAllBtn.checked) {
                      event.target.checked = true;
                    }
                  }}
                />
              );
            },
          },
          {
            title: "ID",
            selector: null,
            key: "id",
            render: ({ index }) =>
              index +
              1 +
              ((learners?.current_page || 1) - 1) *
                (learners?.per_page || perPage),
          },
          {
            title: "Name",
            selector: "name",
            key: "name",
            render: ({row}) => {
                return <span className='xs'> { row?.user?.name || '-----------'} </span>
            }
          },
          {
            title: "Email",
            selector: "email",
            key: "email",
            render: ({row}) => {
                return <span className='xs'> { row?.user?.email || '------------'} </span>
            }
          },
          {
            title: "Phone Number",
            selector: "phoneNumber",
            key: "phoneNumber",
            render: ({row}) => {
                return <span className='xs'> { row?.user?.phoneNumber || '------------'} </span>
            }
          },
          {
            title: "Gender",
            selector: "gender",
            key: "gender",
            render: ({row}) => {
                return <span className='xs'> { row?.user?.gender || '------------'} </span>
            }
          },
        ],
        [learners?.current_page, learners?.per_page, perPage]
      );
    
   const tableProps = { isLoading: false, columns, data: learners?.data }

  return (
    <>
         <CenteredModal 
            title={
            <h5 className='text-md font-semibold bi bi-plus-circle'>
                &nbsp; Add Learners To Team
            </h5>
            }
            open={openModal}
            setOpen={setOpenModal}
            style={{padding: 0}}
            width={'w-[700px] overflow-y-auto'}
            headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
            withCloseBtn
            closeButtonLabel={'Save & Proceed'}
            btnBgColor={'bg-customBlue'}
       > 
         <div className='text-left p-3'>
             <header className='flex justify-between'>
                 <h5 className='text-sm pt-2'>Total Learners ({learners?.meta?.total}) </h5>
                 <Search 
                       className={'w-[250px] mb-0'} 
                       onChange={ e => setSearch(e.target.value) }
                       placeholder={'Search Learners....'}
                 />
             </header>
             <section className='-mt-5'>
                  {
                    isLoading ? <Loader /> : 
                    learners?.data?.length ?
                        <Table {...tableProps} /> : 
                        <EmptyData text={'No learner found.'} />
                  }
             </section>
             <Pagination 
                data={learners?.meta}
                perPage={perPage}
                pageNo={currentPage}
                setPageNo={setCurrentPage}
                setPerPage={setPerPage}
            />
         </div>
      </CenteredModal>
    </>
  )
}

export default AddLearners