import React from 'react'
import Card from '../components/Card'
import { Routes, Route, Link, useParams } from 'react-router-dom'
import CreditIcon from '../../../../../../assets/Bagde (5).png'
import Overview from './pages/Overview'
import ProjectIcon from '../../../../../../assets/Badge (1).png'
import TotalProjectIcon from '../../../../../../assets/Badge.png'
import Activity from './pages/Activity/Activity'
import Login from './pages/Login/Login'
import LandingPage from './pages/LandingPage/LandingPage'
import EmailSetting from './pages/EmailSetting/EmailSetting'
import Notifications from './pages/Notifications/Notifications'
import AsideItem from '../components/AsideItem'
import Batch1 from '../../../../../../assets/Bagde (1).png'
import logo from '../../../../../../assets/Frame 287.png'
import BagdeIcon from '../../../../../../assets/Bagde.png'
import Telephone from '../../../../../../assets/Bagde (3).png'
import Envelope from '../../../../../../assets/Bagde (2).png'
import Calender from '../../../../../../assets/Bagde (4).png'
import Button from '../../../../../../components/Buttons/Button' 
import { useQuery } from 'react-query' 
import { api } from '../../../../../../api/clients'
import Loader from '../../../../../../components/loader/Loader'

const ViewClientDetails = () => {

  const params = useParams()

  const { data: client, isLoading, } = useQuery(
    ["get client details", { id: params?.id, type: 'profile' }],
    ({ queryKey }) => {
        return api.getClientSummary(queryKey[1]);
    },
    {
      select: data => data?.data
    } 
);

const { data: summary, isSummaryLoading, } = useQuery(
  ["get client details", { id: params?.id, type: 'summary' }],
  ({ queryKey }) => {
      return api.getClientSummary(queryKey[1]);
  },
  {
    select: data => data?.data
  } 
);

 if(isLoading || isSummaryLoading) return <Loader />
  
  return (
     <section className='bg-lightBlue h-[100vh] p-2'> 
          <h3 className='text-lg font-semibold text-gray-500'> Client Details </h3>
          <nav className='mt-1 flex items-center'>
            <Link to={'/admin/clients/view'} className='text-xs'>
                View Clients
            </Link>
            <span className='bi px-1 text-xs bi-chevron-right'></span>
            <Link to={`/admin/clients/view-client/${params?.id}/`} className='text-xs'>
                Client Details
            </Link>
          </nav>
          <section className='flex mt-5 justify-between bg-lightBlue'>
            <aside className='w-[30%] bg-white rounded-xl p-3'>
                 <img src={logo} alt="logo" className='w-[200px] h-[70px] block mx-auto' />
                 <hr />
                  <div className='mt-5 px-5'>
                     <AsideItem 
                         icon={BagdeIcon} title={'Client Name'}
                         text={client?.rootAccount?.name}
                     /> 
                     <AsideItem 
                        icon={Batch1} title={'Company Name'} 
                        text={client?.organization?.name}
                     /> 
                     <AsideItem
                       icon={Envelope} title={'Email Address'}
                       text={client?.rootAccount?.email} 
                      />
                     <AsideItem 
                        icon={Telephone} title={'Contact Number'}
                        text={client?.organization?.phoneNumber} 
                     />
                     <AsideItem 
                         icon={Calender} title={'Date Added'} 
                         text={`${new Date(client?.organization?.createdAt).toLocaleDateString('en-ng')}`}
                     />
                     <AsideItem 
                       icon={Calender} title={'Date Updated'}
                       text={`${new Date(client?.organization?.updatedAt).toLocaleDateString('en-ng')}`}
                     />
                     <AsideItem 
                        icon={Batch1} title={'Address'} 
                        text={client?.organization?.address} 
                    /> 
                  </div>
                  <hr /> <br />
                   <div className='px-3'>
                       <Button 
                             text={'Edit Information'}
                             width={'w-full '}
                             bgColor={'bg-customBlue'} 
                      />
                      <Button 
                             text={'Delete Client'}
                             width={'w-full'}
                             style={{marginTop: 12}}
                             bgColor={'bg-red-500'} 
                      />
                   </div>
            </aside>
            <section className='w-[68%] mt-3 rounded-lg'>
                <div className='grid grid-cols-3 gap-3'>
                    <Card 
                         image={TotalProjectIcon} title={'Total Cohorts'}
                         total={summary?.numberOfCohorts || 0} 
                    />
                    <Card 
                        image={CreditIcon} title={'Credits'}
                        total={summary?.credits || 0}
                     />
                    <Card 
                       image={ProjectIcon} title={'Completed'} 
                       total={summary?.completed || 0} 
                    />
                </div>
                <section> 
                    <nav className='mt-3 border-b border-gra-600 pb-2'>
                       <Link to={`/admin/clients/view-client/${params?.id}/`}
                             className={`text-xs mr-5 ${params['*'] === '' && 'text-primary'}`}
                        > 
                          Overview
                       </Link>
                       <Link 
                           to={`/admin/clients/view-client/${params?.id}/activity`}
                            className={`text-xs mr-5 ${params['*'] === 'activity' && 'text-primary'}`}
                        >
                         Activity
                        </Link>
                       <Link 
                           to={`/admin/clients/view-client/${params?.id}/login`} 
                           className={`text-xs mr-5 ${params['*'] === 'login' && 'text-primary'}`}
                        > 
                         Login 
                       </Link>
                       <Link 
                            to={`/admin/clients/view-client/${params?.id}/landing-pages`} 
                            className={`text-xs mr-5 ${params['*'] === 'landing-pages' && 'text-primary'}`}
                        >
                         Landing Page 
                        </Link>
                       <Link 
                           to={`/admin/clients/view-client/${params?.id}/email-settings`} 
                           className={`text-xs mr-5 ${params['*'] === 'email-settings' && 'text-primary'}`}
                      >
                          Email Settings 
                        </Link>
                       <Link 
                          to={`/admin/clients/view-client/${params?.id}/notifications`} 
                          className={`text-xs mr-5 ${params['*'] === 'notifications' && 'text-primary'}`}
                        > 
                           Notifications
                       </Link>
                    </nav>
                     <section className='p-3'>
                      <Routes>
                        <Route path='/' element={<Overview orgId={client?.organization?.id} />} />
                        <Route path='/activity' element={<Activity />} />
                        <Route path='/login' element={<Login id={params?.id} />} />
                        <Route path='/landing-pages' element={<LandingPage />} />
                        <Route path='/email-settings' element={<EmailSetting />} />
                        <Route path='/notifications' element={<Notifications />} />
                      </Routes>
                     </section>
                </section>
            </section>
        </section>
     </section>
  )
}

export default ViewClientDetails