import React from "react";
import Summary from "./components/Summary";
import ActionPlanTable from "./components/ActionPlanTable";
import ActionPlanLearnerTable from "./components/ActionPlanLearnerTable";
import GoalProgress from "./components/GoalProgress";
import GoalSubmittedChart from "./components/GoalSubmittedChart";
import TopCourses from "./components/TopCourses";
import Goals from "./components/Goals";
import { Link } from "react-router-dom";

const ActionPlanDashboard = () => {
  return (
    <div>
      <div className="flex justify-between items-center">
        <h2 className="text-lg text-[#0C0D0F] font-semibold ">Action Plan</h2>
        <Link
          to={`/admin/action-plan/create-action-plan`}
          
        >
          <button className="p-2 bg-customBlue text-white text-xs rounded-md">
            Create Action Plan
          </button>
        </Link>
      </div>
      <Summary />
      <div className="flex gap-6 mt-6">
        <div className="w-2/5">
          <GoalProgress />
        </div>
        <div className="w-3/5">
          <GoalSubmittedChart />
        </div>
      </div>
      <ActionPlanTable />
      <div className="flex gap-6 my-6">
        <div className="w-2/3">
          <ActionPlanLearnerTable />
        </div>
        <div className="w-1/3">
          <TopCourses />
        </div>
      </div>
      <Goals />
    </div>
  );
};

export default ActionPlanDashboard;
