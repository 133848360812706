import { useQuery } from "react-query";
import { useState, useMemo, useContext } from "react";
import { learners } from "../api/learners";
import { convertDate } from "../pages/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import VerticalMenu from "../pages/Admin/components/VerticalMenu";
import { LearnersContext } from "../context/learnersContext";
import {
  CompletedState,
  SuspendedState,
  PendingState,
} from "../pages/Admin/pages/learners/components/svg";
import { learnersAnalytics } from "../api/learner-analytics";

const useLearners = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const {
    openModal,
    setOpenModal,
    setCurrentLearner,
    pageType,
    setPageType,
    selectedCohort,
    setSelectedCohort,
    selectedCourse,
    setSelectedCourse,
    selectedPath,
    setSelectedPath,
    statusFilter,
    setStatusFilter,
    setOpenResetModal,
    setOpenAssignModal,
  } = useContext(LearnersContext);

  const handleCohort = (e) => {
    setSelectedCohort(JSON.parse(e.target.value));
    setSelectedPath({});
    setSelectedCourse({});
    setSearch("");
    if (!JSON.parse(e.target.value)?.cohortId) return setPageType("profile");

    setCurrentPage(1);
    setPageType("cohort");
  };

  const handleStatusFilter = (e) => {
    setSearch("");
    setStatusFilter(e.target.value);
  };

  const handleLearningPath = (e) => {
    setSelectedPath(JSON.parse(e.target.value));
    setSelectedCourse({});
    setSearch("");
    if (!JSON.parse(e.target.value)?.learningPathId)
      return setPageType("cohort");

    setCurrentPage(1);
    setPageType("path");
  };

  const handleCourse = (e) => {
    setSelectedCourse(JSON.parse(e.target.value));
    setSearch("");
    if (!JSON.parse(e.target.value)?.courseId) return setPageType("path");

    setCurrentPage(1);
    setPageType("course");
  };

  const { data: filters } = useQuery(
    ["Learner Filters"],
    () => {
      return learners.getFilters(user?.org?.id);
    },
    {
      cacheTime: 10000,
      staleTime: 10000,
      refetchOnWindowFocus: false,
      select: (data) => data?.data,
    }
  );

  const { data: profiledLearners, isLoading: loadingProfiledLearners } =
    useQuery(
      [
        "Profiled Learners",
        pageType,
        currentPage,
        perPage,
        statusFilter,
        search,
      ],
      () => {
        return learners.getLearners_Profile({
          orgId: user?.org?.id,
          page: currentPage,
          perPage,
          statusFilter,
          search,
        });
      },
      {
        cacheTime: 10000,
        staleTime: 10000,
        refetchOnWindowFocus: false,
        select: (data) => data,
      }
    );

  // const {
  //   data: profiledLearnersDownload,
  //   isLoading: loadingProfiledLearnersDownload,
  //   refetch: refetchProfiledLearnersDownload,
  // } = useQuery(
  //   [
  //     "Profiled Learners Download",
  //     pageType,
  //     currentPage,
  //     perPage,
  //     statusFilter,
  //     search,
  //   ],
  //   () => {
  //     return learners.DownloadLearners_Profile({
  //       orgId: user?.org?.id,
  //     });
  //   },
  //   {
  //     cacheTime: 10000,
  //     staleTime: 10000,
  //     refetchOnWindowFocus: false,
  //     select: (data) => data,
  //   }
  // );

  // console.log(profiledLearnersDownload);

  const { data: profiledSummary, isLoading: loadingProfiledSummary } = useQuery(
    ["Profiled Summary", pageType],
    () => {
      return learners.getProfile_Summary(user?.org?.id);
    },
    {
      cacheTime: 10000,
      staleTime: 10000,
      refetchOnWindowFocus: false,
    }
  );

  // console.log(profiledSummary);

  const { data: pathSummary, isLoading: loadingPathSummary } = useQuery(
    ["Path Summary", selectedCohort, pageType, selectedPath],
    () => {
      return (
        selectedCohort?.cohortId &&
        learners.getPath_summary({
          cohortId: selectedCohort?.cohortId,
          pathId: selectedPath?.learningPathId,
        })
      );
    },
    {
      cacheTime: 10000,
      staleTime: 10000,
      refetchOnWindowFocus: false,
      enabled: Boolean(selectedPath?.learningPathId),
    }
  );

  // console.log(pathSummary);

  const { data: cohortLearners, isLoading: loadingCohortLearners } = useQuery(
    [
      "Cohort Learners",
      selectedCohort,
      pageType,
      currentPage,
      perPage,
      statusFilter,
      search,
    ],
    () => {
      return learners.getLearners_Cohort({
        cohortId: selectedCohort?.cohortId,
        page: currentPage,
        perPage,
        statusFilter,
        search,
      });
    },
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: false,
      select: (data) => data,
      enabled: Boolean(selectedCohort?.cohortId),
    }
  );

  const { data: cohortSummary } = useQuery(
    ["Cohort Summaries 2020", selectedCohort, pageType],
    () => learners.getCohort_Summary(selectedCohort?.cohortId),
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: false,
      select: (data) => data,
      enabled: Boolean(selectedCohort?.cohortId),
    }
  );

  const { data: cohortAnalyticSummary } = useQuery(
    ["Cohort Analytics Summary", selectedCohort, pageType],
    () =>
      learnersAnalytics.getCohort_SummaryAnalytics(selectedCohort?.cohortId),
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: false,
      select: (data) => data,
      enabled: Boolean(selectedCohort?.cohortId),
    }
  );

  const { data: pathAnalyticSummary } = useQuery(
    ["Path Analytics Summary", selectedCohort, pageType, selectedPath],
    () => {
      return (
        selectedCohort?.cohortId &&
        learnersAnalytics.getPath_SummaryAnalytics({
          cohortId: selectedCohort?.cohortId,
          pathId: selectedPath?.learningPathId,
        })
      );
    },
    {
      cacheTime: 10000,
      staleTime: 10000,
      refetchOnWindowFocus: false,
      enabled: Boolean(selectedPath?.learningPathId),
    }
  );

  const { data: courseAnalyticSummary } = useQuery(
    [
      "Course Analytics Summary",
      selectedCohort,
      pageType,
      selectedPath,
      selectedCourse,
    ],
    () => {
      return (
        selectedCohort?.cohortId &&
        selectedPath?.learningPathId &&
        learnersAnalytics.getCourse_SummaryAnalytics({
          cohortId: selectedCohort?.cohortId,
          pathId: selectedPath?.learningPathId,
          courseId: selectedCourse?.courseId,
        })
      );
    },
    {
      cacheTime: 10000,
      staleTime: 10000,
      refetchOnWindowFocus: false,
      select: (data) => data,
      enabled: Boolean(selectedCourse?.courseId),
    }
  );

  // console.log(cohortSummary, "cohort summary");

  const {
    data: cohortAnalyticCompletion,
    isLoading: loadingCohortAnalyticCompletion,
  } = useQuery(
    ["Cohort Analytics Completion", selectedCohort, pageType],
    () =>
      learnersAnalytics.getCohort_CompletionAnalytics({
        cohortId: selectedCohort?.cohortId,
        orgId: user?.org?.id,
      }),
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: false,
      select: (data) => data,
      enabled: Boolean(selectedCohort?.cohortId),
    }
  );

  const {
    data: pathAnalyticCompletion,
    isLoading: loadingPathAnalyticCompletion,
  } = useQuery(
    ["Path Analytics Completion", selectedCohort, selectedPath, pageType],
    () => {
      return (
        selectedPath?.learningPathId &&
        learnersAnalytics.getPath_CompletionAnalytics({
          cohortId: selectedCohort?.cohortId,
          orgId: user?.org?.id,
          learningPathId: selectedPath?.learningPathId,
        })
      );
    },
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: false,
      select: (data) => data,
      enabled: Boolean(selectedCohort?.cohortId),
    }
  );

  const {
    data: courseAnalyticCompletion,
    isLoading: loadingCourseAnalyticCompletion,
  } = useQuery(
    [
      "Course Analytics Completion",
      selectedCohort,
      selectedPath,
      selectedCourse,
      pageType,
    ],
    () => {
      return (
        selectedPath?.learningPathId &&
        selectedCourse?.courseId &&
        learnersAnalytics.getCourse_CompletionAnalytics({
          cohortId: selectedCohort?.cohortId,
          learningPathId: selectedPath?.learningPathId,
          courseId: selectedCourse?.courseId,
        })
      );
    },
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: false,
      select: (data) => data,
      enabled: Boolean(selectedCohort?.cohortId),
    }
  );

  const { data: cohortAnalyticList } = useQuery(
    ["Cohort Analytics List", selectedCohort, pageType],
    () =>
      learnersAnalytics.getCohort_ListAnalytics({
        cohortId: selectedCohort?.cohortId,
        orgId: user?.org?.id,
      }),
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: false,
      select: (data) => data,
      enabled: Boolean(selectedCohort?.cohortId),
    }
  );

  const { data: topLearners, isLoading: loadingTopLearners } = useQuery(
    ["Top Learners", selectedCohort, pageType],
    () =>
      learnersAnalytics.getTopLearners({
        cohortId: selectedCohort?.cohortId,
        orgId: user?.org?.id,
      }),
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: false,
      select: (data) => data,
      enabled: Boolean(selectedCohort?.cohortId),
    }
  );

  const { data: bottomLearners, isLoading: loadingBottomLearners } = useQuery(
    ["Bottom Learners", selectedCohort, pageType],
    () =>
      learnersAnalytics.getBottomLearners({
        cohortId: selectedCohort?.cohortId,
        orgId: user?.org?.id,
      }),
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: false,
      select: (data) => data,
      enabled: Boolean(selectedCohort?.cohortId),
    }
  );

  const { data: completionListStatus } = useQuery(
    ["Completion List Status", selectedCohort, selectedPath, pageType],
    () => {
      return (
        selectedPath?.learningPathId &&
        learnersAnalytics.getCompletionListStatus({
          cohortId: selectedCohort?.cohortId,
          learningPathId: selectedPath?.learningPathId,
        })
      );
    },
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: false,
      select: (data) => data,
      enabled: Boolean(selectedCohort?.cohortId),
    }
  );

  // console.log(completionListStatus);

  const { data: pathLearners, isLoading: loadingPathLearners } = useQuery(
    [
      "Path Learners",
      selectedCohort,
      pageType,
      selectedPath,
      currentPage,
      perPage,
      statusFilter,
    ],
    () => {
      return (
        selectedCohort?.cohortId &&
        learners.getLearners_Path({
          cohortId: selectedCohort?.cohortId,
          pathId: selectedPath?.learningPathId,
          page: currentPage,
          perPage,
          statusFilter,
        })
      );
    },
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: false,
      select: (data) => data,
      enabled: Boolean(selectedPath?.learningPathId),
    }
  );

  const { data: pathLearnersList, isLoading: loadingPathLearnersList } =
    useQuery(
      [
        "Path Learners List",
        selectedCohort,
        pageType,
        selectedPath,
        currentPage,
        perPage,
        statusFilter,
        search,
      ],
      () => {
        return (
          selectedCohort?.cohortId &&
          learners.getLearners_PathList({
            cohortId: selectedCohort?.cohortId,
            pathId: selectedPath?.learningPathId,
            page: currentPage,
            perPage,
            statusFilter,
            search,
          })
        );
      },
      {
        cacheTime: 30000,
        staleTime: 30000,
        refetchOnWindowFocus: false,
        select: (data) => data,
        enabled: Boolean(selectedPath?.learningPathId),
      }
    );

  // console.log(pathLearnersList);

  // console.log(selectedPath);

  const { data: courseLearners, isLoading: loadingCourseLearners } = useQuery(
    [
      "Course Learners",
      selectedCohort,
      pageType,
      selectedPath,
      currentPage,
      perPage,
      statusFilter,
    ],

    () => {
      return (
        selectedCohort?.cohortId &&
        selectedPath?.learningPathId &&
        learners.getLearners_Course({
          cohortId: selectedCohort?.cohortId,
          pathId: selectedPath?.learningPathId,
          courseId: selectedCourse?.courseId,
          page: currentPage,
          perPage,
          statusFilter,
        })
      );
    },
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: false,
      select: (data) => data,
      enabled: Boolean(selectedCourse?.courseId),
    }
  );

  const { data: courseLearnersList, isLoading: loadingCourseLearnersList } =
    useQuery(
      [
        "Course Learners List",
        selectedCohort,
        pageType,
        selectedPath,
        currentPage,
        perPage,
        statusFilter,
        search,
      ],
      () => {
        return (
          selectedCohort?.cohortId &&
          selectedPath?.learningPathId &&
          learners.getLearners_CourseList({
            cohortId: selectedCohort?.cohortId,
            pathId: selectedPath?.learningPathId,
            courseId: selectedCourse?.courseId,
            page: currentPage,
            perPage,
            statusFilter,
            search,
          })
        );
      },
      {
        cacheTime: 30000,
        staleTime: 30000,
        refetchOnWindowFocus: false,
        select: (data) => data,
        enabled: Boolean(selectedCourse?.courseId),
      }
    );

  // console.log(courseLearnersList);
  // console.log(selectedCourse);

  const { data: courseSummary, isLoading: loadingCourseSummary } = useQuery(
    ["Course Summary", selectedCohort, pageType, selectedPath, selectedCourse],
    () => {
      return (
        selectedCohort?.cohortId &&
        selectedPath?.learningPathId &&
        learners.getCourse_Summary({
          cohortId: selectedCohort?.cohortId,
          pathId: selectedPath?.learningPathId,
          courseId: selectedCourse?.courseId,
        })
      );
    },
    {
      cacheTime: 10000,
      staleTime: 10000,
      refetchOnWindowFocus: false,
      select: (data) => data,
      enabled: Boolean(selectedCourse?.courseId),
    }
  );

  // console.log(courseSummary, "course");

  // console.log(pageType);

  // console.log(courseLearners);

  // console.log(pathLearners);

  let profileColumns = useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index }) => index + 1,
      },
      {
        title: "Name",
        selector: "user",
        key: "name",
        render: ({ item }) => {
          return (
            <div className="">
              <p className="font-semibold text-[13px]">{item?.name}</p>
              <p>{item?.organization?.name}</p>
            </div>
          );
        },
      },
      {
        title: "Email",
        selector: "user",
        key: "email",
        render: ({ item }) => item?.email,
      },
      {
        title: "Gender",
        selector: "user",
        key: "gender",
        render: ({ item }) => item?.gender,
      },
      {
        title: "Last Logged In",
        selector: "lastLogin",
        key: "lastLogin",
        render: ({ item }) => {
          return <span>{convertDate(item)}</span>;
        },
      },
      {
        title: "Date Created",
        selector: "user",
        key: "user",
        render: ({ item }) => {
          return <span>{convertDate(item?.createdAt)}</span>;
        },
      },
      {
        title: "Actions",
        selector: "actions",
        key: "actions",
        render: ({ item, row }) => {
          return (
            <VerticalMenu isBackground={true}>
              <div className="">
                <button
                  onClick={() => {
                    sessionStorage.setItem(
                      "currentLearner",
                      JSON.stringify(row)
                    );
                    setOpenResetModal(true);
                  }}
                  className="block w-full border border-slate-100 px-4 py-2 text-left bg-white duration-200 text-baseFont text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                >
                  Reset Password
                </button>
                <button
                  onClick={() => {
                    sessionStorage.setItem(
                      "currentLearner",
                      JSON.stringify(row)
                    );
                    setOpenAssignModal(true);
                  }}
                  className="block w-full border border-slate-100 px-4 py-2 text-left bg-white duration-200 text-baseFont text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                >
                  Assign to Cohort
                </button>
              </div>
            </VerticalMenu>
          );
        },
      },
    ],
    []
  );

  let cohortColumns = useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index }) => index + 1,
      },
      {
        title: "Name",
        selector: "name",
        key: "name",
      },
      {
        title: "Email",
        selector: "email",
        key: "email",
      },
      {
        title: "Enrollment Status",
        selector: "enrolledAt",
        key: "enrolledAt",
        render: ({item}) => {
           return <span 
                   className={  
                          item === null ? 
                          'text-red-500 pl-8 bi bi-x-octagon' :
                           'pl-8text-green-500 bi bi-check-circle'}
                    />
        }
      },
      {
        title: "Date Enrolled",
        selector: "enrolledAt",
        key: "enrolledAt",
        render: ({item}) => {
           return <span>
               {item === null ? '------------' : `${new Date(item).toLocaleDateString('en-ng')}`}
           </span>
        }
      },
      {
        title: "Status",
        selector: "status",
        key: "status",
        render: ({ item }) => {
          return (
            <div className="">
               {item === null && <span className="text-primary">Not Started</span>}
               {item === 'completed' && <span className="text-green-500">{item}</span>}
               {item === 'in progress' && <span className="text-blue-500">{item}</span>}
            </div>
          );
        },
      },
    ],
    []
  );

  let pathColumns = useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index }) => index + 1,
      },
      {
        title: "Name",
        selector: "name",
        key: "name",
      },
      {
        title: "Email",
        selector: "email",
        key: "email",
      },
      {
        title: "Total Courses",
        selector: "numberOfCourses",
        key: "numberOfCourses",
      },
      {
        title: "Completed Courses",
        selector: "numberOfCompletedCourses",
        key: "numberOfCompletedCourses",
      },
      {
        title: "Status",
        selector: "status",
        key: "status",
        render: ({ item }) => (
          <div className="capitalize flex items-center justify-start gap-1">
            <span>
              {" "}
              {item === "completed" ? (
                <CompletedState />
              ) : item === "not started" ? (
                <SuspendedState />
              ) : (
                <PendingState />
              )}
            </span>
            {item}
          </div>
        ),
      },
      {
        title: "Actions",
        selector: null,
        key: null,
        render: ({ row }) => {
          return (
            <>
              <VerticalMenu isBackground>
                <div className="flex items-center justify-start gap-2">
                  <button
                    onClick={() => {
                      // console.log(row);
                      setCurrentLearner(row);
                      setOpenModal(!openModal);
                    }}
                    className="block w-full border border-slate-100 px-4 py-2 text-left bg-white duration-200 text-baseFont text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                  >
                    View Courses
                  </button>
                </div>
              </VerticalMenu>
            </>
          );
        },
      },
    ],
    []
  );

  let learningPathColumns = useMemo(
    () =>
      selectedPath?.courses
        ? [
            {
              title: "ID",
              selector: null,
              key: "id",
              render: ({ index }) => index + 1,
            },
            {
              title: "Name",
              selector: "name",
              key: "name",
            },
            {
              title: "Email",
              selector: "email",
              key: "email",
            },
            ...selectedPath?.courses?.map((path, index) => {
              return {
                title: path?.courseName,
                selector: "courses",
                key: path?.courseId,
                render: ({ item }) => {
                  return (
                    <div className="capitalize flex items-center justify-start gap-1">
                      <span>
                        {" "}
                        {item[index]?.status === "completed" ? (
                          <CompletedState />
                        ) : item[index]?.status === "not started" ? (
                          <SuspendedState />
                        ) : (
                          <PendingState />
                        )}
                      </span>
                      {item[index]?.status}
                    </div>
                  );
                },
              };
            }),
            {
              title: "Actions",
              selector: null,
              key: null,
              render: ({ row }) => {
                return (
                  <>
                    <VerticalMenu isBackground>
                      <div className="flex items-center justify-start gap-2">
                        <button
                          onClick={() => {
                            // console.log(row);
                            setCurrentLearner(row);
                            setOpenModal(!openModal);
                          }}
                          className="block w-full border border-slate-100 px-4 py-2 text-left bg-white duration-200 text-baseFont text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                        >
                          View Courses
                        </button>
                      </div>
                    </VerticalMenu>
                  </>
                );
              },
            },
          ]
        : [],
    [selectedPath]
  );

  let courseListColumns = useMemo(
    () =>
      selectedCourse?.modules
        ? [
            {
              title: "ID",
              selector: null,
              key: "id",
              render: ({ index }) => index + 1,
            },
            {
              title: "Name",
              selector: "name",
              key: "name",
            },
            {
              title: "Email",
              selector: "email",
              key: "email",
            },
            ...selectedCourse?.modules?.map((module, index) => {
              return {
                title: module?.module?.name,
                selector: "modules",
                key: module?.module?.id,
                render: ({ item }) => {
                  return (
                    <div className="capitalize flex items-center justify-start gap-1">
                      <span>
                        {" "}
                        {item[index]?.status === "completed" ? (
                          <CompletedState />
                        ) : item[index]?.status === "not started" ? (
                          <SuspendedState />
                        ) : (
                          <PendingState />
                        )}
                      </span>
                      {item[index]?.status}
                    </div>
                  );
                },
              };
            }),
            {
              title: "Actions",
              selector: null,
              key: null,
              render: ({ row }) => {
                return (
                  <>
                    <VerticalMenu isBackground>
                      <div className="flex items-center justify-start gap-2">
                        <button
                          onClick={() => {
                            // console.log(row);
                            setCurrentLearner(row);
                            setOpenModal(!openModal);
                          }}
                          className="block w-full border border-slate-100 px-4 py-2 text-left bg-white duration-200 text-baseFont text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                        >
                          View Courses
                        </button>
                      </div>
                    </VerticalMenu>
                  </>
                );
              },
            },
          ]
        : [],
    [selectedCourse]
  );

  // console.log(learningPathColumns, "columns");
  // console.log(selectedPath, "columns");

  let courseColumns = useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index }) => index + 1,
      },
      {
        title: "Name",
        selector: "name",
        key: "name",
      },
      {
        title: "Email",
        selector: "email",
        key: "email",
      },
      {
        title: "Total Modules & Assessments",
        selector: "numberOfModulesAndAssessments",
        key: "numberOfModulesAndAssessments",
      },
      {
        title: "Completed Modules & Assessments",
        selector: "numberOfCompletedModulesAndAssessments",
        key: "numberOfCompletedModulesAndAssessments",
      },
      {
        title: "Status",
        selector: "status",
        key: "status",
        render: ({ item }) => (
          <div className="capitalize flex items-center justify-start gap-1">
            <span>
              {" "}
              {item === "completed" ? (
                <CompletedState />
              ) : item === "not started" ? (
                <SuspendedState />
              ) : (
                <PendingState />
              )}
            </span>
            {item}
          </div>
        ),
      },
      {
        title: "Actions",
        selector: null,
        key: null,
        render: ({ row }) => {
          return (
            <>
              <VerticalMenu isBackground>
                <div className="flex items-center justify-start gap-2">
                  <button
                    onClick={() => {
                      // console.log(row);
                      setCurrentLearner(row);
                      setOpenModal(!openModal);
                    }}
                    className="block w-full border border-slate-100 px-4 py-2 text-left bg-white duration-200 text-baseFont text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                  >
                    View Modules
                  </button>
                </div>
              </VerticalMenu>
            </>
          );
        },
      },
    ],
    []
  );

  const data = {
    profile: {
      data: profiledLearners?.data,
      meta: profiledLearners?.meta,
      isLoading: loadingProfiledLearners,
      columns: profileColumns,
    },
    cohort: {
      data: cohortLearners?.data,
      meta: cohortLearners?.meta,
      isLoading: loadingCohortLearners,
      columns: cohortColumns,
    },
    path: {
      data: pathLearnersList?.data,
      meta: pathLearnersList?.meta,
      isLoading: loadingPathLearnersList,
      columns: learningPathColumns,
    },
    course: {
      data: courseLearnersList?.data,
      meta: courseLearnersList?.meta,
      isLoading: loadingCourseLearnersList,
      columns: courseListColumns,
    },
  };

  // const downloadData = {
  //   profile: {
  //     data: profiledLearnersDownload?.data?.map((data) => {
  //       return {
  //         name: data?.user?.name,
  //         lastLogin: data?.lastLogin,
  //         email: data?.user?.email,
  //         phoneNumber: data?.user?.phoneNumber,
  //         createdAt: convertDate(data?.user?.createdAt),
  //         emailVerifiedAt: convertDate(data?.user?.emailVerifiedAt),
  //         gender: data?.user?.gender,
  //         organization: data?.user?.organization?.name,
  //       };
  //     }),
  //     refetch: refetchProfiledLearnersDownload,
  //   },
  //   cohort: profiledLearnersDownload?.data,
  //   path: profiledLearnersDownload?.data,
  //   course: profiledLearnersDownload?.data,
  // };

  return {
    pageType,
    setPageType,
    data,
    filters,
    selectedCohort,
    handleCohort,
    selectedCourse,
    handleCourse,
    selectedPath,
    handleLearningPath,
    pathSummary,
    courseSummary,
    profiledSummary,
    cohortSummary,
    currentPage,
    setCurrentPage,
    perPage,
    setPerPage,
    handleStatusFilter,
    cohortAnalyticSummary,
    pathAnalyticSummary,
    courseAnalyticSummary,
    cohortAnalyticCompletion,
    loadingCohortAnalyticCompletion,
    pathAnalyticCompletion,
    loadingPathAnalyticCompletion,
    courseAnalyticCompletion,
    loadingCourseAnalyticCompletion,
    cohortAnalyticList,
    topLearners,
    bottomLearners,
    completionListStatus,
    loadingTopLearners,
    loadingBottomLearners,
    search,
    setSearch,
    // downloadData,
    // refetchProfiledLearnersDownload,
  };
};

export default useLearners;
