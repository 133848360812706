import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
      display: false
    },
    title: {
      display: true,
      text: 'Learners',
    },
  },
};

// Generating dummy data
const generateData = () => {
  const data = [];
  for (let i = 0; i < 7; i++) { // 7 is the number of labels (months)
    data.push(Math.floor(Math.random() * 1000)); // Adjust 1000 to your desired maximum value
  }
  return data;
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: 'Dataset 2',
      data: generateData(),
      borderColor: 'rgba(53, 162, 235,0.5)',
      backgroundColor: 'rgba(53, 162, 235, 0.1)',
    },
  ],
};

const EnrolmentRateSection = () => {
  return (
    <Line options={options} data={data} />
  );
};

export default EnrolmentRateSection;
