import React from 'react'
import DataCard from './DataCard'

const DataChart = ({chartData}) => {

  const data = { label: [1,2,3,4,5,6,7,8,9,10,11,12] }
     
  return (
    <div className='w-full mt-3 overflow-x-auto  pb-4'>
      <p className='text-xs font-medium mb-3'> 
         This metrics tracks the number of learners in a specific cohort or cohort who completes a
         course each week. it helps you understand how learners progress over time.
      </p>
        <section className='flex'>
            <div className='border border-gray-300 pt-3 px-2'>
                <h5 className='text-xs pb-2'> Courses</h5>
                <ul>
                   {chartData?.map( data => {
                     return <li className='whitespace-nowrap border-t text-[11px] font-medium py-3 border-gray-300'>
                         {data?.course}
                      </li>
                   } )}
                </ul>
            </div>
            <div className='border border-gray-300 pt-3 px-2'>
                <h5 className='text-xs pb-2 whitespace-nowrap'>No: Completion </h5>
                <ul>
                   {chartData?.map( (data, index) => {
                     return (<li key={index} className='whitespace-nowrap text-center border-t text-[11px] font-medium py-3 border-gray-300'>
                         {data?.numberOfCompletion}
                      </li>)
                   } )}
                </ul>
            </div>
            <div className='border border-gray-300 pt-3 px-2'>
                <h5 className='text-xs pb-2 whitespace-nowrap'>% Completion </h5>
                <ul>
                   {chartData?.map( (data, index) => {
                     return (<li key={index} className='whitespace-nowrap text-center border-t text-[11px] font-medium py-3 border-gray-300'>
                         {data?.percentageCompletion || 0}%
                      </li>)
                   } )}
                </ul>
            </div>
            <div>
               <header className='flex pt-3 items-center border-t border-gray-200'>
                  {data.label.map( label => {
                     return <span className='text-[11px] font-medium text-center mr-1 w-[62px]'>
                        {`Week ${label}`}
                     </span>
                  } )}
               </header>
               <section className='mt-3'>
                  {chartData?.map( data => {
                     return <div className='flex items-center justify-between'>
                           <div className='flex w-[calc(100%-170px)]'>
                              { data?.weeks?.length ?
                                data?.weeks?.map( data => {
                                 return <DataCard value={data?.count} />
                              }) : <p className='text-xs px-2 py-3 bg-gray-200'> There is no any completion for this course </p>
                           }
                           </div>
                     </div>
                  })}
               </section>
            </div>
        </section>
    </div>
  )
}

export default DataChart