import axios from "axios";
import baseUrl from "../url";

const client = axios.create({
  baseURL: baseUrl,
  headers: {
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

client.interceptors.request.use(
  async (config) => {
    const token = sessionStorage.getItem("userToken");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401 && error.config.url !== "/") {
      // Clear session storage token and user
      sessionStorage.removeItem("userToken");
      sessionStorage.removeItem("user");
      // Redirect to login page
      window.location.replace("/");
    }

    return Promise.reject(error);
  }
);

export default client;

export const api = {
  // LOGIN
  login: (payload) => client.post("auth/login",{
    ...payload, accountType: 'admin'
  }).then(({ data }) => data),
  //REGISTER
  register: (payload) => client.post("auth/register", payload).then(({ data }) => data),

  resendEmailVerification: (payload) => client.post("auth/resend-email-verification", payload).then(({ data }) => data),

  verifyEmail: (payload) => client.post("auth/email-verification", payload).then(({ data }) => data),

  forgotPassword: (payload) => client.post("auth/forgot-password", payload).then(({ data }) => data),

  resetPassword: (payload) => client.post("auth/reset-password", payload).then(({ data }) => data),
  
}; 