import React, {useState, useEffect} from 'react'
import { useQuery } from 'react-query';
import { api } from '../../../../../../../api/courses';
import Search from '../../../../../../../components/Inputs/Search';
import Pagination from '../../../../../../../components/pagination/Pagination';
import toast from 'react-hot-toast';
import Loader from '../../../../../../../components/loader/Loader';

const Modules = ({selectedModules, setSelectedModules}) => {
    
  const [perPage, setPerPage] = useState(6);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const { data: modules, refetch, isLoading, } = useQuery(
        ["Modules", { currentPage, search, perPage }],
        ({queryKey}) => {
          return api.getModules({...queryKey[1]});
        },
        {
          cacheTime: 10000,
          staleTime: 10000,
          refetchOnWindowFocus: true,
          select: (data) => data,
        }
    );
     
    useEffect( () => {
      refetch();
    }, [search, perPage, currentPage]);

  const selectModule = module_ => {
    if(selectedModules?.length){
        const moduleExist = selectedModules.findIndex( module => {
           return module?.id === module_.id;
        })
        if(moduleExist === -1 ){
           setSelectedModules([...selectedModules, module_]);
        }
        else setSelectedModules( selectedModules.filter( module => module?.id !== module_?.id ) )
    }
    else setSelectedModules([...selectedModules, module_]);
  }; 

  if(isLoading) return <Loader />
  
  return (
    <div className='mt-5'>  
        <Search 
           className={'mb-0 w-[300px]'}
           placeholder={'Search modules'}
           onChange={ (event) => setSearch(event.target.value) }
       />
        <section className='grid mt-5 grid-cols-3 gap-5'>
          {
            modules?.data?.length ? 
             modules?.data?.map( module => {
                const moduleExist = selectedModules.findIndex( module_ => {
                    return module?.id === module_.id
                 })
                return(
                    <button key={module?.id} className={`shadow pb-4 py-1 px-3 ${ moduleExist === -1 ? '' : 'border-primary'} border hover:border-primary rounded-lg transform transition-transform duration-300 hover:scale-110`}>
                         <span className='flex justify-end w-full'>
                             <span 
                                 className={`h-[18px] w-[18px] border flex items-center justify-center ${ 
                                    moduleExist === -1 ? 'border-gray-800' : 'border-primary'
                                  } p-1 rounded-full`}
                                 onClick={ () => selectModule(module) }
                              >
                                <span className={`${moduleExist !== -1 ? 'bg-primary' : ''} h-[10px] block w-[10px] rounded-full`} />
                             </span>
                         </span>
                        <span className='text-xs'>{module?.name}</span>
                    </button>
                )
             } )
            : null
          }
        </section>
        <Pagination
            data={modules?.meta}
            perPage={perPage}
            pageNo={currentPage}
            setPageNo={setCurrentPage}
            setPerPage={setPerPage}
        />
    </div>
  )
}

export default Modules