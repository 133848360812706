import React, { useEffect, useState } from 'react'
import Button from '../../../../../../../components/Buttons/Button'
import { useQuery } from 'react-query';
import { api } from '../../../../../../../api/teams';
import { useParams } from 'react-router-dom';
import Search from '../../../../../../../components/Inputs/Search';
import Loader from '../../../../../../../components/loader/Loader';
import Pagination from '../../../../../../../components/pagination/Pagination';
import EmptyData from '../../../../../../../components/EmptyData';
import LearnersTable from './LearnersTable';

const ViewLearners = () => {

    const [perPage, setPerPage] = useState(15);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const orgId = JSON.parse(sessionStorage.getItem('user'))?.org?.id
    const {id, name} = useParams()

    const {
      data: learners,
      isLoading,
      refetch,
    } = useQuery(
      ["view teams learners"],
      ({ queryKey }) => {
        return api.getTeamsLearners({ ...queryKey[1], teamId: id,  perPage, currentPage, search, orgId });
      },
      {
        cacheTime: 100,
        staleTime: 100,
        refetchOnWindowFocus: true,
        refetchOnMount:false,
        select: (data) => data,
      })

    useEffect( () => {
        refetch()
    }, [ currentPage, perPage, search, isLoading, setSearch ] )

  return (
    <div>
        <header className='flex justify-between'>
             <h2>Team Overview</h2>
             <Button 
               renderAs='link' 
               to={-1}
               text={'Back'}
               width={'w-[130px]'}
               bgColor={'bg-customBlue'}
             />
        </header>  
        <section className='flex bg-white pt-3 pb-1 mt-5 px-3 justify-between'>
             <h5 className='text-sm pt-3'>
                 {name} ({learners?.meta?.total || 0}) 
             </h5>
             <Search 
                   className={'mb-0'} placeholder={'Search Learners...'}
                   onChange={ event => setSearch(event.target.value) }
                   width={'w-[400px]'}
                />
        </section>
        <section className='mt-5 bg-white p-3'>
            {
              isLoading ? <Loader /> :
              learners?.data?.length ?  
               <LearnersTable 
                  isLoading={isLoading}
                  perPage={perPage}
                  learners={learners}
                /> 
                :
              <EmptyData text={'There is no any learner available in this team yet.'} />     
            }
        </section>
        <section className='bg-white mt-2 px-2'>
          <Pagination 
              data={learners?.meta}
              perPage={perPage}
              pageNo={currentPage}
              setPageNo={setCurrentPage}
              setPerPage={setPerPage}
           />
        </section>
    </div>
  )
}

export default ViewLearners