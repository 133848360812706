import React from 'react'
import StarRating from './StarRating'
import OpenEndedComment from './OpenEndedComment';
import SatisfactionRating from './SatisfactionRating';
import EmojiReaction from './EmojiReaction';
import YesOrNo from './YesOrNo';
import MultipleChoice from './MultipleChoice';
 
 const AnswerTypeRenderer = ({ 
      type, questionTypes, id, deleteEmoji, setQuestions,
      updateQuestionType, question, questions
  }) => {

    switch(type){
       case questionTypes[0] : 
          return <StarRating />
        break;
        case questionTypes[1] : 
          return <OpenEndedComment />
        break;
        case questionTypes[2] :
          return <SatisfactionRating />
        break;
        case questionTypes[3] :
          return <EmojiReaction 
                   id={id} updateQuestionType={updateQuestionType}
                   question={question} deleteEmoji={deleteEmoji}
                 />
        break;
        case questionTypes[4] : 
          return <YesOrNo />
        break;
        case questionTypes[5] : 
          return <MultipleChoice 
                    id={id} questions={questions} question={question}
                    setQuestions={setQuestions}
                 />
        break;
        default: 
          return <StarRating /> 
    }
 }
 
 export default AnswerTypeRenderer