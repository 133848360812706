import React from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from '../../../../../../../../../../components/Buttons/Button';
import { api } from '../../../../../../../../../../api/billing';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';

const CreditTopUp = ({ userId, orgId }) => {
    
   const creditTopUpMutation = useMutation(api.topUpCredit, {
      onSuccess: (response) => {
        toast.remove();
        toast.success(response?.message);
      },
      onMutate: () => {
        toast.loading("Loading...");
      },
      onError: ({ response }) => {
        toast.remove();
        toast.error(response.data?.message);
      },
    });

  const initialValues = {
    credit: "",
    cost: "",
    type: "offline",
  };

  const validate = (values) => {
   const errors = {}
   if(!values?.credit){
     errors.credit = 'Error! top up credit is required.'
   }
   else if(!values?.cost){
      errors.cost = 'Error! top up cost is required.'
    }
   return errors;
 };

 const topUpCredit = (values, actions) => {
   // Handle submission
   const payload = {
     id: orgId,
     data: {
         createdBy: userId, // userId
         credit: values?.credit,
         amount: values?.cost,
         type: "offline",
     }
 }
   creditTopUpMutation.mutate(payload)
   actions.setSubmitting(false);
 };

  return (
    <div className='bg-white w-[500px] mx-auto mt-2 rounded-lg p-5'>
        <Formik
          initialValues={initialValues}
          validate={validate}
          enableReinitialize={true}
          onSubmit={topUpCredit}
        >
          { () => (
            <Form>
                <h3 className='text-md font-medium'>
                    Credit Top Up
                </h3>
                <p className='text-xs text-gray-700 mt-1 pb-2 border-b border-gray-300'>
                   Client Credit Top Up
                </p>
               <div className='mt-2'>
                  <label htmlFor="course-enrolled" className='text-xs'>
                     Credit
                  </label>
                  <Field name='credit' className='input-md' />
                  <p className='text-xs text-red-500 mt-2'>
                     <ErrorMessage name='credit' />
                  </p>
               </div>
               <div className='mt-3 mb-5'>
                  <label htmlFor="course-enrolled" className='text-xs'>
                     Cost (USD)
                  </label>
                  <Field name='cost' className='input-md' />
                  <p className='text-xs text-red-500 mt-2'>
                     <ErrorMessage name='cost' />
                  </p>
               </div>
               <Button 
                  bgColor={'bg-darkBlue'} width={'w-[60%] mx-auto'}
                  text={'Top Up Credit'}
                  btn_type='submit'
                />
            </Form>
         )}
      </Formik>
    </div>
  )
}

export default CreditTopUp