import React from 'react'
import people from '../../../../../../../../../assets/Add Group.png'
import Button from './../../../../../../../../../components/Buttons/Button';

const StarterPage = ({
   title ='Get started by creating a customized branded login template.',
   navigation = '/admin/clients/create-login-template',
   btnText='Create Login Brand'
}) => {

  return (
    <div className='flex justify-center'>
        <section className='mt-5'>
              <img src={people} alt="people icon" className='w-[190px] block mx-auto h-[180px]' />
               <p className='text-sm  pt-5'>
                  {title} 
               </p>
              <div className='flex justify-center pt-3'>
                  <Button 
                      text={btnText}
                      renderAs='link'
                      to={navigation}
                  />
              </div>
        </section>
    </div>
  )
}

export default StarterPage