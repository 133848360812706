import React, { useState } from "react";
import CenteredModal from "../../../../../components/Modal/CenteredModal";
import { api } from "../../../../../api/learningPath";
import { useQuery, useMutation } from "react-query";
import "./addcourse.css";
import Button from "../../../../../components/Buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import Loader from "../../../../../components/loader/Loader";

const AddCourse = ({
  addCourseModal,
  handleAddCourseModal,
  selectedCourses,
  setSelectedCourses,
}) => {
  const [search, setSearch] = useState("");

  const {
    data: courses,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ["Org Courses", { search }],
    ({ queryKey }) => {
      return api.getCourses(queryKey[1]);
    },
    {
      cacheTime: 300,
      staleTime: 300,
      select: (data) => data.data,
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  );

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const path = sessionStorage.getItem("active-path")
  const current_path = path !== "undefined" ? JSON.parse( path) : null;

  const user = JSON.parse(sessionStorage.getItem("user"));

  //   console.log(current_path);

  //   console.log(courses);

  //   console.log(selectedCourses);

  const addCourseMutation = useMutation(api.addCourses, {
    onSuccess: (data) => {
      toast.remove();
      setTimeout(() => {
        handleAddCourseModal();
      }, 100);
      toast.success(data?.message);
    },
    onError: ({ response }) => {
      toast.remove();
      toast.error(response?.data?.message);
    },
  });

  const handleAddtoPath = () => {
    if (selectedCourses.length < 1) {
      return toast.error("Please select courses you wish to add...");
    }
    toast.loading("Processing...");

    addCourseMutation.mutate({
      payload: {
        newCourseIds: selectedCourses.map((course, index) => {
          return course?.id
        }),
      },
      org_id: user?.org?.id,
      journey_id: current_path?.id,
    });
  };

  const handleDeselect = () => {
    setSelectedCourses([]);
  };

  const handleClick = (selected_course) => {
      setSelectedCourses((prev) => {
        // Check if selected_course is already in selectedCourses
        const isSelected = selectedCourses?.findIndex( course => course?.id === selected_course?.id )
        if (isSelected !== -1) {
          // If it's already selected, remove it
          return prev.filter((course) => course?.id !== selected_course?.id);
        } else {
          // If it's not selected, add it
          return [...prev, selected_course];
        }
      });
  };

  return (
    <CenteredModal
      open={addCourseModal}
      setOpen={handleAddCourseModal}
      hasHeader={false}
      width={"w-[70%]"}
    >    
      <div className="w-full">
        <h1 className="w-full text-left text-lg font-semibold ml-2">
          Add courses to this path 
        </h1>
        <div className="w-full flex my-2 p-2 items-center justify-between">
          <input
            type={"search"}
            onChange={handleSearch}
            className="w-[60%] duration-200 border border-lightGray rounded-md text-xs py-2 pl-2 focus:ring-0 outline-none focus:border-customOrange"
            placeholder="Search..."
          />
        </div>
        <div
          style={{ boxShadow: "0 0 2px 2px #e0e0e0 inset" }}
          className="w-full border border-[#FAFAFA] mt-2 p-3 rounded-md bg-[#FAFAFA]"
        >
          {isLoading ? (
            <div className="w-full h-[500px] overflow-y-scroll p-5 rounded-md flex items-center justify-center gap-5">
              <Loader />
            </div>
          ) : (
            <div className="w-full h-[500px] overflow-y-scroll p-5 rounded-md grid grid-cols-1 md:grid-cols-4 gap-5">
              {courses?.map((course) => (
                <button
                  type="button"
                  key={course?.id}
                  onClick={ () => handleClick(course) }
                  className={`border cursor-pointer ${
                    selectedCourses?.findIndex( course_ => course_?.id === course?.id ) !== -1
                      ? "border-customOrange"
                      : "border-transparent"
                  } hover:border-customOrange hover:p-1 duration-200 rounded-lg h-[200px] relative flex items-end justify-center bg-gray-200 overflow-hidden`}
                >
                  { (selectedCourses?.findIndex( course_ => course_?.id === course?.id ) !== -1) && (
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="absolute text-customOrange text-2xl top-2 right-2"
                    />
                  )}
                  <div className="w-full p-2 bg-white h-[120px] rounded-md truncate flex items-start justify-start flex-col">
                    <p className="text-sm font-semibold text-left text-wrap">
                      {course?.name}
                    </p>
                    <p className="text-xs text-slate-500 text-left text-wrap">
                      {course?.description}
                    </p>
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>
        <div className="w-full flex items-center justify-between my-5">
          <Button
            text={"Deselect All"}
            bgColor={"bg-white"}
            textColor={"text-darkBlue"}
            width={"w-[120px]"}
            onClick={handleDeselect}
          />
          <button
            onClick={handleAddtoPath}
            className="w-[150px] flex items-center justify-center gap-2 bg-darkBlue text-white text-xs rounded-md py-3 px-5"
          >
            Add to Path{""}
            {selectedCourses?.length > 0 && (
              <span className="bg-white text-darkBlue px-2 font-bold text-[9.5px] rounded-lg">
                {selectedCourses?.length}
              </span>
            )}
          </button>
        </div>
      </div>
    </CenteredModal>
  );
};

export default AddCourse;
