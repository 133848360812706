import React, { useState, useRef } from "react";
import { useQuery } from "react-query";
import { api } from "../../../../../../../../api/clients";
import Table from "../../../../../../../../components/table/Table";
import Button from "../../../../../../../../components/Buttons/Button";
import Search from "../../../../../../../../components/Inputs/Search";
import Pagination from "../../../../../../../../components/pagination/Pagination";
import CreateEmail from "./pages/CreateEmail";
import CenteredModal from "../../../../../../../../components/Modal/CenteredModal";

const EmailSetting = () => {
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const user = JSON.parse(sessionStorage.getItem("user"));

  const { data, isLoading, refetch } = useQuery(
    ["paths", { search, perPage, currentPage }],
    ({ queryKey }) => {
      return api.getEmailTemplates({ ...queryKey[1], org_id: user?.org?.id });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  console.log(data);

  const emailEditorRef = useRef(null);

  const exportHtml = () => {
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml((data) => {
      const { design, html } = data;
      console.log("exportHtml", html);
    });
  };

  const onReady = (unlayer) => {
    // editor is ready
    // you can load your template here;
    // the design json can be obtained by calling
    // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)
    // const templateJson = { DESIGN JSON GOES HERE };
    // unlayer.loadDesign(templateJson);
  };

  let columns = React.useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index }) => index + 1,
      },
      {
        title: "Name",
        selector: "name",
        key: "name",
      },
      {
        title: "Subject",
        selector: "subject",
        key: "subject",
      },
      {
        title: "Date Added",
        selector: "dateAdded",
        key: "dateAdded",
        render: ({ item }) => {
          return (
            <span className="">{`${new Date(item).toLocaleDateString(
              "en-ng"
            )}`}</span>
          );
        },
      },
      {
        title: "Date Updated",
        selector: "dateModified",
        key: "dateModified",
        render: ({ item }) => {
          return (
            <span className="">{`${new Date(item).toLocaleDateString(
              "en-ng"
            )}`}</span>
          );
        },
      },
      {
        title: "Action",
        selector: null,
        key: null,
        render: () => {
          return (
            <div className="flex">
              <button className="bi bi-pencil-square hover:cursor-pointer text-blue-500"></button>
              <button
                className="bi bi-trash ml-2 text-red-500 hover:cursor-pointer"
                // onClick={ () => setOpenDeleteModal(true) }
              ></button>
            </div>
          );
        },
      },
    ],
    []
  );

  const tableProps = { isLoading: false, columns, data: data?.data };

  return (
    <React.Fragment>
      <CenteredModal
        title={"Create Template"}
        width={"w-[90%]"}
        open={openModal}
        setOpen={handleOpenModal}
      >
        <CreateEmail handleOpenModal={handleOpenModal} />
      </CenteredModal>
      <div className="w-full">
        <section className="mt-4 flex justify-between">
          <div className=" w-[350px]">
            <Search
              placeholder={"Search..."}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
          <div>
            <Button
              text={"Add New Template"}
              width={"w-[200px]"}
              renderAs="button"
              bgColor={"bg-customBlue"}
              onClick={handleOpenModal}
            />
          </div>
        </section>
        <section className="overflow-x-auto w-full clients-table">
          <Table
            {...tableProps}
            style={{
              overflowX: "auto",
              width:
                window.innerWidth > 500 && window.innerWidth < 1200
                  ? "130%"
                  : "115%",
            }}
            headerClassName={"border-t border-gray-200"}
          />
        </section>
        <section className={`mt-1 px-3 rouded-lg bg-white`}>
          <Pagination
            data={data?.meta}
            perPage={perPage}
            pageNo={currentPage}
            setPageNo={setCurrentPage}
            setPerPage={setPerPage}
          />
        </section>
      </div>
    </React.Fragment>
  );
};

export default EmailSetting;
