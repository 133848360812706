import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import StarterPage from './pages/StarterPage';
import ViewGroups from './pages/ViewGroups';
import { useQuery } from 'react-query';
import { api } from '../../../../../../api/learningPath';
import Loader from '../../../../../../components/loader/Loader';
import CreateCohortStepsWrapper from './pages/createCohort/CreateCohortStepsWrapper';

const LearnersGroupRoutes = () => {

  const [perPage, setPerPage] = useState(16);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const orgId = JSON.parse(sessionStorage.getItem('user'))?.org?.id
  const { data: cohorts, isLoading, refetch } = useQuery(
    ["cohort", { id: orgId, search, perPage, currentPage }],
    ({ queryKey }) => {
      return api.getCohort(queryKey[1]);
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  useEffect( () => {
    refetch()
  }, [search, setSearch, perPage, currentPage] )

  return (
    <Routes>
      <Route path="/" element={
        isLoading ? <Loader /> :
          cohorts?.data?.length ? <Navigate to={'/admin/learning-path/learners-group/view'} /> 
          : <StarterPage />  
      } />
      <Route path="/create" element={<CreateCohortStepsWrapper />} />
      <Route
            path="/view" 
            element={ 
              <ViewGroups 
                isLoading={isLoading}
                search={search}
                setSearch={setSearch}
                perPage={perPage}
                setPerPage={setPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                cohorts={cohorts}
                refetch={refetch}
             />} 
      />
    </Routes>
  )
}

export default LearnersGroupRoutes