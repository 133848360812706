import React from 'react'
import Button from '../../../../../../../../../components/Buttons/Button'
import Editor from '../../../../../../../../../components/Editor'
import { useMutation } from 'react-query'
import toast from 'react-hot-toast'
import { errorResponse } from '../../../../../../../../../components/utils/errorResponse'
import { useNavigate } from 'react-router-dom'
import { api } from '../../../../../../../../../api/teams'

const CohortWelcomeMessage = ({ setCurrentStep, content, setContent, cohortId}) => {

  const orgId = JSON.parse(sessionStorage.getItem('user'))?.org?.id
  const navigate = useNavigate()

  const addInvitationMutation = useMutation(api.addWelcomePage, {
    onSuccess: () => {
        toast.remove()
        toast.success('Cohort Welcome Page Added Successfully!')
        navigate('/admin/learning-path/learners-group/view')
    },
    onError: error => errorResponse(error),
    onMutate: () => {
        toast.loading('Loading...')
    }
  })

  const addWelcomePage = () => {
    if(!content) toast.error('Error! Add Welcome Page Template')
      else addInvitationMutation.mutate({
        cohortId, orgId, payload: {
          messageText: content
        }
     })
  }

  return (
    <>
       <div className={`w-[90%]] mx-auto shadow-2xl p-4 rounded-lg h-[auto] bg-white `}>
              <header className='flex justify-between items-center'>
                   <div>
                      <h1 className='text-md font-semibold mt-1'>
                        Step 4 <span className='bi bi-arrow-right'></span> &nbsp;
                        Cohort Welcome Message
                      </h1>
                      <p className='text-xs mb-2 text-gray-700'>
                        Welcome page to be displayed after learners have been inivted to a cohort.
                      </p>
                   </div>
              </header>
             <div className='border-t border-gray-300'>
                  <Editor 
                    content={content}
                    setContent={setContent}
                  />
                  <div className='flex mt-5 justify-end'>
                      <Button 
                         width={'w-[160px]'} 
                        text={'Add Welcome Page'}
                        bgColor={'bg-customBlue'}
                        onClick={  addWelcomePage }
                      />  
                  </div>    
             </div>
         </div>
    </>
  )
}

export default CohortWelcomeMessage