import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import Settings from './Settings'
import Feedback from './pages/feedback/Feedback'
import ZoomApi from '../zoom-integration/pages/ZoomApi'

const SettingsRoutes = () => {
  return (
    <div>
        <Routes>
          <Route path='/' element={ <Settings /> } />
          <Route path='/feedback/*' element={<Feedback />} />
          <Route path="/zoom/api" element={<ZoomApi />} />
      </Routes>
    </div>
  )
}

export default SettingsRoutes

 