import React, { useState } from "react";

const EmailSettingStep1 = ({ nextStep }) => {
  const [useCustomEmail, setUseCustomEmail] = useState(false);
  const toggleCustomEmail = () => {
    setUseCustomEmail(!useCustomEmail);
  };

  const handleNext = (values) => {
    nextStep(values);
  };
  return (
    <div className="grid place-content-center w-full h-full">
      <div className="flex items-center space-x-3 bg-gray-100 p-10 shadow-lg rounded-md">
        <button
          onClick={toggleCustomEmail}
          className={`w-10 h-6 flex items-center rounded-full p-1 transition-colors duration-300 ${
            useCustomEmail ? "bg-red-500" : "bg-gray-300"
          }`}
        >
          <div
            className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform duration-300 ${
              useCustomEmail ? "translate-x-4" : ""
            }`}
          ></div>
        </button>

        <div>
          <h3 className="text-base font-medium text-gray-900">
            Use Custom Email
          </h3>
          <p className="text-sm text-gray-500">
            Send emails from{" "}
            <span className="font-semibold">example@schoolhouse.com</span>
          </p>
        </div>
      </div>
      {useCustomEmail && (
        <button
          type="submit"
          onClick={handleNext}
          className={`mt-3 font-semibold 
                        bg-customBlue
                     text-white py-3 px-8 rounded-lg text-xs`}
        >
          Next
        </button>
      )}
    </div>
  );
};

export default EmailSettingStep1;
