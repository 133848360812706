import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { Link, useNavigate} from "react-router-dom";

import Input from "../../../../Auth/components/Input";
import { api } from "../../../../../api/zoom";
import toast from "react-hot-toast";

const ZoomApi = () => {
  const navigate = useNavigate();
  const orgId = JSON.parse(window.sessionStorage.getItem("user"))?.org?.id;

  const [buttonText, setButtonText] = useState("Save Changes");

  const validationSchema = Yup.object().shape({
    accountID: Yup.string().required("Account ID is required"),
    clientID: Yup.string().required("Client ID is required"),
    clientSecret: Yup.string().required("Client Secret is required"),
  });

  const initialValues = {
    accountID: "",
    clientID: "",
    clientSecret: "",
  };

  const createZoomSettingMutation = useMutation(api.createZoomSetting, {
    onSuccess: (response) => {
      toast.remove();
      toast.success(response.message);
      setButtonText("Saved"); // Change button text to "Saved"
    },
    onMutate: () => {
      toast.loading("Loading...");
      setButtonText("Saving..."); // Change button text to "Saving..."
    },
    onError: ({ response }) => {
      toast.remove();
      toast.error(response.data?.message);
      setButtonText("Save Changes"); // Revert button text to "Save Changes"
    },
  });

  const handleCreateZoomSetting = (values, actions) => {
    createZoomSettingMutation.mutate(
      {
        orgId,
        payload: {
          accountId: values.accountID,
          clientId: values.clientID,
          clientSecret: values.clientSecret,
          isEnabled: true,
        },
      },
      {
        onSuccess: () => {
          actions.resetForm(); // Reset the form after successful mutation
          actions.setSubmitting(false);
          setButtonText("Save Changes"); // Ensure form is no longer submitting
        },
      }
    );
  };

  const handleTestConnection = async (values, actions) => {
    toast.loading("Loading...");
    let payload = {
      accountId: values.accountID,
      clientId: values.clientID,
      clientSecret: values.clientSecret,
    };
   
    try {
      const response = await api.testConnection({ orgId, payload });
      toast.remove();
      toast.success(response.message);
      navigate("/admin/zoom/meetings")
    } catch (error) {
      toast.remove();
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div className="p-10">
    <div className="flex justify-between items-center">
    <div className="mb-6">
        <h1 className="text-[31px] font-bold text-[#1D1F2C]">Zoom API</h1>
        <p className="font-medium text-base text-[#777980]">
          Use roles to group your users and assign permissions
        </p>
      </div>
    
      <div className="flex justify-end">
          <button
            type="button"
            onClick={() => {
              navigate("/admin/zoom/create-meetings");            
            }}
            className="p-3 bg-customBlue text-white text-xs rounded-md"
          >
            Create new meeting
          </button>
        </div>
    </div>

      
      <div className="w-[400px]">
        <h3 className="text-lg font-semibold text-[#091F46]">
          Set up your Zoom Integration
        </h3>
        <p className="font-medium text-[#4A4C56] text-base">
          Visit your Zoom account and fetch the API key to connect Zoom.{" "}
          <Link
            to="https://developers.zoom.us/docs/internal-apps/create/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="text-[#007AFF]">Go to Zoom website</span>
          </Link>
        </p>
      </div>
      <div className="">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) =>
            handleCreateZoomSetting(values, actions)
          }
        >
          {({ isSubmitting, values, actions }) => (
            <Form>
              <div className="w-[400px]">
                <Input
                  label={"Account ID"}
                  name={"accountID"}
                  type="text"
                  colorScheme={"orange"}
                />
                <Input
                  label={"Client ID"}
                  name={"clientID"}
                  type="text"
                  colorScheme={"orange"}
                />
                <Input
                  label={"Client Secret"}
                  name={"clientSecret"}
                  type="text"
                  colorScheme={"orange"}
                />
              </div>
              <div className="mt-5 flex gap-x-96 items-center">
                <button
                  type="button" // Use "button" type to avoid triggering form submission
                  onClick={() => handleTestConnection(values, actions)} // Pass values to handleTestConnection
                  className={`w-fit font-semibold bg-primary text-white p-3 rounded-lg text-xs`}
                >
                  Check Connection
                </button>
                <button
                  type="submit"
                  className={`w-fit font-semibold bg-customBlue text-white p-3 rounded-lg text-xs`}
                  disabled={isSubmitting} // Disable the button while submitting
                >
                  {isSubmitting ? "Saving..." : buttonText}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ZoomApi;
