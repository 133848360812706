import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import ViewClients from './pages/ViewClients/ViewClients'
import ViewClientDetails from './pages/ViewClientDetails/ViewClientDetails'
import CreateLoginTemplate from './pages/ViewClientDetails/pages/CreateLoginTemplate/CreateLoginTemplate'
import CreateLandingPageTemplate from './pages/ViewClientDetails/pages/CreateLandingPageTemplate/CreateLandingPageTemplate'

const Client = () => {
  return (
    <section>
      <Routes>
          <Route path='/' element={ <Navigate to={'/admin/clients/view'} /> } />
          <Route path='/view' element={<ViewClients />} />
          <Route path='/view-client/:id/*' element={<ViewClientDetails />} />
          <Route path='/create-login-template' element={<CreateLoginTemplate />} />
          <Route path='/create-landing-page-template' element={<CreateLandingPageTemplate />} />
      </Routes>
    </section>
  )
}

export default Client