import React from "react";
import { Routes, Route } from "react-router";
import Overview from "./pages/Overview";
import Teams from "./pages/Teams";
import UserRolesRoute from "./pages/userRoles/UserRolesRoute";

const UserManagement = () => {
  return (
    <Routes>
      <Route path="/" element={<Overview />} />
      <Route path="/teams" element={<Teams />} />
      <Route path="/roles/*" element={<UserRolesRoute />} />
    </Routes>
  );
};

export default UserManagement;
