import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../../../Auth/components/Input";
import toast from "react-hot-toast";
import { api } from "../../../../../api/email";

const EmailSettingStep3 = ({ prevStep, formData }) => {
  const navigate = useNavigate();
  const orgId = JSON.parse(window.sessionStorage.getItem("user"))?.org?.id;
  const [buttonText, setButtonText] = useState("Send Test Email");

  const validationSchema = Yup.object().shape({
    toEmail: Yup.string().required("email is required"),
    subject: Yup.string().required("subject is required"),
    emailBody: Yup.string().required("email body is required"),

  });

  const initialValues = {
    toEmail: "",
    subject: "",
    emailBody: "",
   
  };

  const createEmailSettingMutation = useMutation(api.sendTestEmail, {
    onSuccess: (response) => {
      toast.remove();
      toast.success(response.message);
      navigate("/admin/email/template-configuration");
      // setButtonText("Sent"); // Change button text to "Saved"
    },
    onMutate: () => {
      toast.loading("Loading...");
      // setButtonText("Sending..."); // Change button text to "Saving..."
    },
    onError: ({ response }) => {
      toast.remove();
      toast.error(response.data?.message);
      // setButtonText("Send Test Email"); // Revert button text to "Save Changes"
    },
  });

  const handleCreateEmailSetting = (values, actions) => {
    createEmailSettingMutation.mutate(
      {
        orgId,
        payload: {
          toEmail: values.toEmail,
          subject: values.subject,
          emailBody: values.emailBody,
          
        },
      },
      {
        onSuccess: () => {
          actions.resetForm(); // Reset the form after successful mutation
          actions.setSubmitting(false);
          // setButtonText("Save Changes"); // Ensure form is no longer submitting
        },
      }
    );
  };

  return (
    <div className="p-10">
      <div className="flex justify-between mb-6">
        <div className="">
          <h1 className="text-[31px] font-bold text-[#1D1F2C]"> Email </h1>
        </div>
        <button
          type="button"
          onClick={prevStep}
          className={`flex items-center justify-center gap-1  font-semibold 
                       bg-customBlue
                     text-white py-2 px-6 rounded-lg text-sm`}
        >
          <i className="bi bi-arrow-left text-white "></i>
          Back
        </button>
      </div>
      <div className="w-[400px]">
        <h3 className="text-lg font-semibold text-[#091F46]">
          Send Test Email
        </h3>
        <p className="font-medium text-base text-[#777980]">
          Emails will be sent with configuration below:
        </p>
      </div>
      <div className="">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) =>
            handleCreateEmailSetting(values, actions)
          }
        >
          {({ isSubmitting, values, actions }) => (
            <Form>
              <div className="w-2/3">
              <Input
                  label={"Email Address: "}
                  name={"toEmail"}
                  type="email"
                  colorScheme={"orange"}
                />
                <Input
                  label={"Subject: "}
                  name={"subject"}
                  type="text"
                  colorScheme={"orange"}
                />
                <Input
                  label={"Body"}
                  name={"emailBody"}
                  type="textarea"
                  colorScheme={"orange"}
                />                        
              </div>
              <div className="mt-5 flex gap-x-96 items-center">
                <button
                  type="button"
                  onClick={prevStep}
                  className={`flex items-center justify-center gap-1  font-semibold 
                       bg-customBlue
                     text-white py-2 px-6 rounded-lg text-sm`}
                >
                  <i className="bi bi-arrow-left text-white "></i>
                  Back
                </button>
                <button
                  type="submit"
                  className={`w-fit font-semibold bg-customBlue text-white p-3 rounded-lg text-xs`}
                  disabled={isSubmitting} // Disable the button while submitting
                >
                  Send Test Email
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EmailSettingStep3;
