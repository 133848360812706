import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { useParams, useLocation } from "react-router";
import { useQuery } from "react-query";
import { api } from "../../../../../api/reward";
import { BarLoader } from "react-spinners";
import EmptyData from "../../../../../components/EmptyData";

const colors = ["#0e316e", "#bbd8f6", "#ffe9cc", "#f9cfb4", "#ed6f1e"];

const TopFive = ({ sort }) => {
  const user = JSON.parse(sessionStorage.getItem("user"));

  const { id } = useParams();

  const { pathname } = useLocation();

  // console.log(pathname);

  const { data: topRewardFive, isLoading } = useQuery(
    ["Top 5"],
    ({ queryKey }) => {
      return api.getTopBottomFive({ id: user?.org?.id, rewardId: id, sort });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  const { data: topCategoryFive, isLoading: loadingCategoryTop } = useQuery(
    ["Top 5 Category"],
    ({ queryKey }) => {
      return api.getCategoryTop({ id: user?.org?.id, categoryId: id });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
      enabled: Boolean(pathname.includes("category")),
    }
  );

  // console.log(topCategoryFive);

  const topFive = pathname.includes("category")
    ? topCategoryFive
    : topRewardFive;

  const chartdata = topFive?.data?.topFiveRewards?.map?.((item, index) => {
    return {
      id: item?.name,
      label: item?.name,
      value: item?.totalPoints,
      color: colors[index],
    };
  });

  if (isLoading) {
    return (
      <div className="border border-white p-3 bg-white rounded-md">
        <div className="w-full p-2 h-[300px] flex items-center justify-center">
          <BarLoader size={10} color="#FF9100" />
        </div>
      </div>
    );
  }

  return (
    <div className="border border-white p-3 bg-white rounded-md">
      <div className="w-full p-2 h-[300px]">
        {chartdata?.length ? (
          <ResponsivePie
            data={chartdata}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={7}
            colors={{ datum: "data.color" }}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#ccc"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor={{ from: "color" }}
            slicesLabelsSkipAngle={10}
            animate={true}
            arcLabelsTextColor={"#fff"}
            motionStiffness={90}
            motionDamping={15}
            legends={[
              {
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 10,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
        ) : (
          <EmptyData text={"No data to show..."} />
        )}
      </div>
    </div>
  );
};

export default TopFive;
