import React, { useEffect } from "react";
import { Sidenav, initTE } from "tw-elements";
import NavLinks from "./NavLinks";

const Sidebar = () => {
  useEffect(() => {
    initTE({ Sidenav });
  }, []);

  return (
    <>
      <nav
        id="sidenav-1"
        className="absolute md:hidden left-0 top-0 z-[1035] h-full w-60 -translate-x-full overflow-hidden bg-white shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] data-[te-sidenav-hidden='false']:translate-x-0 dark:bg-zinc-800"
        data-te-sidenav-init
        data-te-sidenav-hidden="true"
        data-te-sidenav-position="absolute"
      >
        <div
          className="relative m-nav-links m-0 list-none px-[0.2rem]"
          data-te-sidenav-menu-ref
        >
          <NavLinks />
        </div>
      </nav>
      <button
        className="inline-block md:hidden rounded ml-2 text-xl text-gray font-medium uppercase   focus:outline-none focus:ring-0"
        data-te-sidenav-toggle-ref
        data-te-target="#sidenav-1"
        aria-controls="#sidenav-1"
        aria-haspopup="true"
      >
        <span className="block [&>svg]:h-[35px] [&>svg]:w-[35px] [&>svg]:text-gray-900">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-5 w-5"
          >
            <path
              fill-rule="evenodd"
              d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
      </button>
    </>
  );
};

export default Sidebar;
