import React, {useState} from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import LoginPanel from './components/LoginPanel'
import PreviewTemplate from './components/PreviewTemplate'
import { useMutation } from 'react-query'
import { api } from '../../../../../../../../api/courses'
import { api as clientApi } from '../../../../../../../../api/clients'
import toast from 'react-hot-toast'
import ResourceLoader from '../../../../../courses/pages/CreateCourse/components/ResourceLoader'

const CreateLoginTemplate = () => {

  const [openModal, setOpenModal] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [loginTemplate, setLoginTemplate] = useState({
     title: '',
     headerText: 'Login to your account!',
     brandLogo: '',
     type: 'color',
     brandColor: '#FF9100',
     bodyBackgroundColor: '',
     containerAlignment: 'center',
     bodyBackgroundImage: '',
  })
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orgId = queryParams.get('org-id');
  const navigate = useNavigate()

  const uploadResourceMutation = useMutation(api.createResource, {
    onMutate: () => {
       setIsUploading(true)
    },
    onSuccess: (data) => {
       setIsUploading(false)
       toast.success('File Uploaded successfully!')
       const imageDestination = sessionStorage.getItem('imageDestination')
       setLoginTemplate({...loginTemplate, [imageDestination]: data?.data?.data})
    }
 })  

 const createLoginTemplateMutation = useMutation(clientApi.createLoginTemplate, {
  onMutate: () => {
    toast.loading('Loading...')
  },
  onError: (error) => {
      toast.remove()
      if(error?.response?.message){
        toast.error(error?.response?.data?.message);
      }else toast.error('Error! an unknown error occured.');
  },
  onSuccess: response => {
     toast.remove()
     toast.success('Login template created successfully!')
     navigate(`/admin/clients/view-client/${orgId}/login`)
   } 
})

  const handleLoginTemplateChange = event => {
     setLoginTemplate({...loginTemplate, [event.target.name]: event.target.value})
  }

  const handleImageUpload = event => {
      const file = event.target.files[0];
      if(file){
          sessionStorage.setItem('imageDestination',event.target.name)
          const formData = new FormData()
          formData.append('file', file)
          uploadResourceMutation.mutate(formData)
      }
  }

  const saveLoginTemplate = () => {
      createLoginTemplateMutation.mutate({
        id: orgId,
        data: {
          templateName: '',
          templateTitle: loginTemplate.title,
          headerText: loginTemplate.headerText,
          primaryColor: loginTemplate.brandColor,
          backgroundType: loginTemplate.type,
          backgroundColor: loginTemplate.type === 'color' ? 
            loginTemplate.bodyBackgroundColor : loginTemplate.bodyBackgroundImage,
          brandLogoUrl: loginTemplate.brandLogo,
          containerAlignment: loginTemplate.containerAlignment
        }
      })
  }

  return (
    <section className='bg-gray-100 h-[100vh] p-2 md:px-5'>
         { isUploading && <ResourceLoader />}  
         <PreviewTemplate openModal={openModal} loginTemplate={loginTemplate} setOpenModal={setOpenModal} />
         <header className='flex justify-between items-center mt-4'>
              <div className='flex items-center'>
                 <div>
                  <Link to={-1} className='bi bi-arrow-left bg-gray-200 px-8 py-2 mr-3 rounded-xl -mt-2 text-sm'> 
                     &nbsp;Back 
                  </Link>
               </div>
               <h5 className='text-center pt-1 bi bi-plus-circle font-semibold text-xs uppercase'>
                  &nbsp;Create Login Template
               </h5>
              </div>
              <div>
                  <button 
                         onClick={ () => setOpenModal(true) } 
                         className='text-xs py-3 px-12 mr-4 rounded-lg bg-gray-300'
                  >
                      Preview
                  </button>
                  <button 
                      className='text-xs py-3 px-12 text-white rounded-lg bg-customBlue'
                      onClick={ saveLoginTemplate }
                  >
                      Save Template
                  </button>
              </div>
         </header>
         <div className='flex justify-between mt-3'>
             <div className='w-[40%] p-4 md:py-5 bg-white rounded-xl border border-gray-300'>
                 <h5 className='text-[11px] font-semibold uppercase text-center border-b border-gray-200 pb-2'> 
                   Customize Your Login Template
                 </h5>
                 <div className='mt-3'>
                     <div>
                        <label className='text-xs'>Template Title</label>
                        <input 
                            type="text" placeholder='e.g my custom login template...'
                            className='input-md'
                            onChange={handleLoginTemplateChange}
                            value={loginTemplate.title}
                            name='title'
                        />
                     </div>
                     <div className='mt-3'>
                        <label className='text-xs'> Header Text </label>
                        <input 
                            type="text" placeholder='Login To Your Account'
                            className='input-md'
                            onChange={handleLoginTemplateChange}
                            value={loginTemplate.headerText}
                            name='headerText'
                        />
                     </div>
                     <div className='mt-3'>
                        <label className='text-xs'> Form Alignment </label>
                         <select 
                            name="containerAlignment" 
                            className='input-md'
                            onChange={handleLoginTemplateChange}
                            value={loginTemplate.containerAlignment}
                         >
                            <option value="center">Center</option>
                            <option value="right">Right</option>
                            <option value="left">Left</option>
                         </select>
                     </div>
                     <div className='mt-3'>
                        <label className='text-xs'> Container Background Type </label>
                         <select 
                            name="type" 
                            className='input-md'
                            onChange={handleLoginTemplateChange}
                            value={loginTemplate.type}
                         >
                            <option value="color">Solid Color</option>
                            <option value="image">Image</option>
                         </select>
                     </div>
                     <section className='flex justify-between mt-4'>
                          <label className='w-[60%] block'>
                            <input 
                                 type="file" className='hidden' 
                                 id='brand-logo' accept="image/*"
                                 onChange={handleImageUpload}
                                 name='brandLogo'
                            />
                            <span className='text-xs'> Brand Logo</span>
                            <div className='py-4 text-center rounded-xl border border-gray-200'>
                                <i className='bi bi-image'></i>
                                <span className='block mt-2 text-xs text-gray-700'>
                                    Upload Logo
                                </span>
                            </div>
                          </label>
                          <label className='w-[37%]' htmlFor='color'>
                            <span className='text-xs'> Brand Color</span>
                            <div className='py-4 text-center rounded-xl border border-gray-200'>
                              <div className='h-[50px] w-[50px] mx-auto flex items-center'>
                              <input 
                                      value={loginTemplate.brandColor} 
                                      type="color" id='color'
                                      onChange={handleLoginTemplateChange}
                                      name='brandColor'
                               />                               
                             </div>
                            </div>
                          </label>
                     </section>
                     <section className='flex justify-between mt-4'>
                          <label className='w-[47%]' htmlFor='bg-color'>
                            <span className='text-xs'> Body Background Color</span>
                            <div className='py-4 text-center rounded-xl border border-gray-200'>
                              <div className='h-[50px] w-[50px] mx-auto flex items-center'>
                              <input 
                                    value={loginTemplate.bodyBackgroundColor || '#FFFFFF'}
                                    type="color" id='bg-color' className='' 
                                    name='bodyBackgroundColor'
                                    onChange={handleLoginTemplateChange}
                               />                               
                             </div>
                            </div>
                          </label>
                          <label className='w-[50%] block'>
                            <input 
                               type="file" className='hidden' id='brand-logo' 
                               onChange={handleImageUpload}
                                name='bodyBackgroundImage'
                            />
                            <span className='text-xs'> Background Image </span>
                            <div className='py-4 text-center rounded-xl border border-gray-200'>
                                <i className='bi bi-image'></i>
                                <span className='block mt-2 text-xs text-gray-700'>
                                    Upload Background Image
                                </span>
                            </div>
                          </label>
                     </section>
                 </div>
             </div>
             <div className='w-[58%]'>
                 <LoginPanel 
                   loginTemplate={loginTemplate}
                   setLoginTemplate= {setLoginTemplate}
                   openModal={openModal}
                 />
             </div>
         </div>
     </section>
  )
}

export default CreateLoginTemplate