import React from "react";
import Success from "../icons/success.png";

const SuccessToast = ({ handleToast, message, visible }) => {
  return (
    <div
      className={`fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 p-6 flex justify-center items-center transition-opacity ${
        visible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        className="bg-transparent flex flex-col items-center justify-center p-7 gap-2 rounded-lg w-full mx-auto bg-white transition-transform transform scale-100 hover:scale-105"
        style={{ maxWidth: "500px" }}
      >
        <div className="w-full p-2 flex items-center justify-center">
          <img src={Success} alt="Success" className="w-40 h-40" />
        </div>
        <h1 className="text-center text-slate-700 font-normal text-2xl">
          Success!
        </h1>
        <div className="w-full flex items-center justify-center gap-2 text-slate-600">
          <p>{message}</p>
        </div>
        <button
          className="w-full mt-4 rounded-md text-baseFont bg-primary text-white py-2 transition-colors duration-300"
          onClick={handleToast}
        >
          Dismiss
        </button>
      </div>
    </div>
  );
};

export default SuccessToast;
