import React, {useState, useEffect} from 'react'
import Card from '../../../../../../client/pages/components/Card'
import { Routes, Route, Link, useParams } from 'react-router-dom'
import CreditIcon from '../../../../../../../../../assets/Bagde (5).png'
import ProjectIcon from '../../../../../../../../../assets/Badge (1).png'
import TotalProjectIcon from '../../../../../../../../../assets/Badge.png'
import AsideItem from '../../../../../../client/pages/components/AsideItem'
import Batch1 from '../../../../../../../../../assets/Bagde (1).png'
import logo from '../../../../../../../../../assets/Frame 287.png'
import BagdeIcon from '../../../../../../../../../assets/Bagde.png'
import Telephone from '../../../../../../../../../assets/Bagde (3).png'
import Envelope from '../../../../../../../../../assets/Bagde (2).png'
import Calender from '../../../../../../../../../assets/Bagde (4).png'
import Button from '../../../../../../../../../components/Buttons/Button'
import Invoices from './pages/Invoices'
import CreditRefund from './pages/CreditRefund'
import CreditTopUp from './pages/CreditTopUp'
import { api } from '../../../../../../../../../api/clients'
import { api as billingApi } from '../../../../../../../../../api/billing'
import { useQuery } from 'react-query'
import TransactionHistory from './pages/transactionHistory/TransactionHistory'
import GenerateReport from './pages/components/GenerateReport'
import { formatCurrency } from '../../../summary/Summary'

const ViewClientDetails = () => {

  const params = useParams()
  const currentYear = new Date().getFullYear()
  const [year, setYear] = useState(currentYear)

  const { data: client, isLoading } = useQuery(
    ["get client details", { id: params?.id, type: 'profile' }],
    ({ queryKey }) => {
        return api.getClientSummary(queryKey[1]);
    },
    {
      select: data => data?.data,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    } 
);

const {
   data: invoices,
   InvoicesLoading,
   refetch,
 } = useQuery(
   ["client invoices"],
   ({ queryKey }) => {
     return billingApi.getClientInvoices({ ...queryKey[1], org_id: params?.id, year });
   },
   {
     cacheTime: 100,
     staleTime: 100,
     refetchOnWindowFocus: false,
     refetchOnReconnect: false,
     select: (data) => data?.data,
   }
 );

 const {
  data: summary,
  summaryLoading,
} = useQuery(
  ["client Summary"],
  () => {
    return billingApi.getClientSummary(params?.id);
  },
  {
    cacheTime: 100,
    staleTime: 100,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    select: (data) => data?.data,
  }
);

 useEffect( () => {
   refetch()
 }, [year, InvoicesLoading])
  
  return (
     <section className='bg-lightBlue h-[100vh] p-2'>   
          <h3 className='text-lg mt-2 font-semibold flex items-center justify-between text-gray-500'> 
            Client Billing Information
            <div className='flex'>
                <Button bgColor={'bg-primary'} width={'w-[150px]'} text={'Send Reminder'} />
                <GenerateReport
                   id={client?.organization?.id}
                />
            </div>
           </h3>
          <section className='flex mt-5 justify-between bg-lightBlue'>
            <aside className='w-[30%] bg-white rounded-xl p-3'>
                 <img src={logo} alt="logo" className='w-[200px] h-[70px] block mx-auto' />
                 <hr />
                  <div className='mt-5 px-5'>
                  <AsideItem 
                         icon={BagdeIcon} title={'Client Name'}
                         text={client?.rootAccount?.name || '----'}
                     /> 
                     <AsideItem 
                        icon={Batch1} title={'Company Name'} 
                        text={client?.organization?.name || '-----'}
                     /> 
                     <AsideItem
                       icon={Envelope} title={'Email Address'}
                       text={client?.rootAccount?.email || '----'} 
                      />
                     <AsideItem 
                        icon={Telephone} title={'Contact Number'}
                        text={client?.organization?.phoneNumber || '----'} 
                     />
                     <AsideItem 
                         icon={Calender} title={'Date Added'} 
                         text={
                               `${
                                 new Date(client?.organization?.createdAt)
                                 .toLocaleDateString('en-ng')}` || '----'
                              }
                     />
                     <AsideItem 
                       icon={Calender} title={'Date Updated'}
                       text={`
                              ${new Date(client?.organization?.updatedAt)
                              .toLocaleDateString('en-ng')}` || '----'
                            }
                     />
                     <AsideItem 
                        icon={Batch1} title={'Address'} 
                        text={client?.organization?.address || '----'} 
                    /> 
                  </div>
                  <hr /> <br />
            </aside>
            <section className='w-[68%] mt-3 rounded-lg'>
                <div className='grid grid-cols-4 gap-3'>
                    <Card 
                         image={TotalProjectIcon} title={'Opening Credits'}
                         total={ summaryLoading ? '...' : formatCurrency(summary?.openingCreditBalance, false) } 
                    />
                    <Card 
                        image={CreditIcon} title={'Closing Credits'}
                        total={summaryLoading ? '...' : formatCurrency(summary?.closingCreditBalance, false)}
                     />
                    <Card 
                       image={ProjectIcon} title={'Credit Used'} 
                       total={summaryLoading ? '...' : formatCurrency(summary?.usedCredit, false)} 
                    />
                    <Card 
                        image={CreditIcon} title={'Total Payments'}
                        total={summaryLoading ? '...' : summary?.numberOfPayments}
                     />
                </div>
                <section> 
                    <nav className='mt-3 border-b border-gra-600 pb-2'>
                       <Link to={`/admin/billing/invoice/client/${params?.id}/`}
                             className={`text-xs mr-5 ${params['*'] === '' && 'text-primary'}`}
                        > 
                          Invoices
                       </Link>
                       <Link 
                           to={`/admin/billing/invoice/client/${params?.id}/transaction-history`} 
                           className={`text-xs mr-5 ${params['*'] === 'transaction-history' && 'text-primary'}`}
                        > 
                          Transaction History
                       </Link>
                       <Link 
                           to={`/admin/billing/invoice/client/${params?.id}/credit-refund`}
                            className={`text-xs mr-5 ${params['*'] === 'credit-refund' && 'text-primary'}`}
                        >
                         Credit Refund
                        </Link>
                       <Link 
                           to={`/admin/billing/invoice/client/${params?.id}/credit-top-up`} 
                           className={`text-xs mr-5 ${params['*'] === 'credit-top-up' && 'text-primary'}`}
                        > 
                         Credit Top Up
                       </Link> 
                    </nav>
                     <section className='p-2'>
                      <Routes>
                        <Route
                            path='/' 
                            element={
                              <Invoices 
                                isLoading={InvoicesLoading}
                                invoices={invoices?.invoices}
                                setYear={setYear}
                               />
                              } 
                        />
                        <Route 
                           path='/credit-refund' 
                           element={
                              <CreditRefund 
                                userId={client?.rootAccount?.id}
                                orgId={client?.organization?.id}
                              />
                           }
                        />
                        <Route 
                           path='/credit-top-up' 
                           element={
                            <CreditTopUp
                              userId={client?.rootAccount?.id}
                              orgId={client?.organization?.id}
                            />
                           }
                        />
                        <Route 
                           path='/transaction-history' 
                           element={
                            <TransactionHistory
                              orgId={params?.id}
                            />
                           }
                        />
                      </Routes>
                     </section>
                </section>
            </section>
        </section>
     </section>
  )
}

export default ViewClientDetails