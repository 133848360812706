import React, { useState } from "react";

import Progress from "../../../../../../components/progress/Progress";
// import Search from '../../../../../components/Inputs/Search';
import { useQuery } from "react-query";
import { api } from "../../../../../../api/action-plan";
import ConfirmModal from "../../../zoom-integration/components/ConfirmModal";
import { Link } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import toast from "react-hot-toast";
import Pagination from "../../../../../../components/pagination/Pagination";
import { orgId } from "../../../../../../utils/org";

const Goals = () => {
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState(null);

  const {
    data: goals,
    isLoading,
    refetch,
  } = useQuery(
    ["get-all-goals", search,perPage, currentPage],
    async () => {
      const response = await api.getAllActionPlansLearnORGoals({
        orgId,
        perPage,
        currentPage,
        search,
      });
      return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const deleteMeeting = async () => {
    if (!selectedGoal) return;
    try {
      const response = await api.deleteGoalPlan({
        orgId,
        goalPlanId: selectedGoal?.plan.id,
      });
      setDeleteModal(false);
      toast.success(response.message);
      refetch(); // Refresh the data after deletion
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="bg-white p-3 rounded-lg shadow">
      <div>
        <h2 className="text-lg text-[#0C0D0F] font-semibold border-b">Goals</h2>
      </div>
      <div className="">
        {isLoading ? (
          <div className="mt-10 flex justify-center items-center">
            <ScaleLoader color="#FF9100" />
          </div>
        ) : (
          <div className="mt-4">
            <div className="overflow-x-auto">
              <table className="table-container">
                <thead className="table-header">
                  <tr>
                    <th className="table-head">Learner ID</th>
                    <th className="table-head">Learner Name</th>
                    <th className="table-head">Email</th>
                    <th className="table-head">Goal</th>
                    <th className="table-head">Key Learnings</th>
                    <th className="table-head">Action Plan ID</th>

                    <th className="table-head">Submit Goals</th>
                    {/* <th className="table-head">Status</th> */}

                    <th className="table-head">Action</th>
                  </tr>
                </thead>

                <tbody className="table-body text-xs">
                  {goals?.length > 0 ? (
                    goals?.map((goal, index) => (
                      <tr key={index}>
                        {/* <td className="table-data ">{index + 1}</td> */}
                        <td className="table-data ">{goal?.userId}</td>
                        <td className="table-data ">{goal?.name}</td>
                        <td className="table-data ">{goal?.email}</td>
                        <td className="table-data ">{goal?.numberOfGoals}</td>
                        <td className="table-data ">{goal?.keyLearnings}</td>
                        <td className="table-data ">{goal?.actionPlan?.id}</td>

                        <td className="table-data">
                          <Progress
                            width={80}
                            value={parseInt(goal?.percentageGoals)}
                          />
                        </td>

                        <td className="table-data flex gap-x-4 items-center">
                          <div className="flex gap-x-4 items-center">
                            <Link to={`/admin/zoom/edit-action/${goal?.id}`}>
                              <i className="bi bi-eye cursor-pointer"></i>
                            </Link>
                            <i
                              className="bi bi-trash3-fill cursor-pointer"
                              onClick={() => {
                                setSelectedGoal(goal);
                                setDeleteModal(true);
                              }}
                            ></i>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="table-data" colSpan="12">
                        <p className="text-center">No Data Available</p>
                      </td>
                    </tr>
                  )}
                </tbody>
                {deleteModal && (
                  <ConfirmModal
                    title="Delete Action Plan"
                    description={`Are you sure you want to delete ‘‘${selectedGoal?.name}”?`}
                    onYes={deleteMeeting}
                    onClose={() => setDeleteModal(false)}
                  />
                )}
              </table>
            </div>
          </div>
        )}
      </div>
      <section className={`mt-1 px-3 rouded-lg bg-white`}>
        <Pagination
          data={goals?.meta}
          perPage={perPage}
          pageNo={currentPage}
          setPageNo={setCurrentPage}
          setPerPage={setPerPage}
        />
      </section>
    </div>
  );
};

export default Goals;
