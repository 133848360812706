import React, { useState } from "react";
import CenteredModal from "../../../../../components/Modal/CenteredModal";
import path from "../assets/path.png";
import program from "../assets/program.png";
import useLearningPath from "../../../../../hooks/useLearningPath";
import { useNavigate } from "react-router";

const ChooseType = ({ selectionModal, handleSelectionModal }) => {
  //   const { typeProps } = useLearningPath();

  //   console.log(typeProps.open);

  const navigate = useNavigate();

  const [currentType, setCurrentType] = useState("path");

  const handleType = (selectedType) => {
    return () => {
      setCurrentType(selectedType);
    };
  };

  const Types = [
    { id: 1, img: path, desc: "I want to create a single path", type: "path" },
    {
      id: 2,
      img: program,
      desc: "I want to create a program",
      type: "program",
    },
  ];

  const handleNavigation = () => {
    if (currentType === "path") {
      navigate("create-path");
    } else {
      navigate("create-program");
    }
    handleSelectionModal();
  };

  return (
    <CenteredModal
      title={"Choose what type of learning path"}
      hasHeader={false}
      open={selectionModal}
      setOpen={handleSelectionModal}
    >
      <div className="p-2">
        <h1 className="w-full text-left text-2xl font-semibold">
          Choose what type of learning path
        </h1>
        <p className="w-full text-left text-xs text-gray-500 font-light">
          Do you want to create a single learning path or a program with
          multiple learning paths
        </p>
        <div className="w-full mt-3 grid grid-cols-1 md:grid-cols-2 gap-2">
          {Types.map((type) => (
            <div key={type.id}>
              <button
                onClick={handleType(type.type)}
                className={`border ${
                  currentType === type.type
                    ? "border-customPink bg-customPink"
                    : "border-lightGray"
                } relative h-[240px] py-7 w-full rounded-lg flex items-center justify-center flex-col`}
              >
                <img
                  src={type.img}
                  className="max-w-full h-auto"
                  alt={type.type}
                />
                <p className="absolute bottom-[10px] text-sm">{type.desc}</p>
              </button>
            </div>
          ))}
        </div>
        <div className="w-full mt-3 grid grid-cols-1 md:grid-cols-2 gap-2">
          <button
            className="mt-5 rounded-lg w-full py-2 text-sm"
            onClick={handleSelectionModal}
          >
            Back
          </button>
          <button
            className="border border-darkBlue bg-darkBlue text-white mt-5 w-full rounded-lg py-2 text-sm"
            onClick={handleNavigation}
          >
            Proceed
          </button>
        </div>
      </div>
    </CenteredModal>
  );
};

export default ChooseType;
