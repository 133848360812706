import React from 'react'
import StarterPage from './components/StarterPage'
import { api } from '../../../../../../../../api/clients'
import { useQuery } from 'react-query'
import Loader from '../../../../../../../../components/loader/Loader'
import LoginTemplate from '../CreateLoginTemplate/components/LoginTemplate'

const Login = ({id}) => {

  const { data: clientLoginTemplate, isLoading } = useQuery(
    ["get client login template", { id }],
    ({ queryKey }) => {
        return api.getLoginTemplate(queryKey[1]);
    },
    {
      select: data => data?.data
    } 
   );

  if(isLoading) return (
    <Loader />
  )

  const loginTemplate = {
    title: clientLoginTemplate?.templateTitle,
    headerText: clientLoginTemplate?.headerText,
    brandLogo: clientLoginTemplate?.brandLogoUrl,
    type: clientLoginTemplate?.backgroundType,
    brandColor: clientLoginTemplate?.primaryColor,
    bodyBackgroundColor: clientLoginTemplate?.backgroundColor,
    containerAlignment: clientLoginTemplate?.containerAlignment,
    bodyBackgroundImage: clientLoginTemplate?.backgroundColor,
  }

  return (
    <section>  
        {
          clientLoginTemplate === null ?
          <StarterPage
              navigation={`/admin/clients/create-login-template?org-id=${id}`} 
          />
          :
          <section className='bg-white w-full p-4'>
              {
                true ?
                  <section 
                  className='w-full -mt-1 h-full text-left py-5' 
                  style={{
                       background: loginTemplate?.type === 'image' ? 
                          loginTemplate?.bodyBackgroundImage : loginTemplate.bodyBackgroundColor
                   }}
               >
                    <div className='w-[50%] bg-white mx-auto shadow-md my-8 p-5 rounded-lg'>
                      <LoginTemplate loginTemplate={loginTemplate} />
                    </div>
                </section>
                :
                <section 
                    className={`w-[96%] h-full text-left rounded-lg mt-5 mx-3 py-0 flex ${
                      loginTemplate.containerAlignment !== 'left' ? 'justify-end' : 'justify-start' }`
                    } 
                    style={{
                        background: loginTemplate?.type === 'image' ? 
                          loginTemplate?.bodyBackgroundImage : loginTemplate.bodyBackgroundColor
                    }}
                 >
                <div className='w-[400px] bg-white shadow-md p-5 rounded-lg'>
                   <LoginTemplate loginTemplate={loginTemplate} />
                </div>
            </section>
           }
          </section>
        }
    </section>
  )
}

export default Login