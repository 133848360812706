import React from 'react'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

const Progress = ({ width, value, withTooltip = false, id }) => {

 const colorRenderer = value => {
    let color = 'bg-primary'
   if((value > 20 && value <= 50))  color = 'bg-secondary'
   else if((value > 50 && value <= 100)) color = 'bg-green-700'
   return color
 }

  return (
    <div style={{width}} className='h-[7px] bg-gray-300 rounded-full'>
       <div 
          style={{ width:`${value}%` }} 
          className={`p-0 h-full block rounded-full m-0 ${colorRenderer(value)}`}
       />
       {withTooltip && <Tooltip id={id} content={`${value}% Progress`}  />}
    </div>
  )
}

export default Progress