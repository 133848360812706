import React from 'react'
import { Field, ErrorMessage } from 'formik'

const Input = ({
       label, type='text', name, setFieldValue, setFieldError, 
       setVisible, visible, showError=false, colorScheme='orange',
    }) => {

  return (
    <>
       <div className='mt-3 w-[90%]'>
            <label className='text-xs'> {label} </label> <br />
            {
              name === 'phone_number' ?
                <Field 
                  type={type}
                  name={name}
                  className='auth-input font-semibold'
                  onChange = { (event) => {
                     const name = 'phone_number'
                     const number = event.target.value
                      if(number.toString().length > 13 ){
                         /*setFieldError(
                          name, 'Error! maximum phone number is 13 digits'
                          ) */
                          return;
                      }
                      setFieldValue(name, number)
                  } }
                />
                :
                (name === 'password' || name === 'cpassword') ?
                  <> 
                   <div class="relative mb-4 flex  items-stretch">
                    <span
                      className="flex items-center border-t border-l border-b  whitespace-nowrap rounded-l bg-white border-gray-200 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-neutral-700 dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200"
                      id="basic-addon1"
                      onClick={() => setVisible(!visible)}
                      > 
                         {
                           visible ? 
                           <i className='bi bi-eye-slash text-xs'></i>
                           :
                           <i className='bi bi-eye text-xs'></i>
                         }
                      </span>
                    <Field 
                      type={ visible ? 'text' : type}
                      name={name}
                      className='font-semibold auth-input'
                      style={{
                            borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px',
                            color: colorScheme === 'blue' && '#333',
                            borderColor: colorScheme === 'blue' && '#ccc',
                          }}
                   />
                  </div>
                  {
                    showError && <ErrorMessage 
                               name='password' 
                               className='mt-3 text-xs text-red-500'
                               component={'p'} 
                     />
                  }
                  </>
                :
                  <Field 
                    type={type}
                    name={name}
                    style={{
                         color: colorScheme === 'blue' && '#333',
                         borderColor: colorScheme === 'blue' && '#ccc',
                    }}
                    className='auth-input font-semibold'
                  />
             }
            { name !== 'password' && <ErrorMessage 
                  name={name} className={'text-xs text-red-500 mt-2' }
                  component='p'
             />}
       </div>
    </>
  )
}

export default Input