import React from "react";
import OverviewCard from "../../../../report-and-analysis/components/OverviewCard";

import BadgeIconGreen from "../../../../../../../assets/Badge.png";
import BadgeIconOrange from "../../../../../../../assets/Bagde (5).png";
import { api } from "../../../../../../../api/billing";
import { useQuery } from "react-query";
import toast from "react-hot-toast";

const SummarySection = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));

  const { data: summaryData, isLoading: summaryDataLoading } = useQuery(
    ["summarry-data"],
    async () => {
      const response = await api.getClientSummary(user?.org?.id);
      return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
  return (
    <div className="flex flex-wrap xl:flex-nowrap  gap-2">
      <OverviewCard
        title={"Opening Credit"}
        total={
          summaryDataLoading
            ? "..."
            : `$${summaryData?.openingCreditBalance.toLocaleString()}`
        }
        headerTextSize="text-2xl"
        iconPath={BadgeIconGreen}
      />
      <OverviewCard
        title={"Closing Credit"}
        total={
          summaryDataLoading
            ? "..."
            : `$${summaryData?.closingCreditBalance.toLocaleString()}`
        }
        headerTextSize="text-2xl"
        iconPath={BadgeIconOrange}
      />
      <OverviewCard
        title={"Used Credits"}
        total={
          summaryDataLoading ? "..." : summaryData?.usedCredit.toLocaleString()
        }
        headerTextSize="text-2xl"
        iconPath={BadgeIconOrange}
      />
    </div>
  );
};

export default SummarySection;
