import React, { useState, useEffect } from 'react'
import Search from '../../../../../../../../components/Inputs/Search'
import Button from '../../../../../../../../components/Buttons/Button'
import userIcon from '../../../../../../../../assets/flat-color-icons_manager.png'
import Table from '../../../../../../../../components/table/Table'
import { useNavigate } from 'react-router-dom'
import AddRoleModal from './components/AddRoleModal'
import { api } from '../../../../../../../../api/roles-and-permissions'
import { useQuery } from 'react-query'
import DeleteRoleModal from './components/DeleteRoleModal'
import Pagination from '../../../../../../../../components/pagination/Pagination'
import ViewPermissions from './components/ViewPermissionsModal'

const ViewRoles = () => {

    const [openModal, setOpenModal] = useState(false)
    const [deleteRoleModal, setDeleteRoleModal] = useState(false)
    const [addPermissionModal, setAddPermissionModal] = useState(false)
    const [activeRole, setActiveRole] = useState(null)
    const [activeRoleId, setActiveRoleId] = useState(false)
    const [action, setAction] = useState(null)
    const [perPage, setPerPage] = useState(8);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate()
    const orgId = JSON.parse( window.sessionStorage.getItem('user') )?.org?.id

    const { data: roles, isLoading, refetch } = useQuery(
      ["roles", { search, perPage, currentPage, orgId }],
      ({ queryKey }) => {
        return api.getRoles(queryKey[1]);
      },
      {
        cacheTime: 100,
        staleTime: 100,
        refetchOnWindowFocus: true,
        select: (data) => data,
      }
    );

    useEffect( () => {
      refetch()
    }, [search, currentPage, perPage] )

    let columns = React.useMemo(
        () => [
          {
            title: "Account Role",
            selector: "name",
            key: "name",
            render: ({ item, row}) => {           
              return <div className="flex items-center"> 
                    <img src={userIcon} alt="user" className='w-[30px] h-[30px]' />
                   <span className='text-xs ml-3'>{item}</span>
               </div>;
            },
          },
          {
            title: "Members",
            selector: "numberOfUsers",
            key: "numberOfUsers",
            render: ({ item }) => {           
              return <span className="ml-3">{item}</span>;
            },
          },
          {
            title: "Permissions",
            selector: "permissions",
            key: "permissions",
            render: ({ item }) => {           
              return <span className="ml-3">{item?.length}</span>;
            },
          },
          {
            title: "Date Added",
            selector: "createdAt",
            key: "createdAt",
            render: ({ item }) => {           
              return <span className="">{`${new Date(item).toLocaleDateString('en-ng')}`}</span>;
            },
          },
          {
            title: "Date Updated",
            selector: "updatedAt",
            key: "updatedAt",
            render: ({ item }) => {           
              return <span className="">{`${new Date(item).toLocaleDateString('en-ng')}`}</span>;
            },
          },
          {
            title: "Action",
            selector: null,
            key: null,
            render: ({row}) => {           
                return  <div className='flex items-center'>
                     <span 
                       className="bi bi-key mr-2 text-xl text-gray-900 hover:cursor-pointer"
                        onClick={ () => {
                          setActiveRole(row)
                          setAddPermissionModal(true)
                        }}
                    />
                    <span 
                       className="bi bi-pencil-square hover:cursor-pointer text-blue-500" 
                       onClick={ () => {
                          setActiveRoleId(row?.id)
                          setActiveRole(row)
                          setAction('edit')
                          setOpenModal(true)
                       }}
                     />
                    <span 
                       className="bi bi-trash ml-2 text-red-500 hover:cursor-pointer"
                        onClick={ () => {
                          setActiveRoleId(row?.id)
                          setDeleteRoleModal(true)
                        } }
                    />
                </div>
            },
        },
        ],
        [1, 20, 20]
      );
    
  const tableProps = { isLoading: isLoading, columns, data: roles?.data }

  return (
    <section className='p-5'>  
       <ViewPermissions
          openModal={addPermissionModal}
          setOpenModal={setAddPermissionModal}
          refetch={refetch}
          activeRole={activeRole}
       />
       <AddRoleModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          refetch={refetch}
          action={action}
          activeRole={activeRole}
       />
       <DeleteRoleModal 
         openModal={deleteRoleModal}
         setOpenModal={setDeleteRoleModal}
         activeRoleId={activeRoleId}
         refetch={refetch}
       />
       <h3 className='text-md font-semibold'>
          Roles and Permissions
       </h3>
       <p className='text-xs text-gray-600'>
         use roles to group your users and assign permissions
       </p>
       <section>
            <div className='flex justify-between mt-5'>
                <div className='w-[50%]'>
                   <Search 
                      placeholder={'Search roles...'}
                      onClick={ () => refetch() }
                      onChange={ event => setSearch(event.target.value) }
                   />
                </div>
                <div className='w-[200px]'>
                     <Button 
                           width={'w-full'} bgColor={'bg-customBlue'} 
                           text={'Create Role'}
                           onClick={ () => {
                             setAction(null)
                             setOpenModal(true)
                           } }
                     />
                </div>
            </div>
            <div className='border border-gray-200 rounded-lg mx-auto mt-3'>
                 <Table headerClassName={'shadow'}  {...tableProps} />
            </div>
            <div>
              <Pagination
                data={roles?.meta}
                perPage={perPage}
                pageNo={currentPage}
                setPageNo={setCurrentPage}
                setPerPage={setPerPage}
              />
            </div>
       </section>
    </section>
  )
}

export default ViewRoles