import React, { useState } from "react";
import Button from "../../../../../../../components/Buttons/Button";
import { useMutation, useQuery } from "react-query";
import toast from "react-hot-toast"; 
import add_course from "../../../assets/add_course.png";
import AddCourse from "../../../components/AddCourse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowsUpDownLeftRight,
  faGear,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { arrayMoveImmutable } from "array-move";
import SortableList, { SortableItem } from "react-easy-sort";
import { api } from "../../../../../../../api/learningPath";
import img_icon from "../../../assets/img_icon.png";
import Settings from "../../../components/Settings";
import PreviewPath from "../../PreviewPath";
import { useNavigate } from "react-router";
import Loader from "../../../../../../../components/loader/Loader";

const PathContent = () => {

  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const active_path = JSON.parse(sessionStorage.getItem("active-path"));
   
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [addCourseModal, setAddCourseModal] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [previewIsOpen, setPreviewIsOpen] = useState(false);

  const { isLoading, data: journey } = useQuery(
    ["Get Individual Learning Path"],
    () => {
      return api.getIndividualLearningPath({ org_id: user?.org?.id, id: active_path?.id});
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: false,
      refetchOnMount:false,
      select: (data) => data?.data,
      onSuccess: data => {
        const courses = data?.journeyCourses?.length ? data?.journeyCourses?.map( journey => {
          return journey?.course
        } ) : []
        setSelectedCourses(courses)
      }
    }
  );

  const addCourseMutation = useMutation(api.addCourses, {
    onSuccess: (data) => {
      toast.remove();
      toast.success('Course deleted successfully');
    },
    onError: ({ response }) => {
      toast.remove();
      toast.error(response?.data?.message);
    },
  });

  const handlePreview = () => {
    setPreviewIsOpen(!previewIsOpen);
  };

  const handleSettingsModal = () => {
    setSettingsModal(!settingsModal);
  };

  const handleAddCourseModal = () => {
    setAddCourseModal(!addCourseModal);
  };

  const onSortEnd = (oldIndex, newIndex) => {
    setSelectedCourses((array) =>
      arrayMoveImmutable(array, oldIndex, newIndex)
    );
  };

  const handlePublish = () => {
    toast.success("Published successfully...");
    navigate("/admin/learning-path");
  };

  const removeCoursefromList = (selected_course) => {
  
      setSelectedCourses((prevCourses) =>
        prevCourses.filter((course) => course?.id !== selected_course.id)
      );

     addCourseMutation.mutate({
      payload: {
        newCourseIds: selectedCourses.filter( course => course?.id !== selected_course?.id ).map((course, index) => {
          return course?.id
        }),
        deletedCourseIds: selectedCourses?.filter((course) => course?.id === selected_course.id)?.map(
          course => course?.id,
        )
      },
      org_id: user?.org?.id,
      journey_id: active_path?.id,
    });
    
  };

  if(isLoading) return <Loader />

  if (selectedCourses?.length < 1) {
    return (
      <React.Fragment> 
        <AddCourse
          addCourseModal={addCourseModal}
          handleAddCourseModal={handleAddCourseModal}
          selectedCourses={selectedCourses}
          setSelectedCourses={setSelectedCourses}
        />
        <div className="mt-1 relative mr-2 rounded-lg p-2">
          <div className="w-full mb-2 flex items-end justify-between">
            <p className="text-sm underline">{active_path?.name}</p>
            <Button
              text={"Save Changes"}
              onClick={handlePublish}
              className={"bg-darkBlue text-white text-xs rounded-md px-10 py-2"}
            />
          </div>
          <section className="w-full flex items-center gap-[10px] justify-center flex-col py-[50px] bg-white border border-lightGray rounded-md p-2 pr-8">
            <img src={add_course} alt="add course" />
            <Button
              text={"Add courses"}
              className={"bg-darkBlue text-white text-sm rounded-md px-10 py-2"}
              onClick={handleAddCourseModal}
            />
          </section>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {previewIsOpen && (
        <PreviewPath
          previewIsOpen={previewIsOpen}
          handlePreview={handlePreview}
        />
      )} 
      <Settings
        settingsModal={settingsModal}
        handleSettingsModal={handleSettingsModal}
        selectedLearners_={ journey?.journeyCohorts?.length ? 
           journey?.journeyCohorts?.map( path => path )
          : [] }
      />
      <AddCourse
        addCourseModal={addCourseModal}
        handleAddCourseModal={handleAddCourseModal}
        selectedCourses={selectedCourses}
        setSelectedCourses={setSelectedCourses}
      />
      <div className="mt-1 relative mr-2 rounded-lg p-2">
        <div className="w-full mb-2 flex items-end justify-between">
          <p className="text-sm underline">{active_path?.name}</p>
          <div className="flex items-center justify-end gap-3">
            <Button
              text={"Edit Course Content"}
              onClick={handlePublish}
              className={"bg-darkBlue text-white text-xs rounded-md px-10 py-3"}
            />
          </div>
        </div>
        <div className="flex items-start justify-between gap-2">
          <section className="w-full md:w-[90%] md:mx-auto md:mt-3 flex items-end gap-[10px] justify-center flex-col p-[20px] bg-white border border-lightGray rounded-md">
            <div className="flex items-center justify-between w-full">
            <h1 className="text-sm">
               Courses
            </h1>
              <button
                onClick={handleSettingsModal}
                className="bg-neutral-200 text-customBlue flex items-center justify-center gap-1 text-xs rounded-md px-4 py-2"
              >
                <FontAwesomeIcon icon={faGear} />
                 Add Prerequisites & Learners
              </button>
            </div>
            <SortableList
              onSortEnd={onSortEnd}
              draggedItemClassName="dragged"
              className="my-3 w-full p-4"
            >
              {selectedCourses?.map((course, index) => (
                <div key={index}>
                  <SortableItem key={course}>
                    <div
                      className={`shadow border-t border-gray-100 flex items-center justify-between cursor-grab w-full px-3 py-5 rounded-md ${
                        index + 1 !== selectedCourses?.length && "mb-2"
                      }`}
                    >
                      <div className="text-sm flex items-center justify-start gap-2">
                        <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                        <img
                          src={img_icon}
                          className="w-10 h-10"
                          alt={`img-icon ${index}`}
                        />
                        <p>{course?.name}</p>
                      </div>
                      <div className="text-sm flex items-center justify-end gap-2">
                        <p>{course?.points}</p>
                        <button onClick={ () => removeCoursefromList(course)}>
                          <FontAwesomeIcon
                            className="text-red-500 ml-2"
                            icon={faTrash}
                          />
                        </button>
                      </div>
                    </div>
                  </SortableItem>
                  {index + 1 !== selectedCourses?.length && (
                    <div className="text-xs flex items-center justify-center w-full my-2 p-2">
                      <FontAwesomeIcon icon={faArrowDown} />
                    </div>
                  )}
                </div>
              ))}
            </SortableList>
            <Button
              text={"Add courses"}
              className={"bg-darkBlue text-white text-sm rounded-md px-10 py-2"}
              onClick={handleAddCourseModal}
            />
          </section>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PathContent;
