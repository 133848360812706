import axios from "axios";
import baseUrl from "../url";

const createApiClient = (isFormData=false) => {
  const client = axios.create({
    baseURL: baseUrl,
    headers: {
      "Cache-Control": "no-cache",
      "Content-Type": isFormData ? "multipart/form-data" : "application/json",
      Accept: "application/json",
    },
  });

  client.interceptors.request.use(
    async (config) => {
      const token = JSON.parse(sessionStorage.getItem("user"))?.token;

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  client.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (
        error.response &&
        error.response.status === 401 &&
        error.config.url !== "/"
      ) {
        // Clear session storage token and user
        sessionStorage.removeItem("userToken");
        sessionStorage.removeItem("user");
        // Redirect to login page
        window.location.replace("/");
      }
      return Promise.reject(error);
    }
  );

  return client;
};

export default createApiClient;
