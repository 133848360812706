import React, { useState } from "react";
import ToggleSwitch from "../components/ToggleSwitch";
import { BeatLoader } from "react-spinners";
import toast from "react-hot-toast";
import { api } from "../../../../../api/zoom";
import SingleSelectDropdown from "../../../components/SingleSelectDropdown";
import { useNavigate } from "react-router";

const Options = [
  { label: "No Recording", value: "no" },
  { label: "Local Drive", value: "drive" },
  { label: "Zoom Cloud", value: "cloud" },
];

const EditZoomSettings = ({ nextStep, prevStep, formData }) => {
    const navigate = useNavigate();
  const orgId = JSON.parse(window.sessionStorage.getItem("user"))?.org?.id;
  const meetingId = formData?.meetingId;

  const [recordType, setRecordType] = useState(
    formData?.meeting?.zoomMeetingDetails?.settings?.auto_recording
  );
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIdLoggedIn] = useState(false);
  const [settings, setSettings] = useState({
    setting1:
      formData?.meeting?.zoomMeetingDetails?.settings?.join_before_host ||
      false,
    setting2:
      formData?.meeting?.zoomMeetingDetails?.settings?.host_video || false,
    setting3:
      formData?.meeting?.zoomMeetingDetails?.settings?.participant_video ||
      false,
    setting4:
      formData?.meeting?.zoomMeetingDetails?.settings?.mute_upon_entry || false,
    setting5:
      formData?.meeting?.zoomMeetingDetails?.settings?.meeting_authentication ||
      false,
  });

  const handleSelectedActivity = (option) => {
    setRecordType(option.value);
  };

  const handleToggleChange = (settingName) => {
    setSettings({
      ...settings,
      [settingName]: !settings[settingName],
    });
  };
  const handleCreateMeeting = async () => {
    let payload = {
      agenda: formData.summary,
      topic: formData.topic,
      startTime: formData.dateTime,
      duration: formData.duration,
      timezone: formData.selectTimeZone,
      default_password: true,
      password: formData.password,
      private_meeting: formData.meetingType,
      enableRecording: formData.isRecording,
      settings: {
        join_before_host: settings.setting1,
        host_video: settings.setting2,
        participant_video: settings.setting3,
        mute_upon_entry: settings.setting4,
        meeting_authentication: settings.setting5,
        auto_recording: recordType,
      },
    };
    setLoading(true);
    try {
      const response = await api.updateMeeting({ orgId, meetingId, payload });
      setIdLoggedIn(true);
      navigate("/admin/zoom/meetings");
      toast.success(response.message);
    } catch (error) {
        console.log(error, "erro")
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="tracking-normal md:p-10">
      {" "}
      <div className="flex flex-col  rounded-2xl border border-gray-300">
        <div className="grid grid-cols-3 gap-2 items-center p-6  border-b">
          <ToggleSwitch
            checked={settings.setting1}
            onChange={() => handleToggleChange("setting1")}
          />
          <label htmlFor="setting1" className="">
            <h3 className="text-base font-medium text-[#1D1F2C]">
              Join Before Host
            </h3>
          </label>
          <p className="text-base   text-[#667085]">
            Learners can join meeting before host starts
          </p>
        </div>
        <div className="grid grid-cols-3 gap-2 items-center p-6  border-b">
          <ToggleSwitch
            checked={settings.setting2}
            onChange={() => handleToggleChange("setting2")}
          />
          <label htmlFor="setting2" className="">
            <h3 className="text-base font-medium text-[#1D1F2C]">Host Video</h3>
          </label>
          <p className="text-base   text-[#667085]">
            Host will join meeting with video enabled
          </p>
        </div>
        <div className="grid grid-cols-3 gap-2 items-center p-4  border-b">
          <ToggleSwitch
            checked={settings.setting3}
            onChange={() => handleToggleChange("setting3")}
          />
          <label htmlFor="setting3" className="">
            <h3 className="text-base font-medium text-[#1D1F2C]">
              Learner Video
            </h3>
          </label>
          <p className="text-base   text-[#667085]">
            Learners will join meeting with video enabled
          </p>
        </div>
        <div className="grid grid-cols-3 gap-2 items-center p-4  border-b">
          <ToggleSwitch
            checked={settings.setting4}
            onChange={() => handleToggleChange("setting4")}
          />
          <label htmlFor="setting4" className="">
            <h3 className="text-base font-medium text-[#1D1F2C]">
              Mute Learners
            </h3>
          </label>
          <p className="text-base font-medium  text-[#667085]">
            Learners will join meeting with audio muted
          </p>
        </div>

        <div className="grid grid-cols-3 gap-2 items-center p-4  border-b w-full">
          <div className="w-fit">
            <ToggleSwitch
              checked={settings.setting5}
              onChange={() => handleToggleChange("setting5")}
            />
          </div>
          <label htmlFor="setting5" className="w-fit">
            <h3 className="text-base font-medium text-[#1D1F2C]">
              Enforce Login
            </h3>
          </label>
          <div className="">
            <p className="text-base font-medium  text-[#667085]">
              Only learners logged into zoom app can join meeting
            </p>
          </div>
        </div>

        <div className="my-3 px-6">
          <h2 className="text-base font-semibold text-[#1D1F2C]">
            Recording Settings
          </h2>
          <p className="text-xs text-[#667085] font-normal">
            Select where you want to record
          </p>
          <div className="flex flex-col w-fit mt-2">
            <SingleSelectDropdown
              options={Options}
              onSelect={handleSelectedActivity}
              placeholder={`${recordType}`}
              placeholderColor="#A5A5A5"
            />
          </div>
          {/* <div className="flex items-center gap-x-3 mt-3">
            <div className="flex gap-x-3 items-center">
              <div className="h-4 w-4 rounded-full bg-[#0E316E]"></div>
              <p className="text-sm font-semibold text-[#1D1F2C]">
                No Recordings
              </p>
            </div>
            <div className="flex gap-x-3 items-center">
              <div className="h-4 w-4 rounded-full bg-[#0E316E]"></div>
              <p className="text-sm font-semibold text-[#1D1F2C]">
                Local Drive
              </p>
            </div>
            <div className="flex gap-x-3 items-center">
              <div className="h-4 w-4 rounded-full bg-[#0E316E]"></div>
              <p className="text-sm font-semibold text-[#1D1F2C]">Zoom Cloud</p>
            </div>
          </div> */}
        </div>
      </div>
      <div className="flex justify-between items-center py-10">
        <button
          type="button"
          onClick={prevStep}
          className="py-2.5 px-4 bg-primary border border-primaryGrey text-white  transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
        >
          Go back
        </button>
        <button
          type="submit"
          onClick={handleCreateMeeting}
          disabled={loading || isLoggedIn}
          className="bg-customBlue text-white py-2.5 px-4 rounded-lg font-medium text-lg"
        >
          {loading ? (
            <div className="flex justify-center">
              {" "}
              <BeatLoader color="#fff" />{" "}
            </div>
          ) : isLoggedIn ? (
            "updated"
          ) : (
            "Update Meeting"
          )}
        </button>
      </div>
    </div>
  );
};

export default EditZoomSettings;
