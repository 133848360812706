"use client";

import { createContext, useState } from "react";

const LearnersContext = createContext({
  openModal: false,
  setOpenModal: () => {},
  openInviteModal: false,
  setOpenInviteModal: () => {},
  openResetModal: false,
  setOpenResetModal: () => {},
  openAssignModal: false,
  setOpenAssignModal: () => {},
  currentLearner: {},
  setCurrentLearner: () => {},
  selectedCohort: {},
  setSelectedCohort: () => {},
  selectedPath: {},
  setSelectedPath: () => {},
  selectedCourse: {},
  setSelectedCourse: () => {},
  pageType: {},
  setPageType: () => {},
  statusFilter: {},
  setStatusFilter: () => {},
});

const LearnersProvider = ({ children }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openResetModal, setOpenResetModal] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [currentLearner, setCurrentLearner] = useState({});
  const [pageType, setPageType] = useState("profile");
  const [selectedCohort, setSelectedCohort] = useState({});
  const [selectedPath, setSelectedPath] = useState({});
  const [selectedCourse, setSelectedCourse] = useState({});

  const [statusFilter, setStatusFilter] = useState("");

  return (
    <LearnersContext.Provider
      value={{
        openModal,
        setOpenModal,
        currentLearner,
        setCurrentLearner,
        pageType,
        setPageType,
        selectedCohort,
        setSelectedCohort,
        selectedPath,
        setSelectedPath,
        selectedCourse,
        setSelectedCourse,
        statusFilter,
        setStatusFilter,
        openResetModal,
        setOpenResetModal,
        openInviteModal,
        setOpenInviteModal,
        openAssignModal,
        setOpenAssignModal,
      }}
    >
      {children}
    </LearnersContext.Provider>
  );
};

export { LearnersProvider, LearnersContext };
