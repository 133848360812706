import React from "react";
import { ResponsivePie } from "@nivo/pie";
import useLearners from "../../../../../hooks/useLearners";
import { BarLoader } from "react-spinners";
import EmptyData from "../../../../../components/EmptyData";

const TopFive = () => {
  const { topLearners, loadingTopLearners: isLoading } = useLearners();

  const colors = ["#0e316e", "#bbd8f6", "#ffe9cc", "#f9cfb4", "#ed6f1e"];

  // console.log(topLearners);

  const chartdata = topLearners?.data?.learners?.map?.((item, index) => {
    return {
      id: item?.name,
      label: `${item?.name?.split(" ")[0]} (${item?.totalPoints})`,
      value: item?.totalPoints,
      color: colors[index],
    };
  });

  if (isLoading) {
    return (
      <div className="border border-white shadow-lg p-3 bg-white rounded-md">
        <h1>Top 5 Learners</h1>
        <p className="text-gray-500 text-xs mb-5">
          Learners with highest average assessment score
        </p>
        <div className="w-full p-2 h-[300px] flex items-center justify-center">
          <BarLoader size={10} color="#FF9100" />
        </div>
      </div>
    );
  }

  return (
    <div className="border border-white shadow-lg p-3 bg-white rounded-md">
      <h1>Top 5 Learners</h1>
      <p className="text-gray-500 text-xs mb-5">
        Learners with highest average assessment score
      </p>
      <div className="w-full p-2 h-[300px]">
        {topLearners?.data?.learners?.length > 1 ? (
          <ResponsivePie
            data={chartdata}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={7}
            colors={{ datum: "data.color" }}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#ccc"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor={{ from: "color" }}
            slicesLabelsSkipAngle={10}
            animate={true}
            arcLabelsTextColor={"#fff"}
            motionStiffness={90}
            motionDamping={15}
            legends={[
              {
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 10,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
        ) : (
          <EmptyData text={"No data"} />
        )}
      </div>
    </div>
  );
};

export default TopFive;
