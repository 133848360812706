import React, { useContext } from "react";
import { Link } from "react-router-dom";
import EmptyData from "../../../../components/EmptyData";
import { RewardsContext } from "../../../../context/rewardContext";
import StatCard from "../learners/components/StatCard";
import img from "../learners/assets/total.png";
import TopFive from "./components/TopFive";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import { api } from "../../../../api/reward";

const ViewCategory = () => {
  const { currentCategory } = useContext(RewardsContext);

  // console.log(currentCategory);

  const user = JSON.parse(sessionStorage.getItem("user"));

  const { id } = useParams();

  const { data: summary, isLoading } = useQuery(
    ["Category Summary"],
    ({ queryKey }) => {
      return api.getSummary({ id: user?.org?.id, categoryId: id });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  const summaryData = [
    {
      title: "Total Count",
      amount: isLoading ? "..." : summary?.data?.numberOfOrgRewards,
      imgUrl: img,
    },
    {
      title: "Number of Earners",
      amount: isLoading ? "..." : summary?.data?.numberOfEarners,
      imgUrl: img,
    },
    {
      title: "Average Points earned per learner",
      amount: isLoading ? "..." : summary?.data?.averagePoints,
      imgUrl: img,
    },
    {
      title: "Average Badges earned per learner",
      amount: isLoading ? "..." : summary?.data?.averageBadge,
      imgUrl: img,
    },
  ];

  // console.log(summary);

  return (
    <div className="w-full p-5">
      <span className="text-xs">
        <Link className="hover:underline" to={"/admin/rewards"}>
          Rewards
        </Link>{" "}
        / Category
      </span>
      <h1 className="font-semibold mt-3">{currentCategory?.name}</h1>
      <p className="text-gray-500 text-xs">{currentCategory?.description}</p>
      <div className="w-full mt-4 grid grid-cols-1 md:grid-cols-4 gap-5">
        {summaryData?.map((data, index) => (
          <StatCard key={index} data={data} />
        ))}
      </div>
      <div className="w-full flex items-center justify-between gap-5 my-5">
        <div className="w-full border border-white shadow-lg p-3 bg-white rounded-md">
          <p className="text-sm font-medium">Top 5 Rewards</p>
          <div className="">
            <TopFive sort={"desc"} />
          </div>
        </div>
        <div className="w-full border border-white shadow-lg p-3 bg-white rounded-md">
          <p className="text-sm font-medium">Bottom 5 Rewards</p>
          <div className="">
            <TopFive sort={"asc"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCategory;
