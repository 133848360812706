import createApiClient from "./api";

const client = createApiClient();

export const feedbackApi = {

    createQuestions: (payload) => client
    .post(`/admin/questions`, payload).then(({ data }) => data ),

    getQuestions: ({search, perPage, currentPage }) =>
        client.get(`/admin/questions?perPage=${perPage}${
          search ? `&search=${search}` : ""
        }&page=${currentPage}`)
       .then(({ data }) => data),
        
  createTemplate: (payload) => client
       .post(`/admin/feedback-templates`, payload).then(({ data }) => data ),

 editTemplate: (payload) => client
       .patch(`/admin/feedback-templates/${payload?.id}`, payload?.data)
       .then(({ data }) => data ),

  addFeedbackSettings: (payload) => client
       .post(`/admin/feedbacks/${payload.id}/settings`, payload?.data)
       .then(({ data }) => data ),  

 getFeedbackTemplates: ({search, perPage, currentPage }) =>
    client.get(`/admin/feedback-templates?perPage=${perPage}${
        search ? `&search=${search}` : ""
    }&page=${currentPage}`)
    .then(({ data }) => data),
}