import React from 'react'
import CenteredModal from '../../../../../../../../../components/Modal/CenteredModal'
import LoginTemplate from './LoginTemplate'

const PreviewTemplate = ({openModal, setOpenModal, loginTemplate}) => {

 const PreveiwAlignment = ({alignment}) => {
    if(alignment === 'center'){
        return(
            <section 
                className='w-full -mt-1 h-full text-left py-5' 
                style={{
                     background: loginTemplate?.type === 'image' ? 
                        loginTemplate?.bodyBackgroundImage : loginTemplate.bodyBackgroundColor
                 }}
             >
                <div className='w-[400px] bg-white mx-auto shadow-md my-8 p-5 rounded-lg'>
                  <LoginTemplate loginTemplate={loginTemplate} />
                </div>
            </section>
        )
    }
    else{
        return(
            <section 
                className={`w-[96%] h-full text-left rounded-lg mt-5 mx-3 py-0 flex ${
                    alignment !== 'left' ? 'justify-end' : 'justify-start' }`
                } 
                style={{
                    background: loginTemplate?.type === 'image' ? 
                       loginTemplate?.bodyBackgroundImage : loginTemplate.bodyBackgroundColor
                }}
           >
                <div className='w-[400px] bg-white shadow-md p-5 rounded-lg'>
                   <LoginTemplate loginTemplate={loginTemplate} />
                </div>
            </section>
        )
    }
 }

  return (
    <div>
        <CenteredModal 
            title={<h5 className='text-md font-semibold bi bi-eye'> &nbsp; Preview Template </h5> }
            open={openModal}
            setOpen={setOpenModal}
            style={{padding: 0}}
            width={'w-[800px] h-[540px] overflow-y-auto'}
            headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
      >
            <PreveiwAlignment alignment={loginTemplate.containerAlignment} />
        </CenteredModal>
    </div>
  )
}

export default PreviewTemplate