import createApiClient from "./api";

const client = createApiClient();

export const dashboardApi = {

  getClientDashboardSummary: ({orgId}) =>
    client.get(`/admin/dashboard/summary${orgId ? `?orgId=${orgId}` : ''}`)
    .then(({ data }) => data),  
  
 getOrganisations: ({search, perPage, currentPage }) =>
      client.get(`/admin/dashboard/organizations?perPage=${perPage}${
        search ? `&search=${search}` : ""
      }&page=${currentPage}`)
     .then(({ data }) => data),

  getClientActiveCohorts: ({ orgId, search, perPage, currentPage }) =>
    client.get(`/admin/dashboard/cohorts?orgId=${orgId}&perPage=${perPage}${
              search ? `&search=${search}` : ""
            }&page=${currentPage}`)
           .then(({ data }) => data),
            
  getClientCoursesCompletionRate: ({ period, cohorts }) =>
      client.get(`/admin/dashboard/completion-rate?period=${period}${
        cohorts ? `&cohortIds=${cohorts}` : ""
      }`).then(({ data }) => data),
   
  getClientStagesRate: ({ period, cohorts }) =>
      client.get(`admin/dashboard/stage-rate?period=${period}${
        cohorts ? `&cohortIds=${cohorts}` : ""
      }`).then(({ data }) => data),

  getClientEnrollmentRate: ({ cohorts }) =>
    client.get(`admin/dashboard/enrollment-rate?period=weekly${
      cohorts ? `&cohortIds=${cohorts}` : ""
    }`).then(({ data }) => data),
  
  getCourseCompletionByWeek: ({ cohort, journey }) =>
      client.get(`/admin/dashboard/course-completion/${cohort}/${journey}`)
      .then(({ data }) => data),
  
  getCourseCompletionRateByWeek: ({ cohorts, journey }) =>
    client.get(`/admin/dashboard/cohort-completion?cohortId=${cohorts}&groupBy=week&journeyId=${journey}`)
    .then(({ data }) => data),

    getAverageAttemps: ({ cohorts, journey }) =>
      client.get(`/admin/dashboard/cohort-course-completion?cohortId=${cohorts}&journeyId=${journey}`)
      .then(({ data }) => data),
}