import React from "react";
import { useQuery } from "react-query";
import { api } from "../../../../../../api/action-plan";
import { ScaleLoader } from "react-spinners";
import toast from "react-hot-toast";
import { orgId } from "../../../../../../utils/org";

const TopCourses = () => {
  const { data: topCourses, isLoading } = useQuery(
    ["get-all-action-topcourses"],
    async () => {
      const response = await api.getAllActionPlansTopCourses({
        orgId,
      });
      return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  return (
    <div className="bg-white p-3 rounded-lg shadow">
      <div className="mb-4">
        <h2 className="text-lg text-black font-semibold">Top Courses</h2>
        <h2 className="text-sm text-gray-500 font-medium">
          Courses selected by Learners
        </h2>
      </div>
      <div>
        {isLoading ? (
          <div className="mt-10 flex justify-center items-center">
            <ScaleLoader color="#FF9100" />
          </div>
        ) : (
          <div>
            {topCourses?.length > 0 ? (
              topCourses.map((action, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center mb-4"
                >
                  <div className="flex items-center gap-2">
                    <div className="h-12 w-12 rounded-md bg-gray-500">
                      <img
                        src={action?.imageUrl || ""}
                        className="w-full h-full object-cover rounded-md"
                        alt={action?.title || "Course Image"}
                      />
                    </div>
                    <div>
                      <h4 className="text-sm font-bold">
                        {action?.courseName}
                      </h4>
                      {/* <h5 className="text-xs text-gray-500 font-normal">
                    {action?.plan?.description}
                  </h5> */}
                    </div>
                  </div>
                  <div>
                    <p className="text-sm font-semibold text-[#0C0D0F]">
                      {action?.courseCount}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-xs">No Data Available</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopCourses;
