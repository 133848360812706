import React, { useContext } from "react";
import { useLocation } from "react-router";
import OverViewTitle from "./OverviewTitle";
import { LearnersContext } from "../../../../../context/learnersContext";

const Header = () => {
  const location = useLocation();

  const path = location.pathname;

  const { setOpenInviteModal } = useContext(LearnersContext);

  // console.log(path);

  return (
    <div className="flex items-center mb-5 justify-between">
      <h1 className="font-semibold text-lg">
        {path.includes("analytics") ? (
          <p>
            Analytics <OverViewTitle />
          </p>
        ) : (
          <p>
            Overview <OverViewTitle />
          </p>
        )}
      </h1>
      <div className="flex gap-2">
        <button
          onClick={() => setOpenInviteModal(true)}
          className="border text-sm border-darkBlue text-white bg-darkBlue py-3 px-5 rounded-md"
        >
          Invite Learner
        </button>
        {/* <button className="border text-sm border-darkBlue text-white bg-darkBlue py-3 px-5 rounded-md">
          Assign Learner
        </button> */}
      </div>
    </div>
  );
};

export default Header;
