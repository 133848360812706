import React from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../../assets/logo-2.png";

const Sidebar_ = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const accountType = user?.role?.slug;
  const isClientAdmin = accountType === "client-admin";

  return (
    <div>
      <section className="admin-sidebar">
        <Sidebar
          rootStyles={{
            height: "100vh",
            backgroundColor: "#091F46",
            opacity: 1,
          }}
          width={250}
        >
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                return {
                  color: active ? "#FF9100 !important" : undefined,
                  //color: active ? '#FF9100' : undefined,
                };
              },
            }}
          >
            <div className="border-b mb-1 border-gray-600 flex items-center pt-3 pb-2 px-3">
              <img src={logo} alt="logo" className="w-[110px] h-[60px] pl-5" />
            </div>
            <MenuItem
              active={location.pathname.includes("/admin/dashboard")}
              icon={<span className="bi bi-speedometer text-2xl"></span>}
              component={<Link to={"/admin/dashboard"} />}
            >
              Dashboard
            </MenuItem>
            {!isClientAdmin ? (
              <MenuItem
                active={location.pathname.includes("/admin/clients")}
                icon={<span className="bi bi-person-rolodex text-2xl"></span>}
                component={<Link to={"/admin/clients"} />}
              >
                Clients
              </MenuItem>
            ) : null}
            {isClientAdmin ? (
              // <MenuItem
              //   active={location.pathname.includes("/admin/learners")}
              //   icon={<span className="bi bi-people text-2xl"></span>}
              //   component={<Link to={"/admin/learners"} />}
              // >
              //   Learners
              // </MenuItem>
              <SubMenu
                icon={<span className="bi bi-people text-2xl"></span>}
                label="Learners"
              >
                <MenuItem
                  active={location.pathname === "/admin/learners"}
                  component={<Link to={"/admin/learners"} />}
                >
                  <i className="bi bi-brightness-high"></i> Overview
                </MenuItem>
                <MenuItem
                  active={location.pathname.includes("/learners/analytics")}
                  component={<Link to={"/admin/learners/analytics"} />}
                >
                  <i className="bi bi-graph-up-arrow"></i> Analytics
                </MenuItem>
                <MenuItem
                  active={location.pathname.includes("/teams")}
                  component={
                    <Link to={"/admin/learners/teams"} />
                  }
                >
                  <i className="bi bi-people"></i> Teams
                </MenuItem>
                <MenuItem
                  active={location.pathname.includes("/action-plan")}
                  component={
                    <Link to={"/admin/learners/action-plan"} />
                  }
                >
                  <i className="bi bi-app-indicator"></i> Action Plan
                </MenuItem>
              </SubMenu>
            ) : null}
            {!isClientAdmin ? (
              <SubMenu
                icon={<span className="bi bi-award text-2xl"></span>}
                label="Courses"
              >
                <MenuItem
                  active={location.pathname.includes("/admin/course/")}
                  component={<Link to={"/admin/course/"} />}
                >
                 <i className="bi bi-book"></i> <span className="text-xs">Courses</span>
                </MenuItem>
                <MenuItem
                  active={location.pathname.includes("/admin/courses/module")}
                  component={<Link to={"/admin/courses/module"} />}
                >
                 <i className="bi bi-cookie"></i> <span className="text-xs">Modules</span>
                </MenuItem>
                {/*<MenuItem
                active={location.pathname.includes("/admin/courses/analytics")}
                component={<Link to={"/admin/courses/analytics"} />}
              >
                <span className="text-xs">Analytics</span>
          </MenuItem>*/}
              </SubMenu>
            ) : null}
            {isClientAdmin ? (
              <SubMenu
                icon={<span className="bi bi-bar-chart-steps text-2xl"></span>}
                label="Learning Path"
              >
                <MenuItem
                  active={location.pathname === "/admin/learning-path/"}
                  component={<Link to={"/admin/learning-path/"} />}
                >
                  <i className="bi bi-journal"></i> Paths
                </MenuItem>
                <MenuItem
                  active={location.pathname.includes("/learners-group")}
                  component={
                    <Link to={"/admin/learning-path/learners-group"} />
                  }
                >
                  <i className="bi bi-person-gear"></i> Cohorts
                </MenuItem>
              </SubMenu>
            ) : null}
            {!isClientAdmin ? (
              <SubMenu
                icon={<span className="bi bi-person-gear text-2xl"></span>}
                label="User Management"
              >
                {/*<MenuItem
                active={location.pathname === "/admin/user-management"}
                component={<Link to={"/admin/user-management/"} />}
              >
                Overview
              </MenuItem>
              <MenuItem
                active={location.pathname.includes("/teams")}
                component={<Link to={"/admin/user-management/teams"} />}
              >
                Teams
              </MenuItem>*/}
                <MenuItem
                  active={location.pathname.includes("/roles")}
                  component={<Link to={"/admin/user-management/roles"} />}
                >
                   <i className="bi bi-database-lock"></i> Roles & Permissions
                </MenuItem>
              </SubMenu>
            ) : null}
            {isClientAdmin ? (
              <SubMenu
                icon={<span className="bi bi-brightness-high text-2xl"></span>}
                label="Reports & Analysis"
                className="hidden"
              >
                <MenuItem
                  active={
                    location.pathname === "/admin/report-and-analysis/overview"
                  }
                  component={
                    <Link to={"/admin/report-and-analysis/overview"} />
                  }
                >
                  Overview
                </MenuItem>
                <MenuItem
                  className="hidden"
                  active={location.pathname.includes(
                    "/admin/report-and-analysis/learners"
                  )}
                  component={
                    <Link to={"/admin/report-and-analysis/learners"} />
                  }
                >
                  Learners
                </MenuItem>
                <MenuItem
                  active={location.pathname.includes(
                    "/admin/report-and-analysis/courses"
                  )}
                  component={<Link to={"/admin/report-and-analysis/courses"} />}
                >
                  Courses
                </MenuItem>
                {/*<MenuItem
                active={location.pathname.includes("/admin/report-and-analysis/learning-path")}
                component={<Link to={"/admin/report-and-analysis/learning-path"} />}
               >
                Learning Path
              </MenuItem>
              <MenuItem
                active={location.pathname.includes("/admin/report-and-analysis/programs")}
                component={<Link to={"/admin/report-and-analysis/programs"} />}
              >
                Programs
              </MenuItem>
              <MenuItem
                active={location.pathname.includes("/admin/report-and-analysis/events")}
                component={<Link to={"/admin/report-and-analysis/events"} />}
              >
                Events
              </MenuItem>*/}
              </SubMenu>
            ) : null}
            <MenuItem
              active={location.pathname.includes("/admin/rewards")}
              icon={<span className="bi bi-trophy text-2xl"></span>}
              component={<Link to={"/admin/rewards"} />}
            >
              Rewards
            </MenuItem>
           { isClientAdmin ?
             <SubMenu
             icon={<span className="bi bi-credit-card text-2xl"></span>}
             label="Billing"
           >
             <MenuItem
               active={location.pathname === "/admin/billing/summary"}
               component={<Link to={"/admin/billing/summary"} />}
             >
               <i className="bi bi-sliders2-vertical"></i> Summary
             </MenuItem>
             <MenuItem
              active={location.pathname === "/admin/billing/invoice"}
              component={<Link to={"/admin/billing/invoice"} />}
            >
              <i className="bi bi-receipt"></i> Invoice
            </MenuItem>
             <MenuItem
               active={location.pathname.includes("/admin/billing/pricing")}
               component={<Link to={"/admin/billing/pricing"} />}
               className="hidden"
             >
               Pricing & Calculator
             </MenuItem>
             </SubMenu>
            : 
            <SubMenu
            icon={<span className="bi bi-credit-card text-2xl"></span>}
            label="Billing"
          >
            <MenuItem
              active={location.pathname === "/admin/billing/summary"}
              component={<Link to={"/admin/billing/summary"} />}
            >
              <i className="bi bi-sliders2-vertical"></i> Summary
            </MenuItem>
        
            {/*<MenuItem
              active={location.pathname.includes("/admin/billing/pricing")}
              component={<Link to={"/admin/billing/pricing"} />}
            >
              Pricing & Discount
            </MenuItem>*/}
            <MenuItem
              active={location.pathname.includes("/admin/billing/invoice")}
              component={<Link to={"/admin/billing/invoice"} />}
            >
             <i className="bi bi-receipt"></i>  Invoice   
            </MenuItem>
            {/*<MenuItem
              active={location.pathname.includes("/admin/billing/transactions")}
              component={<Link to={"/admin/billing/transactions"} />}
              className="hidden"
            >
              Transactions
            </MenuItem>*/}
            <MenuItem
              active={location.pathname.includes("/admin/billing/discount")}
              component={<Link to={"/admin/billing/discount"} />}
            >
              <i className="bi bi-coin"></i> Discounts
            </MenuItem>  
            <MenuItem
              active={location.pathname.includes("/admin/billing/course-credit-code")}
              component={<Link to={"/admin/billing/course-credit-code"} />}
            >
             <i className="bi bi-shield-lock"></i> Course Credit Code
            </MenuItem>
            </SubMenu>
           }
           <MenuItem
              active={location.pathname.includes("/admin/email/template-onboarding")}
              icon={<span className="bi bi-envelope text-2xl"></span>}
              component={<Link to={"/admin/email/template-onboarding"} />}
            >
              Email
            </MenuItem>
          { isClientAdmin ?
            <SubMenu
            icon={<span className="bi bi-gear text-2xl"></span>}
            label="Settings"
            component={<Link to={"/admin/settings/"} />}
            />
            : null
          }
          </Menu>
        </Sidebar>
      </section>
    </div>
  );
};

export default Sidebar_;
