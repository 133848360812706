import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ViewRoles from './pages/viewRoles/ViewRoles'
import ViewPermissions from './pages/viewPermissions/ViewPermissions';

const UserRolesRoute = () => {
  
  return (
    <>
       <Routes>
          <Route path='/' element={<ViewRoles />} />
          <Route path='/permissions' element={<ViewPermissions />} />
        </Routes>
    </>
  )
}

export default UserRolesRoute