import React, { useState } from "react";
import vectorImg from "../../../../../assets/Vector.png";
import vectorImg1 from "../../../../../assets/Vector (1).png";
import vectorImg2 from "../../../../../assets/Vector (2).png";
import vectorImg3 from "../../../../../assets/Vector (3).png";
import vectorImg4 from "../../../../../assets/icomoon-free_file-pdf.png";
import vectorImg5 from "../../../../../assets/Group.png";
import ResourseCard from "../../courses/pages/CreateCourse/components/ResourseCard";
import CenteredModal from "./../../../../../components/Modal/CenteredModal";
import toast from "react-hot-toast";
import { v4 as uuid } from "uuid";
import AllMeetingsModals from "../../zoom-integration/pages/AllMeetingsModals";
import AllActionPlanModal from "../../learners/ActionPlan/components/AllActionPlanModal";

const AddResoursePanel = ({
  addResource,
  processFile,
  setShowResourceModal,
  fileType,
  setFileType,
  withTitle = true,
  openPanel = false,
  setModules,
  modules,
}) => {
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [openMeetingModal, setOpenMeetingModal] = useState(false);
  const [openActionPlanModal, setOpenActionPlanModal] = useState(false);
  const [link, setLink] = useState("");

  const openFile = (type, isLink = false) => {
    if (openPanel && !isLink) processFile(type);
    setShowResourceModal(true);
  };

  return (
    <>
      {withTitle && (
        <h5 className="text-xs font-semibold pb-2 border-b border-gray-200">
          Media Library
        </h5>
      )}
       <form id="resouce-form">
          <input
            type="file" accept="image/*" id="image-input" className="hidden"
            onChange={(event) => addResource(event)}
          />
          <input
            type="file" accept="video/*" id="video-input" className="hidden"
            onChange={(event) => addResource(event)}
          />
          <input
            type="file" accept="audio/*" id="audio-input" className="hidden"
            onChange={(event) => addResource(event)}
          />
          <input
            type="file" accept="application/pdf" id="pdf-input" className="hidden"
            onChange={(event) => addResource(event)}
          />
       </form>
     
      <label htmlFor="course-resource" className="hidden"></label>
      <div className="grid grid-cols-3 gap-5 mt-3">
        <ResourseCard
          text={"Image"}
          src={vectorImg}
         // onClick={() => openFile("image")}
          target={'image-input'}
        />
        <ResourseCard
          text={"Video"}
          src={vectorImg1}
          target={'video-input'}
          //onClick={() => openFile("video")}
        />
        <ResourseCard
          text={"PDF"}
          src={vectorImg4}
          target={'pdf-input'}
         // onClick={() => openFile("pdf")}
        />
        <ResourseCard
          text={"Audio"}
          src={vectorImg5}
          target={'audio-input'}
         // onClick={() => openFile("audio")}
        />
        <ResourseCard
          text={"Webinar link"}
          src={vectorImg2}
          onClick={() => {
            openFile("w-link", true);
            setFileType("w-link");
            setTimeout(() => setShowLinkModal(true), 100);
          }}
        />
        <ResourseCard
          text={"Links"}
          /*onClick={() => {
            openFile("r-link", true);
            setFileType("r-link");
            setTimeout(() => setShowLinkModal(true), 100);
          }}*/
          src={vectorImg3}
        />
        <ResourseCard
          text={"Action Plan"}
          onClick={() => {
            setOpenActionPlanModal(true)
          }}
          src={vectorImg3}
        />
        <ResourseCard
          text={"Zoom Meeting"}
          onClick={() => {
            setOpenMeetingModal(true)
          }}
          src={vectorImg3}
        />
      </div>
      <CenteredModal
        open={showLinkModal}
        setOpen={setShowLinkModal}
        style={{ padding: 5 }}
        title={
          <h5 className="pb-2 bi bi-link text-sm border-b border-gray-500 w-full">
            {" "}
            Add Link{" "}
          </h5>
        }
        width={"w-[350px]"}
        withCloseBtn
        closeButtonLabel={"Save Link"}
        onClose={() => {
          const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
          if (!link) toast.error("Please add a resource link.");
          else if (!urlPattern.test(link))
            toast.error("Error! invalid resource link.");
          else {
            setModules([
              ...modules,
              {
                file: { type: fileType },
                link: link,
                type: fileType,
                id: uuid(),
              },
            ]);
            setShowLinkModal(false);
          }
        }}
      >
        <div className="py-4">
          <input
            style={{ borderColor: "#999" }}
            value={link}
            onChange={(e) => setLink(e.target.value)}
            type="text"
            className="input-md"
            placeholder="paste resource link.."
          />
          <p className="text-xs text-red-500"></p>
        </div>
      </CenteredModal>
      <AllMeetingsModals openMeetingModal={openMeetingModal} setOpenMeetingModal={setOpenMeetingModal}/>
      <AllActionPlanModal openActionPlanModal={openActionPlanModal} setOpenActionPlanModal={setOpenActionPlanModal} />
    </>
  );
};

export default AddResoursePanel;
