import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
// import "chartjs-plugin-datalabels";

const StackedBarChart = ({ data, options, indexOf }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy(); // Destroy existing chart instance
      }

      const ctx = chartRef.current.getContext("2d");
      chartInstance.current = new Chart(ctx, {
        type: "bar",
        data: data,
        options: options,
      });
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy(); // Ensure the chart is destroyed on unmount
      }
    };
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default StackedBarChart;
