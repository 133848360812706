import React from "react";
import Header from "../../../layout/header/Header";
import Loader from "../../../../../components/loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faBarsStaggered,
  faChalkboardUser,
  faCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../../components/Buttons/Button";
import { api } from "../../../../../api/learningPath";
import { useQuery } from "react-query";
import Faq from "react-faq-component";
import { convertTime } from "../../../../helpers";
import dummyVideo from "../assets/Intro to Backend Development, a High Level Introduction.mp4";

const PreviewPath = ({ previewIsOpen, handlePreview }) => {
  // State variable to toggle visibility

  const current_path = JSON.parse(sessionStorage.getItem("active-path"));

  const user = JSON.parse(sessionStorage.getItem("user"));

  const { data, isLoading } = useQuery(
    ["Current Path"],
    () => {
      return api.getSinglePath({
        org_id: user?.org?.id,
        journey_id: current_path?.id,
      });
    },
    {
      cacheTime: 300,
      staleTime: 300,
      select: (data) => data.data,
      refetchOnWindowFocus: true,
    }
  );

//   console.log(data);

  const ModuleHeader = ({ text }) => {
    return (
      <h1 className="w-full flex items-center py-3 justify-start gap-2">
        <FontAwesomeIcon icon={faChalkboardUser} /> {text}
      </h1>
    );
  };

  const Modules = ({ modules }) => {
    return (
      <div className="w-full">
        {modules?.map((module, index) => (
          <div
            key={index}
            className="py-3 w-full flex items-center justify-between gap-1 px-2"
          >
            <FontAwesomeIcon icon={faBarsStaggered} />
            <div className="flex-1 ml-2">
              <p className="font-medium text-[14px]">{module?.module?.name}</p>
              <p>{convertTime(module?.module?.duration)}</p>
            </div>
            <p>
              <FontAwesomeIcon
                icon={faCirclePlus}
                className="text-customOrange"
              />{" "}
              {module?.points}
            </p>
          </div>
        ))}
      </div>
    );
  };

  const faqdata = {
    title: data?.name,
    rows: data?.journeyCourses?.map((course) => {
      return {
        title: <ModuleHeader text={course?.course?.name} />,
        content: <Modules modules={course?.course?.courseModules} />,
      };
    }),
  };

  const styles = {
    // bgColor: 'white',
    titleTextColor: "black",
    rowTitleColor: "black",
    titleTextSize: "20px",
    rowTitleTextSize: "14px",
    rowContentTextSize: "12px",
    border: "none",
    // rowContentColor: 'grey',
    arrowColor: "#FF9100",
  };

  const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
  };

  if (isLoading) {
    return (
      <div
        className={`fixed top-0 left-0 w-full h-full bg-white z-50 ${
          previewIsOpen ? "block" : "hidden"
        }`}
      >
        <Header />
        <Loader />
      </div>
    );
  }

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full bg-white z-50 ${
        previewIsOpen ? "block" : "hidden"
      }`}
    >
      <Header />
      <div className="bg-darkBlue py-3 px-8 flex items-center justify-between">
        <button
          className="text-white text-sm flex items-center justify-start gap-2"
          onClick={handlePreview}
        >
          <FontAwesomeIcon icon={faArrowLeftLong} />
          Continue editing
        </button>
        <Button
          text={"Publish Path"}
          // onClick={handlePreview}
          className={
            "bg-transparent text-white border border-white text-xs rounded-md px-10 py-3"
          }
        />
      </div>
      <div className="flex w-full h-full items-start justify-between">
        <div className="border-r border-gray-200 overflow-y-scroll shadow flex-[1] p-2 h-full">
          <Faq data={faqdata} styles={styles} config={config} />
        </div>
        <div className="flex-[4] h-[86vh] bg-gray-100 p-2">
          <video className="w-full bg-white p-3 rounded-md h-full" loop muted controls>
            <source src={dummyVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default PreviewPath;
