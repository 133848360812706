export const transactionHistory = [
    {
      date: '07-08-2024',
      amount: 100.00,
      courses: 2,
      learners: 10,
      credits: 500
    },
    {
      date: '07-08-2024',
      amount: 150.00,
      courses: 3,
      learners: 15,
      credits: 700
    },
    {
      date: '07-08-2024',
      amount: 200.00,
      courses: 4,
      learners: 20,
      credits: 1000
    },
    {
      date: '07-08-2024',
      amount: 250.00,
      courses: 5,
      learners: 25,
      credits: 1200
    },
    {
      date: '07-08-2024',
      amount: 300.00,
      courses: 6,
      learners: 30,
      credits: 1560
    }
  ];

  export const invoices = [
    {
      month: 'January',
      creditTopUp: 50,
      amount: 1000,
      creditUsed: 3000,
      startDate: '01/01/2024', // Nigerian date format: day/month/year
      endDate: '31/01/2024'
    },
    {
      month: 'February',
      creditTopUp: 60,
      amount: 1200,
      creditUsed: 400,
      startDate: '01/02/2024',
      endDate: '29/02/2024'
    },
    {
      month: 'March',
      creditTopUp: 70,
      amount: 1400,
      creditUsed: 5000,
      startDate: '01/03/2024',
      endDate: '31/03/2024'
    },
    {
      month: 'April',
      creditTopUp: 80,
      amount: 1600,
      creditUsed: 60000,
      startDate: '01/04/2024',
      endDate: '30/04/2024'
    },
    {
      month: 'May',
      creditTopUp: 90,
      amount: 1800,
      creditUsed: 700,
      startDate: '01/05/2024',
      endDate: '31/05/2024'
    }
  ];

  export const courses = [
    {
      name: 'Introduction to Programming',
      duration: '40 hours',
      creditCost: 10,
      discount: '$10 for 50+ Learners',
      startDate: '01/08/2024', // Nigerian date format: day/month/year
      endDate: '28/08/2024'
    },
    {
      name: 'Advanced Data Science',
      duration: '60 hours',
      creditCost: 15,
      discount: '$10 for 50+ Learners',
      startDate: '01/09/2024',
      endDate: '12/10/2024'
    },
    {
      name: 'Web Development Bootcamp',
      duration: '80 hours',
      creditCost: 20,
      discount: '$10 for 50+ Learners',
      startDate: '01/10/2024',
      endDate: '26/11/2024'
    },
    {
      name: 'Machine Learning Mastery',
      duration: '50 hours',
      creditCost: 12,
      discount: '$10 for 50+ Learners',
      startDate: '01/11/2024',
      endDate: '05/12/2024'
    },
    {
      name: 'Cybersecurity Essentials',
      duration: '30 hours',
      creditCost: 8,
      discount: '$10 for 50+ Learners',
      startDate: '01/12/2024',
      endDate: '21/12/2024'
    }
  ];

  export const overdueAccounts = [
    {
      clientName: 'Access Bank',
      email: 'contact@accessbank.com',
      amount: 500.00,
      paymentDate: '01/06/2024' // Nigerian date format: day/month/year
    },
    {
      clientName: 'UBA',
      email: 'support@ubagroup.com',
      amount: 7500.00,
      paymentDate: '15/06/2024'
    },
    {
      clientName: 'Fidelity Bank',
      email: 'info@fidelitybank.ng',
      amount: 63200.00,
      paymentDate: '20/06/2024'
    },
    {
      clientName: 'Access Bank',
      email: 'service@accessbank.com',
      amount: 4500.00,
      paymentDate: '25/06/2024'
    },
    {
      clientName: 'UBA',
      email: 'help@ubagroup.com',
      amount: 88200.00,
      paymentDate: '30/06/2024'
    }
  ];

  export const paymentHistory = [
    {
      clientName: 'Access Bank',
      email: 'contact@accessbank.com',
      amount: 15000.00,
      courses: 12,
      learners: 520,
      paymentDate: '05/07/2024', // Nigerian date format: day/month/year
      paymentStatus: 'confirmed'
    },
    {
      clientName: 'UBA',
      email: 'support@ubagroup.com',
      amount: 200.00,
      courses: 15,
      learners: 600,
      paymentDate: '10/07/2024',
      paymentStatus: 'unconfirmed'
    },
    {
      clientName: 'Fidelity Bank',
      email: 'info@fidelitybank.ng',
      amount: 8000.00,
      courses: 13,
      learners: 550,
      paymentDate: '15/07/2024',
      paymentStatus: 'confirmed'
    },
    {
      clientName: 'GTBank',
      email: 'service@gtbank.com',
      amount: 22000.00,
      courses: 18,
      learners: 750,
      paymentDate: '20/07/2024',
      paymentStatus: 'confirmed'
    },
    {
      clientName: 'Zenith Bank',
      email: 'help@zenithbank.com',
      amount: 25000.00,
      courses: 20,
      learners: 800,
      paymentDate: '25/07/2024',
      paymentStatus: 'unconfirmed'
    }
  ];
  
  
  
  
  