import React from 'react'
import Button from '../../../../../../../../../components/Buttons/Button'
import Editor from '../../../../../../../../../components/Editor'
import { useMutation } from 'react-query'
import toast from 'react-hot-toast'
import { api } from '../../../../../../../../../api/teams'
import { errorResponse } from '../../../../../../../../../components/utils/errorResponse'

const TeamInvitation = ({ setCurrentStep, content, setContent, cohortId}) => {

 const orgId = JSON.parse(sessionStorage.getItem('user'))?.org?.id
  const addInvitationMutation = useMutation(api.addTeamInvitationEmail, {
    onSuccess: () => {
        toast.remove()
        toast.success('Team Invitation Email Added Successfully!')
        setCurrentStep(6)
    },
    onError: error => errorResponse(error),
    onMutate: () => {
        toast.loading('Loading...')
    }
  })

  const addTeamInvitationEmail = () => {
    if(!content) toast.error('Error! Add Team Invitation Template')
    else addInvitationMutation.mutate({
          cohortId, orgId, payload: {
            welcomeEmail: content
          }
       })
  }

  return (
    <>
       <div className={`w-[90%]] mx-auto shadow-2xl p-4 rounded-lg h-[auto] bg-white `}>
              <header className='flex justify-between items-center'>
                   <div>
                      <h1 className='text-md font-semibold mt-1'>
                        Step 4 <span className='bi bi-arrow-right'></span> &nbsp;
                        Team Invitation Email
                      </h1>
                      <p className='text-xs mb-2 text-gray-700'>
                        Welcome email to recieve when you are enrolled in a team 
                      </p>
                   </div>
              </header>
             <div className='border-t border-gray-300'>
                  <Editor 
                    content={content}
                    setContent={setContent}
                  />
                  <div className='flex mt-5 justify-end'>
                      <Button 
                         width={'w-[160px]'} 
                        text={'Add Welcome Email'}
                        bgColor={'bg-customBlue'}
                        onClick={ addTeamInvitationEmail }
                      />  
                  </div>    
             </div>
         </div>
    </>
  )
}

export default TeamInvitation