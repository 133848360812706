import React, { useState } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const Editor = ({content, setContent}) => {
    
    const modules = {
        toolbar: [
          [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
          [{ 'size': [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image', 'video'],
        //   [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          [{ 'align': [] }],
          ['code-block'],  // code block
          [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
          ['clean']                                         // remove formatting button
        ],
        clipboard: {
          matchVisual: false,
        },
      };
  return (
    <div className="bg-white p-6 rounded-lg shadow-lg mt-1">
      <ReactQuill theme="snow" value={content} className='px-3' onChange={setContent} modules={modules}
    />
  </div>
  )
}

export default Editor