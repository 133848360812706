
const accountType = JSON.parse(sessionStorage.getItem('user'))?.role?.slug
const isClient = accountType === "client-admin"

export const menus = [
    'Summary', isClient ? 'Active Cohort' : 'Client Overview', 
    isClient ? 'Team Leaderboard' : 'Monthly Revenue', 'Enrollment Rate', 
    'Learners Stage', 'Course Completion Per Week Charts', 'Course Completion'
 ] 

const AppearanceMenu = ({ setOpen, open, visibleSections, setVisibleSection }) => {

 const key = 'client-dashboard-appearance'
 const setAppearance = menu => {
     if(localStorage.getItem(key)){
        const menus = JSON.parse(localStorage.getItem(key))
        if(menus?.includes(menu)){
            const visibleMenus = menus?.filter( menu_ => menu_ !== menu)
            localStorage.setItem(key,JSON.stringify(visibleMenus))
            setVisibleSection(visibleMenus)
        }
        else {
            const visibleMenus = [...menus, menu]
            localStorage.setItem(key,JSON.stringify(visibleMenus))
            setVisibleSection(visibleMenus)
        }
     }
     else localStorage.setItem(key, JSON.stringify([menu]))
 }

  return (
    <>
        <ul
            className={`absolute z-[10] w-[300px] mt-0 float-left m-0 ${open ? 'block' : 'hidden'} min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-surface-dark [&[data-twe-dropdown-show]]:block`}
            aria-labelledby="dropdownMenuButton1"
            data-te-dropdown-menu-ref
          >
             <li className='border-b border-gray-200'>
              <button
                className="w-full whitespace-nowrap pl-2 px-4 py-2 pb-0 text-sm font-normal text-neutral-700 hover:bg-zinc-200/60 focus:bg-zinc-200/60 focus:outline-none active:bg-zinc-200/60 active:no-underline dark:bg-surface-dark"
                data-te-dropdown-item-ref
              >
                 <span className='text-sm text-gray-800 uppercase ml-3 font-medium'> Dashboard Appearances </span>
              </button>
              <p className='text-[11px] pl-5 -mt-2'> Select sections you want to display </p>
            </li>
             {
                menus.map( menu => {
                    return( 
                        <li key={menu}>
                            <button
                                className="flex items-center w-full whitespace-nowrap px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-zinc-200/60 focus:bg-zinc-200/60 focus:outline-none active:bg-zinc-200/60 active:no-underline dark:bg-surface-dark dark:text-white"
                                data-te-dropdown-item-ref
                            >
                                <input 
                                   type="checkbox" 
                                   value={menu}
                                   defaultChecked = {JSON.parse(localStorage.getItem(key))?.includes(menu)}
                                   className="w-[18px] hover:cursor-pointer rounded-lg h-[18px]" 
                                   onClick={ () => setAppearance(menu) }
                                />
                                <span className='text-sm ml-3'> {menu} </span>
                            </button>
                       </li>
                    )
                })
             }
            <li className='border-t border-gray-200 mt-1'> 
              <button
                className="block hover:cursor-pointer text-center bg-gray-100 w-full whitespace-nowrap border border-gray-300 py-2 text-sm font-normal text-red-500 focus:outline-none"
                data-te-dropdown-item-ref
                onClick={() => setOpen(false)}
              >
                 Close
              </button>
            </li>
           </ul>
    </>
  )
}

export default AppearanceMenu