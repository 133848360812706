import React from "react";
import { Dropdown, Ripple, initTE } from "tw-elements";
import userImg from "../../../../assets/user.PNG";
import { Link } from "react-router-dom";

const Header = () => {
  React.useEffect(() => {
   // initTE({ Dropdown, Ripple });
  });

  return (
    <header className="sticky shadow-md">
      <div className="flex items-center py-1 justify-end pr-4">
        <span className="bi bi-search text-xl mr-5"></span>
        <span className="bi bi-bell mr-0 text-xl"></span>
        <div className="relative" data-te-dropdown-ref>
          <button
            className="flex items-center whitespace-nowrap rounded bg-white px-6 pb-2 pt-2.5 text-xs font-medium capitalise leading-normal transition duration-150 ease-in-out focus:outline-none motion-reduce:transition-none"
            type="button"
            id="dropdownMenuButton1"
            data-te-dropdown-toggle-ref
            aria-expanded="false"
            data-te-ripple-init
            data-te-ripple-color="light"
          >
            <img
              src={userImg}
              className="w-[35px] h-[32px] rounded-full mr-1"
              alt="user"
            />
            {JSON.parse(sessionStorage.getItem("user"))?.name}
            <span className="ml-2 w-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </button>
          <ul
            className="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
            aria-labelledby="dropdownMenuButton1"
            data-te-dropdown-menu-ref
          >
            <li>
              <Link
                className="block w-full whitespace-nowrap px-12 py-2 text-sm font-normal text-red-500 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                to={"/auth/login"}
                data-te-dropdown-item-ref
              >
                Logout
              </Link>
            </li>
          </ul>
        </div>
        <Link
            className="block  whitespace-nowrap px-5 py-2 text-sm font-normal rounded-lg text-white bg-red-500 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
            to={"/auth/login"}
            data-te-dropdown-item-ref
          >
             Logout
          </Link>
      </div>
    </header>
  );
};

export default Header;
