import React from 'react'

const Counter = ({values, setFieldValue, value, field}) => {

 const increment = () => setFieldValue(field, value + 1 )

 const decrement = () =>  {
    if(value > 0 ) setFieldValue(field, value - 1 )
 }

  return (
    <div className='flex items-center'>
       <button type='button' className='py-0 px-3 bg-gray-100 text-lg border border-gray-300'
        onClick={increment}
       >
         +
        </button>
       <input 
          type="number" className='py-2 text-center text-xs rounded-lg w-[50px] focus:outline-none focus:border-none px-2 border border-gray-200' 
          value={values[field]} 
          onChange={ event => setFieldValue(field, event.target.value) }
        />
       <button type='button' className='py-0 px-3 bg-gray-100 text-lg border border-gray-300'
        onClick={decrement}
       >
        -
        </button>
    </div>
  )
}

export default Counter