import {useState} from 'react'
import BadgeIcon from '../../../../../../../assets/Badge.png'
import BadgeIconOrange from '../../../../../../../assets/Bagde (5).png'
import BadgeIconGreen from '../../../../../../../assets/Badge (1).png'
import OverviewCard from '../../../../report-and-analysis/components/OverviewCard'
import Button from '../../../../../../../components/Buttons/Button'
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import Search from '../../../../../../../components/Inputs/Search'
import TransactionHistory from './components/TransactionHistory'

const Transactions = () => {

  const [dateRange, setDateRange] = useState([new Date(), new Date()]);

  return (
    <div className='bg-lightBlue p-4'>   
        <header className='text-lg font-semibold flex justify-between items-center'>
            Billing Transactions
            <div className='flex items-center'>
               <div className='mr-3'>
                      <DateRangePicker
                        onChange={setDateRange}
                        format="dd-MM-yyyy"
                        value={dateRange}
                        className={"text-gray-700 w-full bg-white text-xs"}
                      />
                  </div>
                <Button text={'Generate Report'} width={'w-[160px]'} bgColor={'bg-darkBlue'} />
            </div>
        </header>
        <header className='grid pt-5 grid-cols-1 md:grid-cols-4 gap-5'>
           <OverviewCard 
             title={'Clients'}
             total={200}
             iconPath={BadgeIconGreen}
             py={'py-8'}
             headerTextSize='text-2xl'
           />
           <OverviewCard
             title={'Courses'}  
             total={50}
             iconPath={BadgeIconOrange}
             headerTextSize='text-2xl'
             py='py-8'
           />
           <OverviewCard
             title={'Learners'}
             total={80}
             iconPath={BadgeIcon}
             headerTextSize='text-2xl'
             py='py-8'
           />
           <OverviewCard
             title={'Credit Used'}  
             total={2000}
             iconPath={BadgeIconOrange}
             headerTextSize='text-2xl'
             py='py-8'
           />
      </header>
      <section className='mt-5 bg-white p-3 rounded-lg'>
         <header className='flex justify-between items-center'>
            <h2 className='text-sm'>
              Transaction History
            </h2>
            <Search className={'mb-0 w-[300px]'} placeholder={'Search transaction history'} />
            <div className='flex items-center'>
                 <i className='bi bi-filter text-xl mr-3'></i>
                <button className='py-2 px-5 text-xs bg-[#C2C6CE] rounded shadow-lg text-white mr-3'>
                   Client
                </button>
                <button className='py-2 px-5 text-xs bg-customBlue rounded shadow-lg text-white mr-3'>
                   Cohort
                </button>
                <button className='py-2 px-5 text-xs bg-[#C2C6CE] rounded shadow-lg text-white mr-3'>
                   Learning Path
                </button>
                <button className='py-2 px-5 text-xs bg-[#C2C6CE] rounded shadow-lg text-white mr-3'>
                   Courses
                </button>
            </div>
         </header>
      </section>
      <section className='mt-5 bg-white p-3 rounded-lg'>
         <TransactionHistory /> 
      </section>
    </div>
  )
}

export default Transactions