import React, {useState, useEffect} from 'react'
import CenteredModal from '../../../../../../../components/Modal/CenteredModal'
import { api } from '../../../../../../../api/learningPath';
import { useQuery } from 'react-query';
import Search from '../../../../../../../components/Inputs/Search';
import Table from '../../../../../../../components/table/Table';
import Pagination from '../../../../../../../components/pagination/Pagination';
 

const ViewUsers = ({openModal, setOpenModal, activeCohortId}) => {

  const [perPage, setPerPage] = useState(8);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const orgId = JSON.parse(sessionStorage.getItem('user'))?.org?.id
  const { data: users, isLoading, refetch } = useQuery(
    ["cohort users", { id: orgId, cohortId: activeCohortId, search, perPage, currentPage }],
    ({ queryKey }) => {
      return activeCohortId ? api.getCohortUsers(queryKey[1]) : null ;
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  useEffect( () => {
    refetch()
  }, [search, setSearch, perPage, currentPage, activeCohortId] )

  let columns = React.useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index }) =>
          index +
          1 +
          ((users?.meta?.page || 1) - 1) * (users?.meta?.perPage || perPage ),
      },
      {
        title: "Learner Name",
        selector: "name",
        key: "name",
        render: ({ row }) => {                      
            return <span className="">{row?.participant?.user?.name}</span>;
         },
      },
      {
        title: "Email Address",
        selector: "email",
        key: "email",
        render: ({ row}) => {           
          return <span className="">{row?.participant?.user?.email}</span>;
        },
      },
      {
        title: "Status",
        selector: null,
        key: null,
        render: ({ row}) => {           
          return <span className="">{row?.cohort?.status || '------------'}</span>;
        },
      },
      {
        title: "Enrolled At",
        selector: null,
        key: null,
        render: ({ row }) => {           
          return <span className="">
              { !row?.cohort?.enrolledAt ? '-----------' :
              `${ new Date(row?.cohort?.enrolledAt).toLocaleDateString('en-ng')}`
            }
          </span>;
        },
      },
      {
        title: "Date Added",
        selector: null,
        key: null,
        render: ({ row }) => {           
          return <span className="">
              {`${ new Date(row?.cohort?.createdAt).toLocaleDateString('en-ng')}`}
          </span>;
        },
      },
      {
        title: "Last Updated",
        selector: null,
        key: null,
        render: ({ row }) => {           
          return <span className="">
              {`${ new Date(row?.cohort?.updatedAt).toLocaleDateString('en-ng')}`}
          </span>;
        },
      },
    ],
    [1, 20, 20]
  );

const tableProps = { isLoading: isLoading, columns, data:users?.data }

  return (
    <>  
      <CenteredModal  
        title={<h5 className='text-md font-semibold bi bi-eye'> &nbsp; View Cohort Users </h5> }
        open={openModal}
        setOpen={setOpenModal}
        style={{padding: 0}}
        width={'w-[96%] md:w-[780px] h-[500px] overflow-y-auto'}
        headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
      >
         <section className='text-left pb-3 px-5'>
            <div className='flex justfify-between mt-5'>
                <h4 className='text-sm mt-3'>Total Learners ({users?.meta?.total})</h4>
                <div className='ml-4 w-[350px]'>
                        <Search 
                        placeholder={'Search users... e.g ajoke'}
                        onChange={ (e) => {
                            setSearch(e.target.value)
                        }}
                        />
                </div>
            </div>
           <section>
             <Table  
                {...tableProps} 
                  style={{
                  overflowX: 'auto', 
                }}
                headerClassName={'border-t bg-gray-100'}
             />
           </section>
           <section className={`mt-1 px-3 rouded-lg bg-white`}>
            <Pagination 
              data={users?.meta}
              perPage={perPage}
              pageNo={currentPage}
              setPageNo={setCurrentPage}
              setPerPage={setPerPage}
            />
      </section>
         </section>
      </CenteredModal>
    </>
  )
}

export default ViewUsers