import React from 'react'
import Button from '../../../../../../../../components/Buttons/Button'

const ViewPermissions = () => {

  return (
    <section>
     <div className="w-full p-5">
      <h1 className="text-xl font-bold">Roles and Permissions</h1>
      <p className="text-xs text-slate-500">
       Use roles to group your users and assign permissions
      </p>
      <div className="w-full flex items-center justify-between my-4">
        <div className="w-[300px]">
          <input
            type="search"
            className="input-md"
            // value={search}
            placeholder="Search..."
            // onChange={(e) => {
            //   setCurrentPage(1);
            //   setSearch(e.target.value);
            // }}
          />
        </div>
        <div className="flex items-center gap-3 justify-between">
          <Button
            bgColor={"bg-transparent"}
            borderColor={"border-darkBlue"}
            textColor={"text-darkBlue"}
            text={"Cancel"}
            width={"w-[150px]"}
          />
          <Button bgColor={"bg-darkBlue"} text={"Save Changes"} />
        </div>
      </div>
    </div>
    </section>
  )
}

export default ViewPermissions