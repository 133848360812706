import React from 'react'
import CenteredModal from '../../../../../../../../../components/Modal/CenteredModal'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import { useMutation } from 'react-query'
import { api } from '../../../../../../../../../api/roles-and-permissions'
import toast from 'react-hot-toast'

const AddRoleModal = ({ action = null, openModal, setOpenModal, refetch, activeRole }) => {

  const orgId = JSON.parse( window.sessionStorage.getItem('user') )?.org?.id

  const createRoleMutation = useMutation(api.createRole, {
    onMutate: () => {
      toast.loading('Loading...')
    },
    onError: (error) => {
        toast.remove()
        if(error?.response?.message){
          toast.error(error?.response?.data?.message);
        } else toast.error('Error! an unknown error occured.');
    },
    onSuccess: response => {
       toast.remove()
       toast.success('Role added successfully!')
       refetch()
       setOpenModal(false)
    } 
  })

  const editRoleMutation = useMutation(api.updateRole, {
    onMutate: () => {
      toast.loading('Loading...')
    },
    onError: (error) => {
        toast.remove()
        if(error?.response?.message){
          toast.error(error?.response?.data?.message);
        } else toast.error('Error! an unknown error occured.');
    },
    onSuccess: response => {
       toast.remove()
       toast.success('Role updated successfully!')
       refetch()
       setOpenModal(false)
    } 
  })

  const initialValues = {
     name: action === 'edit' ? activeRole?.name : '',
     description: action === 'edit' ? activeRole?.description : '',
     slug: '',
 }

 const validate = values => {
    let errors = {} ;
    if(!values?.name) errors.name = 'Error! Role name is required.'
    else if(!values?.description) errors.description = 'Error! Role description is required.'
    return errors
 }

 const createRole = (values, actions) => {
    const payload = {
       orgId,
       name: values?.name,
       description: values?.description,
       slug: values?.name
    }
    if(action === 'edit') editRoleMutation.mutate({id: activeRole?.id , data: payload })
    else createRoleMutation.mutate(payload);
 }

  return (
    <CenteredModal 
        title={ 
          <h5 className='text-md font-semibold bi bi-plus-circle'> 
              &nbsp;  {action === 'edit' ? 'Edit Role' : 'Add New Role'}  
          </h5> 
        }
        open={openModal}
        setOpen={setOpenModal}
        style={{padding: 0}}
        width={'w-[450px] h-[320px] overflow-y-auto'}
        headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
        withCloseBtn
        closeButtonLabel={action === 'edit' ? 'Edit Role' : 'Create Role'}
        //btnBgColor={'bg-primary'}
        onClose={ () => {
          document.querySelector('#create-role-submit-btn').click()
        }}
      >
         <section className='text-left px-5 py-3'>
         <Formik
                    initialValues={initialValues}
                    validate={validate}
                    enableReinitialize={true}
                    onSubmit={createRole}
                >
                {() => (
                    <Form>
                            {
                              !action && <p className='text-xs'> Create a new role and enable permissions </p>
                            }
                            <div>
                               <Field 
                                  type="text" placeholder='e.g super admin...' 
                                  className='input-md my-3' name='name'
                                />
                              <ErrorMessage name='name' component={'p'} className='text-xs text-red-500 my-1' />
                            </div>
                            <div>
                              <Field 
                                  as='textarea' placeholder='e.g super admin...' 
                                  className='input-md my-3 h-[80px]' name='description' 
                                />
                              <ErrorMessage name='description' component={'p'} className='text-xs text-red-500 my-1' />
                            </div>
                          <button type="submit" id='create-role-submit-btn'></button>
                    </Form>
                )}
           </Formik>
         </section>
      </CenteredModal>
  )
}

export default AddRoleModal