import React, { useEffect, useRef, useState } from 'react'
import { Dropdown, Ripple, initTE } from "tw-elements";
import { useMutation } from 'react-query';
import { api } from '../../../../../../../../../../../api/billing';
import toast from "react-hot-toast";
import * as XLSX from "xlsx/xlsx.mjs";
import { formatCurrency } from '../../../../../summary/Summary';

const GenerateReport = ({id}) => {

  useEffect(() => {
    initTE({ Dropdown, Ripple });
  }, [])

  const downloadXLSX = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "report.xlsx");
    toast.remove()
    toast.success("Downloaded successfully...");
    setOpen(false)
  }

  const pendingPaymentsMutation = useMutation(api.getPendingPayments, {
    onMutate: () => {
      toast.loading('Generating Data...')
    },
    onSuccess: (data) => {
        const documentData = data?.data?.length
        ? data?.data?.map((report) => {
            return {
              ["Client Name"]: report?.organization?.name,
              ["Email Address"]: report?.organization?.email,
              ["Phone Number"]: report?.organization?.phoneNumber,
              ["Amount"]: formatCurrency(report?.amount),
              ["Category"]: report?.category,
              ["Payment Status"]: report?.status,
              ["Date Created"]: new Date(
                report?.createdAt
              ).toLocaleDateString('en-ng'),
            };
          })
        : [];
        downloadXLSX(documentData)
    },
  })

  const paymentsMutation = useMutation(api.getBillingTransactions, {
    onMutate: () => {
      toast.loading('Generating Data...')
    },
    onSuccess: (data) => {
        const months = [
            'January','February','March','April','May','June',
            'July','August','September','October','November','December'
          ];
        const documentData = data?.data?.data?.length
        ? data?.data?.data?.map((report) => {
            return {
              ["Client Name"]: report?.organization?.name,
              ["Year"]: report?.year,
              ["Month"]: months[report?.month],
              ["Transaction Id"]: report?.transactionId,
              ["Amount"]: formatCurrency(report?.amount),
              ["Credits"]: formatCurrency(report?.credit, false),
              ["Category"]: report?.category,
              ["Payment Type"]: report?.type,
              ["Payment Status"]: report?.status,
              ["Date Created"]: new Date(
                report?.createdAt
              ).toLocaleDateString('en-ng'),
            };
          })
        : [];
         downloadXLSX(documentData)
    },
  })

  const [open, setOpen] = useState(false)
  const dropdownRef = useRef(null)

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [])

  const generatePendingPayments = () => {
    pendingPaymentsMutation.mutate({ currentPage: 1, search: '', perPage: 10000 })
  }

  const generatePayments = () => {
    paymentsMutation.mutate({ currentPage: 1, search: '', perPage: 10000 })
  }

  return (
    <div className="relative ml-3" ref={dropdownRef}>
      <button
        className="flex items-center rounded bg-customBlue px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
        type="button"
        id="dropdownMenuButton1"
        aria-expanded={open}
        data-te-ripple-init
        data-te-ripple-color="light"
        onClick={() => setOpen(!open)}
      >
        Generate Report
        <span className="ml-2 [&>svg]:h-5 [&>svg]:w-5">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </button>
      <ul
        className={`absolute z-[1000] mt-2 float-left m-0 ${open ? 'block' : 'hidden'} min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-surface-dark [&[data-twe-dropdown-show]]:block`}
        aria-labelledby="dropdownMenuButton1"
        data-te-dropdown-menu-ref
      >
        <li>
          <button
            className="block w-full text-left whitespace-nowrap bg-white px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-zinc-200/60 focus:bg-zinc-200/60 focus:outline-none active:bg-zinc-200/60 active:no-underline dark:bg-surface-dark dark:text-white dark:hover:bg-neutral-800/25 dark:focus:bg-neutral-800/25 dark:active:bg-neutral-800/25"
            data-te-dropdown-item-ref
            onClick={ generatePendingPayments }
          >
            <i className='bi bi-download'></i> &nbsp; Client Invoices
          </button>
        </li>
        <li>
          <button
            className="block w-full whitespace-nowrap bg-white px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-zinc-200/60 focus:bg-zinc-200/60 focus:outline-none active:bg-zinc-200/60 active:no-underline dark:bg-surface-dark dark:text-white dark:hover:bg-neutral-800/25 dark:focus:bg-neutral-800/25 dark:active:bg-neutral-800/25"
            data-te-dropdown-item-ref
            onClick={generatePayments}
          >
            <i className='bi bi-download'></i> &nbsp; Transaction History
          </button>
        </li>
      </ul>
    </div>
  )
}

export default GenerateReport
