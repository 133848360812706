export const StackedBarChartOption = {
  barPercentage: 0.6,
  categoryPercentage: 0.6,
  borderRadius: 5,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      labels: {
        usePointStyle: true,
        boxWidth: 15,
      },
    },
  },
};

export const StackedBarChartYOption = (title) => ({
  barPercentage: 1,
  categoryPercentage: 0.5,
  borderRadius: 5,
  indexAxis: "y",
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
        boxWidth: 15,
        padding: 20,
      },
    },
    title: {
      display: true,
      text: title,
      position: "bottom",
    },
  },
});

export const doughnutOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
        boxWidth: 15,
      },
    },
  },
};

export const doughnutOptionsLeft = {
  responsive: true,
  plugins: {
    legend: {
      position: "right",
      labels: {
        usePointStyle: true,
        boxWidth: 15,
      },
    },
  },
};
