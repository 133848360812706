import React from 'react'
import Table from '../../../../../../../../components/table/Table';
import Loader from '../../../../../../../../components/loader/Loader';
import EmptyData from '../../../../../../../../components/EmptyData';

const ClientDiscountTable = ({isLoading, data, perPage}) => {

  let columns = React.useMemo( () => [
    {
      title: "ID",
      selector: null,
      key: "id",
      render: ({ index }) =>
        index +
        1 +
        ((data?.meta?.page || 1) - 1) * (data?.meta?.perPage || perPage ),
    },
    {
        title: "Client Code",
        selector: "code",
        key: "code",
        render: ({ item, row }) => {                      
             return( 
                <span className="">{row?.organization?.name}</span>
             )
         },
      },
    {
      title: "Email",
      selector: "",
      key: "",
      render: ({ item, row }) => {                      
           return( 
              <span className="">{row?.organization?.email || '-----'}</span>
           )
       },
    },
    {
      title: "Contact Number",
      selector: "",
      key: "",
      render: ({ item, row }) => {                      
           return( 
              <span className="">{row?.organization?.phoneNumber || '-----'}</span>
           )
       },
    },
    {
      title: "Client Tier",
      selector: "discountAmount",
      key: "discountAmount",
      render: ({ row }) => {                      
        return <span className="">{row?.organization?.discount?.name}</span>;
      }
    },
    {
      title: "Payment Type",
      selector: "",
      key: "",
      render: ({ row }) => {           
        return <span className="">{row?.organization?.paymentType}</span>;
      },
    },
  ],
  [1, 20, 20]
);

const tableProps = { isLoading, columns, data: data?.data }

if(isLoading) return <Loader />

  return (
    <div>
       { data?.data?.length ?
        <Table {...tableProps} /> : <EmptyData text={'No client volume discount found.'} />
       }
    </div>
  )
}

export default ClientDiscountTable;