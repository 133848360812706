import React from "react";
import { Link } from "react-router-dom";

const EmailEditor = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  return (
    <div className="grid place-content-center w-full h-full ">
      <div className=" bg-[#F4F8FF] p-10">
        <div className="flex flex-col justify-center text-center">
          <h2 className="text-[#1D1F2C] text-2xl font-semibold  mb-6">
            Let’s get started, {user?.name}
          </h2>
          <h1 className="text-primary text-3xl font-semibold mb-2">
            Email Configuration
          </h1>
          <p className="text-[#161721] text-lg font-semibold mb-8">
            Get started by selecting the email domain and templates
          </p>
          <Link to={"/admin/email/email-configuration"}>
            <button className="p-3 bg-customBlue text-white text-xs rounded-md">
              Set Up Email
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EmailEditor;
