import React from "react";
import PaymentHistory from "../pages/components/PaymentHistory";
import SummarySection from "./components/SummarySection";
import InvoiceProfile from "./components/InvoiceProfile";

const Invoice = () => {
  return (
    <div className="">
      <div className="flex gap-x-4 p-4">
        <div class="w-1/4">
          <h3 className="text-xl font-semibold text-[#1D1F2C] mb-4">Invoice</h3>
          <InvoiceProfile />
        </div>
        <div class="w-3/4 ">
        <h3 className="text-xl font-semibold text-[#1D1F2C] mb-4">Invoice #2020-05-0001</h3>
          <div class="flex gap-x-4">
            <div class="w-3/5 card-container">
              <SummarySection />
              <div className="card-container mt-4">
                <div className="flex gap-x-6">
                  <div className="bg-[#FAFAFA] p-4 rounded-lg">
                    <div className="flex flex-col mb-4">
                      <p className="text-[#667085] font-normal text-xs">
                        Bill Date
                      </p>
                      <p className="text-[#333843] font-semibold text-sm">
                        03/05/2020
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="text-[#667085] font-normal text-xs">
                        Bill Date
                      </p>
                      <p className="text-[#333843] font-semibold text-sm">
                        03/05/2020
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <div className="flex flex-col mb-4">
                      <p className="text-[#667085] font-normal text-xs">
                        Billing Address
                      </p>
                      <p className="text-[#333843] font-semibold text-sm">
                        Willy Wonka
                      </p>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-[#667085] text-xs font-normal">
                        1445 West Norwood Avenue, Itasca, Illinois, USA
                      </p>
                      <p className="text-[#667085] text-xs font-normal">
                        97223041054 | om@om.com
                      </p>
                      <p className="text-[#667085] text-xs font-semibold">
                        SIRET: 362 521 879 00034
                      </p>
                      <p className="text-[#667085] text-xs font-semibold">
                        VAT: 842-484021
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="my-6">
                    <table className=" divide-y-2 divide-gray-200 bg-white text-xs">
                      <thead className="bg-[#F0F1F3] text-[#1D1F2C] font-medium text-xs">
                        <tr>
                          <th className=" px-1 py-2 font-medium ">No.</th>
                          <th className=" px-1 py-2 font-medium ">ITEM</th>
                          {/* <th className=" px-1 py-2 font-medium ">LEARNERS</th> */}
                          <th className=" px-1 py-2 font-medium">CREDITS</th>
                          <th className=" px-1 py-2 font-medium">UNIT </th>
                          <th className=" px-1 py-2 font-medium">AMOUNT</th>
                          <th className=" px-1 py-2 font-medium">
                            FINAL AMOUNT
                          </th>
                        </tr>
                      </thead>

                      <tbody className="table-body text-xs">
                        <tr>
                          <td className=" px-1 py-2 text-gray-700 ">1</td>
                          <td className=" px-2 py-2 text-gray-700 ">
                            <p className="font-normal text-xs text-[#667085] flex flex-col">
                              <p className="text-xs text-[#333843] font-medium">
                                Name
                              </p>
                              <p> Description</p>
                            </p>
                          </td>

                          {/* <td className=" px-1 py-2 text-gray-700 ">
                            <p className="font-normal text-xs text-[#667085] flex flex-col">
                              <p className="text-xs text-[#333843] font-medium">
                                150
                              </p>
                              <p>Unit(s)</p>
                            </p>
                          </td> */}
                          <td className=" px-1 py-2 text-gray-700 ">$20</td>
                          <td className=" px-1 py-2 text-gray-700">1</td>
                          <td className=" px-1 py-2 text-gray-700">$3,000</td>
                          <td className=" px-1 py-2 text-gray-700">$3,000</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="flex justify-end">
                    <div className="flex  flex-col gap-2 mt-6">
                      <div className="flex gap-6">
                        <p className="text-[#667085] font-normal text-xs">
                          Total Cost
                        </p>
                        <p className="text-[#333843] font-medium text-xs">
                          $3,000
                        </p>
                      </div>
                      <div className="flex gap-6">
                        <p className="text-[#667085] font-normal text-xs">
                          Discount
                        </p>
                        <p className="text-[#333843] font-medium text-xs">
                          $30
                        </p>
                      </div>
                      <div className="flex gap-6">
                        <p className="text-[#667085] font-normal text-xs">
                          Total VAT
                        </p>
                        <p className="text-[#333843] font-medium text-xs">$0</p>
                      </div>
                      <div className="flex gap-6 mt-6">
                        <p className="text-[#333843] font-bold text-xs">
                          Total Price
                        </p>
                        <p className="text-[#333843] font-bold text-xs">
                          $3,030.00
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2 mt-4">
                <p className="text-[#667085] font-normal text-xs">
                  Terms & Conditions
                </p>
                <p className="text-[#333843] font-medium text-xs">
                  Please pay within 15 days of receiving this invoice.
                </p>
              </div>
            </div>
            <div class="w-2/5 ">
              <div className="mb-4 text-[#FC9736] flex gap-x-2 justify-center items-center py-3 px-4 bg-[#FFF5EB] rounded-lg border border-[#FC9736] shadow">
                <i className="bi bi-clock-history"></i>
                <p className="font-semibold text-sm">Payment Due</p>
              </div>
              <div className="mb-4">
                <div className="p-6 bg-white rounded-xl shadow-md flex justify-center">
                  <button className="text-sm font-semibold text-white bg-[#114185] flex gap-x-2 justify-center items-center py-3 px-4  rounded-lg shadow">
                    <i class="bi bi-send"></i>Download Invoice Only
                  </button>
                </div>
              </div>
              <div className="mb-4">
                <div className="py-6 px-2 bg-white rounded-xl shadow-md flex justify-center">
                  <button className="text-sm font-semibold text-white bg-[#114185] flex gap-x-2 justify-center items-center py-3 px-4  rounded-lg shadow">
                    <i class="bi bi-send"></i>Download Invoice and Transactions
                  </button>
                </div>
              </div>
              <div className="p-3 bg-white rounded-lg border border-gray-200 shadow">
                <PaymentHistory />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
