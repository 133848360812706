import React, { useEffect, useState } from 'react'
import Button from '../../../../../../../../components/Buttons/Button'
import Step from './components/Step'
import CreateCohort from './components/CreateCohort'
import AddLearningPath from './components/AddLearningPath'
import AddLearnersToCohort from './components/AddLearnersToCohort'
import { Link } from 'react-router-dom'
import CohortInvitationEmail from './components/CohortInvitationEmail'
import AddTeams from './components/AddTeams'
import { v4 } from 'uuid'
import { teamInvitation } from './components/templates/teamInvitation'
import { welcomePage } from './components/templates/welcomePage'
import TeamInvitation from './components/TeamInvitation'
import CohortWelcomeMessage from './components/CohortWelcomeMessage'
import { cohortInvitation } from './components/templates/cohortInvitation'

const CreateCohortStepsWrapper = () => {

  const [cohortName, setCohortName] = useState('')
  const [currentStep, setCurrentStep] = useState(1)
  const [status, setStatus] = useState(true)
  const [enableTeam, setEnableTeam] = useState(false)
  const [cohortId, setCohortId] = useState(false)
  const [teams, setTeams] = useState([ { id: v4 (), name: '' } ])
  const [team, setTeam] = useState(null)
  const [enableLeaderBoard, setEnableLeaderBoard] = useState(true)
  const [ mapLearnerMethod, setMapLearnerMethod ] = useState(1)
  const [value, setValue] = useState(1)
  const [selectedTeams, setSelectedTeams] = useState([])
  const [selectedJourney, setSelectedJourney] = useState(null)
  const [learners, setLearners] = useState([])
  const [defaultLearners, setDefaultLearners] = useState([])
  const [checked, setChecked] = useState(true)
  const [duplicateLearners, setDuplicateLearners] = useState([])
  const [invalidLearners, setInvalidLearners] = useState([])
  const [content, setContent] = useState(cohortInvitation)
  const [teamInvitationContent, setTeamInvitationContent] = useState(teamInvitation)
  const [welcomePageContent, setWelcomePageContent] = useState(welcomePage)

  useEffect(() => {
    document.querySelectorAll('*').forEach( element => {
       element.scroll({top: 0, left: 0, behavior: 'instant'})
    });
  }, [currentStep]);

  const increment = () => {
    setValue(value + 1 )
 }

 const decrement = () =>  {
    if(value > 1 ) {
        setValue(value - 1 )
    }
 }

  return (
    <div className='p-3 pb-12'>  
         <nav className='mt-1 flex items-center'>
               <Link to={'/admin/learning-path/learners-group/view'} className='text-xs'>
                  Cohorts
               </Link>
               <span className='bi pl-1 text-xs bi-chevron-right'></span>
               <span className='bi pr-1 -ml-2 text-xs bi-chevron-right'></span>
               <Link to={'/admin/learning-path/learners-group/create'} className='text-xs'>
                  Create Cohort
               </Link>
           </nav>
         <header className='flex justify-between'>
           <nav className='mt-1 flex items-center'>
                Step {currentStep} of 6 &nbsp;&nbsp; &nbsp;&nbsp; 
                <Step color={ currentStep >= 1 ? 'bg-primary' : null } /> 
                <Step color={ currentStep >= 2 ? 'bg-primary' : null } /> 
                <Step color={ currentStep >= 3 ? 'bg-primary' : null } />
                <Step color={ currentStep >= 4 ? 'bg-primary' : null } />
                <Step color={ currentStep >= 5 ? 'bg-primary' : null } />
                <Step color={ currentStep >= 6 ? 'bg-primary' : null } />
           </nav>
           <Button   
                width={'w-[100px]'} text={'Back'}
                bgColor={'bg-customBlue'}
                onClick={ () => {
                  if(currentStep === 1 && enableTeam){
                     setEnableTeam(false)
                  }
                  if(currentStep > 1 ) setCurrentStep( currentStep - 1 )
                }}
            />
         </header>
          <section>
              {
                (currentStep === 1 && !enableTeam) &&
                 <CreateCohort 
                     cohortName={cohortName} setCohortName={setCohortName}
                     setCurrentStep={setCurrentStep} status={status}
                     setStatus={setStatus} setEnableTeam={setEnableTeam}
                     cohortId={cohortId} setCohortId={setCohortId}
                />
              }
              {
                (currentStep === 1 && enableTeam) &&
                 <AddTeams 
                     setCurrentStep={setCurrentStep} status={status} cohortId={cohortId}
                     setStatus={setStatus} teams={teams} setTeams={setTeams}
                     team={team} setTeam={setTeam} enableLeaderBoard={enableLeaderBoard}
                     setEnableLeaderBoard={setEnableLeaderBoard} value={value}
                     mapLearnerMethod={mapLearnerMethod} increment={increment}
                     setMapLearnerMethod={setMapLearnerMethod} decrement={decrement}
                     selectedTeams={selectedTeams} setSelectedTeams={setSelectedTeams}
                />
              }
              {
                currentStep === 2 &&
                 <AddLearningPath 
                  setCurrentStep={setCurrentStep}
                  selectedJourney={selectedJourney}
                  setSelectedJourney={setSelectedJourney}
                  cohortId={cohortId}
                />
              }
              {
                currentStep === 3 &&
                 <AddLearnersToCohort
                    setCurrentStep={setCurrentStep} cohortId={cohortId} learners = {learners}
                    setLearners={setLearners} defaultLearners ={defaultLearners}
                    setDefaultLearners={setDefaultLearners} checked={checked}
                    setChecked={setChecked} duplicateLearners={duplicateLearners}
                    setDuplicateLearners={setDuplicateLearners} invalidLearners={invalidLearners}
                    setInvalidLearners={setInvalidLearners}
                />
              }
              {
                currentStep === 4 &&
                 <CohortInvitationEmail 
                    setCurrentStep={setCurrentStep}
                    cohortId={cohortId}
                    content={content}
                    setContent={setContent}
                />
              }
              {
                currentStep === 5 &&
                 <TeamInvitation
                    setCurrentStep={setCurrentStep}
                    cohortId={cohortId}
                    content={teamInvitationContent}
                    setContent={setTeamInvitationContent}
                />
              }
              {
                currentStep === 6 &&
                 <CohortWelcomeMessage
                    setCurrentStep={setCurrentStep} 
                    cohortId={cohortId}
                    content={welcomePageContent}
                    setContent={setWelcomePageContent}
                />
              }
          </section>
    </div>
  )
}

export default CreateCohortStepsWrapper