import React, { useState } from "react";
import { ScaleLoader } from "react-spinners";
import ProgressBar from "./ProgressBar";
import { useParams } from "react-router-dom";
import {useQuery } from "react-query";
import { api } from "../../../../../../../api/report";
import toast from "react-hot-toast";

const CoursesSection = () => {
  const { learnerId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const orgId = JSON.parse(window.sessionStorage.getItem("user"))?.org?.id;

  const { data: coursess } = useQuery(
    ["learner-courses"],
    async () => {
      const response = await api.getIndividualLearnerCourses({orgId, learnerId});
      return response;
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const { data:assessmentss } = useQuery(
    ["learner-assessment"],
    async () => {
      const response = await api.getIndividualLearnerAssessment({orgId, learnerId});
      return response;
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const courses = [
    {
      name: "Website Designing",
      progress: 30,
      performance: "3/5",
      timeSpent: "2h 30m",
      examDate: "27 Aug 2023",
      status: "In Progress",
    },
    {
      name: "Microsoft Tech. Company",
      progress: 50,
      performance: "4/5",
      timeSpent: "4h 00m",
      examDate: "21 Aug 2023",
      status: "In Progress",
    },
    {
      name: "JavaScript Basics",
      progress: 80,
      performance: "5/5",
      timeSpent: "3h 20m",
      examDate: "10 Jul 2023",
      status: "Finished",
    },
    {
      name: "React Consultant",
      progress: 100,
      performance: "5/5",
      timeSpent: "5h 10m",
      examDate: "15 May 2023",
      status: "Finished",
    },
    {
      name: "Google Tech. Company",
      progress: 100,
      performance: "5/5",
      timeSpent: "6h 00m",
      examDate: "01 Apr 2023",
      status: "Finished",
    },
  ];

  const assessments = [
    {
      name: "Assessment 1",
      noOfAttempts: 2,
      score: "80%",
      timeSpent: "1h 20m",
      examDate: "29 May 2023",
      status: "Passed",
    },
    {
      name: "Assessment 2",
      noOfAttempts: 1,
      score: "90%",
      timeSpent: "1h 10m",
      examDate: "14 Jun 2023",
      status: "Passed",
    },
    {
      name: "Assessment 3",
      noOfAttempts: 3,
      score: "70%",
      timeSpent: "1h 50m",
      examDate: "05 Jul 2023",
      status: "Failed",
    },
  ];
 

  return (
    <div>
      <div className="bg-white shadow p-4 rounded-lg mb-8">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold">Courses</h3>
          <button className="bg-blue-600 text-white py-2 px-4 rounded-md">
            Filter
          </button>
        </div>
        {isLoading ? (
          <div className="mt-10 flex justify-center items-center">
            <ScaleLoader color="#602977" />
          </div>
        ) : (
          <div className="mt-4">
            <div className="overflow-x-auto">
              <table className="table-container">
                <thead className="table-header">
                  <tr>
                    <th className="table-head text-left">Courses</th>
                    <th className="table-head text-center">Progress</th>
                    <th
                      className="table-head "
                      //   onClick={() => handleSort("duplicateVotes")}
                    >
                      No. Attempts
                    </th>
                    <th
                      className="table-head"
                      //   onClick={() => handleSort("date")}
                    >
                      Time spent
                    </th>
                    <th
                      className="table-head"
                      //   onClick={() => handleSort("date")}
                    >
                      Due Date
                    </th>
                    <th
                      className="table-head"
                      //   onClick={() => handleSort("updatedAt")}
                    >
                      Status
                    </th>
                  </tr>
                </thead>

                <tbody className="table-body text-xs">
                  {courses?.length > 0 ? (
                    courses.map((course, index) => (
                      <tr key={index}>
                        <td className="table-data ">
                          <div className="flex gap-4 items-center">
                            <div className="rounded-full h-8 w-8 bg-gray-500"></div>
                            <div className="flex flex-col">
                              <p className="font-semibold text-sm text-[#1D1F2C]">
                                {course.name}
                              </p>
                              <p className="font-normal text-xs text-[#667085]">
                                Cohort 4
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="table-data ">
                          <ProgressBar progress={course.progress} />
                        </td>
                        <td className="table-data ">{course.performance}</td>
                        <td className="table-data ">
                          {course.timeSpent}
                          {/* {moment(course.date).format("DD/MM/YYYY, h:mm a")} */}
                        </td>
                        <td className="table-data ">
                          {course.examDate}
                          {/* {moment(course.date).format("DD/MM/YYYY, h:mm a")} */}
                        </td>
                        <td className="table-data">
                          <span
                            className={`px-2 py-1 rounded-full text-xs font-semibold ${
                              course.status === "In Progress"
                                ? "bg-[#FFF0EA] text-[#F86624]"
                                : "bg-[#E9FAF7] text-[#1A9882]"
                            }`}
                          >
                            {course.status}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="table-data" colSpan="12">
                        <p className="text-center">No Data Available</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <div className="bg-white shadow p-4 rounded-lg mb-8">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold">Assessment</h3>
          <button className="bg-blue-600 text-white py-2 px-4 rounded-md">
            Filter
          </button>
        </div>
        {isLoading ? (
          <div className="mt-10 flex justify-center items-center">
            <ScaleLoader color="#602977" />
          </div>
        ) : (
          <div className="mt-4">
            <div className="overflow-x-auto">
              <table className="table-container">
                <thead className="table-header">
                  <tr>
                    <th className="table-head text-left">Assessment</th>
                    <th className="table-head text-center">No. Attempts</th>
                    <th
                      className="table-head "
                      //   onClick={() => handleSort("duplicateVotes")}
                    >
                      Score
                    </th>
                    <th
                      className="table-head"
                      //   onClick={() => handleSort("date")}
                    >
                      Time spent
                    </th>
                    <th
                      className="table-head"
                      //   onClick={() => handleSort("date")}
                    >
                     Date taken
                    </th>
                    <th
                      className="table-head"
                      //   onClick={() => handleSort("updatedAt")}
                    >
                      Status
                    </th>
                  </tr>
                </thead>

                <tbody className="table-body text-xs">
                  {assessments?.length > 0 ? (
                    assessments.map((assessment, index) => (
                      <tr key={index}>
                        <td className="table-data ">
                          <div className="flex gap-4 items-center">
                            <div className="rounded-full h-8 w-8 bg-gray-500"></div>
                            <div className="flex flex-col">
                              <p className="font-semibold text-sm text-[#1D1F2C]">
                                {assessment.name}
                              </p>
                              <p className="font-normal text-xs text-[#667085]">
                                Cohort 4
                              </p>
                            </div>
                          </div>
                        </td>
                        {/* <td className="table-data ">
                          <ProgressBar progress={assessment.progress} />
                        </td> */}
                        <td className="table-data ">{assessment.noOfAttempts}</td>
                        <td className="table-data ">{assessment.score}</td>
                        <td className="table-data ">
                          {assessment.timeSpent}
                          {/* {moment(assessment.date).format("DD/MM/YYYY, h:mm a")} */}
                        </td>
                        <td className="table-data ">
                          {assessment.examDate}
                          {/* {moment(assessment.date).format("DD/MM/YYYY, h:mm a")} */}
                        </td>
                        <td className="table-data">
                          <span
                            className={`px-2 py-1 rounded-full  text-xs font-semibold ${
                              assessment.status === "Failed"
                                ? "bg-[#FEECEE] text-[#EB3D4D]"
                                : "bg-[#E9FAF7] text-[#1A9882]"
                            }`}
                          >
                            {assessment.status}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="table-data" colSpan="12">
                        <p className="text-center">No Data Available</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CoursesSection;
