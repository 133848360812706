import React, {useState} from 'react'
import CenteredModal from './../../../../../../components/Modal/CenteredModal';
import { Form, Formik, Field, ErrorMessage } from 'formik'
import CreateClientForm from './CreateClientForm';
import { createClientValidation } from '../utils/validations';
import { useMutation } from 'react-query';
import { api } from '../../../../../../api/clients';
import toast from 'react-hot-toast';
import URL from '../../../../../../url/index';

const CreateClientModal = ({ openModal, setOpenModal, refetch, type }) => {

 const [visible, setVisible] = useState(false) 
 const [cpVisible, setCpVisible] = useState(false) 

 const createClientMutation = useMutation(api.createClient, {
    onMutate: () => {
      toast.loading('Loading...')
    },
    onError: (error) => {
        toast.remove()
        if(error?.response?.message){
           toast.error(error?.response?.message)
        }
         else toast.error(error?.response?.data?.message);
       // }else toast.error('Error! an unknown error occured.');
    },
    onSuccess: response => {
       toast.remove()
       toast.success('Client account added successfully!')
       refetch()
       setOpenModal(false)
    } 
 })

 const initialValues = {
    email: '', phone: '', ogName: '', ogAddress: '', role: '',
    fname: '', lname: '', password: '', cpassword: '', paymentType: 'pre-paid',
 }

 const validate = (values) => createClientValidation(values)
 
   const createClient = (values, actions) => {
      
    const payload = {
        firstName: values?.fname,
        lastName: values?.lname,
        email: values?.email,
        password: values?.password,
        passwordConfirmation: values?.cpassword,
        organizationName: values?.ogName,
        organizationAddress: values?.ogAddress,
        organizationEmail: values?.email,
        domain: values?.role,
        phoneNumber: values?.phone,
        paymentType: values?.paymentType,
        baseUrl: window.location.origin
    }
    createClientMutation.mutate(payload)
   }

  return (
    <> 
      <CenteredModal 
        title={<h5 className='text-md font-semibold bi bi-plus-circle'> &nbsp; Add New Client </h5> }
        open={openModal}
        setOpen={setOpenModal}
        style={{padding: 0}}
        width={'w-[550px] h-[500px] overflow-y-auto'}
        headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
        withCloseBtn
        closeButtonLabel={'Create Client'}
        btnBgColor={'bg-primary'}
        onClose={ () => {
           document.querySelector('#create-client-submit-btn').click()
        }}
      >
         <section className='text-left pb-3 px-5'>
            <Formik
                    initialValues={initialValues}
                    validate={validate}
                    enableReinitialize={true}
                    onSubmit={createClient}
                >
                {({ values, setFieldError, setFieldValue, isValid }) => (
                    <Form>
                         <h3 className='text-sm text-primary pt-2'>
                            Please fill the required fields marked with *
                         </h3>
                        <CreateClientForm 
                          cpVisible={cpVisible}
                          visible={visible}
                          setVisible={setVisible}
                          setCpVisible={setCpVisible}
                        />
                        <button type="submit" id='create-client-submit-btn'></button>
                    </Form>
                )}
           </Formik>
         </section>
      </CenteredModal>
    </>
  )
}

export default CreateClientModal