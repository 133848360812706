import React, { useContext } from "react";
import useLearners from "../../../../../hooks/useLearners";
import { ResponsiveLine } from "@nivo/line";
import { LearnersContext } from "../../../../../context/learnersContext";
import { BarLoader } from "react-spinners";

const Completion = () => {
  const {
    cohortAnalyticCompletion,
    loadingCohortAnalyticCompletion,
    pathAnalyticCompletion,
    loadingPathAnalyticCompletion,
    courseAnalyticCompletion,
    loadingCourseAnalyticCompletion,
  } = useLearners();

  const { pageType } = useContext(LearnersContext);

  const dataSet = {
    cohort: {
      isLoading: loadingCohortAnalyticCompletion,
      data: cohortAnalyticCompletion?.dateRange,
    },
    path: {
      isLoading: loadingPathAnalyticCompletion,
      data: pathAnalyticCompletion?.dateRange,
    },
    course: {
      isLoading: loadingCourseAnalyticCompletion,
      data: courseAnalyticCompletion?.dateRange,
    },
  };

  // console.log(dataSet[pageType]);

  const chartdata = [
    {
      id: "japan",
      color: "#FF9100",
      data: dataSet[pageType]?.data?.map((item, index) => {
        return { x: item?.date, y: item?.count };
      }),
    },
  ];

  const maxCount = dataSet[pageType]?.data?.reduce((max, item) => {
    return item.count > max ? item.count : max;
  }, 0);

  // console.log(maxCount);
  // console.log(cohortAnalyticCompletion);

  if (dataSet[pageType]?.isLoading) {
    return (
      <div className="border border-white shadow-lg p-3 bg-white rounded-md">
        <h1>Completion</h1>
        <p className="text-gray-500 text-xs">Cohort Completion</p>
        <div className="w-full h-[350px] flex items-center justify-center mt-2">
          <BarLoader size={10} color="#FF9100" />
        </div>
      </div>
    );
  }

  return (
    <div className="border border-white shadow-lg p-3 bg-white rounded-md">
      <h1>Completion</h1>
      <p className="text-gray-500 text-xs">Cohort Completion</p>
      {dataSet[pageType]?.data?.length && (
        <div className="w-full h-[350px] mt-4">
          <ResponsiveLine
            data={chartdata}
            margin={{ top: 20, right: 30, bottom: 80, left: 45 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: maxCount ? "auto" : "0",
              max: maxCount ? "auto" : "100",
              stacked: true,
              reverse: false,
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              legend: "",
              legendOffset: 36,
              legendPosition: "middle",
              tickRotation: -45,
            }}
            axisLeft={{
              orient: "left",
              legend: "",
              legendOffset: -40,
              legendPosition: "middle",
            }}
            colors={{ scheme: "category10" }}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "#091F46" }}
            pointLabelYOffset={-12}
            // useMesh={true}
          />
        </div>
      )}
    </div>
  );
};

export default Completion;
