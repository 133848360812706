import React from 'react'

const DataCard = ({value, isPercentage = false, width = 'w-[65px]', withBorder=true}) => {

 const color = value => {
    if(value < 20) return 'bg-slate-200'
    else if(value >= 20 && value < 50) return 'bg-slate-300' 
    else if(value >= 50 && value < 100) return 'bg-slate-400' 
    else if(value >= 100 && value < 200) return 'bg-slate-500 text-white'
    else if(value >= 200) return 'bg-slate-600 text-white'  
    else return 'bg-gray-100'
 }
  return (
    <div className={`${width} mb-[1px] text-[11px] font-semibold h-[40px] ${
        withBorder ? 'border-r border-white' : ''
       } flex justify-center items-center ${color(value)}`}
    >
       {value}{isPercentage && '%'}
    </div>
  )
}

export default DataCard