import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import Input from "../../../../Auth/components/Input";
import SingleSelectDropdown from "../../../components/SingleSelectDropdown";
import { api } from "../../../../../api/action-plan";
import toast from "react-hot-toast";

const timelineUnitOptions = [
  // { label: "Year", value: "month" },
  { label: "Month", value: "month" },
  { label: "Week", value: "week" },
  { label: "Day", value: "day" },
];

const activityOptions = [
  { label: "Courses", value: "courses" },
  { label: "Quiz", value: "quiz" },
  { label: "Zoom", value: "zoom" },
];

const CreateActionPlan = () => {
  const navigate = useNavigate();
  const orgId = JSON.parse(window.sessionStorage.getItem("user"))?.org?.id;
  const [quantity, setQuantity] = useState(0);
  const [learningQuantity, setLearningQuantity] = useState(0);
  const [selectedPosition, setSelectedPosition] = useState("");
  const [goalSubmitted, setGoalSubmitted] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [completeDate, setCompleteDate] = useState(null);
  const [editStartDate, setEditStartDate] = useState(null);
  const [editEndDate, setEditEndDate] = useState(null);
  const [reminderDate, setReminderDate] = useState(null);
  const [isNotCompleteChecked, setIsNotCompleteChecked] = useState(false);
  const [isRepeatChecked, setIsRepeatChecked] = useState(false);
  const [learnerSelectsDate, setLearnerSelectsDate] = useState(false);

  const [timelineUnit, setTimelineUnit] = useState("");
  const [selectActivity, setSelectedActivity] = useState("");

  const initialValues = {
    title: "",
    timelineForAchievement: "",
  };

 
  const handleEditStartDateChange = (date) => {
    setEditStartDate(date);
  };
  const handleEditEndDateChange = (date) => {
    setEditEndDate(date);
  };
  const handleReminderDate = (date) => {
    setReminderDate(date);
  };

  const handleCheckboxChange = (event) => {
    setSelectedPosition(event.target.name);
  };
  const handleGoalSubmittedChange = (event) => {
    setGoalSubmitted(event.target.name);
  };

  const handleNotCompleteChange = () => {
    setIsNotCompleteChecked(!isNotCompleteChecked);
  };
  const handleRepeatChange = () => {
    setIsRepeatChecked(!isRepeatChecked);
  };

  const handleLearnerSelectDate = () => {
    setLearnerSelectsDate(!learnerSelectsDate);
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("meeting topic is Required"),
     });

  const handleTimelineUnit = (option) => {
    setTimelineUnit(option.value);
  };

  const handleSelectActivity = (option) => {
    setSelectedActivity(option.value);
  };

  const handleIncrement = async () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
  };

  const handleDecrement = async () => {
    const newQuantity = Math.max(0, quantity - 1); // Ensure quantity does not go below 0
    setQuantity(newQuantity);
  };

  const handleLearningIncrement = async () => {
    const newQuantity = learningQuantity + 1;
    setLearningQuantity(newQuantity);
  };

  const handleLearningDecrement = async () => {
    const newQuantity = Math.max(0, learningQuantity - 1); // Ensure quantity does not go below 0
    setLearningQuantity(newQuantity);
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    console.log(date, "date");
    // const dateTimeString = date.toISOString().split(".")[0];
    // setDateTime(dateTimeString);
    // Extract the timezone string
  };

  const handleCompleteDateChange = (date) => {
    setCompleteDate(date);
  };

  const handleCreateActionPlan = async (values) => {
    toast.loading("Loading...");
    let payload = {
      name: values.title, // String - Required
      numberOfGoals: quantity, // Integer - Required
      keyLearningPerGoal: learningQuantity, // Integer - Required
      position: selectedPosition, // Enum - Required, Possible values: "before", "after", "on"
      activityInCohorts: selectActivity, // String - Required
      startDateTime: startDate, // Date - Optional, Format: ISO 8601
      completeByDateTime: completeDate, // Date - Optional, Format: ISO 8601
      notCompleteUntilSubmit: isNotCompleteChecked, // Boolean - Required
      repeat: isRepeatChecked, // Boolean - Required
      goalEditAfterSubmit: goalSubmitted, // Enum - Required, Possible values: "anytime", "betweenDates", "allCourseComplete"
      editStartDateTime: editStartDate, // Date - Optional, Format: ISO 8601, if goalEditAfterSubmit is "betweenDates"
      editEndDateTime: editEndDate, // Date - Optional, Format: ISO 8601, if goalEditAfterSubmit is "betweenDates"
      timelineForAchievement: values.timelineForAchievement, // Integer - Optional
      timelineUnit: timelineUnit, // Enum - Optional, Possible values: "month", "week", "day"
      learnerSelectsDate: learnerSelectsDate, // Boolean - Optional
      sendReminders: true, // Boolean - Optional
      reminderEmail: "123e4567-e89b-12d3-a456-426614174001", // UUID - Optional, should match the ID of an email template
      reminderSendOn: reminderDate, // Date - Optional, Format: ISO 8601
      status: "active",
    };
    console.log("payload", payload);

    try {
      const response = await api.createActionPlan({ orgId, payload });
      toast.remove();
      toast.success(response.message);
      navigate("/admin/learners/action-plan");
    } catch (error) {
      toast.remove();
      toast.warning(error.response.data.message);
    }
  };

  return (
    <div className="p-10 2xl:grid 2xl:place-content-center">
      <h1 className="mb-3 text-3xl text-[#1E1E1E]">Action Plan</h1>
      <div className="flex gap-x-40 mb-3">
        <div className="flex flex-col">
          <h3 className="text-primary text-lg font-semibold">
            Action Plan Information
          </h3>
          <p className="text-base text-[#4A4C56] font-medium">
            Add details about the number of key learnings and actions
          </p>
        </div>
        <div>
          <p className="text-sm font-medium text-[#1D1F2C]">Key Learnings</p>
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, actions) => handleCreateActionPlan(values, actions)}
      >
        {({ values, setFieldError, setFieldValue, Er }) => (
          <Form>
            <div className="flex gap-x-36 mb-3">
              <div>
                <Input
                  label={"Action Title"}
                  name={"title"}
                  type="text"
                  colorScheme={"orange"}
                />
              </div>
              <div className="flex flex-col">
                <h5>Number of Goals</h5>
                <div className="inline-flex gap-x-2 items-center mt-2">
                  <button
                    type="button"
                    onClick={handleDecrement}
                    className="bg-white border border-[#4B5B78] text-gray-600 hover:bg-gray-100 active:bg-gray-200  py-2 px-2  rounded-lg"
                  >
                    <i className="bi bi-dash"></i>
                  </button>
                  <input
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(Number(e.target.value))}
                    className="w-16 border border-[#4B5B78] bg-white text-gray-600 text-center py-2 px-4 rounded-lg"
                    min="0"
                  />
                  {/* <div className="border border-[#4B5B78] bg-white text-gray-600  inline-flex items-center py-2 px-4 select-none rounded-lg">
                    {quantity}
                  </div> */}
                  <button
                    type="button"
                    onClick={handleIncrement}
                    className="bg-white  border border-[#4B5B78] text-gray-600
                        hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50
                        inline-flex items-center py-2 px-2  rounded-lg"
                  >
                    <i className="bi bi-plus"></i>
                  </button>
                </div>
              </div>

              <div className="flex flex-col">
                <h5>Key learning per goal</h5>
                <div className="inline-flex gap-x-2 items-center mt-2">
                  <button
                    type="button"
                    onClick={handleLearningDecrement}
                    className="bg-white border border-[#4B5B78] text-gray-600 hover:bg-gray-100 active:bg-gray-200  py-2 px-2  rounded-lg"
                  >
                    <i className="bi bi-dash"></i>
                  </button>
                  <input
                    type="number"
                    value={learningQuantity}
                    onChange={(e) =>
                      setLearningQuantity(Number(e.target.value))
                    }
                    className="w-16 border border-[#4B5B78] bg-white text-gray-600 text-center py-2 px-4 rounded-lg"
                    min="0"
                  />
                  {/* <div className="border border-[#4B5B78] bg-white text-gray-600  inline-flex items-center py-2 px-4 select-none rounded-lg">
                    {learningQuantity}
                  </div> */}
                  <button
                    type="button"
                    onClick={handleLearningIncrement}
                    className="bg-white  border border-[#4B5B78] text-gray-600
                        hover:bg-gray-100 active:bg-gray-200 disabled:opacity-50
                        inline-flex items-center py-2 px-2  rounded-lg"
                  >
                    <i className="bi bi-plus"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="my-5">
              <div className="flex flex-col">
                <h3 className="text-primary text-lg font-semibold mb-3">
                  Position of Action Plan{" "}
                </h3>
                <p className="text-base text-[#4A4C56] font-medium mb-3">
                  Apply To Specific{" "}
                </p>
              </div>
              <div className="flex items-center gap-x-36">
                <label className="flex items-center  mb-3 space-x-3">
                  <input
                    type="checkbox"
                    name="on"
                    checked={selectedPosition === "on"}
                    onChange={handleCheckboxChange}
                    className="text-center appearance-none bg-white  h-4 w-4 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none"
                  />
                  <span className="font-normal text-gray-700 dark:text-white">
                    On
                  </span>
                </label>
                <label className="flex items-center  mb-3 space-x-3">
                  <input
                    type="checkbox"
                    name="before"
                    checked={selectedPosition === "before"}
                    onChange={handleCheckboxChange}
                    className="text-center appearance-none bg-white  h-4 w-4 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none"
                  />
                  <span className="font-normal text-gray-700 dark:text-white">
                    Before
                  </span>
                </label>
                <label className="flex items-center  mb-3 space-x-3">
                  <input
                    type="checkbox"
                    name="after"
                    checked={selectedPosition === "after"}
                    onChange={handleCheckboxChange}
                    className="text-center appearance-none bg-white  h-4 w-4 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none"
                  />
                  <span className="font-normal text-gray-700 dark:text-white">
                    After
                  </span>
                </label>
              </div>
            </div>
            <div className="w-fit">
              <p className="text-base text-[#4A4C56] font-medium mb-2">
                Select Activity
              </p>
              <SingleSelectDropdown
                options={activityOptions}
                onSelect={handleSelectActivity}
                placeholder="Select activity"
                placeholderColor="#A5A5A5"
              />
            </div>
            <div className="my-5">
              <div className="flex flex-col">
                <h3 className="text-primary text-base font-medium">Unlock</h3>
                <div className="pl-10 flex  gap-x-10">
                  <div className="flex flex-col mt-4">
                    <p className="text-base text-[#4A4C56] font-medium mb-1 ">
                      Start Date/Time
                    </p>

                    <ReactDatePicker
                      selected={startDate}
                      onChange={handleDateChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeCaption="time"
                      className="border p-2 rounded border-gray-300 focus:primary focus:ring-0 focus:ring-offset-2" // Tailwind styling
                    />
                  </div>
                  <div className="flex flex-col mt-4">
                    <p className="text-base text-[#4A4C56] font-medium mb-1">
                      Complete by
                    </p>
                    <ReactDatePicker
                      selected={completeDate}
                      onChange={handleCompleteDateChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeCaption="time"
                      className="border p-2 rounded border-gray-300 focus:primary focus:ring-0 focus:ring-offset-2" // Tailwind styling
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="my-5">
              <h3 className="text-primary text-base font-medium">
                Consequence after Deadline
              </h3>
              <div className="flex items-center gap-x-36">
                <label className="flex items-center  mb-3 space-x-3">
                  <input
                    type="checkbox"
                    name="on"
                    checked={isNotCompleteChecked}
                    onChange={handleNotCompleteChange}
                    className="text-center appearance-none bg-white  h-4 w-4 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none"
                  />
                  <span className="text-base text-[#4A4C56] font-medium">
                    Not complete until all actions submitted
                  </span>
                </label>
                <label className="flex items-center  mb-3 space-x-3">
                  <input
                    type="checkbox"
                    name="repeat"
                    checked={isRepeatChecked}
                    onChange={handleRepeatChange}
                    className="text-center appearance-none bg-white  h-4 w-4 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none"
                  />
                  <span className="text-base text-[#4A4C56] font-medium">
                    Repeat
                  </span>
                </label>
              </div>
            </div>
            <div className="my-5">
              <h3 className="text-primary text-base font-medium">
                When can goal be edited after submit?
              </h3>
              <div className="flex items-center gap-x-36">
                <label className="flex items-center  mb-3 space-x-3">
                  <input
                    type="checkbox"
                    name="anytime"
                    checked={goalSubmitted === "anytime"}
                    onChange={handleGoalSubmittedChange}
                    className="text-center appearance-none bg-white  h-4 w-4 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none"
                  />
                  <span className="text-base text-[#4A4C56] font-medium">
                    Anytime
                  </span>
                </label>
                <label className="flex items-center  mb-3 space-x-3">
                  <input
                    type="checkbox"
                    name="betweenDates"
                    checked={goalSubmitted === "betweenDates"}
                    onChange={handleGoalSubmittedChange}
                    className="text-center appearance-none bg-white  h-4 w-4 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none"
                  />
                  <span className="text-base text-[#4A4C56] font-medium">
                    Between Dates
                  </span>
                </label>
                <label className="flex items-center  mb-3 space-x-3">
                  <input
                    type="checkbox"
                    name="allCourseComplete"
                    checked={goalSubmitted === "allCourseComplete"}
                    onChange={handleGoalSubmittedChange}
                    className="text-center appearance-none bg-white  h-4 w-4 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none"
                  />
                  <span className="text-base text-[#4A4C56] font-medium">
                    All Course Complete
                  </span>
                </label>
              </div>
              {goalSubmitted === "betweenDates" && (
                <div className="flex justify-center gap-x-10 my-6">
                  <div className="flex flex-col mt-4">
                    <p className="text-base text-[#4A4C56] font-medium mb-1 ">
                      Start Date/Time
                    </p>

                    <ReactDatePicker
                      selected={editStartDate}
                      onChange={handleEditStartDateChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeCaption="time"
                      className="border p-2 rounded border-gray-300 focus:primary focus:ring-0 focus:ring-offset-2" // Tailwind styling
                    />
                  </div>
                  <div className="flex flex-col mt-4">
                    <p className="text-base text-[#4A4C56] font-medium mb-1">
                      Complete by
                    </p>
                    <ReactDatePicker
                      selected={editEndDate}
                      onChange={handleEditEndDateChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeCaption="time"
                      className="border p-2 rounded border-gray-300 focus:primary focus:ring-0 focus:ring-offset-2" // Tailwind styling
                    />
                  </div>
                </div>
              )}

              <div className="my-5">
                <h3 className="text-primary text-base font-medium mb-3">
                  Set timeline for achievement
                </h3>
                <div className="flex gap-x-14 items-center">
                  <div className="w-fit ml-5 ">
                    <Field
                      type="text"
                      id="timelineForAchievement"
                      className={`rounded-lg   appearance-none  border-2 border-gray-500
                    w-10 py-2 px-3 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary/80 focus:border-transparent`}
                      name="timelineForAchievement"
                      //   placeholder="Enter meeting topic"
                    />
                    <ErrorMessage
                      name="timelineForAchievement"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                  <SingleSelectDropdown
                    options={timelineUnitOptions}
                    onSelect={handleTimelineUnit}
                    placeholder="Select unit"
                    placeholderColor="#A5A5A5"
                  />
                  <label className="flex items-center  mb-3 space-x-3">
                    <input
                      type="checkbox"
                      name="selectDate"
                      checked={learnerSelectsDate}
                      onChange={handleLearnerSelectDate}
                      className="text-center appearance-none bg-white  h-4 w-4 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none"
                    />
                    <span className="text-base text-[#4A4C56] font-medium">
                      Learner selects date
                    </span>
                  </label>
                </div>
              </div>
              {/* <div className="mt-3 flex items-center gap-10">
                <div className="">
                  <h3 className="text-primary text-base font-medium">
                    Send Reminders if not complete
                  </h3>
                  <SingleSelectDropdown
                    options={timelineUnitOptions}
                    onSelect={handleTimelineUnit}
                    placeholder="Select email"
                    placeholderColor="#A5A5A5"
                  />
                </div>

                <div className="flex flex-col">
                  <p className="text-base text-[#4A4C56] font-medium mb-1">
                    Complete by
                  </p>
                  <ReactDatePicker
                    selected={reminderDate}
                    onChange={handleReminderDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    timeCaption="time"
                    className="border p-2 rounded border-gray-300 focus:primary focus:ring-0 focus:ring-offset-2" // Tailwind styling
                  />
                </div>
              </div> */}
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className={`font-semibold 
                      bg-customBlue
                   text-white py-3 px-8 rounded-lg text-xs`}
              >
                Create Action Plan
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateActionPlan;
