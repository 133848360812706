import React from 'react'
import Stepper from 'react-stepper-horizontal';

const Stepper_ = ({currentStep}) => {
  return (
    <> 
       <Stepper 
        steps={ [
                    {title: 'Personal Details',}, 
                    {title: 'Company Details'},
                    {title: 'Account Password',}
            ] } 
        activeColor={'#FF9100'}
        completeColor={'#FF9100'}
        activeTitleColor={'#FF9100'}
        completeBarColor={'#FF9100'}
        completeTitleColor={'#FF9100'}
        activeStep={ currentStep }
        titleFontSize={12}
        circleFontSize={11}
    />
    </>
  )
}

export default Stepper_