import React from 'react'
import { Field, ErrorMessage } from 'formik'

const CreateClientForm = ({visible, setCpVisible, setVisible, cpVisible}) => {
    
  return (
    <>
       <section className='mb-3'>
                <label htmlFor="fname" className='text-xs'>
                    First Name <span className='text-red-500 text-md font-semibold'>*</span>
                </label>
                <Field 
                 name='fname' className='input-md mt-1' 
                 placeholder='clients first name...'
              />
            <ErrorMessage 
                    name='fname' className='py-1 text-xs text-red-500'
                    component={'p'} 
            />
            </section>
            <section className='mb-3'>
                <label htmlFor="lname" className='text-xs'>
                    Last Name <span className='text-red-500 text-md font-semibold'>*</span>
                </label>
                <Field name='lname' className='input-md mt-1' placeholder='clients last name...' />
                <ErrorMessage 
                    name='lname' className='py-1 text-xs text-red-500'
                    component={'p'} 
                />
            </section>
            <section className='mb-3'>
                <label htmlFor="email" className='text-xs'>
                    Email Address <span className='text-red-500 text-md font-semibold'>*</span>
                </label>
                <Field type='email' name='email' className='input-md mt-1' placeholder='email address...' />
                <ErrorMessage 
                    name='email' className='py-1 text-xs text-red-500'
                    component={'p'} 
            />
            </section>
            <section className='mb-3'>
                <label htmlFor="phone" className='text-xs'>
                    Phone Number <span className='text-red-500 text-md font-semibold'>*</span>
                </label>
                <Field name='phone' className='input-md mt-1' placeholder='phone e.g 08034...56' />
                <ErrorMessage 
                    name='phone' className='py-1 text-xs text-red-500'
                    component={'p'} 
            />
            </section>
            <section className='mb-3'>
                <label htmlFor="ogName" className='text-xs'>
                Organisation Name <span className='text-red-500 text-md font-semibold'>*</span>
                </label>
                <Field name='ogName' className='input-md mt-1' placeholder='organisation name...' />
                <ErrorMessage 
                    name='ogName' className='py-1 text-xs text-red-500'
                    component={'p'} 
            />
            </section>
            <section className='mb-3'>
                <label htmlFor="ogAddress" className='text-xs'>
                Organisation Address <span className='text-red-500 text-md font-semibold'>*</span>
                </label>
                <Field
                    as='textarea' name='ogAddress' className='input-md mt-1 h-[90px]'
                    placeholder='organisation address...' 
                />
                <ErrorMessage 
                    name='ogAddress' className='py-1 text-xs text-red-500'
                    component={'p'} 
            />
            </section>
            <section className='mb-3'>
                <label htmlFor="role" className='text-xs'>
                  Add Domain (unique organisational identifier)<span className='text-red-500 text-md font-semibold'>*</span>
                </label>
                <Field 
                    type='text'
                    name='role' className='input-md mt-1' 
                />
                 
                <ErrorMessage 
                 name='role' className='py-1 text-xs text-red-500'
                 component={'p'} 
               />
            </section>
            <section className='mb-3'>
                <label htmlFor="role" className='text-xs'>
                  Payment Type (client prepared method of payment)
                  <span className='text-red-500 text-md font-semibold'>*</span>
                </label>
                <Field 
                    type='text' as ='select'
                    name='paymentType' className='input-md mt-1' 
                >
                    <option value="pre-paid">Pre Paid</option>
                    <option value="post-paid">Post Paid</option>
                </Field>
                <ErrorMessage 
                 name='paymentType' className='py-1 text-xs text-red-500'
                 component={'p'} 
               />
            </section>
            <section className='mb-3'>
            <label htmlFor="password" className='text-xs'>
                Password <span className='text-red-500 text-md font-semibold'>*</span>
                </label>
            <div class="relative flex  items-stretch">
            <span
            className="flex items-center border-t border-l border-b  whitespace-nowrap rounded-l bg-white border-gray-200 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-neutral-700 dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200"
            id="basic-addon1"
            onClick={() => setVisible(!visible)}
            > 
                {
                visible ? 
                <i className='bi bi-eye-slash text-xs'></i>
                :
                <i className='bi bi-eye text-xs'></i>
                }
            </span>
            <Field 
            type={ visible ? 'text' : 'password'}
            name={'password'}
            className='font-semibold auth-input'
            style={{
                    borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px',
                    color:  '#333',
                    borderColor:'#ccc',
                }}
        />
            </div>
            <ErrorMessage 
                    name='password' className='py-1 text-xs text-red-500'
                    component={'p'} 
            />
            </section>
            <section className='mb-3'>
            <label htmlFor="password" className='text-xs'>
                Confirm Password <span className='text-red-500 text-md font-semibold'>*</span>
                </label>
            <div class="relative flex  items-stretch">
            <span
                className="flex items-center border-t border-l border-b  whitespace-nowrap rounded-l bg-white border-gray-200 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-neutral-700 dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200"
                id="basic-addon1"
                onClick={() => setCpVisible(!cpVisible)}
                > 
                {
                    cpVisible ? 
                    <i className='bi bi-eye-slash text-xs'></i>
                    :
                    <i className='bi bi-eye text-xs'></i>
                    }
                </span>
                <Field 
                type={ cpVisible ? 'text' : 'password'}
                name={'cpassword'}
                className='font-semibold auth-input'
                style={{
                        borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px',
                        color:  '#333',
                        borderColor:'#ccc',
                    }}
            />
            </div>
            <ErrorMessage 
                    name='cpassword' className='py-1 text-xs text-red-500'
                    component={'p'} 
                />
        </section>
    </>
  )
}

export default CreateClientForm