import React, { useState, useMemo, useEffect } from "react";
import Placeholder from "../components/Placeholder";
import ChooseType from "../components/ChooseType";
import Pagination from "../../../../../components/pagination/Pagination";
import Table from "../../../../../components/table/Table";
import Loader from "../../../../../components/loader/Loader";
import { useQuery } from "react-query";
import { api } from "../../../../../api/learningPath";
import { Link, useLocation } from "react-router-dom";

const LearningPath = () => {

  const [selectionModal, setSelectionModal] = useState(false);
  const [perPage, setPerPage] = useState(15);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const refreshStatus = queryParams.get('refresh');
  const user = JSON.parse(sessionStorage.getItem("user"));

  const {
    data: paths,
    isLoading,
    refetch,
  } = useQuery(
    ["paths", { search, perPage, currentPage }],
    ({ queryKey }) => {
      return api.getLearningPath({ ...queryKey[1], org_id: user?.org?.id });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  useEffect( () => {
    if(refreshStatus === '1') refetch()
  }, [] )

  let columns = useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index }) =>
          index +
          1 +
          ((parseInt(paths?.meta?.page) || 1) - 1) *
            (parseInt(paths?.meta?.perPage) || perPage),
      },
      {
        title: "Image",
        selector: "imagePath",
        key: "imagePath",
        render: ({ item }) => {
          return <img className="w-[40px] h-[40px] rounded-full object-cover ml-2" alt="learning path image" src={item} />;
        },
      },
      {
        title: "Title",
        selector: "name",
        key: "name",
      },
      {
        title: "Status",
        selector: "status",
        key: "status",
        render: ({ item }) => {
          if (item === "published") {
            return <span className="text-green-500 capitalize">{item}</span>;
          } else
            return <span className="text-yellow-500 capitalize">{item}</span>;
        },
      },
      {
        title: "Type",
        selector: "type",
        key: "type",
        render: ({ item }) => {
          return (
            <span
              className={`ml-3`}
            >
              {item === 'program' ? 'program' : 'single path'}
            </span>
          );
        },
      },
      {
        title: "Date Created",
        selector: "createdAt",
        key: "createdAt",
        render: ({ item }) => {
          return (
            <span className="">{`${new Date(item).toLocaleDateString(
              "en-ng"
            )}`}</span>
          );
        },
      },
      {
        title: "Date Updated",
        selector: "updatedAt",
        key: "updatedAt",
        render: ({ item }) => {
          return (
            <span className="">{`${new Date(item).toLocaleDateString(
              "en-ng"
            )}`}</span>
          );
        },
      },
      {
        title: "Action",
        selector: null,
        key: "actions",
        render: ({ row }) => {         
          return <Link
                   className='bi bi-pencil-square text-blue-500 text-lg'
                   to={`/admin/learning-path/${row?.type === 'program' ? 'edit-program' : 'edit-path'}`}
                   onClick={ () => sessionStorage.setItem('active-path', JSON.stringify(row)) }
                  />
        },
      }
    ],
    []
  );

  const tableProps = { isLoading: false, columns, data: paths?.data };

  const handleSelectionModal = () => {
    setSelectionModal(!selectionModal);
  };

  if (!isLoading && paths?.meta?.total === 0) {
    return (
      <>
        <Placeholder handleSelectionModal={handleSelectionModal} />
        <ChooseType
          selectionModal={selectionModal}
          handleSelectionModal={handleSelectionModal}
        />
      </>
    );
  }

  return (
    <div>
      <ChooseType
        selectionModal={selectionModal}
        handleSelectionModal={handleSelectionModal}
      />
      <header className="flex justify-between gap-5 mt-3 items-center px-2">
        <h4 className="text-sm">View Learning Paths</h4>
        <button
          className="border border-darkBlue rounded-md py-3 px-10 bg-darkBlue text-white text-sm"
          onClick={handleSelectionModal}
        >
          Create path
        </button>
      </header>
      <section className="bg-lightBlue shadow flex items-center justify-between mx-2 px-2 py-2 mt-3">
        <h3 className="text-xs ">
          Total Learning Paths ({paths?.meta?.total})
        </h3>
        <div className="w-[300px]">
          <input
            type="search"
            className="input-md"
            value={search}
            placeholder="Search..."
            onChange={(e) => {
              setCurrentPage(1);
              setSearch(e.target.value);
            }}
          />
        </div>
      </section>
      <section className="mt-0 px-3">
        {isLoading ? <Loader /> : <Table {...tableProps} />}
      </section>
      <section className={`mt-5 px-3`}>
        <Pagination
          data={paths?.meta}
          perPage={perPage}
          pageNo={currentPage}
          setPageNo={setCurrentPage}
          setPerPage={setPerPage}
        />
      </section>
    </div>
  );
};

export default LearningPath;
