import React from 'react'

const YesOrNo = () => {
  return (
    <div className='flex mt-2'>  
            <div className='flex items-center'>
                <label className='text-sm'>Yes</label>
                <input type="radio" name='yes-or-no' defaultChecked className='ml-2 h-[20px] w-[20px]' />
            </div>
            <div className='flex items-center ml-8'>
                <label className='text-sm'>No</label>
                <input type="radio" name='yes-or-no' className='ml-2 h-[20px] w-[20px]' />
            </div>
    </div>
  )
}

export default YesOrNo