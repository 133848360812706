import React, { useState } from "react";
import { Checkbox } from "./Components";
import { useQuery, useMutation } from "react-query";
import { api } from "../../../../../api/learningPath";
import Button from "../../../../../components/Buttons/Button";
import toast from "react-hot-toast";
import Loader from "../../../../../components/loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

const Learners = ({
  selectedLearners,
  setSelectedLearners,
  handleSettingsModal,
}) => {
  const [search, setSearch] = useState("");

  const [isRequired, setIsRequired] = useState(true);

  const user = JSON.parse(sessionStorage.getItem("user"));

  const current_path = JSON.parse(sessionStorage.getItem("active-path"));

  const handleIsRequired = () => {
    setIsRequired(!isRequired);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const { data: cohorts, isLoading } = useQuery(
    ["Cohorts", search],
    () => {
      return api.getCohorts({ org_id: user?.org?.id, search });
    },
    {
      cacheTime: 30000,
      staleTime: 30000,
      select: (data) => data.data,
      refetchOnWindowFocus: true,
    }
  );

  const assignCohortsMutation = useMutation(api.assignCohorts, {
    onSuccess: (data) => {
      toast.remove();
      setTimeout(() => {
        handleSettingsModal();
      }, 100);
      toast.success(data?.message);
    },
    onError: ({ response }) => {
      toast.remove();
      toast.error(response?.data?.message);
    },
  });

  const deleteCohortsMutation = useMutation(api.assignCohorts, {
    onSuccess: () => {
      toast.success('Cohort Deleted Successfully!');
    },
  });

  const handleDeselect = () => {
    setSelectedLearners([]);
  };

  const handleClick = (selected) => {
      setSelectedLearners((prev) => {
        // Check if selected_cohort is already in selectedcohorts
        const isSelected = selectedLearners?.findIndex( cohort_ => cohort_?.cohort?.id === selected?. cohort?.id )
        if (isSelected !== -1) {
          // If it's already selected, remove it
          const newCohorts = selectedLearners?.filter((cohort) => cohort?. cohort?.id !== selected?.cohort?.id)
          const deleted = selectedLearners?.filter((cohort) => cohort?. cohort?.id === selected?.cohort?.id)

          deleteCohortsMutation.mutate({
            payload: {
              allCohorts: isRequired ? false : true,
              newCohortIds: !isRequired
                ? []
                : newCohorts.map((item) => {
                    return item?.cohort?.id;
                  }),
              deletedCohortIds: deleted?.map((item) =>  item?.cohort?.id )
            },
            journey_id: current_path?.id,
            org_id: user?.org?.id,
          });
          return newCohorts;
        } else {
          // If it's not selected, add it
          return [...prev, selected];
        }
      });
  };


  const handleSave = () => {
    if (isRequired && selectedLearners.length < 1) {
      return toast.error("Please select paths you wish to add...");
    }
    toast.loading("Processing...");

    assignCohortsMutation.mutate({
      payload: {
        allCohorts: isRequired ? false : true,
        newCohortIds: !isRequired
          ? []
          : selectedLearners.map((item) => {
              return item?.cohort?.id;
            }),
      },
      journey_id: current_path?.id,
      org_id: user?.org?.id,
    });
  };

  return (
    <div className="w-full p-3"> {console.log(selectedLearners)}
      <div
        className={`border ${
          isRequired ? "border-lightGray" : "border-customOrange"
        } flex items-center justify-start gap-3 rounded-md p-3`}
      >
        <Checkbox
          checked={!isRequired ? true : false}
          id={"not-required"}
          onChange={handleIsRequired}
        />
        <div>
          <h1 className="flex items-center justify-start text-left gap-1">
            Universal access.{" "}
            <span className="text-[10px] px-5 bg-slate-500 rounded-2xl text-white">
              Default
            </span>
          </h1>
          <p className="text-xs text-gray-500 text-left">
            This path will be visible to all learners.
          </p>
        </div>
      </div>
      <div
        className={`border ${
          !isRequired ? "border-lightGray" : "border-customOrange"
        } mt-3 flex items-center justify-start gap-3 rounded-md p-3`}
      >
        <Checkbox
          checked={isRequired ? true : false}
          id={"required"}
          onChange={handleIsRequired}
        />
        <div>
          <h1 className="text-left">Selected learners.</h1>
          <p className="text-xs text-gray-500 text-left">
            Only learners you select will have access to your learning path.
          </p>
        </div>
      </div>
      {isRequired && (
        <div className="w-full p-2 mt-3">
          <input
            type={"search"}
            onChange={handleSearch}
            className="w-full  duration-200 border border-lightGray rounded-md text-xs py-2 pl-2 focus:ring-0 outline-none focus:border-customOrange"
            placeholder="Search..."
          />
          <p className="text-md text-left mb-1 mt-3">Select Cohorts </p>
          {isLoading ? (
            <div className="w-full h-[300px]  mt-3 py-2 pr-2">
              <Loader />
            </div>
          ) : (
            <div className="w-full max-h-[300px] overflow-y-scroll  py-2 pr-2">
              {cohorts?.map((cohort, index) => (
                <div
                  key={index}
                  className={`border border-lightGray flex items-center justify-between w-full px-3 py-5 rounded-md mb-2`}
                >
                  <div className="text-sm flex items-center justify-start gap-2">
                    <button onClick={ () => handleClick(cohort)}>
                      { selectedLearners?.findIndex( cohort_ => cohort_?.cohort?.id === cohort?.cohort?.id ) !== -1 ? (
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="text-xl font-light text-customOrange"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="text-xl font-light text-gray-400"
                        />
                      )}
                    </button>
                    <p>{cohort?.cohort?.name}</p>
                  </div>
                  <div className="text-xs capitalize flex items-center justify-end gap-2">
                    <p>{cohort?.status}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <div className="w-full flex items-center justify-between my-5">
        <Button
          text={"Deselect All"}
          bgColor={"bg-white"}
          textColor={"text-darkBlue"}
          width={"w-[120px]"}
          onClick={handleDeselect}
        />
        <button
          onClick={handleSave}
          className="w-[150px] flex items-center justify-center gap-2 bg-darkBlue text-white text-xs rounded-md py-3 px-5"
        >
          Save to Path{" "}
          {isRequired && selectedLearners?.length > 0 && (
            <span className="bg-white text-darkBlue px-2 font-bold text-[9.5px] rounded-lg">
              {selectedLearners?.length}
            </span>
          )}
        </button>
      </div>
    </div>
  );
};

export default Learners;
