import React from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from '../../../../../../../../components/Buttons/Button';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import CenteredModal from '../../../../../../../../components/Modal/CenteredModal';
import { api } from '../../../../../../../../api/billing';

const CreateClientDiscount = ({ openModal, setOpenModal, refetch, activeDiscount, setActiveDiscount }) => {
    
  const createClientDiscountMutation = useMutation(
    (activeDiscount === null) ? api.createClientDiscount : api.updateClientDiscount, {
      onSuccess: (response) => {
        toast.remove();
        toast.success(response?.message);
        refetch();
        setOpenModal(false)
        setActiveDiscount(null)
      },
      onMutate: () => {
        toast.loading("Loading...");
      },
      onError: ({ response }) => {
        toast.remove();
        toast.error(response.data?.message);
      },
    });


  const initialValues = {
    name: activeDiscount === null ? "" : activeDiscount?.name,
    description: activeDiscount === null ? "" : activeDiscount?.description,
    discountCode: activeDiscount === null ? "" : activeDiscount?.discountCode,
    discountType: activeDiscount === null ? "" : activeDiscount?.discountType, // amount
    discountAmount: activeDiscount === null ? "" : activeDiscount?.discountAmount,
    status: activeDiscount === null ? "active" : activeDiscount?.status
  };

  const validate = (values) => {
   const errors = {}
   if (!values.name) {
    errors.name = 'Tier Name is required'
  }
  if (!values.description) {
    errors.description = 'Description is required'
  }
  if (!values.discountType) {
    errors.discountType = 'Discount Type is required'
  }
  if (!values.discountCode) {
    errors.discountCode = 'Discount code is required'
  }
  if (!values.discountAmount) {
    errors.discountAmount = 'Discount amount is required'
  }

   return errors;
 };

 const createTier = (values, actions) => {
   // Handle submission
   const editDiscountPayload = {
     id: activeDiscount?.id,
     data: values
   }
   createClientDiscountMutation.mutate( activeDiscount === null ? values : editDiscountPayload )
   actions.setSubmitting(false);
 };

  return (
        <CenteredModal 
            title={
            <h5 className='text-md font-semibold bi bi-plus-circle'>
                &nbsp; Add Tier
            </h5>
            }
            open={openModal}
            setOpen={setOpenModal}
            style={{padding: 0}}
            width={'w-[450px] h-[400px] overflow-y-auto'}
            headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
            //btnBgColor={'bg-primary'}
       > 
         <div className='text-left p-3'>
         <Formik
          initialValues={initialValues}
          validate={validate}
          enableReinitialize={true}
          onSubmit={createTier}
        >
          { ({values}) => (
            <Form>
                <p className='text-xs text-gray-700 pb-2 border-b border-gray-300'>
                  Add Volume Discount Tier
                </p>
               <div className='mt-2'>
                  <label htmlFor="course-enrolled" className='text-xs'>
                     Tier Name
                  </label>
                  <Field name='name' className='input-md' />
                  <p className='text-xs text-red-500 mt-2'>
                     <ErrorMessage name='name' />
                  </p>
               </div>
               <div className='mt-2'>
                  <label htmlFor="course-enrolled" className='text-xs'>
                     Description
                  </label>
                  <Field as='textarea' name='description' className='input-md' />
                  <p className='text-xs text-red-500 mt-2'>
                     <ErrorMessage name='description' />
                  </p>
               </div>
               <div className='mt-3'>
                  <label htmlFor="course-enrolled" className='text-xs'>
                     Discount Type
                  </label>
                  <Field as='select' name='discountType' className='input-md'>
                     <option value="percent">Percent</option>
                     <option value="amount">Amount</option>
                  </Field>
                  <p className='text-xs text-red-500 mt-2'>
                     <ErrorMessage name='discountType' />
                  </p>
               </div>
               <div className='mt-3'>
                  <label htmlFor="course-enrolled" className='text-xs'>
                     Discount Code
                  </label>
                  <Field name='discountCode' className='input-md' />
                  <p className='text-xs text-red-500 mt-2'>
                     <ErrorMessage name='discountCode' />
                  </p>
               </div>
               <div className='mt-3'>
                  <label htmlFor="course-enrolled" className='text-xs capitalize'>
                     Discount {values?.discountType}
                  </label>
                  <Field name='discountAmount' type='number' className='input-md' />
                  <p className='text-xs text-red-500 mt-2'>
                     <ErrorMessage  name='discountAmount' />
                  </p>
               </div>
               <div className='mt-3 mb-3'>
                  <label htmlFor="course-enrolled" className='text-xs'>
                     Status
                  </label>
                  <Field name='status' as='select' className='input-md'>
                     <option value="active">Active</option>
                     <option value="inactive">Disabled</option>
                  </Field>
                  <p className='text-xs text-red-500 mt-2'>
                     <ErrorMessage type='number' name='status' />
                  </p>
               </div>
               <Button 
                  bgColor={'bg-darkBlue'} width={'w-[60%] mx-auto'}
                  text={ activeDiscount === null ? 'Save' : 'Edit'}
                  btn_type='submit'
                />
            </Form>
         )}
        </Formik>
         </div>
      </CenteredModal>
  )
}

export default CreateClientDiscount