import React from 'react'
import BlockchainImg from '../assets/Blockchain.png'

const EmptyData = ({text, width, height, containerWidth, containerClassName}) => {
  return (
    <div style={{width: containerWidth}} className={containerClassName}>
       <img 
          src={BlockchainImg} alt="placeholder image"
          className='w-[240px] h-[170px] block mx-auto mt-5'
          style={{width, height}}
       />
       <p className='mt-2 text-sm text-center pb-3'>{text}</p>
    </div>
  )
}

export default EmptyData