import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { api } from "../../../../../api/reward";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parseISO } from "date-fns";
import Count from "./Count";
import { cohorts, courses, learningPaths, quizzes } from "./dummyData";

const AddReward = ({ handleModal, refetch, categories }) => {
  const user = JSON.parse(sessionStorage.getItem("user"));

  const [selectedCohorts, setSelectedCohorts] = useState([]);
  const [selectedLearningPaths, setSelectedLearningPaths] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedQuizzes, setSelectedQuizzes] = useState([]);
  const [currentSpecific, setCurrentSpecific] = useState("cohorts");

  const handleSpecificChange = (value) => {
    setCurrentSpecific(value);
  };

  const { data: cohortList } = useQuery(
    ["Cohorts"],
    () => {
      return api.getCohorts({ org_id: user?.org?.id });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  const { data: pathList } = useQuery(
    ["paths"],
    () => {
      return api.getPaths({ org_id: user?.org?.id });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  const { data: courseList } = useQuery(
    ["Courses"],
    () => {
      return api.getCourses();
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  const { data: moduleList } = useQuery(
    ["Modules"],
    () => {
      return api.getModules();
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  // console.log(cohortList);
  // console.log(pathList);
  // console.log(courseList);
  // console.log(moduleList);

  const currentReward = JSON.parse(sessionStorage.getItem("currentReward"));

  // console.log(currentReward);

  // console.log(categories);

  const { register, handleSubmit, control, setValue } = useForm();

  useEffect(() => {
    if (currentReward?.startDate) {
      setValue("startDate", parseISO(currentReward?.startDate));
    }

    if (currentReward?.endDate) {
      setValue("endDate", parseISO(currentReward?.endDate));
    }

    if (currentReward?.category?.id) {
      setValue("orgRewardCategoryId", currentReward?.category?.id);
    }
  }, [setValue]);

  const { mutateAsync } = useMutation(api.createReward);
  const { mutateAsync: EditReward } = useMutation(api.editReward);

  const editReward = async (values) => {
    const payload = {
      ...values,
      badgeFileUrl: "",
      createdBy: user?.userId,
      updatedBy: user?.userId,
      startDate: format(values.startDate, "MM/dd/yyyy"),
      endDate: format(values.endDate, "MM/dd/yyyy"),
    };
    // console.log(payload);
    try {
      toast.loading("Editing...");
      const data = await EditReward({
        id: user?.org?.id,
        payload,
        rewardId: currentReward?.id,
      });
      // console.log(data);
      toast.remove();
      toast.success("Reward created successfully...");
      setTimeout(() => {
        refetch();
        handleModal();
      }, 1000);
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  const handleReward = async (values) => {
    if (currentReward?.name) return editReward(values);

    const payload = {
      ...values,
      badgeFileUrl: "",
      createdBy: user?.userId,
      updatedBy: user?.userId,
      startDate: format(values.startDate, "MM/dd/yyyy"),
      endDate: format(values.endDate, "MM/dd/yyyy"),
    };
    // console.log(payload);
    try {
      toast.loading("Processing...");
      const data = await mutateAsync({ id: user?.org?.id, payload });
      // console.log(data);
      toast.remove();
      toast.success("Reward created successfully...");
      setTimeout(() => {
        refetch();
        handleModal();
      }, 1000);
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  const handleCohortChange = (cohort) => {
    setSelectedCohorts((prevState) => {
      if (prevState.includes(cohort)) {
        return prevState.filter((item) => item !== cohort);
      } else {
        return [...prevState, cohort];
      }
    });
  };

  const handleLearningPathChange = (learningPath) => {
    setSelectedLearningPaths((prevState) =>
      prevState.includes(learningPath)
        ? prevState.filter((item) => item !== learningPath)
        : [...prevState, learningPath]
    );
  };

  const handleCourseChange = (course) => {
    setSelectedCourses((prevState) =>
      prevState.includes(course)
        ? prevState.filter((item) => item !== course)
        : [...prevState, course]
    );
  };

  const handleQuizChange = (quiz) => {
    setSelectedQuizzes((prevState) =>
      prevState.includes(quiz)
        ? prevState.filter((item) => item !== quiz)
        : [...prevState, quiz]
    );
  };

  const [fileName, setFileName] = useState(
    currentReward?.badgeFileUrl
      ? currentReward.badgeFileUrl.split("/").pop()
      : null
  );

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };

  // console.log(currentSpecific);

  return (
    <div>
      <h1 className="w-full text-left">Reward Information</h1>
      <p className="w-full text-left text-sm text-gray-500">
        Add details about the Reward
      </p>
      <form onSubmit={handleSubmit(handleReward)}>
        <div className="w-full">
          <div className="flex items-center mt-4 justify-between gap-2">
            <div className="flex-1 flex mb-7 items-start flex-col justify-center gap-1">
              <p className="text-sm">Name</p>
              <input
                defaultValue={currentReward?.name}
                className="w-full flex-1 border text-xs shadow-inner border-gray-200 block px-4 py-2 rounded-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
                type="text"
                {...register("name", { required: true })}
              />
            </div>
            <div className="flex-1 flex mb-7 items-start flex-col justify-center gap-1">
              <p className="text-sm">Select Category</p>
              <select
                defaultValue={currentReward?.category?.id}
                className="w-full flex-1 text-xs border border-gray-200 shadow-inner block px-4 py-2 rounded-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
                {...register("orgRewardCategoryId", { required: true })}
              >
                {categories?.data?.map((category) => (
                  <option key={category?.id} value={category?.id}>
                    {category?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex-1 flex mb-7 items-start flex-col justify-center gap-1">
            <p className="text-sm">Description</p>
            <textarea
              defaultValue={currentReward?.description}
              className="w-full shadow-inner text-xs flex-1 border border-gray-200 block px-4 py-2 rounded-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
              rows={3}
              {...register("description", { required: true })}
            />
          </div>

          <h1 className="w-full text-left mb-1 text-sm">
            Assign Points or Badges
          </h1>
          <div className="flex mt-2 items-center px-4 rounded-sm mb-7 justify-between gap-4">
            <div className="flex flex-1 gap-1 items-center justify-start">
              <input
                defaultChecked={currentReward?.isPointBased}
                type="radio"
                className="border border-gray-200 block px-4 py-2 rounded-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
                {...register("isPointBased")}
              />
              <p className="text-sm">Points</p>

              <Count />
              {/* <input
                type="number"
                defaultValue={currentReward?.numberOfPoints}
                placeholder="Number of Points"
                className="w-full flex-1 border text-xs border-gray-200 block px-4 py-2 rounded-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
                {...register("numberOfPoints")}
              /> */}
            </div>
            <div className="flex-1 flex items-center justify-start gap-2">
              <input
                defaultChecked={currentReward?.isBadgeBased}
                type="radio"
                className="border border-gray-200 block px-4 py-2 rounded-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
                {...register("isBadgeBased")}
              />
              <p className="text-sm">Badges</p>

              <div className="w-full">
                <label
                  htmlFor="badgeFileUrl"
                  className="w-full flex-1 shadow-inner text-xs border border-gray-200 block p-2 rounded-md bg-white text-gray-700 cursor-pointer hover:bg-gray-100 duration-200"
                >
                  {fileName || (
                    <div className="w-full">
                      <span className="text-customOrange underline">
                        Upload
                      </span>{" "}
                      an image for your award
                    </div>
                  )}
                </label>
                <input
                  id="badgeFileUrl"
                  type="file"
                  className="hidden"
                  {...register("badgeFileUrl")}
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between gap-2">
            {/* <div className="flex-1 flex mb-7 items-start flex-col justify-center gap-1">
              <p className="text-sm">Status</p>
              <select
                defaultValue={currentReward?.status}
                className="w-full flex-1 border border-gray-200 block px-4 py-2 rounded-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
                {...register("status", { required: true })}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div> */}
            <div className="flex-1 flex mb-7 items-start flex-col justify-center gap-1">
              <p className="text-sm font-medium">Trigger</p>
              <p className="text-sm">Add criteria</p>
              <select
                defaultValue={currentReward?.actionTrigger}
                className="shadow-inner text-xs flex-1 border border-gray-200 block px-4 py-2 rounded-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
                {...register("actionTrigger", { required: true })}
              >
                <option disabled selected>
                  Select Action
                </option>
                <option value="completeCohort">Complete Cohort</option>
                <option value="completeLearningPath">
                  Complete Learning Path
                </option>
                <option value="completeCourse">Complete Course</option>
                <option value="completeQuiz">Complete Quiz</option>
              </select>
            </div>
          </div>

          <h1 className="w-full text-gray-600 text-sm text-left mb-2">
            Apply to:
          </h1>
          {/* <h1 className="w-full text-left mb-2 text-sm">Apply to Specific</h1> */}
          <div className="flex border-b border-darkBlue mb-1 items-center justify-start">
            <button
              onClick={() => handleSpecificChange("cohorts")}
              className={`px-4 cursor-pointer py-2 text-sm ${
                currentSpecific === "cohorts"
                  ? "bg-darkBlue text-white"
                  : "bg-transparent"
              } rounded-tr-md border border-gray-300`}
            >
              Cohort
            </button>
            <button
              onClick={() => handleSpecificChange("learningPath")}
              className={`px-4 cursor-pointer py-2 text-sm ${
                currentSpecific === "learningPath"
                  ? "bg-darkBlue text-white"
                  : "bg-transparent"
              } rounded-tr-md border border-gray-300`}
            >
              Learning Path
            </button>
            <button
              onClick={() => handleSpecificChange("course")}
              className={`px-4 cursor-pointer py-2 text-sm ${
                currentSpecific === "course"
                  ? "bg-darkBlue text-white"
                  : "bg-transparent"
              } rounded-tr-md border border-gray-300`}
            >
              Course
            </button>
            <button
              onClick={() => handleSpecificChange("quiz")}
              className={`px-4 cursor-pointer py-2 text-sm ${
                currentSpecific === "quiz"
                  ? "bg-darkBlue text-white"
                  : "bg-transparent"
              } rounded-tr-md border border-gray-300`}
            >
              Quiz
            </button>
          </div>
          <div className="border border-gray-200 max-h-[150px] w-full mb-7 p-4 shadow-inner rounded-md overflow-y-scroll">
            {currentSpecific === "cohorts" ? (
              <>
                {cohortList?.data?.map((cohort, index) => (
                  <label
                    htmlFor={`cohort${index}`}
                    key={index}
                    className={`py-4 flex hover:cursor-pointer text-sm items-center justify-start gap-4 mb-3 rounded-md px-3 border ${
                      selectedCohorts.includes(cohort)
                        ? "border-customOrange"
                        : "border-gray-200"
                    }`}
                  >
                    <input
                      id={`cohort${index}`}
                      type="checkbox"
                      checked={selectedCohorts.includes(cohort)}
                      onChange={() => handleCohortChange(cohort)}
                    />
                    <p>{cohort?.cohort?.name}</p>
                  </label>
                ))}
              </>
            ) : currentSpecific === "learningPath" ? (
              <>
                {pathList?.data?.map((learningPath, index) => (
                  <label
                    htmlFor={`learningPath${index}`}
                    key={index}
                    className={`py-4 flex hover:cursor-pointer text-sm items-center justify-start gap-4 mb-3 rounded-md px-3 border ${
                      selectedLearningPaths.includes(learningPath)
                        ? "border-customOrange"
                        : "border-gray-200"
                    }`}
                  >
                    <input
                      id={`learningPath${index}`}
                      type="checkbox"
                      checked={selectedLearningPaths.includes(learningPath)}
                      onChange={() => handleLearningPathChange(learningPath)}
                    />
                    <p>{learningPath?.name}</p>
                  </label>
                ))}
              </>
            ) : currentSpecific === "course" ? (
              <>
                {courseList?.data?.map((course, index) => (
                  <label
                    htmlFor={`course${index}`}
                    key={index}
                    className={`py-4 flex hover:cursor-pointer text-sm items-center justify-start gap-4 mb-3 rounded-md px-3 border ${
                      selectedCourses.includes(course)
                        ? "border-customOrange"
                        : "border-gray-200"
                    }`}
                  >
                    <input
                      id={`course${index}`}
                      type="checkbox"
                      checked={selectedCourses.includes(course)}
                      onChange={() => handleCourseChange(course)}
                    />
                    <p>{course?.name}</p>
                  </label>
                ))}
              </>
            ) : (
              <>
                {moduleList?.data?.map((quiz, index) => (
                  <label
                    htmlFor={`quiz${index}`}
                    key={index}
                    className={`py-4 flex hover:cursor-pointer text-sm items-center justify-start gap-4 mb-3 rounded-md px-3 border ${
                      selectedQuizzes.includes(quiz)
                        ? "border-customOrange"
                        : "border-gray-200"
                    }`}
                  >
                    <input
                      id={`quiz${index}`}
                      type="checkbox"
                      checked={selectedQuizzes.includes(quiz)}
                      onChange={() => handleQuizChange(quiz)}
                    />
                    <p>{quiz?.name}</p>
                  </label>
                ))}
              </>
            )}
          </div>
          {/* <h1 className="w-full text-left">Additional Conditions(Optional)</h1>
          <h1 className="w-full text-gray-600 text-sm text-left mb-2">
            Apply to specific number of learners?
          </h1>
          <div className="flex items-center mb-2 justify-between w-1/2">
            <div className="flex-1 flex items-center justify-start gap-2">
              <p className="text-sm">No</p>
              <input
                type="radio"
                value="cohort"
                name="leaners"
                // checked={currentSpecific === "cohorts"}
                // onChange={() => handleSpecificChange("cohorts")}
                className="border border-gray-200 block px-4 py-2 rounded-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
                // {...register("specific")}
              />
            </div>
            <div className="flex-1 flex items-center justify-start gap-2">
              <input
                type="radio"
                value="cohort"
                name="leaners"
                // checked={currentSpecific === "cohorts"}
                // onChange={() => handleSpecificChange("cohorts")}
                className="border border-gray-200 block px-4 py-2 rounded-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
                // {...register("specific")}
              />
              <div className="flex items-center justify-start gap-1">
                Top
                <input
                  type="number"
                  value="cohort"
                  name="leaners"
                  className="border border-gray-200 block px-4 py-1 text-sm shadow-inner w-[50px] rounded-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
                />
                Learners
              </div>
            </div>
          </div> */}
          <h1 className="w-full text-left mb-2 text-sm">When</h1>
          <div className="mb-7 flex items-center justify-between w-3/4">
            <div className="flex-1 flex items-center justify-start gap-2">
              <p className="text-sm">On</p>
              <input
                type="radio"
                defaultChecked={currentReward?.isRepeated}
                className="border border-gray-200 block px-4 py-2 rounded-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
                {...register("isRepeated")}
              />
            </div>
            <div className="flex-1 flex items-center justify-start gap-2">
              <p className="text-sm">Before</p>
              <input
                type="radio"
                defaultChecked={currentReward?.isRepeated}
                className="border border-gray-200 block px-4 py-2 rounded-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
                {...register("isRepeated")}
              />
            </div>
            <div className="flex-1 flex items-center justify-start gap-2">
              <p className="text-sm">After</p>
              <input
                type="radio"
                defaultChecked={currentReward?.isRepeated}
                className="border border-gray-200 block px-4 py-2 rounded-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
                {...register("isRepeated")}
              />
            </div>
            <div className="flex-1 flex items-center justify-start gap-2">
              <p className="text-sm">Between</p>
              <input
                type="radio"
                defaultChecked={currentReward?.isRepeated}
                className="border border-gray-200 block px-4 py-2 rounded-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
                {...register("isRepeated")}
              />
            </div>
          </div>
          <div className="mb-7 flex items-center justify-between gap-4">
            <div className="flex-1 flex items-start flex-col justify-center gap-1">
              <p className="text-sm">Start Date/Time</p>
              <div className="flex items-center">
                <Controller
                  name="startDate"
                  control={control}
                  defaultValue={currentReward?.startDate}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DatePicker
                      className="w-full flex-1 border border-gray-200 block px-4 py-2 rounded-tl-md rounded-bl-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/mm/yyyy"
                    />
                  )}
                />
                <input
                  type="time"
                  defaultValue={currentReward?.startTime}
                  className="w-full flex-1 border border-gray-200 block px-4 py-[6.8px] rounded-tr-md rounded-br-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
                  {...register("startTime", { required: true })}
                />
              </div>
            </div>
            <div className="flex-1 flex items-start flex-col justify-center gap-1">
              <p className="text-sm">End Date/Time</p>
              <div className="flex items-center">
                <Controller
                  name="endDate"
                  control={control}
                  defaultValue={currentReward?.endDate}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DatePicker
                      className="w-full flex-1 border border-gray-200 block px-4 py-2 rounded-tl-md rounded-bl-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/mm/yyyy"
                    />
                  )}
                />
                <input
                  type="time"
                  defaultValue={currentReward?.endTime}
                  className="w-full flex-1 border border-gray-200 block px-4 py-[6.8px] rounded-tr-md rounded-br-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
                  {...register("endTime", { required: true })}
                />
              </div>
            </div>
          </div>
          <h1 className="w-full text-left mb-2 text-sm">
            Number of times to allocate reward
          </h1>
          <div className="mb-7 flex items-center justify-between w-3/4">
            <div className="flex-1 flex items-center justify-start gap-2">
              <input
                type="radio"
                name="unlimited"
                // defaultChecked={currentReward?.isRepeated}
                className="border border-gray-200 block px-4 py-2 rounded-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
                // {...register("isRepeated")}
              />
              <p className="text-sm">Unlimited</p>
            </div>
            <div className="flex-1 flex items-center justify-start gap-2">
              <input
                type="radio"
                name="unlimited"
                // defaultChecked={currentReward?.isRepeated}
                className="border border-gray-200 block px-4 py-2 rounded-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
                // {...register("isRepeated")}
              />
              {/* <input
                type="number"
                // defaultValue={currentReward?.numberOfPoints}
                placeholder="Frequency to allocate"
                className="w-full flex-1 shadow-inner border text-xs border-gray-200 block px-4 py-2 rounded-md outline-none duration-200 focus:ring-0 focus:px-5 focus:border-customBlue focus:bg-white"
                // {...register("numberOfPoints")}
              /> */}
              <Count />
            </div>
          </div>
        </div>
        <div className="w-full flex mb-4 items-center justify-between">
          <button className="text-sm">Cancel</button>
          <div className="flex items-center justify-between gap-5">
            <button className="px-5 py-2 border border-customOrange cursor-pointer bg-customOrange text-white rounded duration-100 text-xs">
              Save as Draft
            </button>
            <input
              type="submit"
              value="Save Reward"
              className="text-xs px-5 py-2 border border-customBlue cursor-pointer bg-customBlue text-white rounded duration-100"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddReward;
