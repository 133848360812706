import React from 'react';
import Collapsible from 'react-collapsible';
import { v4 as uuid } from 'uuid';

const Collapse = ({children, title, isOpen=true}) => {
  return (
    <Collapsible 
        open={isOpen} 
        triggerElementProps={
            { id: uuid() }
        } 
        trigger={
            <p className='flex py-2 shadow-md border-t border-gray-200 bg-lightBlue px-2 text-xs font-semibold justify-between items-center'> 
               {title} <i className="bi bi-chevron-down text-sm"></i>
            </p>
        }
        triggerWhenOpen={
            <p className='flex text- py-2 shadow-md px-2 border-t bg-lightBlue border-gray-200 text-xs font-semibold justify-between items-center'> 
               {title} <i className="bi bi-chevron-up text-sm"></i>
            </p>
        }
    >
        {children}
    </Collapsible>
  );
};

export default Collapse;