import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import CompanyInfo from "./pages/Auth/CompanyInfo";
import PasswordSection from "./pages/Auth/PasswordSection";
import Login from "./pages/Auth/Login";
import ReleaseNote from "./pages/ReleaseNote";
import RegistrationSteps from "./pages/Auth/RegistrationSteps";
import { Toaster } from "react-hot-toast";
import AdminRoutes from "./pages/Admin/AdminRoutes";
import { ReactQueryDevtools } from "react-query/devtools";
import ResetPassword from "./pages/Auth/ResetPassword";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import EmailVerification from "./pages/Auth/EmailVerification";

import Editor from "./components/Editor";

function App() {
   
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/admin/")) {
      // document.querySelector('.admin-container-panel').scroll({top: 0})
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
    document.querySelectorAll('*').forEach( element => {
       element.scroll({top: 0, left: 0, behavior: 'instant'})
    })
  }, [location.pathname]);

  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<RegistrationSteps />} />
        <Route path="/registration/company-info" element={<CompanyInfo />} />
        <Route path="/registration/password" element={<PasswordSection />} />
        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/admin/login" element={<Login />} />
        <Route path="/admin/*" element={<AdminRoutes />} />
        <Route path="/LR_release_note" element={<ReleaseNote />} />
        <Route path="/auth/forgot-password" element={<ForgotPassword />} />
        <Route path="/auth/reset-password" element={<ResetPassword />} />
        <Route path="/auth/verify-email" element={<EmailVerification />} />
        <Route path="/auth/editor" element={<Editor />} />
      </Routes>
      <Toaster containerClassName="text-xs" />
      <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
    </React.Fragment>
  );
}

export default App;
