import React, {useState} from 'react'
import Collapse from './Collapse'
import Button from '../../../../../../../components/Buttons/Button'
import blockChain from '../../../../../../../assets/Blockchain.png'
import { courses } from './dummyData'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom';
import { api } from '../../../../../../../api/courses'
import { useMutation } from 'react-query'

const Preview = ({modulePreview, setCourses, values, modules, courseId, refetch }) => {

  const [preview, setPreview] = useState(null)
  const navigate = useNavigate()

  const assignModuleToCourseMutation = useMutation(api.assignModuleToCourse, {
    onMutate: () => {
       toast.loading('Loading...')
    },
    onSuccess: data => {
       toast.remove()
       toast.success('Course created successfully.')
       navigate('/admin/course/view')
       refetch()
    }
 })

  const assignModulesToCourse = () => {
    if(modules?.length){
         assignModuleToCourseMutation.mutate({
            id: courseId,
            data: {
             newModules: modules?.map( (module, index) => {
               return {
                  points: module?.point,
                  moduleId: module?.moduleData?.id,
                  sorting: index + 1
               }
            } )},
         })
    }
    else toast.error('Assign Modules created to the course before you proceed')
 }

  return (
    <section> 
         <header className='flex justify-between items-center w-[100%]'>
               <div>
                    <h5 className='text-sm mt-5 font-semibold'>
                      Preview Course Content
                    </h5>
                    <p className='mt-1 text-xs mb-2'>
                      preview your modules, add assessments and resources
                    </p>
               </div>
                <div className='pr-2'>
                  <Button
                    text={'Save & proceed'}
                    width={'w-[150px]'}
                    bgColor={'bg-customBlue'}
                    onClick={assignModulesToCourse}
                />
                </div>
         </header>
         <section className='bg-white flex jusify-between'>
            <div className='w-[38%] p-2 border-r border-r-2 border-gray-100'>
               <h1 className='text-sm pl-2 bg-customBlue text-white font semibold border-b py-3 border-gray-200 '> 
                   <b>Course Title:</b> {values?.c_title}
                </h1>
               <div className='mt-3'>  
                   {
                      modulePreview?.length ? 
                         modulePreview.map((module, index) => {
                            return (
                              <Collapse 
                                  title={`Module ${index+1}: ${' '} ${module.module.label} `}
                                   isOpen key={module.module.id}>
                                  <ul className='pl-3 pb-3'>
                                     {
                                       module.files.map( (file, index) => {
                                         return ( 
                                            <li 
                                              className='text-xs mt-2 whitespace-nowrap'
                                              >
                                               <b>{index + 1}</b>. &nbsp;
                                               {
                                                 file?.file?.type === 'r-link' ?
                                                   <a href={file.link} target='_blank' className='text-blue-500'>
                                                     Web link
                                                   </a>  :
                                                   <button  onClick={() => setPreview(file)} 
                                                           className='text-blue-500'
                                                    >
                                                      {file?.file}
                                                   </button>
                                               }
                                            </li>
                                         )
                                       })
                                     }
                                  </ul>
                              </Collapse>
                            )
                         } )
                      :
                      <p className='text-sm mt-[100px] text-center'>
                          No any resourse attached to a module
                      </p>
                   }
               </div>     
            </div>
            <div className='w-[60%]'>
                 <div className='h-[400px] flex justify-center items-center'>
                      {
                        preview === null ?
                         <div>
                          <img src={blockChain} alt="block chain" className='w-[250px] block mx-auto h-[180px]' />
                          <p className='pt-2 text-sm text-center'>
                              Click on a resourse aside to preview
                          </p>
                         </div>
                         :
                          <>
                             {
                                  preview !== null ?
                                    <div className='filex items-center justify-center'>
                                       {
                                          preview?.type?.includes('image') ?
                                          <div>
                                             <img src={preview.preview} alt=""
                                                 className='w-[500px] h-[350px]'
                                             />
                                          </div>
                                          : null
                                          }
                                        {
                                          preview?.type?.includes('audio') ?
                                          <div>
                                                {/*<p className='text-left text-sm py-2'>File Name: {preview.file.name}</p>
                                                <p className='pb-2 text-left text-sm'>
                                                      Size: {(preview.file.size/(1024*1024)).toFixed(2)} MB
                                        </p>*/}
                                                <audio className='pb-3' controls>
                                                  <source src={preview.preview} type="audio/ogg" />
                                                  Your browser does not support the audio element.
                                                  </audio>
                                          </div>
                                          : null
                                          }
                                          { preview?.type?.includes('video') ?
                                              <div className='rounded-lg'>
                                              <video controls
                                                  src={preview.preview} 
                                                  poster={'/images/file_video.png'}
                                                  className='h-[350px] rounded-lg w-full p-2 rounded-lg w-fullrounded' 
                                              />
                                              </div>
                                              : null
                                          }
                                          { preview?.type?.includes('pdf') ?
                                              <div className='mt-2 w-full'>
                                                  <iframe 
                                                      src={preview.preview} 
                                                      className='h-[380px] w-[500px] p-2 rounded-lg w-fullrounded' 
                                                  ></iframe>
                                              </div>
                                              : null
                                          }
                                    </div>
                                  : null
                              }
                          </>
                      }
                 </div>
            </div>
         </section>
         {/*<section className='mt-1 w-[60%]'>
            <div className='flex justify-between rounded-lg mb-4 items-center bg-white py-4 px-3'>
                <div className='flex items-center'>  
                    <div className='w-[35px] h-[35px] bg-gray-100 rounded'>

                    </div>
                    <p className='ml-2 text-xs '>
                        How to Play
                    </p>
                </div>
                <button className='py-2 mr-5 text-md bi bi-three-dots-vertical'>      
                </button>
            </div>
            <div className='rounded-lg mb-4 items-center bg-white py-4 px-3'>
                <div className='flex items-center justify-between'>  
                    <div className=''>
                         <span className='bi bi-arrows-move text-lg'></span>
                         <span className='text-sm ml-2 font-semibold'>
                            Sample Module 1
                         </span>
                    </div>
                    <div className=''>
                            <span className='text-xs mr-5 px-2'>
                                  <span className='bi bi-plus-circle'></span>
                                  &nbsp; points: &nbsp; 3000
                             </span>
                            <span className='text-xs mr-5'>
                                  <span className='bi bi-clock'></span>
                                  &nbsp; Time: &nbsp; 23hrs
                             </span>
                             <button className='py-2 mr-5 text-md bi bi-three-dots-vertical'>      
                            </button>
                    </div>
                </div>
                <div className='mt-0 ml-3 text-gray-700'>  
                    <span className='text-xs'>
                            <span className='bi bi-border-width'></span>
                            &nbsp; Activity Summary
                    </span>  
                    <span className='text-sm ml-3'>
                            <span className='bi bi-link-45deg'></span> 2
                    </span>  
                    <span className='text-sm ml-3'>
                            <span className='bi bi-file-earmark-image'></span> 5
                    </span>   
                </div>
            </div>
            <div className='rounded-lg mb-4 items-center bg-white py-4 px-3'>
                <div className='flex items-center justify-between'>  
                    <div className=''>
                         <span className='bi bi-arrows-move text-lg'></span>
                         <span className='text-sm ml-2 font-semibold'>
                            Sample Module 2
                         </span>
                    </div>
                    <div className=''>
                            <span className='text-xs mr-5 px-2'>
                                  <span className='bi bi-plus-circle'></span>
                                  &nbsp; points: &nbsp; 3000
                             </span>
                            <span className='text-xs mr-5'>
                                  <span className='bi bi-clock'></span>
                                  &nbsp; Time: &nbsp; 23hrs
                             </span>
                             <button className='py-2 mr-5 text-md bi bi-three-dots-vertical'>      
                            </button>
                    </div>
                </div>
                <div className='mt-0 ml-3 text-gray-700'>   
                    <span className='text-xs ml-3'>
                            <span className='bi bi-link-45deg'></span> Post Assessment
                    </span>     
                </div>
            </div>
            <div className='rounded-lg mb-4 items-center bg-white py-4 px-3'>
                <div className='flex items-center justify-between'>  
                    <div className=''>
                         <span className='bi bi-arrows-move text-lg'></span>
                         <span className='text-sm ml-2 font-semibold'>
                            Sample Media Module
                         </span>
                    </div>
                    <div className=''>
                            <span className='text-xs mr-5'>
                                  <span className='bi bi-clock'></span>
                                  &nbsp; Time: &nbsp; 23hrs
                             </span>
                             <button className='py-2 mr-5 text-md bi bi-three-dots-vertical'>      
                            </button>
                    </div>
                </div>
                <div className='mt-0 ml-3 text-gray-700'>   
                  <div className='flex items-center'>  
                    <div className='w-[35px] h-[35px] bg-gray-100 rounded'>
                    </div>
                    <p className='ml-2 text-xs text-blue-500 underline'>
                         Zoom Link
                    </p>
                  </div>
                </div>
            </div>
  </section>*/}
    </section>
  )
}

export default Preview