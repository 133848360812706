import React, { useState } from "react";
import Header from "../../components/Layout/Header/Header";
import FormHeader from "./components/FormHeader";
import Ellipse3 from "../../assets/Ellipse 3.png";
import Ellipse1 from "../../assets/Ellipse 1 (1).png";
import Input from "./components/Input";
import Button from "../../components/Buttons/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { loginValidation } from "./utils/validation";
import { useMutation } from "react-query";
import { api } from "../../api";
import toast from "react-hot-toast";

const ForgotPassword = () => {
   const initialValues = {
    email: "",
  };

  const location = useLocation();
  const isAdminRoute = location.pathname.includes("admin");
  const navigate = useNavigate();
  const url = window.location.origin
  

  const validate = (values) => loginValidation(values);

  const forgetPasswordMutation = useMutation(api.forgotPassword, {
    onMutate: (values) => {
      toast.loading('Loading...');
      return { values }; // This will be accessible in onSuccess
    },
    
    onSuccess: (response, variables, context) => {
      toast.remove();
      toast.success(response.message);
      sessionStorage.setItem("reg-email", variables.email);
      navigate("/auth/reset-password");
    },
   
    onError: ({ response }) => {
      toast.remove();
      toast.error(response.data?.message);
    },
  });

  const forgetPassword = (values, actions) => {
    // Handle submission
    forgetPasswordMutation.mutate({
      email: values?.email,
      baseUrl: `${url}/auth/reset-password`,
    });
    actions.setSubmitting(false);
  };
  return (
    <div>
      <Header isAdminRoute={isAdminRoute} />
      <div
        className={`mt-12 relative w-[90%] mx-auto shadow-lg rounded-lg md:w-[450px] ${
          isAdminRoute ? "bg-white" : "bg-lightOrange"
        } py-5 px-8`}
      >
        <img
          src={Ellipse3}
          alt="ellipse"
          className="w-[70px] h-[40px] top-0 right-0 absolute"
        />
        <FormHeader
          text="Forgot Password"
          className={`${
            isAdminRoute ? "text-customBlue" : "text-primary"
          } font-bold text-2xl mt-8`}
        />
        <p className="my-4 text-xs">
          Please type in your email to get a link to reset your password
        </p>
        <Formik
          initialValues={initialValues}
          // validate={validate}
          enableReinitialize={true}
          onSubmit={forgetPassword}
        >
          {({ values, setFieldError, setFieldValue }) => (
            <Form>
              <Input
                label={"Email"}
                name={"email"}
                type="email"
                colorScheme={isAdminRoute ? "blue" : "orange"}
              />
              <div className="mt-8">
                <button
                  type="submit"
                  id="submit-btn"
                  className={`w-[60%] block mx-auto ${
                    isAdminRoute ? "bg-customBlue" : "bg-primary"
                  } text-white py-3 rounded-lg text-xs`}
                >
                  Reset
                </button>
                <p className="mt-5 text-xs pl-8"  onClick={() => navigate(-1)}>
                  <Link
                    className={`text-xs ${
                      isAdminRoute
                        ? "underline text-customBlue"
                        : "text-primary"
                    } font-semibold`}
                  
                  >
                    Go Back
                  </Link>
                </p>
              </div>
              <img
                src={Ellipse1}
                alt="ellipse"
                className="w-[70px] h-[40px] bottom-[1px] left-0 absolute"
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ForgotPassword;
