
export const signUpValidation = (values) => {
    const errors = {};
    const strongPasswordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?=.*[a-zA-Z]).{8,}$/;

     if (!values.fname) {
        errors.fname = "Error! first name field is required.";
      } 
      else if (!values.lname) {
        errors.lname = "Error! last name field is required.";
      } 
     else if (!values.email) {
      errors.email = "email address is required.";
    } 
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    } else if (!values.phone_number) {
      errors.phone_number = "Error! phone number field cannot be empty.";
    } 
    else if (!values.phone_number.toString().length > 13) {
      errors.phone_number = "Error! maximum phone number is 13 digits";
    } 
    else if (!values.company_name) {
        errors.company_name = "Error! company name field is required.";
      } 

    else if (!values.company_address) {
       errors.company_address = "Error! company address field is required.";
    } 

    else if (!values.role) {
      errors.role = "Error! add your company domain to continue.";
    } 
    else if(!values?.password){
       errors.password = 'Error! Password field is required.'
    }
    else if(!strongPasswordRegex.test(values.password)){
      errors.password =  `Password must be at least 8 characters long, 
      contain at least one uppercase letter, 
      one lowercase letter, one number,
       and one special character.`
   }
    else if(!values?.cpassword){
      errors.cpassword = 'Error! Confirm your password.'
   }
    else if(values?.password !== values?.cpassword){
      errors.password = 'Error! Password choosen are not the same.'
      errors.cpassword = 'Error! Password choosen are not the same.'
   }
    return errors;
}

export const loginValidation = (values) => {
  const errors = {};

 if (!values.email) {
    errors.email = "email address is required.";
  } 
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }  
  else if(!values?.password){
     errors.password = 'Error! Password field is required.'
  } 
  return errors;
}