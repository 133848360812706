import React, { useContext } from "react";
import { LearnersContext } from "../../../../../context/learnersContext";

const OverViewTitle = () => {
  const { pageType, selectedCohort, selectedPath, selectedCourse } =
    useContext(LearnersContext);

  //   console.log(pageType);
  //   console.log(selectedCohort);
  //   console.log(selectedPath);
  //   console.log(selectedCourse);

  const renderContent = () => {
    switch (pageType) {
      case "profile":
        return "";
      case "cohort":
        return selectedCohort?.cohortName
          ? `- ${selectedCohort?.cohortName}`
          : "";
      case "path":
        return selectedPath?.learningPathName
          ? `- ${selectedPath?.learningPathName}`
          : "";
      case "course":
        return selectedCourse?.courseName
          ? `- ${selectedCourse?.courseName}`
          : "";
      default:
        return null;
    }
  };

  return <span>{renderContent()}</span>;
};

export default OverViewTitle;
