import React, { useState } from "react";
import Button from "../../../../components/Buttons/Button";
import CenteredModal from "../../../../components/Modal/CenteredModal";
import CreateModule from "./CreateModule";
import EditModule from "./EditModule";
import useModules from "../../../../hooks/useModules";
import Table from "../../../../components/table/Table";
import Search from "../../../../components/Inputs/Search";
import Pagination from "../../../../components/pagination/Pagination";

const Module = () => {
  const {
    buttonProps,
    tableProps,
    createModuleProps,
    editModuleProps,
    handleModal,
    handleEditModal,
    setSearch,
    perPage,
    setPerPage,
    currentPage,
    setCurrentPage,
    data
  } = useModules();

  return (
    <div className="p-2 mt-5">
      <CenteredModal 
        title={
          <h5 className='pb-2 flex mb-3 items-center border-b border-gray-500 w-full'> 
            <i className='text-lg  pr-2 bi bi-plus-circle'></i> 
               Create Module  
          </h5>
       }
      {...createModuleProps}
       >
        <CreateModule handleModal={handleModal} />
      </CenteredModal>
      <CenteredModal 
         title={
          <h5 className='pb-2 flex mb-3 items-center border-b border-gray-500 w-full'> 
            <i className='text-lg  pr-2 bi bi-plus-circle'></i> 
               Edit Module  
          </h5>
       }
        {...editModuleProps}
      >
        <EditModule handleModal={handleEditModal} />
      </CenteredModal>
      <div className="w-full bg-lightBlue mt-0 pt-3 px-3 flex items-center justify-between">
        <h1 className="text-xl items-center font-semibold flex justify-between">
           <span className="-mt-5 text-sm mr-12"> Modules ({data?.meta?.total}) &nbsp;</span>
            <Search
               className={'mb-0'} onChange={ e => setSearch(e.target.value) } 
               placeholder={'search modules...'} width={'w-[400px]'}
            />
        </h1>
        <div className="flex items-center -mt-5 justify-center">
          <Button {...buttonProps} />
        </div>
      </div>
      <div className="w-full flex bg-white mt-5 items-center justify-center">
        <Table {...tableProps} />
      </div>
      <div className="bg-white mt-5 px-3">
      <Pagination 
            data={data?.meta}
            perPage={perPage}
            pageNo={currentPage}
            setPageNo={setCurrentPage}
            setPerPage={setPerPage}
      />
      </div>
    </div>
  );
};

export default Module;
