import React from 'react'
import Button from '../../../../../../../../../components/Buttons/Button'
import CustomDropdown from '../../../../../../../../../components/dropdown/CustomDropdown'
import toast from 'react-hot-toast'
import { api } from '../../../../../../../../../api/learningPath'
import { useMutation, useQuery } from 'react-query'
import { errorResponse } from '../../../../../../../../../components/utils/errorResponse'

const AddLearningPath = ({ setCurrentStep, selectedJourney, setSelectedJourney, cohortId }) => {

   const orgId = JSON.parse(sessionStorage.getItem('user'))?.org?.id
   const { data, isLoading, } = useQuery(
    ["view journeys"],
    () => {
      return api.getLearningPath({ perPage: 10000, currentPage:1, org_id: orgId, });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      refetchOnMount:true,
      select: (data) => data?.data,
    })

    const addCohortMutation = useMutation(api.assignCohorts, {
      onSuccess: (data) => {
          toast.remove()
          toast.success('Learning Path Added  Successfully!')
          setCurrentStep(3)
      },
      onError: error => errorResponse(error),
      onMutate: () => {
          toast.loading('Loading...')
      }
    })

 const addLearningPath = () => {
    if(selectedJourney === null) toast.error('Error! Select Learning Path To Proceed.')
    else {
      const payload = {
          org_id: orgId, journey_id: selectedJourney?.value,
          payload: {
            allCohorts: false,
            newCohortIds: [cohortId]
          }
      }
      addCohortMutation.mutate(payload)
    }
 }

  return (
    <>   
       <div className={`w-[500px] mx-auto shadow-2xl p-4 rounded-lg h-[auto] bg-white mt-8`}>
              <header className='flex justify-between items-center'>
                   <div>
                      <h1 className='text-md font-semibold mt-1'>
                        Step 2 <span className='bi bi-arrow-right'></span> &nbsp;
                        Add Learning Path 
                      </h1>
                      <p className='text-xs mb-2 text-gray-700'>
                         add learning path to your cohort
                      </p>
                   </div>
              </header>
             <div className='border-t border-gray-300 mt-3 pt-3'>
              <label className='text-xs pb-2'> Select Learning Path </label>
                 <CustomDropdown
                    value={selectedJourney}
                    options={ isLoading ? [] :
                      data?.length ? 
                       data?.map( journey => {
                          return { label: journey?.name, value: journey?.id }
                       } )
                      : []
                    }
                    onChange={ value => setSelectedJourney(value) }
                    placeholder={'Select Learning Path'}
                    //multiSelect
                    width={'w-full mt-2'}
                 />
                  <div className='flex mt-5 justify-end'>
                      <Button 
                        width={'w-[160px]'} 
                        text={'Add Learning Paths'}
                        bgColor={'bg-customBlue'}
                        onClick={ addLearningPath }
                      />  
                  </div>    
             </div>
         </div>
    </>
  )
}

export default AddLearningPath