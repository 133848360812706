import React, {useEffect} from 'react'
import {Dropdown, Ripple, initTE, } from "tw-elements";
  

const ModuleMediaType = ({title, price, points, time, id, setModules, modules }) => {

 useEffect( () => {
    initTE({ Dropdown, Ripple });
 }, [] )

 const deleteModule = () => {
    setModules(modules.filter( module => module.id !== id ))
    document.querySelector(`div[data-module-id="${id}"]`).remove()
 }

  return (
      <div data-module-id={id} className='rounded-lg hover:cursor-pointer mb-4 items-center shadow-md border-t border-gray-300 py-4 px-3'>
        <div className='flex items-center justify-between'>  
            <div className=''> 
                <span className='bi bi-arrows-move text-lg'></span>
                <span className='text-xs ml-2'>
                    {title}
                </span>
            </div>
            <div className='flex items-center'>
                   {/*<span className='text-xs mr-5 px-2'>
                        <span className='bi bi-coin'></span>
                        &nbsp; price: &nbsp; {price}
                    </span>*/}
                   {/* <span className='text-xs mr-5 px-2'>
                        <span className='bi bi-plus-circle'></span>
                        &nbsp; points: &nbsp; {points}
                    </span>*/}
                    <span className='text-xs mr-5'>
                        <span className='bi bi-clock'></span>
                        &nbsp; : &nbsp; {time} mins
                    </span>
                  <div class="relative w-auto" data-te-dropdown-ref>
                   <button className='py-2 mr-5 text-md bi bi-three-dots-vertical'
                       id="dropdownMenuButton1"
                       data-te-dropdown-toggle-ref
                       aria-expanded="false"
                       data-te-ripple-init
                       data-te-ripple-color="light"
                    >      
                    </button>
                    <ul
                        class="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
                        aria-labelledby="dropdownMenuButton1"
                        data-te-dropdown-menu-ref>
                        <li>
                        <button
                            class="block w-full bi bi-trash whitespace-nowrap bg-transparent px-4 py-2 text-xs font-normal text-red-500 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                            data-te-dropdown-item-ref
                            onClick={ deleteModule }
                            >
                                &nbsp;Delete
                            </button>
                        </li>
                        
                    </ul>
                    </div>
            </div>
        </div>
    </div>
  )
}

export default ModuleMediaType