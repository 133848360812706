import React, { useContext } from "react";
import { LearnersContext } from "../../../../../context/learnersContext";
import { CompletedState, SuspendedState } from "./svg";
import ViewCourse from "./ViewCourse";

const ViewModules = () => {
  const { currentLearner, pageType } = useContext(LearnersContext);

  // console.log(currentLearner);

  if (pageType === "path") return <ViewCourse />;

  return (
    <div className="">
      <div className="bg-gray-200 p-2 flex items-center justify-between">
        <p className="font-semibold">{currentLearner?.name}</p>
        <p className="font-semibold">Score: {currentLearner?.overallScore}</p>
      </div>
      <div className="mt-4">
        <h1 className="underline w-full text-xs text-start">Modules</h1>
        {currentLearner?.moduleData?.map((module) => (
          <div className="bg-gray-100 p-3 mt-1 flex items-center justify-between">
            <p className="text-sm">{module?.module?.name}</p>
            <div
              className={`text-sm flex items-center justify-center gap-1 font-medium capitalize ${
                module?.status === "pass" ? "text-green-600" : "text-red-500"
              }`}
            >
              <span>
                {" "}
                {module?.status === "pass" ? (
                  <CompletedState />
                ) : (
                  <SuspendedState />
                )}
              </span>
              {module?.status}
            </div>
          </div>
        ))}
        <h1 className="underline w-full text-xs font-medium text-start mt-4">
          Assessments
        </h1>
        <div>
          {currentLearner?.assessmentData?.map((module) => (
            <div className="bg-gray-100 p-3 mt-1 flex items-center justify-between">
              <p className="text-sm">{module?.module?.name}</p>
              <div
                className={`text-sm flex items-center justify-center gap-1 font-medium capitalize ${
                  module?.status === "pass" ? "text-green-600" : "text-red-500"
                }`}
              >
                <span>
                  {" "}
                  {module?.status === "pass" ? (
                    <CompletedState />
                  ) : (
                    <SuspendedState />
                  )}
                </span>
                {module?.status}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewModules;
