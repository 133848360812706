import React, { useState } from 'react';
import Button from '../../../../../../../components/Buttons/Button';
import { useMutation, useQuery } from 'react-query';
import { api } from '../../../../../../../api/courses';
import CenteredModal from '../../../../../../../components/Modal/CenteredModal';
import toast from 'react-hot-toast';
import ModuleMediaType from './ModuleMediaType';
import ReorderList from "react-reorder-list";
import ResourceLoader from '../../CreateCourse/components/ResourceLoader';
import { useNavigate } from 'react-router-dom';
import EmptyData from './../../../../../../../components/EmptyData';
import Modules from './Modules';
import Loader from '../../../../../../../components/loader/Loader';

const CourseContent = ({ courseId, refetch }) => {
 
const [showModal, setShowModal] = useState(false)
const [selectedModules, setSelectedModules] = useState([])
const [isUploading, setIsUploading] = useState(false)
const navigate = useNavigate()  

const { data: modules, isLoading } = useQuery(
   ["Modules Details", {id: courseId}],
   ({ queryKey }) => {
     return api.getCourseDetails({...queryKey[1]});
   },
   {
     cacheTime: 10000,
     staleTime: 10000,
     refetchOnWindowFocus: true,
     select: (data) => data,
     onSuccess: data => {
        setSelectedModules(
           data?.data?.courseModules?.map( module => {
              return { ...module?.module }
           })
        )
     }
   },
);

const assignModuleToCourseMutation = useMutation(api.assignModuleToCourse, {
   onMutate: () => {
      toast.loading('Loading...')
   },
   onSuccess: data => {
      toast.remove()
      toast.success('Course content updated successfully.')
      navigate('/admin/course/view')
      refetch()
   },
   onError: error => {
      toast.remove()
      if(error.response.status === 500) toast.error(error.response.statusText)
        else{
           if(error?.response?.data?.errors?.length > 1){
            error?.response?.data?.errors?.map( error => {
              toast?.error(error?.message)
            }) 
           }
           else toast.error(error?.response?.data?.errors[0]?.message)
        }
   }
 })

 const assignModulesToCourse = () => {
     
   if(selectedModules?.length){
         assignModuleToCourseMutation.mutate({
            id: courseId,
            data: {
             newModules: selectedModules?.map( (module, index) => {
               return {
                  points: module?.point || 1,
                  moduleId: module?.id,
                  sorting: module?.sorting || index + 1
               }
            }),
            /*
            deleted modules are modules that are modules that comes by default that are not
             included in the newly updated module
             */
             deletedModules: modules?.data?.courseModules?.map( module => {
               return { ...module?.module }
               }).filter( oldModule => !selectedModules.some( newModule =>  newModule.id === oldModule.id))?.map(
                  (module, index) => {
                     return module?.id /*{
                        points: module?.point || 1,
                        moduleId: module?.id,
                        sorting: module?.sorting || index + 1
                     }*/
                }
            )
         },
         })
    }
    else toast.error('Assign Modules created to the course before you proceed') 
 }

  return (
    <div className='mt-0 relative'>   
        {
            isUploading && 
             <ResourceLoader />
        }
         <header className='flex justify-between items-center w-[90%]'>
               <div>  
                    <h5 className='text-lg font-semibold'>
                      Course Content  
                    </h5>
                    <p className='mt-1 text-sm mb-5'>
                       Let’s create your modules, assessments and resources
                    </p>
               </div>
               <Button
                  bgColor={'bg-customBlue'}
                  text={'Save Changes'}
                  width={'w-[200px]'}
                  onClick={assignModulesToCourse}
              />
         </header>
         <section className='flex justify-center pr-8'>
              <div className='w-[80%] rounded-lg h-[auto] bg-white pt-2 px-8'>
                    <div className='flex justify-between pb-3 pt-2'>
                        <p className='pb-2 text-sm'>
                            Select a module
                        </p>
                        <button 
                                className='py-2 text-white rounded-lg text-xs bi bi-upload bg-primary px-8'
                                onClick={ () => setShowModal(true) }
                            >
                               &nbsp; Add Module
                         </button>
                            {showModal ? 
                            <CenteredModal
                                  open={showModal} 
                                   width={'w-[700px] overflow-y-auto h-[500px]'} 
                                   bgColor={'bg-white'}
                                  setOpen={setShowModal} 
                                  title={
                                     <h5 className='pb-2 flex items-center border-b border-gray-500 w-full'> 
                                       <i className='text-lg  pr-2 bi bi-plus-circle'></i> 
                                          Add Modules  
                                        <span className='ml-8 text-xs'>Selected ({selectedModules?.length}) </span>
                                     </h5>
                                  }
                                  withCloseBtn
                                  closeButtonLabel={'Ok'}
                                  onClose={ () => setShowModal(false) }
                            >
                               <Modules 
                                 selectedModules={selectedModules} 
                                 setSelectedModules={setSelectedModules}
                               /> 
                            </CenteredModal> : null}
                    </div> 
                    { 
                      isLoading ? <Loader /> : 
                     selectedModules?.length ? <section className='mt-3 h-[400px] overflow-y-auto'>
                        <h6 className='text-left text-xs mb-2'>
                           Arrange your modules according on your requirements,
                           you can drag and drop module to reorder.
                        </h6>
                        <ReorderList  onPositionChange={ ({newItems})  => {
                           const updatedModules = newItems?.map( item => {
                              return item?.props?.modules?.find( module => {
                                 return module?.id === item?.props?.id
                              })
                           })
                           setSelectedModules(updatedModules)
                        }}
                        watchChildrenUpdates={true}
                        >
                           {
                             selectedModules?.length ?
                               selectedModules?.map( module => {
                                 return (
                                       <ModuleMediaType 
                                          points={module?.point}
                                          price={module?.price}
                                          time={module?.duration/60}
                                          title={module?.name}
                                          key={module?.id}
                                          id={module?.id}
                                          setModules={setSelectedModules}
                                          modules={selectedModules}
                                       />                                             
                                 )
                              } )
                           : null
                           }
                           </ReorderList>
                     </section> 
                     : 
                     <EmptyData text={'There is no any module to preview yet.'} />
                  }  
              </div>
         </section>
         <div className='flex justify-end mt-4 w-[60%]'>
         </div>
         <div className='mt-8 flex w-[400px]'>
              {/*<Button
                  bgColor={'bg-transparent'}
                  textColor={'text-gray-900'}
                  text={'Back'}
                  width={'w-[150px]'}
                  icon={'bi bi-arrow-left'}
                  onClick={
                    () => setActiveScreen(1)
                 }
                /> */}&nbsp; &nbsp;
         </div>
    </div>
  )
}

export default CourseContent