import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Invoice from './pages/Invoice'
import ClientInvoice from './pages/client-invoice/ClientInvoice'

const InvoiceRoutes = () => {
  return (
    <section>
      <Routes>
          <Route path='/' element={ <Invoice /> } />
          <Route path='/client/:id/*' element={ <ClientInvoice /> } />
      </Routes>
    </section>
  )
}

export default InvoiceRoutes