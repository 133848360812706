import React from 'react'
import Button from '../../../../../components/Buttons/Button'

const Index = () => {
  return (
    <>
       <section className='flex items-center justify-center'>
            <div className='py-12 w-[70%] mt-[100px] bg-lightBlue rounded'>
                 <h5 className='text-md text-center'>
                    Let’s get started, {
                        JSON.parse(sessionStorage.getItem('user'))?.name
                    }.
                 </h5>
                 <h2 className='text-xl mt-3 text-primary text-center'>
                     Create your first course
                 </h2>
                 <p className='text-center mt-3 text-sm mb-4'>
                     Get started with our intuitive course builder.
                 </p>
                  <div className='flex justify-center'>
                    <Button 
                      bgColor={'bg-customBlue'}
                      text={'Create Course'}
                      width={'w-[300px]'}
                      renderAs='link'
                      to='/admin/course/create-course'
                  />
                  </div>
            </div>
       </section>
    </>
  )
}

export default Index