import React from 'react'
import Button from '../../../../../../../../../components/Buttons/Button'

const LoginTemplate = ({loginTemplate, setLoginTemplate}) => {
  return (
    <div className='py-4'>
        <header>
        {  
           loginTemplate.brandLogo ?   
           <div className='w-[150px] mx-auto text-center rounded border border-gray-200'>
              <img src={loginTemplate.brandLogo} alt="logo" className='w-full block h-[80px]' />
           </div>
           :      
            <div className='py-1 w-[120px] mx-auto bg-gray-100 text-center rounded border border-gray-200'>
                <i className='bi bi-image'></i>
                <span className='block mt-1 text-xs text-gray-700'>
                    Brand Logo
                </span>
            </div>
        }
            <h2 
               className='text-md mt-2 text-center font-semibold'
               style={{color: loginTemplate.brandColor}}
            >
               {loginTemplate.headerText}
            </h2>
        </header>
        <div>
         <label className='text-xs'> Email/username </label>
         <input type="text" className='input-md' disabled />
        </div>
        <div className='my-3'>
            <label className='text-xs'> Password </label>
            <input 
                 type="text" className='input-md'
                 disabled
             />
        </div>
         <div className='flex justify-center'>
           <Button style={{backgroundColor:loginTemplate.brandColor}} text={'Login'} />
         </div>
         <div>
             <p className='text-xs mt-3 text-center'>Forgot your password?  
                <span style={{color:loginTemplate.brandColor}} className='ml-2 text-xs'> 
                   Reset password 
                </span>
             </p>
         </div>
    </div>
  )
}

export default LoginTemplate