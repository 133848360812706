import React, { useEffect, useState } from 'react'
import EmptyData from '../../../../../../../../components/EmptyData'
import Button from '../../../../../../../../components/Buttons/Button'
import { feedbackApi } from '../../../../../../../../api/feedback'
import { useMutation, useQuery } from 'react-query'
import Search from '../../../../../../../../components/Inputs/Search'
import Pagination from '../../../../../../../../components/pagination/Pagination'
import Loader from '../../../../../../../../components/loader/Loader'
import toast from 'react-hot-toast'
import ScreenNavigator from '../../../../../learning-path/components/ScreenNavigator'
import { errorResponse } from '../../../../../../../../components/utils/errorResponse'
import FeedbackSetting from './components/FeedbackSetting'
import { useNavigate } from 'react-router-dom'

const CreateFeedbackTemplate = ({selectedQuestions, setSelctedQuestions}) => {

 const [perPage, setPerPage] = useState(5);
 const [search, setSearch] = useState("");
 const [currentPage, setCurrentPage] = useState(1);
 const [isCreated, setIsCreated] = useState(false)
 const [status, setStatus] = useState(true)
 const [ activeScreen, setActiveScreen] = useState(1)
 const [templeteName, setTemplateName] = useState('')
 const [isCourse, setIsCourse] = useState(true)
 const [afterCourses, setAfterCourses] = useState(true)
 const [isMandatory, setIsMandatory] = useState(true)
 const [afterAssessment, setAfterAssessment]= useState(false)
 const [journeyId, setJourneyId] = useState(null)
 const [courseId, setCourseId] = useState(null)
 const [templateId, setTemplateId] = useState(null)
 const navigate = useNavigate()
 
 const { data: questionBank, isLoading, refetch } = useQuery(
    ["get question bank", { perPage, currentPage, search }],
    ({queryKey}) => { 
        return feedbackApi.getQuestions({...queryKey[1]});
    },
    {
        cacheTime: 100,
        staleTime: 100,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
  });

  const createTemplateMutation = useMutation(feedbackApi.createTemplate, {
    onSuccess: res => {
        toast.remove()
        toast.success(res?.message)
        setTemplateId(res?.data?.id)
        setIsCreated(true)
        setActiveScreen(2)
     },
     onMutate: () => {
        toast.loading('Loading...')
     },
     onError: error => errorResponse(error)
  })

  const addSettingMutation = useMutation(feedbackApi.addFeedbackSettings,{
    onSuccess: res => {
        toast.remove()
        toast.success(res?.message)
        navigate('/admin/settings/feedback/view-feedback-template')
     },
     onMutate: () => {
        toast.loading('Loading...')
     },
     onError: error => errorResponse(error)
  })

  useEffect( () => {
    refetch({ queryKey: ["get question bank", { perPage, currentPage, search }] })
  }, [ currentPage, perPage, isLoading] )

  useEffect(() => {
    document.querySelectorAll('*').forEach( element => {
       element.scroll({top: 0, left: 0, behavior: 'instant'})
    })
  }, [activeScreen]);

  const addQuestions = (question) => {
     if(selectedQuestions.length){
        const questionExist = selectedQuestions.findIndex( q => q?.id === question?.id ) !== -1
       if(questionExist) setSelctedQuestions( selectedQuestions?.filter( q => q?.id !== question?.id ) )
       else setSelctedQuestions([...selectedQuestions, question])
     }
     else setSelctedQuestions([question])
  }

  const createFeedback = () => {
     if(!templeteName) toast.error('Error! template name is required.')
     else if(!selectedQuestions.length) toast.error('Error! no question selected')
     else {
       const payload = {
         name: templeteName,
         questionIds: selectedQuestions?.map( question => question?.id )
       }
     createTemplateMutation.mutate(payload)
    }
  }

  const addSettings = () => {
    const payload = {
      data: {
        isForCourse: isCourse, ifForLearningPath: !isCourse, courseId: courseId?.value || null, 
        journeyId: journeyId?.value || null, afterAssessment, afterCourses, isMandatory,
      },
      id: templateId,
    }
    addSettingMutation.mutate(payload)
 } 

 const deleteQuestion = id => {
    setSelctedQuestions( selectedQuestions?.filter( q => q?.id !== id ) )
 }

  return (
    <div className={`p-3 px-5 bg-lightBlue ${activeScreen === 2 ? 'h-[100vh]' : ''}`}>
         <header className='flex justify-between mt-3'>
            <h5 className='text-lg'>
                Create Feedback Template   
            </h5>
            { activeScreen === 1 ?
             <Button 
                bgColor={'bg-customBlue'}
                text={'Create Feedback Template'}
                width={'w-[170px]'} 
                onClick={createFeedback}
            /> :
            <Button 
                bgColor={'bg-customBlue'}
                text={'Save Settings'}
                width={'w-[170px]'} 
                onClick={addSettings}
            />
            }
         </header>
         <header>
            <ScreenNavigator
                activeScreen={activeScreen}
                set1Validated={isCreated}
                setIsCreated={setIsCreated}
                setActiveScreen={setActiveScreen}
                title1={'Create Template'}
                title2={'Customize'}
            />
        </header>
        { activeScreen === 1 &&
        <div className='flex justify-between'>
            <section className='border w-[49%] bg-white mt-5 border-gray-200 p-3 rounded-lg shadow'>
                <div>
                    <label className='text-sm mb-1'>Template Name</label>
                    <input
                        type="text" className='input-md shadow mt-1'
                        placeholder='Feedback Template Title...'   
                        value={templeteName}
                        onChange={ e => setTemplateName(e.target.value) }  
                    />
                </div>
                <section className='mt-3'>
                    <h5 className='text-sm mb-3'>Questions ({selectedQuestions.length})</h5>
                    <div>
                        {
                         selectedQuestions.length ? 
                           selectedQuestions?.map( question => {
                              return (
                                <div className='flex justify-between p-3 border border-gray-200 mb-3 rounded shadow items-center'>
                                    <span className='text-sm font-light'>
                                        {question?.text}
                                    </span>
                                    <span 
                                      className='bi bi-trash text-red-500 hover:cursor-pointer text-md ml-2'
                                      onClick={ () => deleteQuestion(question?.id)}
                                     />
                                </div>
                              )
                           } )
                         : <EmptyData text={'No question selected.'} />
                        }
                    </div>
                </section>
            </section>
            <section className='border bg-white w-[49%] mt-5 border-gray-200 p-3 rounded-lg shadow'>
                <div className='flex items-center justify-between mb-3'>
                    <h3 className='text-sm mb-1'>Question Bank ({questionBank?.meta?.total})</h3>
                    <Button 
                        bgColor={'bg-customBlue'}
                        text={'Create New Questions'}
                        width={'w-[170px]'}
                        renderAs='link'
                        to='/admin/settings/feedback/create-feedback?redirect=create'
                    />
                </div>
                <Search 
                  placeholder={'Search question bank'} 
                  onChange={ event => {
                     setSearch(event.target.value)
                     refetch(["get question bank", { perPage, currentPage, search: event.target.value }])
                  }}
                 />
                <section className='mt-3'>
                    {
                     isLoading ? <Loader /> :
                     questionBank?.data?.length ? 
                      questionBank?.data?.map( question => {
                        return (
                            <div className='flex p-3 border border-gray-200 mb-3 rounded shadow items-center'>
                                <div>
                                    <input 
                                      type="checkbox" className='w-[20px] h-[20px] mr-2'
                                      onClick={ () => addQuestions(question) }
                                      defaultChecked={selectedQuestions.findIndex( q => q?.id === question?.id ) !== -1}
                                   />
                                </div>
                               <label className='text-sm font-light'>
                                  {question?.text}
                               </label>
                            </div>
                        )
                      })
                    : null}
                </section>
                <Pagination 
                    data={questionBank?.meta}
                    perPage={perPage}
                    pageNo={currentPage}
                    setPageNo={setCurrentPage}
                    setPerPage={setPerPage}
                />
            </section>
        </div>}
        {
         activeScreen === 2 && 
            <FeedbackSetting 
                isCourse={isCourse}
                setIsCourse={setIsCourse}
                afterCourses={afterCourses}
                setAfterCourses={setAfterCourses}
                afterAssessment={afterAssessment}
                setAfterAssessment={setAfterAssessment}
                isMandatory={isMandatory}
                setIsMandatory={setIsMandatory}
                courseId={courseId}
                setCourseId={setCourseId}
                journeyId={journeyId}
                setJourneyId={setJourneyId}
            /> 
        }
    </div>
  )
}

export default CreateFeedbackTemplate