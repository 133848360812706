import React, { useState } from 'react';
import Button from '../../../../../../../components/Buttons/Button';
import { useMutation } from 'react-query';
import { api } from '../../../../../../../api/courses';
import CenteredModal from '../../../../../../../components/Modal/CenteredModal';
import toast from 'react-hot-toast';
import ModuleMediaType from './ModuleMediaType';
import AddMedia from './AddMedia';
import AddResoursePanel from './AddResoursePanel';
import { v4 as uuid } from 'uuid';
import ReorderList from "react-reorder-list";
import ResourceLoader from './ResourceLoader';
import { useNavigate } from 'react-router-dom';
import EmptyData from './../../../../../../../components/EmptyData';
import Modules from './Modules';

const CourseContent = ({ 
          activeScreen , setFieldValue,
          setActiveScreen, values, 
          setModules, modules,
          setModuleMediaFiles,
          moduleMediaFiles,
          setModulePreview,
          modulePreview,
          courseId, refetch
 }) => {
 
const [enableAssessment, setEnableAssessment] = useState(false)
const [learnerReview, setLearnerReview] = useState(false)
const [pointDropdown, setPointDropdown] = useState(true)
const [timeDropdown, setTimeDropdown] = useState(false)
const [showAssessmentDropdown, setShowAssessmentDropdown] = useState(false)
const [showModal, setShowModal] = useState(false)
const [resoursePreview, setResourcePreview] = useState(null)
const [fileType, setFileType] = useState(null)
const [selectedModule, setSelectedModule] = useState(null)
const [selectedMediaModule, setSelectedMediaModule] = useState(null)
const [activeFile, setActiveFile] = useState(null)
const [showResourceModal, setShowResourceModal] = useState(false)
const [queue, setQueue] = useState([])
const [selectedModules, setSelectedModules] = useState([])
const [isUploading, setIsUploading] = useState(false)
const navigate = useNavigate()

const abortController = new AbortController()    

const assignModuleToCourseMutation = useMutation(api.assignModuleToCourse, {
   onMutate: () => {
      toast.loading('Loading...')
   },
   onSuccess: data => {
      toast.remove()
      toast.success('Course created successfully.')
      navigate('/admin/course/view')
      refetch()
   },
   onError: error => {
      toast.remove()
      if(error.response.status === 500) toast.error(error.response.statusText)
        else{
           if(error?.response?.data?.errors?.length > 1){
            error?.response?.data?.errors?.map( error => {
              toast?.error(error?.message)
            })
           }
           else toast.error(error?.response?.data?.errors[0]?.message)
        }
   }
})

const addResourcesToModuleMutation = useMutation(api.addResourceToModule, {
   onMutate: () => {
      toast.loading('Loading...')
   },
   onSuccess: (data) => {
      toast.remove()
      toast.success('Resource added successfully!')
      setModulePreview([...modulePreview, {
         module: selectedMediaModule,
         files: moduleMediaFiles,
      }])
      setShowResourceModal(false)
      setModuleMediaFiles([])
   },
   onError: error => {
      toast.remove()
      if(error.response.status === 500) toast.error(error.response.statusText)
        else{
           if(error?.response?.data?.errors?.length > 1){
            error?.response?.data?.errors?.map( error => {
              toast?.error(error?.message)
            })
           }
           else toast.error(error?.response?.data?.errors[0]?.message)
        }
   }
})

const uploadResourceMutation = useMutation(api.createResource, {
   onMutate: () => {
      setIsUploading(true)
   },
   onSuccess: (data) => {
      setIsUploading(false)
      toast.success('File Uploaded successfully!')
      const media = {
        id: uuid(),
        file: sessionStorage.getItem('active-file'),
        preview: data?.data?.data ,
        type: fileType,
      }
      setModuleMediaFiles([...moduleMediaFiles, media ])
      document.querySelector('#resouce-form').reset()
   },
   onError: error => {
      setIsUploading(false)
      if(error.response.status === 500) toast.error(error.response.statusText)
        else{
           if(error?.response?.data?.errors?.length > 1){
            error?.response?.data?.errors?.map( error => {
              toast?.error(error?.message)
            })
           }
           else toast.error(error?.response?.data?.errors[0]?.message)
        }
      document.querySelector('#resouce-form').reset()
   }
})

const addResource = e => {              
    const file = e.target.files[0];  
    if (file) {
       if( fileType === 'image' || fileType === 'audio' || 
           fileType === 'video' || fileType === 'pdf'
        ){
           if( 
                file.type.includes('image/') || file.type.includes('video/') || 
                file.type.includes('audio')  || file.type.includes('/pdf')
           ){   
              const formData = new FormData()
              formData.append('file', file)
              sessionStorage.setItem('active-file', file?.name)
              if(isUploading) toast.error('Please wait another file is processing...')
              else  uploadResourceMutation.mutate( formData )
           }
           else toast.error('Media file type is expected to be image, audio, pdf or video')
       }
    } 
    else setResourcePreview(null)                             
  }

 const processFile = (type) => {
    setFileType(type)
 }

 const addModule = () => {
     if(!values.module_point) toast.error('Please assign point to this module before you proceed.')
     else if(!values.module) toast.error('Please select a module')
     else{
         const moduleData = JSON.parse(values.module)
         const data = {
          newModules: [
              {
                  moduleId: moduleData?.id,
                  points: values.module_point,
                  sorting: 1,
             }
          ]
      }
        if(modules?.length){
           const moduleExist = modules.findIndex( module => {
              return module?.moduleData?.id === JSON.parse(values?.module).id
           })
           const new_module = {
                point: values.module_point,
                moduleData,
            }
           if(moduleExist === -1 ){
              setModules([...modules, new_module])
              toast.success('Module added successfully!')
           }
           //search for the key and update
           else {
            setModules([...modules?.map( module_ => {
              if(module_.moduleData?.id === JSON.parse(values?.module)?.id){
                 
                 return {
                    ...module_, point: values.module_point,
                }
              }
              else return {...module_}
           })])
           toast.success('Module updated successfully!')
          }
        }
        else {
              setModules([{
                point: values.module_point,
                moduleData,
             }])
             toast.success('Module added successfully!')
        }
        setSelectedModule(null)
        setFieldValue('module', '')
        setFieldValue('module_point', '')
       /* assignModuleToCourseMutation.mutate({
           id: courseId,
           data,
        }) */
    }
 }

 const addResourcesToModule = () => {
      if(selectedMediaModule === null){
         toast.error('Select a module to attach resources.')
      }
      else if(!moduleMediaFiles.length){
        toast.error('Error! add at least one resource to a module')
      }
      else{
         addResourcesToModuleMutation.mutate({
            payload: {
               resourcesData: moduleMediaFiles?.map( mediaFile => {
                  return {
                     filePath: mediaFile?.preview,
                     type: mediaFile?.type,
                  }
               }),
               itemId: selectedMediaModule?.value ,
               source: 'assigned-module'
            }, 
           // courseId:courseId , 
         })
      }
 }

 const assignModulesToCourse = () => {
    if(selectedModules?.length){
         assignModuleToCourseMutation.mutate({
            id: courseId,
            data: {
             newModules: selectedModules?.map( (module, index) => {
               return {
                  points: module?.point || 1,
                  moduleId: module?.id,
                  sorting: index + 1
               }
            } )},
         })
    }
    else toast.error('Assign Modules created to the course before you proceed')
 }

  return (
    <div className='mt-0 relative'>   
        {
            isUploading && 
             <ResourceLoader />
        }
         <header className='flex justify-between items-center w-[60%]'>
               <div>  
                    <h5 className='text-lg mt-5 font-semibold'>
                      Course Content  
                    </h5>
                    <p className='mt-1 text-sm mb-5'>
                       Let’s create your modules, assessments and resources
                    </p>
               </div>
         </header>
         <section className='flex justify-between pr-8'>
              <div className='w-[60%] h-[auto] bg-white pt-2 px-8'>
                    <div className='flex justify-between pb-3 pt-2'>
                        <p className='pb-2 text-sm'>
                        {selectedModules?.length ? 'My Selected Modules' : 'Select a module' }  
                        </p>
                        <button 
                                className='py-2 text-white rounded-lg text-xs bi bi-upload bg-customBlue px-8'
                                onClick={ () => setShowModal(true) }
                            >
                               &nbsp; Add Module
                         </button>
                            {showModal ? 
                            <CenteredModal
                                  open={showModal} 
                                   width={'w-[700px] overflow-y-auto h-[500px]'} 
                                   bgColor={'bg-white'}
                                  setOpen={setShowModal} 
                                  title={
                                     <h5 className='pb-2 flex items-center border-b border-gray-500 w-full'> 
                                       <i className='text-lg  pr-2 bi bi-plus-circle'></i> 
                                          Add Modules  
                                        <span className='ml-8 text-xs'>Selected ({selectedModules?.length}) </span>
                                     </h5>
                                  }
                                  withCloseBtn
                                  closeButtonLabel={'Ok'}
                                  onClose={ () => setShowModal(false) }
                            >
                               <Modules 
                                 selectedModules={selectedModules} 
                                 setSelectedModules={setSelectedModules}
                               /> 
                            </CenteredModal> : null}
                    </div>
                    <header className='flex items-center'>
                       {/* <span className='bi bi-arrows-move text-lg mr-3'></span>
                       <CustomDropdown
                             width={'w-[60%]'}
                             value={
                                selectedModule ? 
                                  {
                                    value: JSON.parse(selectedModule).id,
                                    label: JSON.parse(selectedModule).name
                                  }
                                : null
                             }
                             options={
                                modules_?.length ?  modules_?.map( module => {
                                     return {label: module.name, value: JSON.stringify(module)  }
                                })
                                : []
                             }
                             onChange={
                                value => {
                                    setSelectedModule(value.value)
                                    setFieldValue('module', value.value)
                                }
                             }
                        />*/}
                    </header>
                    {/*<section className='mt-3 mb-2'>
                            <button 
                                className='py-2 text-white rounded-lg text-xs bi bi-upload bg-customBlue px-8'
                                onClick={addModule}
                            >
                               &nbsp; Add Module
                            </button>
                            <DropDown 
                                showDD={pointDropdown}
                                ml={'ml-5'}
                                setShowDD={setPointDropdown}
                                title={
                                    <>
                                        <span className='bi bi-plus-circle'></span>
                                        &nbsp; Points
                                    </>
                                }
                                content = {
                                    <li>
                                        <Field
                                            className='border w-[100px] text-customBlue rounded-lg border-customBlue px-3 py-2 text-xs focus:outline-none'
                                            type='number'
                                            name='module_point'
                                         />
                                    </li>
                                }
                             />
                    </section>*/}
                    {/*<section className='mt-3 border-t border-gray-300'>
                        <div className='flex  mt-3'> 
                           {/* 
                                <span className='text-xs mr-5 py-2 px-4 bg-gray-100'>
                                  <span className='bi bi-coin'></span>
                                  &nbsp; Price: 
                                  &nbsp; { JSON.parse(selectedModule)?.price || '0'}
                             </span>
                              */}
                            {/* 
                                <span className='text-xs mr-5 py-2 px-4 bg-gray-100'>
                                  <span className='bi bi-clock'></span>
                                  &nbsp; Time: 
                                  &nbsp; { JSON.parse(selectedModule)?.duration || '00:00'}
                             </span>
                            }
                            { 
                                <span className='text-xs mr-5 py-2 px-4 bg-gray-100'>
                                  <span className='bi bi-plus-circle'></span>
                                  &nbsp; points: &nbsp; {values?.module_point || '0'}
                             </span>
                            }
                        </div>
                    </section> */}  
                    { 
                     selectedModules?.length ? <section className='mt-3 h-[250px] overflow-y-auto'>
                        <h6 className='text-left text-xs mb-2'>
                           Arrange your modules according on your requirements,
                           you can drag and drop module to reorder.
                        </h6>
                        <ReorderList  onPositionChange={ ({newItems})  => {
                           const updatedModules = newItems?.map( item => {
                              return item?.props?.modules?.find( module => {
                                 return module?.id === item?.props?.id
                              })
                           })
                           setSelectedModules(updatedModules)
                        }}
                        watchChildrenUpdates={true}
                        >
                           {
                             selectedModules?.length ?
                               selectedModules?.map( module => {
                                 return (
                                       <ModuleMediaType 
                                          points={module?.point}
                                          price={module?.price}
                                          time={module?.duration/60}
                                          title={module?.name}
                                          key={module?.id}
                                          id={module?.id}
                                          setModules={setSelectedModules}
                                          modules={selectedModules}
                                       />                                             
                                 )
                              } )
                           : null
                           }
                           </ReorderList>
                     </section> 
                     : 
                     <EmptyData text={'There is no any module to preview yet.'} />
                  }  
              </div>
              <div className='w-[35%] bg-white p-4'>
                <AddResoursePanel
                   addResource={addResource}
                   processFile={processFile}
                   setShowResourceModal={setShowResourceModal}
                   showResourceModal={showResourceModal}
                   openPanel={true}
                   fileType={fileType}
                   modules={moduleMediaFiles}
                   setModules={setModuleMediaFiles}
                   setFileType={setFileType}
                />
                <CenteredModal
                    open={showResourceModal} 
                    width={'w-[700px] overflow-y-auto h-[500px]'} 
                    bgColor={'bg-white'}
                    setOpen={setShowResourceModal} 
                    title={
                        <h5 className='pb-2 border-b border-gray-500 w-full'> 
                           Add Resouce Files To A Module
                        </h5>
                    }
                    onClose={ () => addResourcesToModule() }
                    withCloseBtn={moduleMediaFiles.length ? true : false}
                    closeButtonLabel={'Save Changes'}
                >  
                    <AddMedia  
                         modules={selectedModules}
                         processFile={processFile}
                         setModuleMediaFiles={setModuleMediaFiles}
                         moduleMediaFiles={moduleMediaFiles}
                         setShowResourceModal={setShowResourceModal}
                         resoursePreview={resoursePreview}
                         fileType={fileType}
                         setFileType={setFileType}
                         selectedModule={selectedMediaModule}
                         setSelectedModule={setSelectedMediaModule}
                    />         
                </CenteredModal>
              </div>
         </section>
         <div className='flex justify-end mt-4 w-[60%]'>
         </div>
         <div className='mt-8 flex w-[400px]'>
              {/*<Button
                  bgColor={'bg-transparent'}
                  textColor={'text-gray-900'}
                  text={'Back'}
                  width={'w-[150px]'}
                  icon={'bi bi-arrow-left'}
                  onClick={
                    () => setActiveScreen(1)
                 }
                /> */}&nbsp; &nbsp;
              <Button
                  bgColor={'bg-customBlue'}
                  text={'Save Changes'}
                  width={'w-[250px]'}
                  onClick={assignModulesToCourse}
              />
         </div>
    </div>
  )
}

export default CourseContent