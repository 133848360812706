import React, { useRef } from "react";
import { useMutation } from "react-query";
import { api } from "../../../../../../../../../api/clients";
import EmailEditor from "react-email-editor";
import Button from "../../../../../../../../../components/Buttons/Button";
// import { Input } from "../components";
import { useForm } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import toast from "react-hot-toast";

const CreateEmail = ({ handleOpenModal }) => {
  const { mutateAsync } = useMutation(api.createEmailTemplates);

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm();

  const user = JSON.parse(sessionStorage.getItem("user"));

  const emailEditorRef = useRef(null);

  const saveTemplate = async (values) => {
    const unlayer = emailEditorRef.current?.editor;

    // console.log(values);

    const { name, subject, fromAddress, fromName, eventSlug } = values;

    toast.loading("Processing...");

    const exportHtmlAsync = () => {
      return new Promise((resolve, reject) => {
        unlayer?.exportHtml((data) => {
          const { design, html } = data;
          //   console.log({ design });
          resolve(html);
        });
      });
    };

    try {
      const html = await exportHtmlAsync();

      const payload = {
        emailData: {
          name,
          subject,
          fromAddress,
          fromName,
          content: html,
          customHtml: html,
        },
        orgId: user?.org?.id,
        eventSlug,
      };
      //   console.log(payload);

      const data = await mutateAsync(payload);
      toast.remove();
      toast.success(data?.message || "Templated saved successfully...");
    } catch ({ response }) {
      console.log(response);
      toast.remove();
      toast.error(
        response?.data?.message ||
          "Unable to save template, please contact admin..."
      );
    }
  };

  const onReady = (unlayer) => {
    // editor is ready
    // you can load your template here;
    // the design json can be obtained by calling
    // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)
    // const templateJson = { DESIGN JSON GOES HERE };
    // unlayer.loadDesign(templateJson);
  };

  const eventSlugs = [
    { id: 1, title: "New Account", value: "newAccount" },
    { id: 2, title: "Forgot Password", value: "forgotPassword" },
    { id: 3, title: "Reset Password", value: "resetPassword" },
    { id: 4, title: "Course Completion", value: "courseCompletion" },
    {
      id: 5,
      title: "Learning Path Completion",
      value: "learningPathCompletion",
    },
    { id: 6, title: "Program Completion", value: "programCompletion" },
  ];

  const formFields = [
    {
      id: 1,
      label: "Name",
      slug: "name",
      type: "text",
      placeholder: "Enter name of template...",
    },
    {
      id: 2,
      label: "Subject",
      slug: "subject",
      type: "text",
      placeholder: "Enter subject of template...",
    },
    {
      id: 3,
      label: "Email Address",
      slug: "fromAddress",
      type: "email",
      placeholder: "Enter email address of sender...",
    },
    {
      id: 4,
      label: "Name of Sender",
      slug: "fromName",
      type: "text",
      placeholder: "Enter name of sender...",
    },
  ];

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit(saveTemplate)}>
        <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-5 mb-5 p-2">
          {formFields.map((field, index) => (
            <div key={index} className="w-full flex flex-col gap-2 items-start">
              <label className="text-xs">{field.label}</label>
              <input
                {...register(field.slug, {
                  required: true,
                })}
                type={field.type}
                className="border-t shadow outline-0 focus:px-3 focus:border focus:border-customBlue focus:ring-0 duration-200 border-gray-100 rounded-md py-2 px-1 w-full text-xs"
                placeholder={field.placeholder}
              />
            </div>
          ))}
          <div className="w-full flex flex-col gap-2 items-start">
            <label className="text-xs">Select Event</label>
            <select
              {...register("eventSlug", {
                required: true,
              })}
              className="border-t shadow outline-0 focus:px-3 focus:border focus:border-customBlue focus:ring-0 duration-200 border-gray-100 rounded-md py-2 px-1 w-full text-xs"
            >
              {eventSlugs.map((eventType) => (
                <option key={eventType.id} value={eventType.value}>
                  {eventType.title}
                </option>
              ))}
            </select>
          </div>
        </div>
        <EmailEditor ref={emailEditorRef} onReady={onReady} />
        <div className="w-full flex items-center my-3 justify-between">
          <Button
            text={"Cancel"}
            width={"w-[200px]"}
            renderAs="button"
            borderColor={"border-customBlue"}
            textColor={"text-customBlue"}
            bgColor={"bg-transparent"}
            onClick={handleOpenModal}
          />
          <button
            type="submit"
            id="submit"
            disabled={isSubmitting ? true : false}
            className={` ${
              isSubmitting ? "cursor-not-allowed opacity-70" : "cursor-pointer"
            } text-xs w-[200px] rounded-md flex items-center duration-200 justify-center bg-customBlue text-white py-3`}
          >
            {isSubmitting ? <ClipLoader size={15} color={"#fff"} /> : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateEmail;
