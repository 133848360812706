import React, {useState} from 'react'
import Counter from '../../../../../../learners/teams/pages/createTeam/components/Counter'
import { v4 } from 'uuid'

const CreateTeams = ({teams, setTeams, inputWidth}) => {

  const [value, setValue] = useState(1)

  const deleteTeam = id => {
    setTeams(teams?.filter( team => team?.id !== id ) )
    setValue(value -1)
  }

 const updateTeams = (event, team) => {
     setTeams( teams?.map( team_ => {
        if(team?.id === team_?.id){
             return { ...team_, name: event.target.value }
        }
        else return team_
     }))
  }

  const increment = () => {
    setValue(value + 1 )
    setTeams([...teams, { id: v4(), name: '' } ])
 }

 const decrement = () =>  {
    if(value > 1 ) {
        setValue(value - 1 )
        setTeams([...teams?.slice(0, teams?.length -1 )])
    }
 }

  return (
    <section className='flex justify-between'>
      <section>
            <h3 className='flex text-sm items-center'>
            Create Teams &nbsp;&nbsp;&nbsp;&nbsp;
            </h3>
            <div className='mt-5'>
                <Counter increment={increment} decrement={decrement} value={value}  />
            </div>
      </section>
      <section className='pl-5 pt-3'>
         <h5 className='mb-2 text-xs'>Teams</h5>
         {
            teams?.map( (team, index) => {
                return ( <div className='flex mb-3 items-center' key={team.id}>
                    <span className='text-xs mr-2'>{index + 1}.</span>
                    <input
                        type="text" style={{ width: inputWidth || 350}} className='input-md'
                        placeholder='Team Name'
                        value={team?.name}
                        onChange={ event => updateTeams(event, team) }
                     />
                    { index >= 1 && <button 
                       className='bi ml-2 bi-trash text-lg text-red-500'
                       onClick={ () => deleteTeam(team?.id) }
                    />}
                </div>)
            } )
         }
      </section>
    </section>
  )
}

export default CreateTeams