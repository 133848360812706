import React from 'react'
import CenteredModal from '../../../../../../../../components/Modal/CenteredModal'
import { formatCurrency } from '../Summary'
import { api } from '../../../../../../../../api/billing'
import { useMutation } from 'react-query'
import toast from 'react-hot-toast'

const PaymentConfirmationModal = ({
                    openModal, setOpenModal, activeTransaction, 
                    setActiveTransaction, refetch
            }) => {

const approvePaymentMutation = useMutation(api.approveTransaction, {
        onSuccess: (response) => {
            toast.remove();
            refetch()
            toast.success(response?.message);
            setOpenModal(false)
            setActiveTransaction(null)
        },
        onMutate: () => {
            toast.loading("Loading...");
        },
        onError: ({ response }) => {
            toast.remove();
            toast.error(response.data?.message);
        },
   });

 const APPROVED = 'approved'
  
 return (
    <>
      <CenteredModal 
        title={
           <h5 className='text-md font-semibold bi bi-plus-circle'>
              &nbsp; Payment Details
          </h5>
         }
        open={openModal}
        setOpen={setOpenModal}
        style={{padding: 0}}
        width={'w-[450px] overflow-y-auto'}
        headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
        withCloseBtn = { false }
        closeButtonLabel={'Approve Payment'}
        btnBgColor={'bg-primary'}
        onClose={ () => {
            if(activeTransaction?.status !==  APPROVED ){
               const payload = {
                  transactionId: activeTransaction?.transactionId,
                  approvedBy: activeTransaction?.user?.id // userId
              }
            approvePaymentMutation.mutate(payload)
          }
        }}
      >
         <section className='text-left pb-3 px-5'>
             <ul className='mt-3'>  
                <li className='pb-2'>
                    <strong className='text-sm font-medium'>
                        Client Name: 
                    </strong>
                    <span className='text-sm ml-3 text-gray-800'>
                      {activeTransaction?.user?.name}
                    </span>
                </li>
                <li className='pb-2'>
                    <strong className='text-sm font-medium'>
                        Transaction Id:
                    </strong>
                    <span className='text-xs ml-3 text-blue-800 underline'>
                     {activeTransaction?.transactionId}
                    </span>
                </li>
                <li className='pb-2'>
                    <strong className='text-sm font-medium'>
                        Payment Type:
                    </strong>
                    <span className='text-sm ml-3 text-gray-800'>
                      {activeTransaction?.type}
                    </span>
                </li>
                <li className='pb-2'>
                    <strong className='text-sm font-medium'>
                        Payment Date:
                    </strong>
                    <span className='text-sm ml-3 text-gray-800'>
                      {`${ new Date(activeTransaction?.createdAt).toLocaleDateString('en-ng')}`}
                    </span>
                </li>
                <li className='pb-2'>
                    <strong className='text-sm font-medium'>
                        Payment Status:
                    </strong>
                    <span className={`text-sm ml-3 ${ activeTransaction?.status !== APPROVED  ? 'text-primary' : 'text-green-500'}`}>
                      {activeTransaction?.status}
                    </span>
                </li>
                <hr />
                <li className='pb-2'>
                    <strong className='text-sm font-medium'>
                        Credit:
                    </strong>
                    <span className={`text-sm ml-3 text-gray-800`}>
                      {activeTransaction?.credit}
                    </span>
                </li>
                <li className='pb-2'>
                    <strong className='text-sm font-medium'>
                        Amount:
                    </strong>
                    <span className={`text-sm ml-3 text-gray-800`}>
                      {formatCurrency(activeTransaction?.amount)}
                    </span>
                </li>
             </ul>
         </section>
      </CenteredModal>
    </>
  )
}

export default PaymentConfirmationModal