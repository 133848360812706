import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { learners } from "../../../../../api/learners";
import toast from "react-hot-toast";
import { LearnersContext } from "../../../../../context/learnersContext";
import useLearners from "../../../../../hooks/useLearners";

const AssignLearner = () => {
  const { register, handleSubmit } = useForm();

  const user = JSON.parse(sessionStorage.getItem("user"));

  const currentLearner = JSON.parse(sessionStorage.getItem("currentLearner"));

  // console.log(currentLearner);

  const { mutateAsync } = useMutation(learners.InviteLearner);

  const { setOpenAssignModal } = useContext(LearnersContext);

  const { filters } = useLearners();

  //   console.log(filters);

  const onSubmit = async (values) => {
    toast.loading("Assigning learner...");
    // console.log(payload);
    const payload = {
      participants: [
        {
          name: currentLearner?.user?.name,
          email: currentLearner?.user?.email,
          employeeId: currentLearner?.user?.participant?.id,
        },
      ],
    };
    try {
      const data = await mutateAsync({
        payload,
        cohortId: values?.cohortId,
        orgId: user?.org?.id,
      });
      toast.remove();
      toast.success(data?.message);
      setTimeout(() => {
        setOpenAssignModal(false);
      }, 500);
    } catch (error) {
      toast.remove();
      toast.error(error?.data?.message);
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full mx-auto p-2 bg-white rounded-md"
      >
        <div className="mb-4">
          <p className="text-gray-700 text-sm font-medium text-left mb-2">
            Select Cohort
          </p>
          <select
            id="cohortId"
            type="cohortId"
            {...register("cohortId")}
            className="text-xs border rounded w-full p-3 focus:outline-none focus:shadow-outline"
          >
            {filters?.map((cohort) => (
              <option key={cohort?.cohortId} value={cohort?.cohortId}>
                {cohort?.cohortName}
              </option>
            ))}
          </select>
        </div>

        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-darkBlue text-white font-medium text-sm py-2 px-4 rounded w-full"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AssignLearner;
