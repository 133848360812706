import React from "react";
import { Formik, Form } from "formik";
import { FormBuilder, Flex, Submit, Toggler, Time } from "./Components";
import "./toggle.css";
import useModule from "../../../../hooks/useModules";
import UploadModule from "./UploadModule";

const EditModule = () => {

  const { 
         assessmentTypeProps, formProps, filePathProps,
         fileProps, timeProps,currentModule 
         } = useModule('edit');

  return (
    <div className="w-full">
      <Formik {...formProps} enableReinitialize={true}>
        {({ values, setFieldValue, setFieldError }) => (
           <Form>
           <Flex>  
              <Toggler
                  title={"Assessment?"}
                  value={values.isAssessment}
                  onChange={() =>
                    setFieldValue("isAssessment", !values.isAssessment)
                  }
                />
           </Flex>
          { values.isAssessment && <FormBuilder {...assessmentTypeProps} /> }
          <FormBuilder fieldTitle={"name"} fieldType={"text"} />
          <FormBuilder fieldTitle={"description"} fieldType={"textarea"} />
          <Flex>
            <Time {...timeProps} />
            <FormBuilder fieldTitle={"sorting"} fieldType={"number"} />
          </Flex>
          <Flex>
            <FormBuilder fieldTitle={"points"} fieldType={"number"} />
          </Flex>
          <Flex>
              <UploadModule 
                fileProps={fileProps} 
                setFieldError={setFieldError}
                setFieldValue={setFieldValue}
              />
             <FormBuilder readOnly {...filePathProps} />
             </Flex>
          <Flex>
            <Submit />
          </Flex>
        </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditModule;
