import React from 'react'
import CenteredModal from '../../../../../../../../components/Modal/CenteredModal'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { api } from '../../../../../../../../api/billing';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';

const CreateDiscount = ({openModal, setOpenModal, refetch, setActiveDiscount, activeDiscount}) => {

    const createDiscountMutation = useMutation(api.createDiscount, {
        onSuccess: (response) => {
            toast.remove();
            refetch()
            toast.success(response?.message);
            setOpenModal(false)
            setActiveDiscount(null)
        },
        onMutate: () => {
            toast.loading("Loading...");
        },
        onError: ({ response }) => {
            toast.remove();
            toast.error(response.data?.message);
        },
    });

    const editDiscountMutation = useMutation(api.updateDiscount, {
        onSuccess: (response) => {
            toast.remove();
            refetch()
            toast.success(response?.message);
            setOpenModal(false)
            setActiveDiscount(null)
        },
        onMutate: () => {
            toast.loading("Loading...");
        },
        onError: ({ response }) => {
            toast.remove();
            toast.error(response.data?.message);
        },
    });

 const initialValues = {
    name: activeDiscount !== null ? activeDiscount?.name : "",
    description: activeDiscount !== null ? activeDiscount?.description : "",
    discountCode: activeDiscount !== null ? activeDiscount?.discountCode: "",
    percentOff: activeDiscount !== null ? activeDiscount?.percentOff: "",
    numberOfCoursesRequired: activeDiscount !== null ? activeDiscount?.numberOfCoursesRequired : ""
  }

  const validate = (values) => {
    const errors = {}
    if(!values?.name){
      errors.name = 'Error! name is required.'
    }
    else if(!values?.description){
        errors.description = 'Error! description field is required.'
    }
    else if(!values?.discountCode){
        errors.discountCode = 'Error! discount code is required.'
    }
    else if(!values?.percentOff){
        errors.percentOff = 'Error! precentage off is required.'
    }
    else if(!values?.numberOfCoursesRequired){
        errors.numberOfCoursesRequired = 'Error! number of courses is required.'
    }

    return errors;
  };

  const createDiscount = (values, actions) => {
    // Handle submission
    if(activeDiscount !== null) editDiscountMutation.mutate({
        id: activeDiscount?.id,
        data: values
    })
    else createDiscountMutation.mutate(values)
    actions.setSubmitting(false);
  }

  return (
    <>
      <CenteredModal 
        title={
           <h5 className='text-md font-semibold bi bi-plus-circle'>
              &nbsp; {activeDiscount !== null ? 'Edit Discount' : 'Create Discount'}
          </h5>
         }
        open={openModal}
        setOpen={setOpenModal}
        style={{padding: 0}}
        width={'w-[450px] h-[500px] overflow-y-auto'}
        headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
        withCloseBtn
        closeButtonLabel={ activeDiscount !== null ? 'Edit Discount' : 'Create Discount'}
        btnBgColor={'bg-primary'}
        onClose={ () => {
           document.querySelector('#create-discount-submit-btn').click()
        }}
      >
         <section className='text-left pb-3 px-5'>
            <Formik
                    initialValues={initialValues}
                    validate={validate}
                    enableReinitialize={true}
                    onSubmit={createDiscount}
                >
                { () => (
                    <Form>
                         <h2 className='text-sm mb-2 pt-2'>
                           Course Quantity  Discounts
                         </h2>
                         <h3 className='text-sm text-primary pt-2'>
                            Please fill the required fields marked with *
                         </h3>
                         <div className='mt-2'>
                            <label htmlFor="course-enrolled" className='text-xs'>
                                Name <i className='text-red-500 text-xs'>*</i>
                            </label>
                            <Field name='name' className='input-md'  />
                             <p className='text-xs text-red-500 mt-2'>
                               <ErrorMessage name='name' />
                             </p>
                          </div>
                          <div className='mt-2'>
                            <label htmlFor="course-enrolled" className='text-xs'>
                                Description <i className='text-red-500 text-xs'>*</i>
                            </label>
                            <Field as='textarea' name='description' className='input-md h-[70px]'  />
                             <p className='text-xs text-red-500 mt-2'>
                               <ErrorMessage name='description' />
                             </p>
                          </div>
                          <div className='mt-2'>
                            <label htmlFor="course-enrolled" className='text-xs'>
                             Discount Code <i className='text-red-500 text-xs'>*</i>
                            </label>
                            <Field name='discountCode' className='input-md'  />
                             <p className='text-xs text-red-500 mt-2'>
                               <ErrorMessage name='discountCode' />
                             </p>
                          </div>
                          <div className='mt-2'>
                            <label htmlFor="course-enrolled" className='text-xs'>
                             Percent Off <i className='text-red-500 text-xs'>*</i>
                            </label>
                            <Field type='number' name='percentOff' className='input-md'  />
                             <p className='text-xs text-red-500 mt-2'>
                               <ErrorMessage name='percentOff' />
                             </p>
                          </div>
                          <div className='mt-2'>
                            <label htmlFor="course-enrolled" className='text-xs'>
                             Number Of Courses Required <i className='text-red-500 text-xs'>*</i>
                            </label>
                            <Field type='number' name='numberOfCoursesRequired' className='input-md'  />
                             <p className='text-xs text-red-500 mt-2'>
                               <ErrorMessage name='numberOfCoursesRequired' />
                             </p>
                          </div>
                        <button type="submit" id='create-discount-submit-btn'></button>
                    </Form>
                )}
           </Formik>
         </section>
      </CenteredModal>
    </>
  )
}

export default CreateDiscount