import createApiClient from "./api";

const client = createApiClient();

export const api = {
  createActionPlan: ({ orgId, payload }) =>
    client
      .post(`admin/org/${orgId}/action-plans`, payload)
      .then(({ data }) => data),

  updateActionPlan: ({ orgId, actionPlanId, payload }) =>
    client
      .patch(`admin/org/${orgId}/action-plans/${actionPlanId}`, payload)
      .then(({ data }) => data),
  deleteActionPlan: ({ orgId, actionPlanId }) =>
    client
      .delete(`admin/org/${orgId}/action-plans/${actionPlanId}`)
      .then(({ data }) => data),

  getAllActionPlans: ({ orgId, search, perPage, currentPage }) =>
    client
      .get(
        `admin/org/${orgId}/action-plans?search=${search}&perPage=${perPage}&page=${currentPage}`
      )
      .then(({ data }) => data),

  getAllActionPlansLearnORGoals: ({ orgId, search, perPage, currentPage }) =>
    client
      .get(
        `admin/org/${orgId}/action-plans/learners?search=${search}&perPage=${perPage}&page=${currentPage}`
      )
      .then(({ data }) => data),

  getAllActionPlansTopCourses: ({ orgId }) =>
    client
      .get(`admin/org/${orgId}/action-plans/courses`)
      .then(({ data }) => data),

  getGoalProgress: ({ orgId }) =>
    client
      .get(`admin/org/${orgId}/action-plans/goal-progress`)
      .then(({ data }) => data),

  getCourses: () => client.get(`admin/courses`).then(({ data }) => data),

  getEmails: (orgId) =>
    client.get(`emails?orgId=${orgId}`).then(({ data }) => data),

  getActionPlanSummary: ({ orgId }) =>
    client
      .get(`admin/org/${orgId}/action-plans/summary`)
      .then(({ data }) => data),
};
