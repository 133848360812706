import React from 'react'
import Tab from '../report-and-analysis/pages/overview/components/horizontal-tab'
import ActiveMeetings from './pages/ActiveMeetings';
import ExpiredMeetings from './pages/ExpiredMeetings';
import ZoomApi from './pages/ZoomApi';
import ZoomSettings from './pages/ZoomSettings';
import CreateMeeting from './pages/CreateMeeting';

const Zoom = () => {
    const tabsData = [
        {
          label: `Active Meetings`,
          content: <ActiveMeetings />,
        },
        {
          label: `Expired Meetings`,
          content: <ExpiredMeetings />,
        },
        {
          label: `API`,
          content: <ZoomApi />,
        },
        {
          label: `Zoom Settings`,
          content: <ZoomSettings />,
        },
        {
          label: `Zoom Settings`,
          content: <CreateMeeting />,
        },
      ];
  return (
    <div>
        <h1>Zoom Meeting</h1>
        <Tab tabsData={tabsData} />
    </div>
  )
}

export default Zoom