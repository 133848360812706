import React, {useState} from 'react'
import { Field, ErrorMessage } from 'formik'
import CompanyRoleButton from '../../../../../../Auth/components/CompanyRoleButton'
import Button from '../../../../../../../components/Buttons/Button'
import { api } from '../../../../../../../api/courses'
import { useMutation, useQuery } from 'react-query'
import toast from 'react-hot-toast'
import ResourceLoader from '../../CreateCourse/components/ResourceLoader'
import { useNavigate } from 'react-router-dom'

const CourseInfo = ({
        values, setFieldValue, setLogoPreview, logoPreview, 
        step1Validated, setActiveScreen, courseId, refetch,
        setCompletedStep,
}) => {

 const [isUploading, setIsUploading] = useState(false)
 const activeCourse = JSON.parse(sessionStorage.getItem('active-course'))
 const navigate = useNavigate()

 const uploadResourceMutation = useMutation(api.createResource, {
    onMutate: () => {
       setIsUploading(true)
    },
    onSuccess: (data) => {
       setIsUploading(false)
       toast.success('File Uploaded successfully!')
       setLogoPreview(data?.data?.data)
    }
 })

 const { data: categories, isLoading } = useQuery(
    ["get categories"],
    () => {
        return api.getCategories();
    },
    {
        cacheTime: 10000,
        staleTime: 10000,
        refetchOnWindowFocus: true,
        select: (data) => data?.data,
    }
   );

 const addCourseMutation = useMutation(api.updateCourse, {
    onSuccess: data => {
        toast.remove()
        toast.success('Course Information Updated successfully!')
        refetch()
       // navigate(-1)
    },
    onError: error => {
        toast.remove()
        //toast.success('Course Create successfully!')
        //setActiveScreen(2)
        if(error.response.status === 500) toast.error(error.response.statusText)
        else{
           if(error?.response?.data?.errors?.length > 1){
            error?.response?.data?.errors?.map( error => {
              toast?.error(error?.message)
            })
           }
           else toast.error(error?.response?.data?.errors[0]?.message)
        }
    },
    onMutate: () => {
        toast.loading('Loading...')
    }
 })

 const addCourseInfo = () => {
      document.querySelector('#create-course-btn').click()
      const payload = {
        name: values.c_title,
        description: values.c_des,
        categoryId: values.c_category,
        points: values.c_point,
        price: values.c_price,
        status: values?.status ? 'published' : 'draft',
        duration: 500,
        imagePath: logoPreview,
        isBranded: activeCourse?.isBranded,
        repeatModule: activeCourse?.repeatModule,
        moduleOrder: "set",
        "image": "aiui",
        "isBranded": false,
      }
      addCourseMutation.mutate({
        id: courseId, data: payload
      }) 
 };

 const uploadClientLogo = e => {              
    const file = e.target.files[0];                            
    if (file) {
      /*const reader = new FileReader();
      reader.onload = () => {
         setLogoPreview(reader.result)
      };
      reader.readAsDataURL(file);*/
        const formData = new FormData()
        formData.append('file', file)
        uploadResourceMutation.mutate(formData)
    } 
    else setLogoPreview(null)                               
  }

  const cancelImagePreview = () => {
    setLogoPreview('');
    // Reset the file input
  };

  return (
    <div>  { isUploading && <ResourceLoader />}
        <h5 className='text-md mt-5 font-semibold'>
           Course Information 
        </h5>
        <p className='mt-1 text-sm mb-5'>
           Tell us more about your course.
        </p>
         <div className='flex'>
         <section className='w-[500px]'>
            <div>
                <label className='block font-semibold w-full text-xs mb-1'>
                    Course Title
                </label>
                <Field 
                    name='c_title' 
                    className='primary-form-input w-full'
                />
                <ErrorMessage
                    name='c_title' 
                    className='text-xs mt-2 ml-2 text-red-500 font-semibold w-full'
                    component='p'
                />
           </div>
           <div className='mt-5'>
                <label className='block font-semibold w-full text-xs mb-1'>
                    Course Description
                </label>
                <Field 
                    name='c_des' 
                    as='textarea'
                    className='primary-form-input w-full'
                />
                <ErrorMessage
                    name='c_des' 
                    className='text-xs mt-2 ml-2 text-red-500 font-semibold w-full'
                    component='p'
                />
           </div>
           <div className='mt-5 flex justify-between'>
                <div className='w-[60%]'>
                        <label className='block font-semibold w-full text-xs mb-1'>
                            Course Category
                        </label>
                        <Field 
                            name='c_category' 
                            as='select'
                            className='primary-form-input w-full'
                        >
                             {
                                isLoading ? 
                                  <option value=""> Loading.. </option>
                                :
                                 categories?.length ?
                                  <>
                                     <option value=""> Select Category </option>
                                     {
                                        categories?.map(category => {
                                            return(
                                                <option key={category?.id} value={category?.id}>
                                                     {category?.name}
                                               </option>
                                            )
                                        })
                                     }
                                  </>
                                 :
                                 <option value=""> No any category found </option>
                             }
                        </Field>
                        <ErrorMessage
                            name='c_category' 
                            className='text-xs mt-2 ml-2 text-red-500 font-semibold w-full'
                            component='p'
                         />
                </div>
                <div className='w-[35%]'>
                    <label className='block font-semibold w-full text-xs mb-1'>
                        Course Point
                    </label>
                    <Field 
                        name='c_point' 
                        type='number'
                        className='primary-form-input w-full'
                    />
                    <ErrorMessage
                            name='c_point' 
                            className='text-xs mt-2 ml-2 text-red-500 font-semibold w-full'
                            component='p'
                    />
            </div>
           </div>
           <div className='mt-5 flex justify-between'>
                <div className='w-[60%]'>
                        <label className='block font-semibold w-full text-xs mb-1'>
                            Course Price (₦)
                        </label>
                        <Field 
                            name='c_price' 
                            type='number'
                            className='primary-form-input w-full'
                        />
                        <ErrorMessage
                            name='c_price' 
                            className='text-xs mt-2 ml-2 text-red-500 font-semibold w-full'
                            component='p'
                         />
                </div>
                <div className='w-[35%] flex items-center pt-4'>
                     <CompanyRoleButton 
                        text={'Publish'}
                        height={'h-[30px]'}
                        active={values?.status}
                        onClick={ () => setFieldValue('status', true) }
                     />
                     &nbsp; &nbsp;
                     <CompanyRoleButton 
                        text={'Draft'}
                        height={'h-[30px]'}
                        active={!values.status}
                        onClick={ () => setFieldValue('status', false) }
                     />
               </div>
           </div>
        </section>
          <div className='ml-16 mt-5 bg-white w-[200px] h-[180px] border flex items-center justify-center border-gray-200 rounded-lg'>
                  { logoPreview ?
                      <div className='h-full w-full'>
                         <img src={logoPreview} alt="profile" className='w-full h-[150px]' />
                         <button onClick={cancelImagePreview} className='text-red-500 text-sm block mt-1 w-[auto] mx-auto'>
                            Cancel
                         </button>
                      </div>
                   :
                   <p className='text-sm text-center'>
                    <input
                            type='file'
                            id='course-img'
                            className='hidden' 
                            name='image' 
                            onChange={ event => {
                                uploadClientLogo(event)
                                setFieldValue('image', event.target.files[0])
                            }}
                    />
                    <label htmlFor='course-img' className='text-primary mr-1'>Upload</label> an image
                        for your course
                    </p>
                  }
           </div>
         </div>
        <div className='w-[300px] mt-4'>
            <Button 
               text={'Edit Course Information'} 
               bgColor={'bg-customBlue'}
               onClick={addCourseInfo}
               style={{opacity: step1Validated ? 1 : 0.6}}
            />
        </div>
    </div>
  )
}

export default CourseInfo