import React, {useState, useEffect} from 'react'
import Button from '../../../../../../../components/Buttons/Button'
import OverviewCard from '../../../../report-and-analysis/components/OverviewCard'
import BadgeIcon from '../../../../../../../assets/Badge.png'
import BadgeIconOrange from '../../../../../../../assets/Bagde (5).png'
import BadgeIconGreen from '../../../../../../../assets/Badge (1).png'
import OverDueAccount from './componets/OverDueAccounts'
import Search from '../../../../../../../components/Inputs/Search'
import TopUpTrends from './componets/TopUpTrends'
import PaymentHistory from './componets/PaymentHistory'
import { useMutation, useQuery } from 'react-query'
import { api } from '../../../../../../../api/billing'
import Pagination from '../../../../../../../components/pagination/Pagination'
import Loader from '../../../../../../../components/loader/Loader'
import GenerateReport from './componets/GenerateReport'

export const formatCurrency = (amount, withSign=true) => {
  if(!amount) return withSign ? `$0.00` : '0'
  // Convert the amount to an integer if it is a decimal number
  const convertedAmount = Math.floor(amount);
  // Convert the amount to a string and separate every three digits with comma
  const formattedAmount = convertedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // Append '₦' symbol to the formatted amount
  return withSign ? `$${formattedAmount}.00` : formattedAmount;
}

const Summary = () => {

  const [perPage, setPerPage] = useState(6);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false)
  const [aciveFilter, setActiveFilter] = useState('daily')
  const [revenueChart, setRevenueChart] = useState(null)

  const filters = [
    { type: 'daily', active: false },
    { type: 'weekly', active: true },
    { type: 'monthly', active: false },
  ]

  const { data: revenueSummary, isLoading } = useQuery(
    ["revenue summary"],
    ({ queryKey }) => {
      return api.getRevenueSummary();
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      select: (data) => data?.data,
    }
  );

  const revenueChartMutation = useMutation(api.getRevenueChart, {
     onSuccess: data => {
        setRevenueChart(data)
     }
  })

  const { data: billingTransactions, isLoading: billingTransactionsLoading, refetch } = useQuery(
    ["site admin billing payments", { id: null, currentPage, search, perPage }],
    ({ queryKey }) => {
      return api.getBillingTransactions({...queryKey[1]});
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      select: (data) => data?.data,
    }
  );

  useEffect( () => {
    revenueChartMutation.mutate(aciveFilter)
  }, [])

  useEffect( () => {
    refetch()
  }, [search, currentPage, perPage] )


  return (
    <div className='bg-lightBlue p-4'>  
        <header className='text-lg font-semibold flex justify-between items-center'>
            Billing Summary
            <div className='flex items-center'>
                <GenerateReport />
            </div>
        </header>
        <header className='grid pt-3 grid-cols-1 md:grid-cols-4 gap-5'>
           <OverviewCard 
             title={'Total Revenue (USD)'}
             total={isLoading ? '----' :  formatCurrency(revenueSummary?.totalRevenue?.paidAmount)}
             iconPath={BadgeIconGreen}
             py={'py-8'}
             headerTextSize='text-2xl'
           />
           <OverviewCard
             title={'Revenue from Prepaid Billing'}  
             total={isLoading ? '----' :  formatCurrency(revenueSummary?.prePaidRevenue?.paidAmount)}
             iconPath={BadgeIconOrange}
             headerTextSize='text-2xl'
             py='py-8'
           />
           <OverviewCard
             title={'Revenue from Postpaid Billing'}
             total={isLoading ? '----' :  formatCurrency(revenueSummary?.postPaidRevenue?.paidAmount)}
             iconPath={BadgeIcon}
             headerTextSize='text-2xl'
             py='py-8'
           />
           <OverviewCard
             title={'Payment Pending'}  
             total={isLoading ? '----' :  formatCurrency(revenueSummary?.pendingPayment?.paidAmount)}
             iconPath={BadgeIconOrange}
             headerTextSize='text-2xl'
             py='py-8'
           />
        </header>
        <section className='mt-5'>
             <div className='p-3 bg-white rounded-lg'>
               <section className='pb-2 flex justify-between border-b font-medium border-gray-200 text-md'>
                   Revenue
                   <div className='flex items-center'>
                     { filters?.map( filter => {
                        return(
                        <button 
                          key={filter.type}
                          className={`py-1 capitalize ${
                           filter.type ===  aciveFilter ? ' bg-green-100 text-green-500' : ' text-gray-800 bg-gray-300'
                          } text-xs px-5 mr-3 rounded-lg`}
                          onClick={ () => {
                            setActiveFilter(filter.type)
                            revenueChartMutation.mutate(filter.type)
                          } }
                           >
                               {filter.type}
                           </button>)
                     } )}
                   </div>
                </section>
                <div className='flex items-center mt-3'>
                    <div className='flex items-center'>
                        <span className='w-[20px] rounded-full h-[20px] bg-[#FF9100]'></span>
                        <span className='ml-2 text-xs'>Pre-paid</span>
                        <span className='ml-2 text-sm font-medium'>
                           {
                              revenueChart?.prePaid?.length ?
                               formatCurrency(
                                revenueChart.prePaid?.reduce((accumulator, amount) => accumulator + amount?.amount, 0),
                                false
                               )
                              : '0'
                            }
                        </span>
                    </div>
                    <div className='flex items-center ml-5'>
                        <span className='w-[20px] rounded-full h-[20px] bg-customBlue'></span>
                        <span className='ml-2 text-xs'>Invoice</span>
                        <span className='ml-2 text-sm font-medium'>
                           {
                              revenueChart?.invoice?.length ?
                               formatCurrency(
                                revenueChart.invoice?.reduce((accumulator, invoice) => accumulator + invoice?.amount, 0),
                                false
                               )
                              : '0'
                            }
                        </span>
                    </div>
                </div>
                <div className='mt-3'>
                   {
                     revenueChartMutation.isLoading ? <Loader /> :
                     <TopUpTrends revenueChart={revenueChart} />
                   }
                </div>
            </div>
            <div className='p-3 mt-5 bg-white rounded-lg'>
                <OverDueAccount />
            </div>
            <div className='p-3 mt-5 bg-white rounded-lg'>

               <h5 className='pb-3 flex justify-between items-center border-b font-medium border-gray-200 text-md'>
                   Payment History
                  <Search 
                      className={'w-[300px]'}   placeholder={'e.g access bank'} 
                      onChange={ event => setSearch(event.target.value) }
                  />
                </h5>
                <PaymentHistory 
                  isLoading={billingTransactionsLoading}
                  transactions={billingTransactions}
                  refetch={refetch}
                  perPage={perPage}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                />
                <section className={`mt-1 px-3 rouded-lg bg-white`}>
                  { billingTransactions?.data?.length ?
                   <Pagination 
                      data={billingTransactions?.meta}
                      perPage={perPage}
                      pageNo={currentPage}
                      setPageNo={setCurrentPage}
                      setPerPage={setPerPage}
                  /> : null }
                </section>
            </div>
        </section>
    </div>
  )
}

export default Summary