import React, { useState, useEffect } from 'react'
import Search from '../../../../../../components/Inputs/Search'
import Button from '../../../../../../components/Buttons/Button'
import Table from '../../../../../../components/table/Table'
import userAvatar from '../../../../../../assets/user.PNG'
import { api } from '../../../../../../api/clients'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { Tooltip, initTE } from "tw-elements"
import Pagination from '../../../../../../components/pagination/Pagination'
import BlockChainImg from '../../../../../../assets/Blockchain.png'
import Loader from '../../../../../../components/loader/Loader'
import CreateClientModal from '../components/CreateClientModal'
import DeleteClientModal from '../components/DeleteClientModal'
import EditClientModal from '../components/EditClient'

const ViewClients = () => {

  const [perPage, setPerPage] = useState(8);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [activeClient, setActiveClient] = useState(null)

  const { data: clients, isLoading, refetch } = useQuery(
    ["courses", { search, perPage, currentPage }],
    ({ queryKey }) => {
      return api.getClients(queryKey[1]);
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: false,
      refetchOnMount:false,
      select: (data) => data,
    }
  );

  useEffect( () => {
    initTE({ Tooltip });
  }, [] )

  useEffect( () => {
    refetch()
  }, [search, currentPage, perPage] )

    let columns = React.useMemo(
        () => [
          {
            title: "ID",
            selector: null,
            key: "id",
            render: ({ index }) =>
              index +
              1 +
              ((clients?.meta?.page || 1) - 1) * (clients?.meta?.perPage || perPage ),
          },
          {
            title: "Client Name",
            selector: "name",
            key: "name",
            render: ({ item, row }) => {                      
                 return( 
                  <Link 
                      className='flex items-center view-client-link hover:cursor-pointer'
                      to={`/admin/clients/view-client/${row?.id}/`}
                   >
                     <img src={userAvatar} className='w-[40px] h-[38px]' alt="user" />
                     <div className='pl-1'>
                         <span className='text-[12px]'>
                           {row?.rootAccountUser?.name}
                         </span>
                         <span className='block  text-[10px]'> {item} </span>
                     </div>
                 </Link>)
             },
          },
          {
            title: "Email",
            selector: "email",
            key: "email",
            render: ({ row}) => {           
              return <span className="">{row?.rootAccountUser?.email || '--------------------'}</span>;
            },
          },
          {
            title: "Payment Type",
            selector: "paymentType",
            key: "paymentType",
            render: ({ item }) => {           
              return <span className="">{item || '-------'}</span>;
            },
          },
          {
            title: "Address",
            selector: "address",
            key: "address",
            render: ({ item }) => {           
              return <span 
                        className="whitespace-nowrap w-[100px] block overflow-hidden" 
                        style={{textOverflow: 'ellipsis'}}
                        data-tw-toggle="tooltip"
                        title={item}
                      >
                      {item}
                    </span>;
            },
          },
          {
            title: "Domain",
            selector: "domain",
            key: "domain",
            render: ({ item }) => {           
              return <span className="">{item}</span>;
            },
          },
          {
            title: "Credit",
            selector: "credit",
            key: "credit",
            render: ({ item }) => { 
               if(!item) return <span> -------- </span>                     
               if(item < 1000){
                  return <span className="bg-red-100 rounded text-red-500 py-1 px-2 text-xs">
                             {item}
                          </span>;
               }
               else if(item < 10000 && item > 1000){
                 return <span className="bg-blue-100 rounded text-blue-500 py-1 px-2 text-xs">
                        {item}
                    </span>;
               }
               else return <span className="text-green-500 bg-green-100 rounded py-1 px-2 text-xs">
                      {item}
                 </span>;
            },
          },
          {
            title: "Learners",
            selector: "learners",
            key: "learners",      
            render: ({ item}) => {           
              return <span className="">{item || '---------'}</span>;
            },  
          },
          {
            title: "Phone Number",
            selector: "phoneNumber",
            key: "phoneNumber",
            render: ({ item }) => {           
              return <span className="">{item}</span>;
            },
          },
          {
            title: "Date Added",
            selector: "createdAt",
            key: "createdAt",
            render: ({ item }) => {           
              return <span className="">{`${new Date(item).toLocaleDateString('en-ng')}`}</span>;
            },
          },
          {
            title: "Date Updated",
            selector: "updatedAt",
            key: "updatedAt",
            render: ({ item }) => {           
              return <span className="">{`${new Date(item).toLocaleDateString('en-ng')}`}</span>;
            },
          },
          {
            title: "Action",
            selector: null,
            key: null,
            render: ({row}) => {           
                return  <div className='flex'>
                    <span 
                       className="bi bi-pencil-square hover:cursor-pointer text-blue-500" 
                       onClick={ () => {
                         setActiveClient(row)
                         setOpenEditModal(true)
                       }}
                     />
                    <span 
                       className="bi bi-trash ml-2 text-red-500 hover:cursor-pointer"
                        onClick={ () => setOpenDeleteModal(true) }
                    />
                </div>
            },
        },
        ],
        [1, 20, 20]
      );
    
  const tableProps = { isLoading: false, columns, data: clients?.data }

  return (
    <section className={`p-2 bg-lightBlue ${clients?.data?.length < 5 ? 'h-[100vh]' : ''}`}> 
        <h3 className='text-md mt-2'>Clients Overview</h3>
        <section className='mt-4 flex justify-between'>
            <div className=' w-[350px]'>
                <Search 
                  placeholder={'Search clients... e.g ajoke'}
                  onChange={ (e) => {
                    setSearch(e.target.value)
                  }}
                />
            </div>
            <div>
                <Button 
                    text={'Add New Client'} 
                    width={'w-[200px]'} 
                    renderAs='button'
                    bgColor={'bg-customBlue'}
                    onClick={ () => setOpenModal(true) }
                />
            </div>
        </section>
        <CreateClientModal 
            openModal={openModal} 
            setOpenModal={setOpenModal} 
            refetch={refetch}
        />
        <EditClientModal 
            openModal={openEditModal} 
            setOpenModal={setOpenEditModal}
            activeClient={activeClient}
            refetch={refetch}
        />
        <DeleteClientModal openModal={openDeleteModal} setOpenModal={setOpenDeleteModal} />
        <section className='bg-white border border-gray-200 rounded-lg p-3'>
              <h3 className='text-sm mb-3'>Total Clients ({clients?.meta?.total})</h3>
               <section className='overflow-x-auto w-full clients-table'>
                  {  !isLoading ? 
                      !clients?.data?.length ? 
                          <div className='w-full mt-12'>
                            <img src={BlockChainImg} alt="no result" className='w-[260px] block mx-auto h-[190px]' />
                            <p className='text-center font-semibold text-xs mt-3 ml-3'> 
                              No any client found.
                            </p>
                        </div>
                      : 
                       <Table 
                        {...tableProps} 
                        style={{
                        overflowX: 'auto',
                        width: (window.innerWidth > 500) && window.innerWidth < 1200 ? '130%' : '140%' 
                        }}
                        headerClassName={'border-t border-gray-200'}
                  /> : <Loader /> 
                }
               </section>
        </section>
        <section className={`mt-1 px-3 rouded-lg bg-white`}>
            <Pagination
              data={clients?.meta}
              perPage={perPage}
              pageNo={currentPage}
              setPageNo={setCurrentPage}
              setPerPage={setPerPage}
            />
      </section>
    </section>
  );
};

export default ViewClients;
