import React, { useState } from "react";
import Header from "../../components/Layout/Header/Header";
import FormHeader from "./components/FormHeader";
import Ellipse3 from "../../assets/Ellipse 3.png";
import Ellipse1 from "../../assets/Ellipse 1 (1).png";
import Input from "./components/Input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { loginValidation } from "./utils/validation";
import { useMutation } from "react-query";
import { api } from "../../api";
import toast from "react-hot-toast";

const ResetPassword = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const initialValues = {
    email: sessionStorage.getItem("reg-email") || "",
    otp: "",
    password: "",
    cpassword: "",
  };

  const location = useLocation();
  const isAdminRoute = location.pathname.includes("admin");
  const navigate = useNavigate();

  const validate = (values) => loginValidation(values);

  const resetPasswordMutation = useMutation(api.resetPassword, {
    onSuccess: (response) => {
      toast.remove();
      toast.success("A password reset link has been sent to your email. Please check your email for further instructions"); 
        navigate("/auth/login");
    },
    onMutate: () => {
      toast.loading("Loading...");
    },
    onError: ({ response }) => {
      toast.remove();
      toast.error(response.data?.message);
    },
  });

  const resetPassword = (values, actions) => {
    // Handle submission

    resetPasswordMutation.mutate({
      email: values?.email,
      otp: values?.otp,
      password: values?.password,
      passwordConfirmation: values?.cpassword,
    });
    actions.setSubmitting(false);
  };
  return (
    <div>
      <Header isAdminRoute={isAdminRoute} />
      <div
        className={`mt-12 relative w-[90%] mx-auto shadow-lg rounded-lg md:w-[450px] ${
          isAdminRoute ? "bg-white" : "bg-lightOrange"
        } py-5 px-8`}
      >
        <img
          src={Ellipse3}
          alt="ellipse"
          className="w-[70px] h-[40px] top-0 right-0 absolute"
        />
        <FormHeader
          text="Reset your Password"
          className={`${
            isAdminRoute ? "text-customBlue" : "text-primary"
          } font-bold text-2xl mt-8`}
        />
        <p className="">
          Please type in your email to get a link to reset your password
        </p>
        <Formik
          initialValues={initialValues}
          validate={validate}
          enableReinitialize={true}
          onSubmit={resetPassword}
        >
          {({ values, setFieldError, setFieldValue }) => (
            <Form>
              <Input
                label={"Email"}
                name={"email"}
                type="email"
                colorScheme={isAdminRoute ? "blue" : "orange"}
              />
              <Input
                label={"Otp"}
                name={"otp"}
                type="text"
                colorScheme={isAdminRoute ? "blue" : "orange"}
              />
              <Input
                label={"Password"}
                name={"password"}
                type="password"
                visible={passwordVisible}
                setVisible={setPasswordVisible}
                showError={isAdminRoute ? true : false}
                colorScheme={isAdminRoute ? "blue" : "orange"}
              />
              <Input
                label={"Confirm  Password"}
                name={"cpassword"}
                type="password"
                visible={passwordVisible}
                setVisible={setPasswordVisible}
                showError={isAdminRoute ? true : false}
                colorScheme={isAdminRoute ? "blue" : "orange"}
              />
              <div className="mt-8">
                <button
                  type="submit"
                  id="submit-btn"
                  className={`w-[80%] block mx-auto ${
                    isAdminRoute ? "bg-customBlue" : "bg-primary"
                  } text-white py-3 rounded-lg text-xs`}
                >
                  Reset
                </button>
                <p className="mt-5 text-xs pl-8">
                 
                  <Link
                    className={`text-xs ${
                      isAdminRoute
                        ? "underline text-customBlue"
                        : "text-primary"
                    } font-semibold`}
                    to="/"
                  >
                   Go Back
                  </Link>
                </p>
              </div>
              <img
                src={Ellipse1}
                alt="ellipse"
                className="w-[70px] h-[40px] bottom-[1px] left-0 absolute"
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPassword;
