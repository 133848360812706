import React from "react";
import toast from "react-hot-toast";
import { api } from "../../../../../../../api/billing";
import { useQuery } from "react-query";

const PaymentHistory = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));

   return (
    <div className="w-full">
      <h3 className="text-black text-lg font-bold mb-1">PaymentHistory</h3>
      <hr />
      <div className="flex justify-between items-center bg-[#F0F1F3] p-2 mb-4">
        <h3 className="text-sm font-semibold text-primaryBlack">Total</h3>
        <h3 className="text-sm font-semibold text-primaryBlack">
          €3,030 Incl. VAT
        </h3>
      </div>
      <div className="mb-2">
        <div className="flex  items-center  gap-x-2">
          <div className="h-3 w-3 rounded-full bg-[#1EB386]"></div>
          <div className="flex">
            <h3 className="text-sm font-semibold text-primaryBlack">
              Success: No. 2020-04-0006
            </h3>
          </div>
        </div>
        <div className="flex justify-between items-center mb-2 px-6">
          <p className="text-sm font-normal text-[#667085] ">Date</p>
          <p className="text-sm font-medium text-primaryBlack">Oct 24, 2019</p>
        </div> 
        <div className="flex justify-between items-center mb-2 px-6">
          <p className="text-sm font-normal text-[#667085] ">Amount</p>
          <p className="text-sm font-medium text-primaryBlack">$400</p>
        </div>
      </div>
      <div className="mb-2">
        <div className="flex items-center  gap-x-2">
          <div className="h-3 w-3 rounded-full bg-[#FB923C]"></div>

          <div>
            <h3 className="text-sm font-semibold text-primaryBlack">
              Pending Payment
            </h3>
          </div>
        </div>
        <div className="flex justify-between items-center mb-2 px-6">
          <p className="text-sm font-normal text-[#667085] ">Date</p>
          <p className="text-sm font-medium text-primaryBlack">Oct 24, 2019</p>
        </div>
        <div className="flex justify-between items-center mb-2 px-6">
          <p className="text-sm font-normal text-[#667085] ">Amount</p>
          <p className="text-sm font-medium text-primaryBlack">$400</p>
        </div>
      </div>
      <div className="mb-2">
        <div className="flex items-center  gap-x-2">
          <div className="h-3 w-3 rounded-full bg-[#FF0202]"></div>
          <div className="flex">
            <h3 className="text-sm font-semibold text-primaryBlack">
              Failed Payment
            </h3>
          </div>
        </div>
        <div className="flex justify-between items-center mb-2 px-6">
          <p className="text-sm font-normal text-[#667085] ">Date</p>
          <p className="text-sm font-medium text-primaryBlack">Oct 24, 2019</p>
        </div>
        <div className="flex justify-between items-center mb-2 px-6">
          <p className="text-sm font-normal text-[#667085]">Amount</p>
          <p className="text-sm font-medium text-primaryBlack">$400</p>
        </div>
      </div>
      <hr />
      <div className="flex justify-between items-center mt-2">
        <h3 className="text-sm font-semibold text-primaryBlack">
          Remaining Amount
        </h3>
        <h3 className="text-sm font-semibold text-primaryBlack bg-[#FEF0F0] p-1 rounded">
          $100 Incl. VAT
        </h3>
      </div>
    </div>
  );
};

export default PaymentHistory;
