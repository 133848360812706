import React, { useEffect, useState } from 'react'
import StackedBarChart from './StackedChart'
import { useQuery } from 'react-query'
import { dashboardApi } from '../../../../../api/dashboard'
import { colors } from './LearnersStage'
import { formatCurrency } from '../../billing/site-admin/pages/summary/Summary'

const CourseCompletion = ({cohorts}) => {

const [aciveFilter, setActiveFilter] = useState('daily')
const cohortIds = ( cohorts?.length >= 2 ) ? cohorts?.map( (cohort) => cohort?.value ).join(',') : ''

const filters = [
    { type: 'daily', active: false },
    { type: 'weekly', active: true },
    { type: 'monthly', active: false },
 ]

 const getCohorts = (chartData) => {
   return chartData
    .flatMap(course => course.cohorts) // Flatten all cohorts into a single array
    .reduce((acc, cohort) => {
        // If the cohort is not already in the accumulator, add it
        if (!acc.some(c => c.cohortName === cohort.cohortName)) {
            acc.push(cohort);
        }
        return acc;
    }, []);
}

const getCohortSummary = (chartData, cohortName) => {
  return chartData.reduce((total, course) => {
      // Find the cohort within the current course that matches the cohortName
      const cohort = course.cohorts.find(c => c.cohortName === cohortName);
      // If the cohort is found, add its completedCount to the total
      return cohort ? total + cohort.completedCount : total;
  }, 0);
}

 /*const chartData = [
        {
            "courseId": "326fed31-0583-4d6c-b793-c772288b5c96",
            "courseName": "Banking overview",
            "cohorts": [
                {
                    "cohortId": "a7c0659f-194b-4b88-9f8b-cedbd47bb3d6",
                    "cohortName": "ETS BATCH 7",
                    "completedCount": 94
                },
                {
                  "cohortId": "a7c0659f-194b-4b88-9f8b-cedbd47bb3d6",
                  "cohortName": "ETS BATCH 8",
                  "completedCount": 200
              },
            ]
        },
        {
            "courseId": "2c224139-d77c-4b2d-a024-c1d102960e99",
            "courseName": "How to impress difficult callers - Handling difficult calls",
            "cohorts": [
                {
                    "cohortId": "a7c0659f-194b-4b88-9f8b-cedbd47bb3d6",
                    "cohortName": "ETS BATCH 7",
                    "completedCount": 36
                },
                {
                  "cohortId": "a7c0659f-194b-4b88-9f8b-cedbd47bb3d6",
                  "cohortName": "ETS BATCH 9",
                  "completedCount": 200
              },
            ]
        },
        {
            "courseId": "1d934ab3-eb89-4add-9864-8fe6307c1eb5",
            "courseName": "How to Impress Difficult Callers  - Categories of Difficult Callers",
            "cohorts": [
                {
                    "cohortId": "a7c0659f-194b-4b88-9f8b-cedbd47bb3d6",
                    "cohortName": "ETS BATCH 7",
                    "completedCount": 42
                },
                {
                  "cohortId": "a7c0659f-194b-4b88-9f8b-cedbd47bb3d6",
                  "cohortName": "ETS BATCH 17",
                  "completedCount": 200
              },
              {
                "cohortId": "a7c0659f-194b-4b88-9f8b-cedbd47bb3d6",
                "cohortName": "ETS BATCH 9",
                "completedCount": 101
            },
            {
              "cohortId": "a7c0659f-194b-4b88-9f8b-cedbd47bb3d6",
              "cohortName": "ETS BATCH 8",
              "completedCount": 101
          },
            ]
        },
        {
            "courseId": "a14c2f8e-f9f4-4410-aeb1-bd4858502cc2",
            "courseName": "Customer Basics",
            "cohorts": [
                {
                    "cohortId": "a7c0659f-194b-4b88-9f8b-cedbd47bb3d6",
                    "cohortName": "ETS BATCH 7",
                    "completedCount": 77
                }
            ]
        },
        {
            "courseId": "ead2497d-564b-4490-925a-90eef7f06160",
            "courseName": "Professional Phone Skills - Effective use of words",
            "cohorts": [
                {
                    "cohortId": "a7c0659f-194b-4b88-9f8b-cedbd47bb3d6",
                    "cohortName": "ETS BATCH 7",
                    "completedCount": 27
                }
            ]
        },
        {
            "courseId": "6be9fa85-e161-4b4a-aead-caa772b2c325",
            "courseName": "Being a valuable employee",
            "cohorts": [
                {
                    "cohortId": "a7c0659f-194b-4b88-9f8b-cedbd47bb3d6",
                    "cohortName": "ETS BATCH 7",
                    "completedCount": 35
                }
            ]
        },
        {
            "courseId": "609196df-08d5-4ea2-a240-dad8e5cd3c77",
            "courseName": "Professional Phone Skills - Your voice as a tool",
            "cohorts": [
                {
                    "cohortId": "a7c0659f-194b-4b88-9f8b-cedbd47bb3d6",
                    "cohortName": "ETS BATCH 7",
                    "completedCount": 20
                }
            ]
        },
        {
            "courseId": "6f327dfc-c422-4112-8bef-bef59625e1b0",
            "courseName": "Time Effectiveness",
            "cohorts": [
                {
                    "cohortId": "a7c0659f-194b-4b88-9f8b-cedbd47bb3d6",
                    "cohortName": "ETS BATCH 7",
                    "completedCount": 19
                }
            ]
        },
        {
            "courseId": "25fdac3e-7a04-4eda-b28a-0340e10a357f",
            "courseName": "Professional Phone Skills - How to handle calls ",
            "cohorts": [
                {
                    "cohortId": "a7c0659f-194b-4b88-9f8b-cedbd47bb3d6",
                    "cohortName": "ETS BATCH 7",
                    "completedCount": 14
                },
            ]
        },
        {
            "courseId": "d9bf19f6-a9ac-49df-8aed-00cb44139442",
            "courseName": "Effective email communication",
            "cohorts": [
                {
                    "cohortId": "a7c0659f-194b-4b88-9f8b-cedbd47bb3d6",
                    "cohortName": "ETS BATCH 7",
                    "completedCount": 28
                },
                {
                  "cohortId": "a7c0659f-194b-4b88-9f8b-cedbd47bb3d6",
                  "cohortName": "ETS BATCH 9",
                  "completedCount": 101
              },
            ]
        },
        {
            "courseId": "e1d58e42-973c-4c0b-99ee-06c179b93bdb",
            "courseName": "The power to make a difference",
            "cohorts": [
                {
                    "cohortId": "a7c0659f-194b-4b88-9f8b-cedbd47bb3d6",
                    "cohortName": "ETS BATCH 7",
                    "completedCount": 21
                },
                {
                  "cohortId": "a7c0659f-194b-4b88-9f8b-cedbd47bb3d6",
                  "cohortName": "ETS BATCH 8",
                  "completedCount": 201
              },
              {
                "cohortId": "a7c0659f-194b-4b88-9f8b-cedbd47bb3d6",
                "cohortName": "ETS BATCH 9",
                "completedCount": 100
            },
            ]
        }
    ] */

  const uniqueKey = "client-dashboard-course-completion-rate"

  const { data: chartData, isLoading, refetch } = useQuery(
    [uniqueKey, { period: aciveFilter, cohorts: cohortIds } ],
    ({queryKey}) => { 
        return cohortIds && dashboardApi.getClientCoursesCompletionRate(queryKey[1]);
    },
    {
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        select: response => response?.chartData
    });

    useEffect( () => {
      refetch({queryKey: [uniqueKey, { period: aciveFilter, cohorts: cohortIds }]})
    }, [cohorts, cohortIds])

  return (
    <div className='bg-white p-4 rounded-lg shadow'>
        <header className='flex justify-between'>   
             <h5 className='font-bold'>Completion Rate</h5>
             <div className='flex'>
               {
                (chartData?.length && !isLoading) ? 
                  getCohorts(chartData)?.map( (cohort, index) => {
                  return <div className='flex bg-slate-200 py-1 px-2 items-center mr-5'>
                            <span 
                              className={`w-[15px] rounded-full h-[15px]`}
                              style={{backgroundColor: colors[index]}}
                            /> 
                            <span className='ml-2 text-xs capitalize'>
                                {cohort?.cohortName}
                              </span>
                            <span className='ml-2 text-sm font-medium'>
                               = {formatCurrency(getCohortSummary(chartData, cohort?.cohortName),false)}
                            </span>
                        </div>
                  }) : null
               }
            </div>
        </header>
        <p className='text-xs mb-5'>
          course completion rate for multiple cohort
        </p>
       <StackedBarChart 
          barThickness={15}
          withLegend={false}
          chartData={{
            labels: (chartData?.length && !isLoading) ? 
              chartData?.map( course => course?.courseName)
            : [],
            datasets: (chartData?.length && !isLoading) ? 
               getCohorts(chartData)?.map( (cohort, index) => {
                 return {
                    label: cohort?.cohortName,
                    backgroundColor: colors[index],
                    borderWidth: 1,
                    data: chartData?.map( course => {
                        const data = course?.cohorts?.find(cohort_ => cohort_?.cohortName === cohort?.cohortName)
                        return data?.completedCount; 
                    }),
                  }
               })
             : []
          }}
      />
    </div>
  )
}

export default CourseCompletion