import React from "react";
import Sidebar from "./layout/sidebar/Sidebar";
import Header from "./layout/header/Header";
import { Routes, Route } from "react-router";
import Dashboard from "./pages/dashboard/Dashboard";
import Client from "./pages/client/ClientRoutes";
import Learners from "./pages/learners/Learners";
import Courses from "./pages/courses/Courses";
import RewardRoutes from "./pages/rewards/RewardRoutes";
import LearningPath from "./pages/learning-path/PathRoutes";
import Module from "./pages/module/Module";
import Analytics from "./pages/analytics/Analytics";
import UserManagement from "./pages/user-management/UserManagement";
import ReportAndAnalysisRoutes from "./pages/report-and-analysis/ReportAndAnalysisRoutes";
import BillingRoutes from "./pages/billing/site-admin/BillingRoutes";
import ClientBillingRoutes from "./pages/billing/client-admin/BillingRoutes";
import ZoomRoutes from "./pages/zoom-integration/ZoomRoutes";
import ActionPlanRoutes from "./pages/action-plan/ActionPlanRoute";
import SettingsRoutes from "./pages/settings/SettingsRoutes";
import EmailEditorRoutes from "./pages/email-editor/EmailEditorRoutes";

const AdminRoutes = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const accountType = user?.role?.slug;
  const isClientAdmin = accountType === "client-admin";

  return (
    <div className="flex justify-between overflow-y-hidden">
      <div className="w-[250px]">
        <Sidebar />
      </div>
      <div className="text-gray-900 w-[calc(100%-200px)]">
        <Header />
        <section className="h-[calc(100vh-10vh)] admin-container-panel overflow-y-auto">
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/clients/*" element={<Client />} />
            <Route path="/learners/*" element={<Learners />} />
            <Route path="/course/*" element={<Courses />} />
            <Route path="/courses/module" element={<Module />} />
            <Route path="/courses/analytics" element={<Analytics />} />
            <Route path="/learning-path/*" element={<LearningPath />} />
            <Route path="/user-management/*" element={<UserManagement />} />
            <Route
              path="/billing/*"
              element={
                isClientAdmin ? <ClientBillingRoutes /> : <BillingRoutes />
              }
            />
            <Route path="/rewards/*" element={<RewardRoutes />} />
            <Route
              path="/report-and-analysis/*"
              element={<ReportAndAnalysisRoutes />}
            />
            <Route path="/zoom/*" element={<ZoomRoutes />} />
            {/* <Route path="/settings/*" element={<SettingRoutes />} /> */}
            <Route path="/action-plan/*" element={<ActionPlanRoutes />} />
            <Route path="/settings/*" element={<SettingsRoutes />} />
            <Route path="/email/*" element={<EmailEditorRoutes />} />
          </Routes>
        </section>
      </div>
    </div>
  );
};

export default AdminRoutes;
