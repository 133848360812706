import EmojiPicker from 'emoji-picker-react'
import { questionTypes } from '../CreateFeedback'

const EmojiReaction = ({ id, updateQuestionType, question, deleteEmoji }) => {

  return (
    <div>
        <EmojiPicker 
           width={'100%'}
           height={400}
           onEmojiClick={ emoji => {
              updateQuestionType( id, null, questionTypes[3], emoji.getImageUrl() )
           }} 
        />
        <div className='mt-3'>
            <span className='text-xs'>Selected</span>
             <div className='flex flex-wrap'>
                { 
                question?.emojis ? 
                 question.emojis?.map( emoji => {
                    return <div className='bg-gray-100 mr-5 px-3 my-2 rounded-full'>
                          <img 
                             src={emoji} alt="emoji"
                             className='w-[30px] h-[30px] object-cover' 
                          />
                          <i 
                            className='bi bi-x-lg hover:cursor-pointer text-red-500 text-sm ml-2'
                            onClick={ () => deleteEmoji(id, emoji) }
                        />
                    </div>
                } )
                : null 
                }
             </div>
        </div>
    </div>
  )
}

export default EmojiReaction