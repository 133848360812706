import createApiClient from "./api";

const client = createApiClient();
// const formDataClient = createApiClient(true);

export const api = {
  createCategory: ({ id, payload }) =>
    client
      .post(`admin/org/${id}/reward-categories`, payload)
      .then(({ data }) => data),

  getCategories: ({ id }) =>
    client.get(`admin/org/${id}/reward-categories`).then(({ data }) => data),

  createReward: ({ id, payload }) =>
    client.post(`admin/org/${id}/rewards`, payload).then(({ data }) => data),

  editReward: ({ id, payload, rewardId }) =>
    client
      .patch(`admin/org/${id}/rewards/${rewardId}`, payload)
      .then(({ data }) => data),

  deleteReward: ({ id, rewardId }) =>
    client
      .delete(`admin/org/${id}/rewards/${rewardId}`)
      .then(({ data }) => data),

  getReward: ({ id }) =>
    client.get(`admin/org/${id}/rewards`).then(({ data }) => data),

  getLeaderboard: ({ id, rewardId }) =>
    client
      .get(`admin/org/${id}/rewards/${rewardId}/leaderboard`)
      .then(({ data }) => data),

  getDistribution: ({ id, rewardId }) =>
    client
      .get(`admin/org/${id}/rewards/${rewardId}/chart?period=daily`)
      .then(({ data }) => data),

  getSummary: ({ id, categoryId }) =>
    client
      .get(`admin/org/${id}/reward-categories/${categoryId}/summary`)
      .then(({ data }) => data),

  getCategoryTop: ({ id, categoryId }) =>
    client
      .get(`admin/org/${id}/reward-categories/${categoryId}`)
      .then(({ data }) => data),

  getTopBottomFive: ({ id, rewardId, sort }) =>
    client
      .get(`admin/org/${id}/rewards/ranking?sort=${sort}`)
      .then(({ data }) => data),

  getModules: () => client.get(`admin/modules`).then(({ data }) => data),

  getCourses: () => client.get(`admin/courses`).then(({ data }) => data),

  getCohorts: ({ org_id }) =>
    client.get(`admin/org/${org_id}/cohorts`).then(({ data }) => data),

  getPaths: ({ org_id }) =>
    client.get(`admin/org/${org_id}/journies`).then(({ data }) => data),

  uploadImage: ({ payload }) =>
    client.post(`admin/upload-file`, payload).then(({ data }) => data),
};
