import React, {useState} from 'react'
import ScreenNavigator from './components/ScreenNavigator'
import CourseInfo from './components/CourseInfo'
import { Form, Formik } from 'formik'
import CourseContent from './components/CourseContent'
import Preview from './components/Preview'

const CreateCourse = ({setCourses, refetch}) => {

  const [activeScreen , setActiveScreen ] = useState(1)
  const [preview, setPreview] = useState(null)
  const [modules, setModules] = useState([])
  const [moduleMediaFiles, setModuleMediaFiles] = useState([])
  const [modulePreview, setModulePreview] = useState([])
  const [completedStep, setCompletedStep] = useState(0)
  const [courseId, setCourseId] = useState(null)

  const initialValues = {
     c_title: '',
     c_des: '',
     image: '',
     c_category: '',
     c_point: '50',
     module: '',
     module_point: '',
     time: '',
     c_price: '50',
     status: false,
     c_pre_assessment_type: false,
     c_post_assessment_type: false,
  }

  const validate = (values) => {
      const errors = {};
      if(!values?.c_title) errors.c_title = `Error! Course title is required`
      else if(!values?.c_des) {
        errors.c_des = `Error! Course decription is required`
      }
      else if(!values?.c_category) {
        errors.c_category = `Error! Course category is required`
      }
      else if(!values?.c_point) {
        errors.c_point = `Error! Course point is required`
      }
      else if(!values?.c_price) {
        errors.c_price = `Error! Course price is required`
      }
      return errors;
  }

  const createCourse = (values, actions) => {
    actions.setSubmitting(false);
  }

  const step1Validated = (values) =>  (
                                values.c_title && values.c_des //&& values.c_category 
                                 && values.c_point && values.c_price
                                )

  return (
    <div className='bg-lightBlue h-[100vh] pl-8 pt-2 pb-8'> 
       <h3 className='text-md font-semibold mt-3'>
          &nbsp;Create Course
       </h3>
         <section>
            <Formik
                   initialValues={initialValues}
                   validate={validate}
                  //  enableReinitialize={true}
                   onSubmit={createCourse}
             >
            {({ values, setFieldError, setFieldValue, isValid }) => (
                <Form>
                    <ScreenNavigator 
                        activeScreen={activeScreen}
                        set1Validated={step1Validated(values)}
                        setActiveScreen={setActiveScreen}
                        completedStep={completedStep}
                    />
                    {
                        activeScreen === 1 && <CourseInfo
                          values={values}
                          setFieldValue={setFieldValue}
                          logoPreview={preview}
                          setActiveScreen={setActiveScreen}
                          setLogoPreview={setPreview}
                          step1Validated={step1Validated(values)}
                          setCourseId={setCourseId}
                          setCompletedStep={setCompletedStep}
                        />
                    }
                    {
                        activeScreen === 2 && 
                             <CourseContent
                                activeScreen={activeScreen}
                                setActiveScreen={setActiveScreen}
                                values={values}
                                setFieldValue={setFieldValue}
                                modules={modules}
                                setModules={setModules}
                                moduleMediaFiles={moduleMediaFiles}
                                setModuleMediaFiles={setModuleMediaFiles}
                                modulePreview={modulePreview}
                                setModulePreview={setModulePreview}
                                courseId={courseId}
                                refetch={refetch}
                             />
                    }
                    {
                        activeScreen === 3 && 
                             <Preview
                                modulePreview={modulePreview}
                                setCourses={setCourses}
                                values={values}
                                modules={modules}
                                setModules={setModules}
                                courseId={courseId}
                                refetch={refetch}
                             />
                    }
                    <button type='submit' id='create-course-btn' className={`hidden`}></button>
                 </Form>
             )}
         </Formik>
        </section>
    </div>
  )
}

export default CreateCourse