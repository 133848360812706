import React, {useEffect} from 'react'
import { Link } from 'react-router-dom';
import { Collapse, Dropdown, initTE, } from "tw-elements";
import logo from '../../../assets/logo.png'
import NavLinks from './components/NavLinks';
import Sidebar from './components/Sidebar';
import userImg from '../../../assets/user.PNG'

const Header = ({isAdminRoute=false}) => {
  
    useEffect( () => {
        initTE({ Collapse, Dropdown });
    }, [] )

    return (
    <header className='flex justify-between py-5 md:py-3 items-center shadow-lg pr-5'>
       { !isAdminRoute && <Sidebar />}
       <Link to={'/'}>
         <img src={logo} alt="logo" className='pl-2 w-[150px] h-[37px]' /> 
       </Link>
       <div className='flex m-hidden justify-end items-center'>
           { isAdminRoute ? 
              <div className='flex items-center'>
                   <img src={userImg} alt="user" className='w-[35px] h-[32px]' />
                  <span className='text-sm font-semibold ml-1'> Welcome Guest </span>
              </div>
              : <NavLinks />
            }
       </div>
    </header>
  )
}

export default Header