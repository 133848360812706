import React, { useState } from "react";
import CenteredModal from "../../../../../components/Modal/CenteredModal";
import ActiveMeetings from "./ActiveMeetings";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { api } from "../../../../../api/zoom";
import { api as resourcesApi } from "../../../../../api/resources";
import { ScaleLoader } from "react-spinners";
import moment from "moment/moment";
import Search from "../../../../../components/Inputs/Search";
import VerticalMenu from "../../../../../components/dropdown/VerticalMenu";
import MenuItem from "../../../../../components/dropdown/MenuItem";
import ConfirmModal from "../components/ConfirmModal";
import Pagination from "../../../../../components/pagination/Pagination";

const AllMeetingsModals = ({ openMeetingModal, setOpenMeetingModal }) => {
  const navigate = useNavigate();
  //   const [createMeetingModal, setCreateMeetingModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const orgId = JSON.parse(window.sessionStorage.getItem("user"))?.org?.id;
  const active_path = JSON.parse(sessionStorage.getItem("active-path"));
  const {
    data: meetings,
    isLoading,
    refetch,
  } = useQuery(
    ["all-meetings", search],
    async () => {
      const response = await api.getAllMeetings({ orgId, search });
      return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const deleteMeeting = async () => {
    if (!selectedMeeting) return;
    try {
      const response = await api.deleteMeeting({
        orgId,
        meetingId: selectedMeeting.id,
      });
      setDeleteModal(false);
      toast.success(response.message);
      refetch(); // Refresh the data after deletion
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  // Copy Meeting URL to Clipboard
  const handleCopyUrl = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success("Meeting URL copied to clipboard!");
      })
      .catch(() => {
        toast.error("Failed to copy the meeting URL.");
      });
  };

  const addToResources = async (meeting) => {
    let payload = {
      resourcesData: [
        {
          filePath: null, // URL or null - Optional
          type: "zoom-meeting", // String - Required, e.g., "image", "document", etc.
          itemId: meeting.id, // id of action-plan, quiz
        },
      ],
      parentId: active_path?.id, // UUID or String - Required
      source: "learning-path",
    };
    try {
      toast.loading("Loading...");
      const res = await resourcesApi.createResources(payload);
      toast.dismiss();
      toast.success(res.message);
    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.message);
    }
  };
  return (
    <div>
      <CenteredModal
        title={""}
        hasHeader={false}
        open={openMeetingModal}
        setOpen={setOpenMeetingModal}
        width={"w-[900px]"}
      >
        {/* <ActiveMeetings /> */}
        <div className="p-4 md:p-6 overflow-auto ">
          <div className="flex justify-between mb-3">
            <h3 className="text-lg font-semibold text-[#091F46] ">
              Active Meetings
            </h3>
            <div className=" flex justify-end">
              <Search
                onChange={(event) => setSearch(event.target.value)}
                placeholder={"Search ..."}
                className={"w-fit"}
              />
            </div>
          </div>
          <div className="bg-white p-3 rounded-lg shadow">
            {isLoading ? (
              <div className="mt-10 flex justify-center items-center">
                <ScaleLoader color="#FF9100" />
              </div>
            ) : (
              <div className="mt-4 ">
                <div className="">
                  <table className="table-container">
                    <thead className="table-header">
                      <tr>
                        <th className="table-head text-left">Meeting Name</th>

                        <th className="table-head">Start Time</th>
                        <th className="table-head">Duration</th>

                        <th className="table-head">Date</th>

                        <th className="table-head">Action</th>
                      </tr>
                    </thead>

                    <tbody className="table-body text-xs">
                      {meetings?.length > 0 ? (
                        meetings.map((meeting, index) => (
                          <tr key={index}>
                            <td className="table-data text-left">
                              {meeting.topic}
                            </td>
                            <td className="table-data text-left">
                              {meeting.startTime}
                            </td>
                            <td className="table-data text-left">
                              {meeting.duration}
                            </td>

                            <td className="table-data ">
                              {moment(meeting.updatedAt).format(
                                "YYYY/MM/DD, h:mm a"
                              )}
                            </td>

                            <td className="table-data flex justify-center px-2">
                              {/* <span className="flex justify-center"> */}
                              <VerticalMenu>
                                <div className="w-fit flex flex-col gap-2">
                                  <Link
                                    to={meeting.joinUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <button className="p-2 bg-customBlue text-white text-xs rounded-md mt-2">
                                      + Start Meeting
                                    </button>
                                  </Link>
                                  <MenuItem
                                    icon={
                                      <i className="bi bi-pencil-fill cursor-pointer"></i>
                                    }
                                    label="Edit"
                                    onClick={() => {
                                      navigate(
                                        `/admin/zoom/edit-meeting/${meeting.id}`
                                      );
                                    }}
                                  />
                                  <MenuItem
                                    icon={
                                      <i className="bi bi-copy font-bold cursor-pointer"></i>
                                    }
                                    label="Copy Link"
                                    onClick={() =>
                                      handleCopyUrl(meeting.joinUrl)
                                    }
                                  />
                                  <MenuItem
                                    icon={
                                      <i className="bi bi-plus font-bold cursor-pointer text-green-500"></i>
                                    }
                                    label="Add to resources"
                                    onClick={() => addToResources(meeting)}
                                  />
                                  <MenuItem
                                    icon={
                                      <i className="bi bi-trash3-fill  text-red-500 cursor-pointer"></i>
                                    }
                                    label="Delete"
                                    onClick={() => {
                                      setSelectedMeeting(meeting);
                                      setDeleteModal(true);
                                    }}
                                  />
                                </div>
                              </VerticalMenu>
                              {/* </span> */}
                              {/* <div className="flex gap-x-4 items-center">
                            <i
                              className="bi bi-copy cursor-pointer font-bold"
                              onClick={() => handleCopyUrl(meeting.joinUrl)}
                            ></i>
                            <Link to={`/admin/zoom/edit-meeting/${meeting.id}`}>
                              <i className="bi bi-pencil-fill cursor-pointer"></i>
                            </Link>
                            <i
                              className="bi bi-trash3-fill cursor-pointer"
                              onClick={() => {
                                setSelectedMeeting(meeting);
                                setDeleteModal(true);
                              }}
                            ></i>
                          </div> */}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="table-data" colSpan="12">
                            <p className="text-center">No Data Available</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                    {deleteModal && (
                      <ConfirmModal
                        title="Delete Meeting"
                        description={`Are you sure you want to delete ‘‘${selectedMeeting?.topic}”?`}
                        onYes={deleteMeeting}
                        onClose={() => setDeleteModal(false)}
                      />
                    )}
                  </table>
                </div>
              </div>
            )}
            <section className={`mt-1 px-3 rouded-lg bg-white`}>
              <Pagination
                data={meetings?.meta}
                perPage={perPage}
                pageNo={currentPage}
                setPageNo={setCurrentPage}
                setPerPage={setPerPage}
              />
            </section>
          </div>
        </div>
        <div className="mt-5 flex justify-end">
          <button
            type="button"
            onClick={() => {
              navigate("/admin/zoom/create-meetings");
              setOpenMeetingModal(false);
            }}
            className="p-3 bg-customBlue text-white text-xs rounded-md"
          >
            Create new meeting
          </button>
        </div>
      </CenteredModal>
    </div>
  );
};

export default AllMeetingsModals;
