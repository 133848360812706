import React, { useState } from "react";

const ConfirmModal = ({ title, description, onYes, onClose, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleYesClick = () => {
    setIsOpen(false);
    onYes(); // Call the callback function when "Yes" is clicked
  };

  const handleCloseClick = () => {
    setIsOpen(false);
    onClose(); // Call the callback function when the modal is closed without clicking "Yes"
  };

  return (
    <div className="fixed top-0 left-0 w-screen h-screen grid place-items-center bg-black bg-opacity-50 z-50">
      <div className="px-10 py-[30px] bg-white rounded-lg flex flex-col justify-center  relative shadow-2xl">
        <div className="flex flex-col ">
          <h1 className="mb-6 font-black text-2xl tracking-normal">{title}</h1>
          <p className="mb-[84px] font-medium text-grey500 text-2xl tracking-normal">
            {description}
          </p>
        </div>

        <div className="mt-4 flex justify-end items-center gap-x-6">
          <button
            onClick={handleYesClick}
            className="bg-white text-primary py-2.5 px-20 border border-primary rounded-lg font-bold text-xl"
          >
            Yes
          </button>
          <button
            onClick={handleCloseClick}
            className="bg-primary text-white py-2.5 px-20 rounded-lg font-bold text-xl"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
