import React, {useEffect} from 'react'
import youngWomanImg from '../../assets/young-woman-holding-tablet-work-removebg-preview 1.png'
import Ellipse1 from '../../assets/Ellipse 1.png'
import Ellipse2 from '../../assets/Ellipse 2.png'
import Ellipse3 from '../../assets/Ellipse 3.png'
import Input from './components/Input'
import FormHeader from './components/FormHeader'
import Button from '../../components/Buttons/Button'
import { Field } from 'formik'

const Registration = ({setCurrentStep, agreed, setIsActive , values, setFieldError, setFieldValue }) => {
 
  const isValid = !values?.agreed || !values?.fname || !values?.lname || !values?.email
                  || !values?.phone_number || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email);

  return (
    <div className='text-sm mb-12'>
       <section className='mt-0'>
          <div className='md:w-[50%] mx-auto border border-gray-100 rounded-lg'>
              <div className='flex justify-end relative h-[auto]'>
                 <div className='flex h-[auto] items-center absolute left-0 bottom-[80px]'>
                    <div className='relative m-hidden -ml-[120px] h-[300px] w-[240px]'>
                       <img 
                          src={Ellipse3} alt="ellipse"
                          className='absolute top-[17px] right-[100px] w-[50px] h-[30px]'
                          style={{transform: 'rotate(140deg)'}}
                        />
                      <img 
                          src={Ellipse1} alt="ellipse"
                          className='absolute bottom-[40px] right-[70px] w-[150px] h-[150px]'
                        />
                        <img 
                          src={youngWomanImg} 
                          alt="young woman holding work tablet" 
                          className='h-full w-[240px] absolute  right-0 h-[300px]'
                        />
                        <img 
                          src={Ellipse2} alt="ellipse"
                          className='absolute bottom-[-7px] right-[50px] w-[150px] h-[10px]'
                        />
                    </div>
                 </div>
                 <div className='w-[96%] mx-auto md:mr-0  md:w-[80%] p-4 bg-lightOrange'>
                      <FormHeader />
                           <Input 
                                 label={'First Name'} 
                                 name={'fname'}
                            />
                           <Input 
                               label={'Last Name'}  
                               name={'lname'}
                            />
                           <Input 
                                label={'Email Address'} type='email'
                                name={'email'}
                            />
                           <Input 
                                 label={'Phone Number'} 
                                 type='number' 
                                 name={'phone_number'}
                                 setFieldValue={setFieldValue}
                                 setFieldError={setFieldError}
                           />
                          <p className='mt-3 flex items-center text-xs pl-3'>
                              <Field type="checkbox" name='agreed' className='w-[15px] h-[15px] mr-1' />
                               I agree to the Terms and Conditions and Privacy Policy
                          </p> 
                         <Button
                             style={{
                                  opacity: values?.agreed ? 1 : 0.5,
                                  marginTop: 10 
                               }}
                                  text={'Continue'}
                             onClick={ () => {
                                if(isValid){
                                    document.querySelector('#submit-btn').click()
                                }
                                else {
                                  setFieldError('company_name', '')
                                  setIsActive(true)
                                  setTimeout( () => setCurrentStep(1) , 100 )
                                }
                             }}
                         />
                  </div>
              </div>
          </div>
       </section>
    </div>
  )
}

export default Registration