import React from 'react'
import { Routes, Route } from "react-router";
import Zoom from './Zoom';
import CreateMeeting from './pages/CreateMeeting';
import ZoomSettings from './pages/ZoomSettings';
import MultiStepForm from './pages/MultiStepForm';
import EditMultiStepForm from './pages/EditMultiStepForm';
import ActiveMeetings from './pages/ActiveMeetings';

const ZoomRoutes = () => {
  return (
    <Routes>
      <Route path="/overview" element={<Zoom />} />
      <Route path="/meetings" element={<ActiveMeetings />} />
      <Route path="/create-meetings" element={<MultiStepForm />} />
      <Route path="/edit-meeting/:meetingId" element={<EditMultiStepForm />} />
      </Routes>
  )
}

export default ZoomRoutes