import createApiClient from "./api";

const client = createApiClient();

export const api = {

  getClientInvoices: ({ org_id, year }) =>
    client
      .get(
        `/admin/org/${org_id}/billings/invoices?year=${year}`
      )
      .then(({ data }) => data),

    getBillingTransactions: ({ id, search, perPage, currentPage }) =>
      client
        .get(
          `admin/billings/payments?perPage=${perPage}${
            search ? `&search=${search}` : ""
          }&page=${currentPage}${ id ? `&orgId=${id}` : ''}`
        ),

     getBillingDiscounts: ({ search, perPage, currentPage }) =>
      client
        .get(
          `/admin/discounts?perPage=${perPage}${
            search ? `&search=${search}` : ""
          }&page=${currentPage}`
        )
     .then(({ data }) => data),

     getRevenueSummary: () => client.get(`/admin/billings/revenue-summary`).then(({ data }) => data),

     
     getInvoices: ({search}) => client.get(`/admin/billings/invoices${
            search ? `?search=${search}` : ""
      }`).then(({ data }) => data),

    refundCredit: (payload) => client.post("/admin/billings/transactions/refund",payload)
   .then(({ data }) => data),

   topUpCredit: (payload) => client.post(`/admin/org/${payload?.id}/billings/top-up`,payload?.data)
   .then(({ data }) => data),

   createDiscount: (payload) => client.post("/admin/discounts",payload).then(({ data }) => data),

   updateDiscount: (payload) => client.patch(`/admin/discounts/${payload?.id}`,payload?.data)
   .then(({ data }) => data),

  approveTransaction: (payload) => client.post("/admin/billings/transactions/approval",payload)
  .then(({ data }) => data),


  createClientDiscount: (payload) => client.post("/admin/discounts",payload)
  .then(({ data }) => data),
 
  updateClientDiscount: (payload) => client.patch(`/admin/discounts/${payload?.id}`,payload?.data)
  .then(({ data }) => data),

  addClientToDiscount: (payload) => client.post("/admin/discounts/add-organization",payload)
  .then(({ data }) => data),   

  getOrganisationDiscount: ({ search, perPage, currentPage }) => client
   .get(`/admin/discounts/organizations?perPage=${perPage}${
            search ? `&search=${search}` : ""
          }&page=${currentPage}`)
  .then(({ data }) => data),

  addBundle: (payload) => client.post("/admin/bundles",payload)
  .then(({ data }) => data),

  updateBundle: (payload) => client.patch(`/admin/bundles/${payload?.id}`,payload?.data)
  .then(({ data }) => data),

  getBundle: ({ search, perPage, currentPage }) => client
    .get(`/admin/bundles?perPage=${perPage}${
            search ? `&search=${search}` : ""
          }&page=${currentPage}`)
  .then(({ data }) => data),

  getPendingPayments: ({ search, perPage, currentPage }) => client
  .get(`/admin/billings/pending-payments?perPage=${perPage}${
            search ? `&search=${search}` : ""
          }&page=${currentPage}`)
  .then(({ data }) => data),  

  getCourseCreditCodes: ({ search, perPage, currentPage }) => client
   .get(`/admin/credit-codes?perPage=${perPage}${
            search ? `&search=${search}` : ""
          }&page=${currentPage}`)
  .then(({ data }) => data),

  createCourseCreditCodes: (payload) => client.post(`/admin/credit-codes`, payload).then(({ data }) => data),
 
  updateCourseCreditCodes: (payload) => client.patch(`/admin/credit-codes/${payload?.id}`, payload?.data)
  .then(({ data }) => data),
  
  deleteCourseCreditCodes: (payload) => client.delete(`/admin/credit-codes/${payload}`)
  .then(({ data }) => data),

  getClientTransactions: ({ id, search, perPage, currentPage,cohortId, courseId, learningPathId  }) => client
   .get(`/admin/org/${id}/billings/credit-usage?perPage=${perPage}${
            search ? `&search=${search}` : ""
          }&page=${currentPage}${cohortId ? `&cohortId=${cohortId}` : "" }${
            courseId ? `&courseId=${courseId}` : ""
          }${ learningPathId ? `&learningPathId=${learningPathId}` : ""}`)
  .then(({ data }) => data),

  getRevenueChart: (payload) => client
   .get(`/admin/billings/revenue-chart${payload ? `?period=${payload}` : ""}`)
  .then(({ data }) => data),


  getBundleByCreditAmount: (payload) => client.post("/admin/bundles/find",payload)
  .then(({ data }) => data),

  getAllBundles: () => client.get(`/admin/bundles`).then(({ data }) => data),
 
  getCreditUsage: (org_id) => client.get(`/admin/org/${org_id}/billings/credit-usage-trend/2024-07-01/2024-07-20`).then(({ data }) => data),
  
  getCreditUsage: (org_id) => client.get(`/admin/org/${org_id}/billings/credit-usage-trend/2024-07-01/2024-07-20`).then(({ data }) => data),

  getClientSummary: (org_id) => client.get(`/admin/org/${org_id}/billings/summary`).then(({ data }) => data),

  getPaymentSummary: (org_id) => client.get(`/admin/billings/payments?orgId=${org_id}`).then(({ data }) => data),
  
  getAllLearners: (org_id) => client.get(`/admin/learners/profile?orgId=${org_id}`).then(({ data }) => data),

  
}


  


