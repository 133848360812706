import { Field, ErrorMessage } from "formik";
import Toggle from "react-toggle";

export const FormBuilder = ({ fieldTitle, label, fieldType, options, readOnly=false, onFileChange }) => {
  
  if (fieldType === "select") {
    return (
      <div className="flex flex-col items-start justify-center w-full">
        <label htmlFor={fieldTitle.toLowerCase()} className="text-sm mb-1">
          {label ||
            fieldTitle.charAt(0).toUpperCase() +
              fieldTitle.slice(1).toLowerCase()}
        </label>
        <Field
          as={fieldType}
          id={fieldTitle}
          name={fieldTitle}
          style={{
            fontSize: "12px",
          }}
          className="py-3 px-4 w-full border border-slate-300 outline-none duration-200 focus:border-customGreen focus:ring-0 rounded-md"
        >
          {options?.map((option, index) => (
            <option key={index} value={option?.value}>
              {option?.title}
            </option>
          ))}
        </Field>
        <span className="h-8 mt-1 mb-2">
          <ErrorMessage
            name={fieldTitle}
            component="div"
            style={{ color: "red", fontSize: "12px" }}
          />
        </span>
      </div>
    );
  }

  if (fieldType === "textarea") {
    return (
      <div className="flex flex-col items-start justify-center w-full">
        <label htmlFor={fieldTitle.toLowerCase()} className="text-sm mb-1">
          {label ||
            fieldTitle.charAt(0).toUpperCase() +
              fieldTitle.slice(1).toLowerCase()}
        </label>
        <Field
          as={fieldType}
          id={label ? fieldTitle : fieldTitle.toLowerCase()}
          name={label ? fieldTitle : fieldTitle.toLowerCase()}
          
          style={{
            fontSize: "12px",
          }}
          className="py-2 h-[100px] px-4 w-full focus:bg-white bg-slate-100 border border-slate-300 outline-none duration-200 focus:border-darkBlue focus:ring-0 rounded-md"
        />
        <span className="h-8 mt-1 mb-2">
          <ErrorMessage
            name={label ? fieldTitle : fieldTitle.toLowerCase()}
            component="div"
            style={{ color: "red", fontSize: "12px" }}
          />
        </span>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-start justify-center w-full">
      <label htmlFor={fieldTitle} className="text-sm mb-1">
        {label ||
          fieldTitle.charAt(0).toUpperCase() +
            fieldTitle.slice(1).toLowerCase()}
      </label>
        { fieldType === 'file' ?      
         <Field
          type={fieldType}
          id={label ? fieldTitle : fieldTitle.toLowerCase()}
          name={label ? fieldTitle : fieldTitle.toLowerCase()}
          style={{
            fontSize: "12px",
          }}
          onChange={onFileChange}
          readOnly={readOnly}
          className="py-2 px-4 w-full border border-slate-300 bg-slate-100 focus:bg-white outline-none duration-200 focus:border-darkBlue focus:ring-0 rounded-md"
        />
         :
         <Field
            type={fieldType}
            id={label ? fieldTitle : fieldTitle.toLowerCase()}
            name={label ? fieldTitle : fieldTitle.toLowerCase()}
            style={{
              fontSize: "12px",
            }}
            readOnly={readOnly}
            className="py-2 px-4 w-full border border-slate-300 bg-slate-100 focus:bg-white outline-none duration-200 focus:border-darkBlue focus:ring-0 rounded-md"
         />
        }
      <span className="h-8 mt-1 mb-2">
        <ErrorMessage
          name={label ? fieldTitle : fieldTitle.toLowerCase()}
          component="div"
          style={{ color: "red", fontSize: "12px" }}
        />
      </span>
    </div>
  );
};

export const Time = ({ label, hours, minutes, seconds }) => {
  return (
    <div className="flex flex-col items-start justify-center w-full">
      <label className="text-sm mb-1">{label}</label>
      <div className="w-full flex items-end justify-start gap-1">
        <Field
          type={"number"}
          id={hours.toLowerCase()}
          name={hours.toLowerCase()}
          className="pl-1 w-full text-xs border border-slate-300 bg-slate-100 focus:bg-white outline-none duration-200 focus:border-darkBlue focus:ring-0 rounded-md flex-1 py-[9px]"
        />
        <span className="text-xs">h:</span>
        <Field
          type={"number"}
          id={minutes.toLowerCase()}
          name={minutes.toLowerCase()}
          className="pl-1 w-full text-xs border border-slate-300 bg-slate-100 focus:bg-white outline-none duration-200 focus:border-darkBlue focus:ring-0 rounded-md flex-1 py-[9px]"
        />
        <span className="text-xs">m:</span>
        <Field
          type={"number"}
          id={seconds.toLowerCase()}
          name={seconds.toLowerCase()}
          className="pl-1 w-full text-xs border border-slate-300 bg-slate-100 focus:bg-white outline-none duration-200 focus:border-darkBlue focus:ring-0 rounded-md flex-1 py-[9px]"
        />
        <span className="text-xs">s</span>
      </div>
    </div>
  );
};

export const Flex = ({ children }) => {
  return (
    <div className="w-full flex items-start justify-between md:flex-row flex-col gap-3">
      {children}
    </div>
  );
};

export const Toggler = ({ value, onChange, title }) => {
  return (
    <div className="flex items-start w-full ">
      <label htmlFor={title} className="text-sm mb-3 mr-3">
        {title}
      </label>
      <Toggle defaultChecked={value} onChange={onChange} />
    </div>
  );
};

export const Submit = () => {
  return (
    <button
      type="submit"
      className="border border-darkBlue text-white bg-darkBlue rounded-md px-10 text-sm py-2"
    >
      Submit
    </button>
  );
};
