import React, { useState } from 'react'
import { v4 } from 'uuid'

const MultipleChoice = ({ questions, setQuestions, id, question }) => {
 
 const intialOption = { id: v4(), title: '' }
 const [options, setOptions] = useState([intialOption])

 const deleteOption = id => {
    setOptions( prevOptions => {
      return prevOptions.filter( option => option.id !== id )
    })
 }

const updateAnwers = (answerId, type, value) => {
  setQuestions( prevQuestions => {
    return prevQuestions?.map( question => {
       if(question?.id === id){
           switch(type){
              case 'add' :
                return {...question, answers: [...question.answers, {...intialOption, id: v4()}]}
              break;
             case 'update' :
               return {...question, answers: question.answers.map( (answer) =>  {
                    if(answerId === answer.id) return {...answer, title: value }
                    else return answer
               })}
             break
             case 'delete' :
               return {...question, answers: question.answers.filter( ({id}) => answerId !== id )}
             break
           }
        }
       else return {...question}
     })
  })
}

  return (
    <div className='grid grid-cols-2 gap-5 w-[90%]'>
        {
            question?.answers?.map( (option, index) => {
                return( 
                    <div key={option.id} className='flex items-center justify-between'>
                        <input 
                            type="text" 
                            className='input-md shadow' 
                            placeholder='add option... (question answer)'
                            value={option.title}
                            onChange={ event => updateAnwers(option.id, 'update', event.target.value) }
                        />
                         {  (question?.answers.length > 1 ) &&
                            <span  
                             className='bi text-sm ml-2 bi-trash rounded-full text-red-500 hover:cursor-pointer'
                             onClick={ () => updateAnwers(option.id, 'delete') }
                           />
                         }
                         {  (index === question?.answers.length - 1 && question?.answers.length < 5) &&
                            <span  
                             className='bi text-sm ml-2 bi-plus-circle flex justify-center items-center w-[40px] h-[30px] rounded-full bg-secondary hover:cursor-pointer text-white'
                             onClick={ () => updateAnwers(null,'add') }
                           />
                         }
                    </div>
                 )
            } )
        }
    </div>
  )
}

export default MultipleChoice