import createApiClient from "./api";

const client = createApiClient();

export const api = {
  createResources: (payload) =>
    client.post(`admin/resources`, payload).then(({ data }) => data),

  deleteResources: (resourceId) =>
    client.delete(`admin/resources/${resourceId}`).then(({ data }) => data),
};
