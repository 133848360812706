import React, {useState} from 'react'
import AddResoursePanel from './AddResoursePanel'
import CustomDropdown from '../../../../../../../components/dropdown/CustomDropdown'
import CenteredModal from '../../../../../../../components/Modal/CenteredModal'


const AddMedia = ({ 
            modules, setModuleMediaFiles, moduleMediaFiles, fileType,
            processFile, setShowResourceModal, resoursePreview, setFileType,
            selectedModule, setSelectedModule
 }) => {

 const [activeMedia, setActiveMedia] = useState(null)
 const [showModal, setShowModal] = useState(false)

 const deleteMedia = id => {
    setModuleMediaFiles([...moduleMediaFiles.filter( file => file.id !== id )])
 }

  return (
    <div>
        <div className='mt-3 flex justify-between px-3'>
            <div className='text-left mt-5'>
                <h6 className='text-sm'>  
                    Select A module 
                </h6>
                <p className='text-xs pb-3'>
                     select from your recently selected modules and add a resource
                </p>
                <CustomDropdown
                   width={'w-[250px]'}
                   value={
                     selectedModule ? selectedModule : null
                   }
                   options={
                    modules?.length ?
                      modules?.map( module => {
                         return { label: module.name, value: module.id  }
                      } )
                    : []
                   }
                   onChange={
                     (input) => setSelectedModule({ label: input.label, value: input.value })
                   }
                />
            </div>
            <AddResoursePanel
                  processFile={processFile} 
                  openPanel={true} 
                  withTitle={false} 
                  setShowResourceModal={setShowResourceModal}
                  modules={moduleMediaFiles}
                  setModules={setModuleMediaFiles}
                  fileType={fileType}
                  setFileType={setFileType}
           />
        </div>
        <section>
             <h5 className='bg-gray-100 py-3 px-2 text-xs text-left mt-5'>
                 My Resources
             </h5>
              <CenteredModal
                 width={(activeMedia !== null && activeMedia?.type?.includes('/pdf')) ?
                   'w-[800px]' : 'w-[500px]'
                  }
                 title={
                    <h5 className='pb-2 border-b border-gray-500 w-full'> Preview Media </h5>
                 }
                 style={{padding: 10}}
                 open={showModal}
                 setOpen={setShowModal}
              >
                 {
                    activeMedia !== null ?
                      <>
                          {
                             activeMedia?.type?.includes('audio') ?
                             <div>
                                 {/* <p className='text-left text-sm py-2'>File Name: {activeMedia.file.name}</p>
                                  <p className='pb-2 text-left text-sm'>
                                        Size: {(activeMedia.file.size/(1024*1024)).toFixed(2)} MB
                                  </p>*/}
                                  <audio className='pb-3' controls>
                                    <source src={activeMedia.preview} type="audio/ogg" />
                                       Your browser does not support the audio element.
                                  </audio>
                             </div>
                            : null
                            }
                            { activeMedia?.type?.includes('video') ?
                                <div className='-mt-5'>
                                <video controls
                                    src={activeMedia.preview} 
                                    poster={'/images/file_video.png'}
                                    className='h-[350px] w-full p-2 rounded-lg w-fullrounded' 
                                />
                                </div>
                                : null
                            }
                            { activeMedia?.type?.includes('/pdf') ?
                                <div className='mt-2'>
                                    <iframe 
                                        src={activeMedia.preview} 
                                        className='h-[450px] w-full p-2 rounded-lg w-fullrounded' 
                                    ></iframe>
                                </div>
                                : null
                            }
                      </>
                    : null
                 }
              </CenteredModal>
             <div className='mt-3 grid grid-cols-4 gap-4'>
                   {
                    moduleMediaFiles?.length ?
                          moduleMediaFiles?.map( file => {
                            if(file.type.includes('w-link') || file.type.includes('r-link')){
                                return(
                                    <div className='shadow' key={file.id}>
                                      <span
                                        onClick={ () => {
                                            //setActiveMedia(file)
                                            setShowModal(true)
                                        }}
                                        className='h-[100px] text-[70px] bi bi-link-45deg flex items-center justify-center  hover:cursor-pointer p-2 rounded-lg w-[120px] rounded' 
                                       />
                                       <div className='border-t border-gray-200'>
                                          <p className='text-xs flex justify-between overflow-x-hidden text-blue-500 px-1 text-left pt-2'>
                                              Web link 
                                              <span 
                                                onClick={ () => deleteMedia(file.id) }
                                                className='text-xs hover:cursor-pointer bi bi-trash text-red-500'
                                              ></span>
                                          </p>
                                       </div>
                                 </div>
                                )
                             }
                             if(file.type.includes('video')){
                                return(
                                    <div className='shadow' key={file.id}>
                                       <img 
                                        src={'/images/file_video.png'} 
                                        onClick={ () => {
                                            setActiveMedia(file)
                                            setShowModal(true)
                                        } }
                                        className='h-[100px] block mx-auto hover:cursor-pointer p-2 rounded-lg w-[110px] rounded' 
                                        alt={file.file.name}
                                       />
                                       <div className='border-t border-gray-200'>
                                          <p className='text-xs overflow-x-hidden px-1 text-left pt-2'>{file.file.name}</p>
                                          <div className='flex py-1 px-2 justify-between'>
                                            <span className='text-xs font-semibold'>
                                               Size: {Math.ceil(file.file.size/1024)} Kb
                                            </span>
                                            <span
                                              onClick={ () => deleteMedia(file.id) }
                                              className='bi bi-trash text-xs hover:cursor-pointer text-red-500'></span>
                                          </div>
                                       </div>
                                 </div>
                                )
                             }
                             if(file.type.includes('pdf')){
                                return(
                                    <div className='shadow' key={file.id}>
                                       <span
                                        onClick={ () => {
                                            setActiveMedia(file)
                                            setShowModal(true)
                                        } }
                                        className='h-[100px] flex items-center justify-center text-center bi bi-filetype-pdf text-gray-600 text-[70px] hover:cursor-pointer p-2 rounded-lg w-[120px] rounded' 
                                       ></span>
                                       <div className='border-t border-gray-200'>
                                            <span 
                                               onClick={ () => deleteMedia(file.id) }
                                               className='bi bi-trash hover:cursor-pointer text-xs text-red-500'
                                             >
                                                &nbsp; Delete
                                              </span>
                                       </div>
                                 </div>
                                )
                             }
                             if(file.type.includes('audio')){
                                return(
                                    <div className='shadow' key={file.id}>
                                      <img 
                                        src={'/images/pngegg.png'} 
                                        onClick={ () => {
                                            setActiveMedia(file)
                                            setShowModal(true)
                                        } }
                                        className='h-[100px] block mx-auto  hover:cursor-pointer p-2 rounded-lg w-[120px] rounded' 
                                        alt={file.file.name}
                                       />
                                       <div className='border-t border-gray-200'>               
                                            <span 
                                              onClick={ () => deleteMedia(file.id) }
                                              className='bi bi-trash text-xs hover:cursor-pointer text-red-500'
                                             >
                                              &nbsp; Delete
                                            </span>
                                       </div>
                                 </div>
                                )
                             }
                             else return(
                                 <div className='shadow' key={file.id}> 
                                      <img 
                                        src={file.preview} 
                                        className='h-[100px] block mx-auto p-2 rounded-lg w-[120px] rounded' 
                                        alt={'resource image'}
                                       />
                                       <div className='border-t border-gray-200'>
                                            <span 
                                                onClick={ () => deleteMedia(file.id) }
                                                className='bi bi-trash text-xs hover:cursor-pointer text-red-500'>
                                                    &nbsp; Delete
                                            </span>
                                       </div>
                                 </div>
                              )
                          } )
                     : null
                   }
             </div>
        </section>
    </div>
  )
}

export default AddMedia