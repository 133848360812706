import React, { useEffect, useLayoutEffect, useState } from 'react'
import Button from '../../../../components/Buttons/Button'
import ActiveCohorts from './components/ActiveCohorts'
import Enrollment from './components/Enrollment'
import LearnersStage from './components/LearnersStage'
import CourseCompletion from './components/CourseCompletion'
import Summary from './components/Summary'
import TeamLeaderBoard from './components/TeamLeaderBoard'
import CustomDropdown from '../../../../components/dropdown/CustomDropdown'
import { api } from '../../../../api/learningPath'
import { api as clientsApi } from '../../../../api/clients' 
import { useMutation, useQuery } from 'react-query'
import toast from 'react-hot-toast'
import AppearanceMenu, { menus } from './components/AppearanceMenu'
import CourseCompletionChart from './components/CourseCompletionChart'
import SiteAdminSummary from './components/SiteAdminSummary'
import Clients from './components/Clients'
import MonthlyRevenue from './components/MonthlyRevenue'

const Dashboard = () => {

  const [ cohorts, setCohorts ] = useState([])
  const [cohortList, setCohortList] = useState([])
  const [open, setOpen] = useState(false)
  const [visibleSections, setVisibleSection] = useState(menus)
  const key = 'client-dashboard-appearance'
  const accountType = JSON.parse(sessionStorage.getItem('user'))?.role?.slug
  const isClient = accountType === "client-admin"
  const [activeClient, setActiveClient] = useState(null)
  const orgId = JSON.parse(sessionStorage.getItem('user'))?.org?.id

  const getCohortMutation = useMutation(api.getCohort,{
    onSuccess: res => {
       let response = res?.data
      if(response?.length) setCohorts(options(response)?.slice(0,2))
      else {
         if(!isClient) setCohorts([])
      }
      setCohortList(response)
    },
  },)

  const { data: clients, isLoading: clientsIsLoading, } = useQuery(
    ["get organisations list", { perPage:10000, currentPage:1 }],
    ({ queryKey }) => {
      return !isClient && clientsApi.getClients(queryKey[1]);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount:true,
      select: (data) => data?.data,
      onSuccess: res => {
        if(res?.length) {
          setActiveClient({ value: res[0]?.id, label: res[0]?.name })
          getCohortMutation.mutate({ id: res[0]?.id, perPage: 10000, currentPage: 1 })
        }
      }
    }
  );

  const options = data => data?.map( cohort => {
    return { label: cohort?.cohort?.name, value: cohort?.cohort?.id }
  })

  useLayoutEffect( () => {
    if(localStorage.getItem(key)){
       const sections = JSON.parse(localStorage.getItem(key))
       setVisibleSection(sections)
    }
  }, [])

 useEffect( () => {
  if(isClient) getCohortMutation.mutate({ id: orgId, perPage: 10000, currentPage: 1 })
 },[])

  const showSection = section => {
      if(visibleSections.includes(section)) return true
      else return
   }

  return (
    <section className='p-3 bg-lightBlue'>   
        <h3 className='text-lg flex justify-between'>
           Dashboard Overview
        <div className='relative' style={{zIndex: 'auto'}}> 
          <Button 
               bgColor={'bg-secondary'} text={'Customize Dashboard'} 
               width={'w-[300px]'} onClick={ () => setOpen(true) }
          />
           <AppearanceMenu 
              open={open} setOpen={setOpen} 
              visibleSections={visibleSections}
              setVisibleSection={setVisibleSection}
            />
          </div>
        </h3>
         { 
            showSection(menus[0]) ?  
             isClient ? <Summary /> : <SiteAdminSummary /> 
            : null
         }
        <section className='mt-5 flex justify-between'>
             { 
              showSection(menus[1]) ? <section className='w-[71%]'>
                { isClient ? <ActiveCohorts /> : <Clients /> }
             </section>
             : null
             }
             { showSection(menus[2]) ?
              isClient ?
              <section className='bg-white shadow w-[27%] p-3 rounded-lg'> 
                <h5 className='text-md font-medium pb-2 border-b border-b-gray-300'>
                   Team Leaderboard
                </h5>
                <p className='text-xs my-2'>
                  Team performance by cohorts
                </p>
                <TeamLeaderBoard />
              </section> :
              <section className='bg-white shadow w-[27%] p-3 rounded-lg'> 
              <h5 className='text-md font-medium pb-2 border-b border-b-gray-300'>
                 Monthly Revenue
              </h5>
              <MonthlyRevenue />
            </section>
             : null
             }
        </section>
        <section className='mt-5 bg-white items-center flex justify-between py-2 px-4 rounded-lg'>
           <h2 className='text-xs font-medium'> Select Multiple Cohorts And Compare Below</h2>
            <div className='flex'>
            { !isClient && 
              <CustomDropdown
                placeholder={'Select Client'}
                options={ clients?.length ?
                  clients?.map( client => {
                    return { label: client?.name, value: client?.id }
                  } ) : [] }
                value={activeClient}
                onChange={ values => {
                  setActiveClient(values)
                  getCohortMutation.mutate({ id: values?.value, perPage: 10000, currentPage: 1 })
                }}
                width={isClient ? 'w-[650px]': 'w-[300px]'}
              />} &nbsp;&nbsp;
            <CustomDropdown
                placeholder={'Select Cohorts To Compare'}
                options={ cohortList?.length ? options(cohortList) : [] }
                value={cohorts}
                onChange={ values => {
                  if(values?.length > 4) toast.error('Max limit is 4 cohort')
                  else setCohorts(values)
                }}
                multiSelect
                width={isClient ? 'w-[650px]': 'w-[300px]'}
              />
            </div>
        </section>
        {
          showSection(menus[3]) &&
         <section className='mt-5'>
            <Enrollment
               cohorts={cohorts}
               data={cohortList}
               isClient={isClient}
            />
        </section>
        }
        { 
          showSection(menus[4]) &&
          <section className='mt-5'>
            <LearnersStage 
                   isLoading={getCohortMutation.isLoading} cohorts={cohorts}
                  setCohorts={setCohorts} data={cohortList} 
            />
        </section>
        }
        { showSection(menus[5]) &&
        <section className='mt-5 bg-white p-3 shadow rounded-lg'>
             <CourseCompletionChart
                 clientId={activeClient?.value}
                  cohorts={cohorts} 
                  isClient={isClient}
            />
        </section>}
        { 
        showSection(menus[6]) &&
          <section className='mt-5 bg-white p-3 shadow rounded-lg'>
           <CourseCompletion cohorts={cohorts} />
        </section>
        }
    </section>
  )
}

export default Dashboard