import React, { useState } from "react";
import EditMeeting from "./EditMeeting";
import EditZoomSettings from "./EditZoomSettings";


const EditMultiStepForm = () => {
  const [step, setStep] = useState(1); // Manage current step
  const [formData, setFormData] = useState({}); // Store form data across steps

  const nextStep = (data) => {
    setFormData({ ...formData, ...data }); // Merge new data with existing form data
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  switch (step) {
    case 1:
      return <EditMeeting nextStep={nextStep} />;
    case 2:
      return <EditZoomSettings nextStep={nextStep} prevStep={prevStep}  formData={formData}/>;    default:
      return null;
  }
};

export default EditMultiStepForm;
