import React, {useState} from 'react'
import CenteredModal from './../../../../../../components/Modal/CenteredModal';

const DeleteClientModal = ({openModal, setOpenModal}) => {

  return (
    <>
      <CenteredModal 
        title={<h5 className='text-md font-semibold bi bi-trash'> &nbsp; Delete Client </h5> }
        open={openModal}
        setOpen={setOpenModal}
        style={{padding: 0}}
        width={'w-[380px] overflow-y-auto'}
        headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
        withCloseBtn
        closeButtonLabel={'Yes,  Proceed'}
        btnBgColor={'bg-red-500 w-full ml-3'}
      >
         <section className='text-left pb-3 px-5'>
             <h5 className='bi bi-exclamation-triangle text-center text-red-500 text-7xl'></h5>
             <p className='text-md text-center'> Are you sure you want to delete client  </p>
         </section>
      </CenteredModal>
    </>
  )
}

export default DeleteClientModal