import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../../../Auth/components/Input";
import toast from "react-hot-toast";
import { api } from "../../../../../api/email";
import SingleSelectDropdown from "../../../components/SingleSelectDropdown";

const Options = [
  { label: "TLS", value: "TLS" },
  { label: "SSL", value: "SSL" },
];

const EmailSettingStep2 = ({ prevStep, formData, nextStep }) => {
  const navigate = useNavigate();
  const orgId = JSON.parse(window.sessionStorage.getItem("user"))?.org?.id;
  const [encryptionType, setEncryptionType] = useState("");
  const [buttonText, setButtonText] = useState("Save Changes");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const handleSelectedActivity = (option) => {
    setEncryptionType(option.value);
  };
  const handleNext = (values) => {
    nextStep(values);
  };

  const validationSchema = Yup.object().shape({
    displayName: Yup.string().required("Display Name is required"),
    hostName: Yup.string().required("Host Name is required"),
    fromEmailAddress: Yup.string().required("Email is required"),
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });

  const initialValues = {
    displayName: "",
    hostName: "",
    fromEmailAddress: "",
    port: 0,
    username: "",
    password: "",
  };

  const saveConnectionMutation = useMutation(api.saveConnection, {
    onSuccess: (response) => {
      toast.remove();
      toast.success(response.message);
      // setButtonText("Saved"); // Change button text to "Saved"
    },
    onMutate: () => {
      toast.loading("Loading...");
      // setButtonText("Saving..."); // Change button text to "Saving..."
    },
    onError: ({ response }, variables, context) => {
      toast.remove();
      toast.error(response?.data?.message || "Something went wrong");
      variables.actions.setSubmitting(false); // Reset isSubmitting when an error occurs
    },
  });

  const handleSaveConnection = (values, actions) => {
 
    saveConnectionMutation.mutate(
      {
        orgId,
        payload: {
          hostName: values.hostName,
          displayName: values.displayName,
          fromEmailAddress: values.fromEmailAddress,
          port: values.port,
          username: values.username,
          encryption: encryptionType,
          password:values.password
        },
      },
      {
        onSuccess: (values) => {
          actions.resetForm(); // Reset the form after successful mutation
          actions.setSubmitting(false);
          nextStep(values);
          
          // setButtonText("Save Changes"); // Ensure form is no longer submitting
        },
      }
    );
  };

  const handleTestConnection = async (values, actions) => {
    toast.loading("Loading...");
    let payload = {
      hostName: values.hostName,
      displayName: values.displayName,
      fromEmailAddress: values.fromEmailAddress,
      port: values.port,
      username: values.username,
      encryption: encryptionType,
      password:values.password
    };

    try {
      const response = await api.testConnection({ orgId, payload });
      toast.remove();
      toast.success(response.message);
          } catch (error) {
      toast.remove();
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <div className="p-10">
      <div className="flex justify-between mb-6">
        <div className="">
          <h1 className="text-[31px] font-bold text-[#1D1F2C]"> Email </h1>
        </div>
        <button
          type="button"
          onClick={prevStep}
          className={`flex items-center justify-center gap-1  font-semibold 
                       bg-customBlue
                     text-white py-2 px-6 rounded-lg text-sm`}
        >
          <i className="bi bi-arrow-left text-white "></i>
          Back
        </button>
      </div>

      <div className="w-[400px]">
        <h3 className="text-lg font-semibold text-[#091F46]">
          Use Custom Email
        </h3>
        <p className="font-medium text-base text-[#777980]">
          Emails will be sent with configuration below:
        </p>
      </div>
      <div className="">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) =>
            handleSaveConnection(values, actions)
          }
        >
          {({ isSubmitting, values, actions }) => (
            <Form>
              <div className="w-2/3">
                <Input
                  label={"Email sender display name "}
                  name={"displayName"}
                  type="text"
                  colorScheme={"orange"}
                />
                <Input
                  label={"SMTP Server Host name"}
                  name={"hostName"}
                  type="text"
                  colorScheme={"orange"}
                />
                <Input
                  label={"Email address to send mail from "}
                  name={"fromEmailAddress"}
                  type="email"
                  colorScheme={"orange"}
                />
                <div className="grid md:grid-cols-2">
                  <Input
                    label={"SMTP Port "}
                    name={"port"}
                    type="number"
                    colorScheme={"orange"}
                  />
                  <div className="my-3 px-6">
                    <p className="text-xs text-[#667085] font-normal">
                      Encryption
                    </p>
                    <div className="flex flex-col w-fit mt-2">
                      <SingleSelectDropdown
                        options={Options}
                        onSelect={handleSelectedActivity}
                        placeholder="Select encryption type"
                        placeholderColor="#A5A5A5"
                      />
                    </div>
                  </div>
                </div>
                <div className="grid md:grid-cols-2">
                  <Input
                    label={"SMTP Server  username"}
                    name={"username"}
                    type="text"
                    colorScheme={"orange"}
                  />
                  <Input
                    label={"Password"}
                    name={"password"}
                    type="password"
                    visible={passwordVisible}
                    setVisible={setPasswordVisible}
                    colorScheme={"orange"}
                  />
                </div>
              </div>
              <div className="mt-5 flex gap-x-96 items-center">
                <button
                  type="button" // Use "button" type to avoid triggering form submission
                  onClick={() => handleTestConnection(values, actions)} // Pass values to handleTestConnection
                  className={`w-fit font-semibold bg-primary text-white p-3 rounded-lg text-xs`}
                >
                  Test Connection
                </button>
                <button
                  type="submit"
                  className={`w-fit font-semibold bg-customBlue text-white p-3 rounded-lg text-xs`}
                  // disabled={isSubmitting}// Disable the button while submitting
                >
                  Save Changes
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
     
    </div>
  );
};

export default EmailSettingStep2;
