import React from 'react'
import StepButton from './StepButton'
import toast from 'react-hot-toast'

const ScreenNavigator = ({activeScreen , setActiveScreen, set1Validated, completedStep}) => {

  const navigateScreen = (index, isValid, disabled=false) => {
    if(isValid && !disabled) setActiveScreen(index)
    else if(disabled) return
    else toast.error(`please complete step ${index - 1} before you proceed.`)
  }

  return (
    <div className='flex mt-3'>
        <StepButton 
              completed={ activeScreen >= 1} index={1} title={'Course info'}
              onClick={ () => navigateScreen( 1, true, completedStep === 1 ) }
              
         />
        <StepButton 
             index={2} title={'Course Content'} 
             onClick={ () => navigateScreen(2, set1Validated) }
             completed={activeScreen >= 2}
        />
        <StepButton 
            index={3} title={'Preview'} 
            onClick={ () => navigateScreen(3, true) }
            completed={activeScreen>=3}
        />
    </div>
  )
}

export default ScreenNavigator