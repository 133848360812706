import React from "react";
import StatCard from "./StatCard";
import useLearners from "../../../../../hooks/useLearners";
import total from "../assets/total.png";
import active from "../assets/active.png";
import completion from "../assets/complete.png";
import { learners } from "../../../../../api/learners";
import { useQuery } from "react-query";

const Summary = ({ status }) => {
  const { pathSummary, courseSummary, profiledSummary, cohortSummary } =
    useLearners();

  const profileHeaders = [
    {
      title: "Total Learners",
      amount: profiledSummary?.numberOfLearners?.toLocaleString(),
      imgUrl: total,
    },
    {
      title: "Total Cohorts",
      amount: profiledSummary?.numberOfCohorts?.toLocaleString(),
      imgUrl: active,
    },
    {
      title: "Total Learning Paths",
      amount: profiledSummary?.numberOfLearningPaths?.toLocaleString(),
      imgUrl: completion,
    },
    {
      title: "Total Courses",
      amount: profiledSummary?.numberOfCourses?.toLocaleString(),
      imgUrl: completion,
    },
  ];

  const cohortHeaders = [
    {
      title: "Total Learners",
      amount: cohortSummary?.numberOfParticipants?.toLocaleString(),
      imgUrl: total,
    },
    {
      title: "Learners - Completed",
      amount:
        cohortSummary?.numberOfParticipantsCompletedLearningPaths?.toLocaleString(),
      imgUrl: active,
      percentage: cohortSummary?.completedPercentage?.toLocaleString(),
    },
    {
      title: "Learners - Enrolled",
      amount:
        cohortSummary?.numberOfParticipantsInProgressLearningPaths?.toLocaleString(),
      percentage: cohortSummary?.inProgressPercentage?.toLocaleString(),
      imgUrl: completion,
    },
    {
      title: "Learners - In Progress",
      amount:
        cohortSummary?.numberOfParticipantsInProgressLearningPaths?.toLocaleString(),
      percentage: cohortSummary?.inProgressPercentage?.toLocaleString(),
      imgUrl: active,
    },
    {
      title: "Learners - Not Started",
      amount:
        cohortSummary?.numberOfParticipantsNotStartedLearningPaths?.toLocaleString(),
      percentage: cohortSummary?.notStartedPercentage?.toLocaleString(),
      imgUrl: completion,
    },
  ];

  const pathHeaders = [
    {
      title: "Total Learners",
      amount: pathSummary?.numberOfLearners?.toLocaleString(),

      imgUrl: total,
    },
    {
      title: "Completed",
      amount: pathSummary?.completed?.toLocaleString(),
      percentage: pathSummary?.completedPercentage?.toLocaleString(),
      imgUrl: completion,
    },
    {
      title: "Learners in Progress",
      amount: pathSummary?.inProgress?.toLocaleString(),
      percentage: pathSummary?.inProgressPercentage?.toLocaleString(),
      imgUrl: completion,
    },
    {
      title: "In-active Learners",
      amount: pathSummary?.notStarted?.toLocaleString(),
      percentage: pathSummary?.notStartedPercentage?.toLocaleString(),
      imgUrl: active,
    },
  ];

  const courseHeaders = [
    {
      title: "Total Learners",
      amount: courseSummary?.numberOfLearners?.toLocaleString(),
      imgUrl: total,
    },

    {
      title: "Completed",
      amount: courseSummary?.completed?.toLocaleString(),
      percentage: courseSummary?.completedPercentage?.toLocaleString(),
      imgUrl: completion,
    },
    {
      title: "Learners in Progress",
      amount: courseSummary?.inProgress?.toLocaleString(),
      percentage: courseSummary?.inProgressPercentage?.toLocaleString(),
      imgUrl: completion,
    },
    {
      title: "In-active Learners",
      amount: courseSummary?.notStarted?.toLocaleString(),
      percentage: courseSummary?.notStartedPercentage?.toLocaleString(),
      imgUrl: active,
    },
  ];

  const renderContent = () => {
    switch (status) {
      case "profile":
        return profileHeaders.map((item, index) => (
          <StatCard key={index} data={item} />
        ));
      case "cohort":
        return cohortHeaders.map((item, index) => (
          <StatCard key={index} data={item} />
        ));
      case "path":
        return pathHeaders.map((item, index) => (
          <StatCard key={index} data={item} />
        ));
      case "course":
        return courseHeaders.map((item, index) => (
          <StatCard key={index} data={item} />
        ));
      default:
        return null;
    }
  };

  return (
    <div className={`mb-1 w-full p-2 grid  ${status === 'cohort' ? 'md:grid-cols-5' : 
      'md:grid-cols-4'
    }  grid-cols-1 gap-5`}>
      {renderContent()}
    </div>
  );
};

export default Summary;
