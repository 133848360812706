import React from 'react'
import { useMutation } from 'react-query';
import { api } from '../../../../api/courses';
import toast from 'react-hot-toast';
import { errorResponse } from '../../../../components/utils/errorResponse';
import { FormBuilder } from './Components';
import ResourceLoader from './../courses/pages/CreateCourse/components/ResourceLoader';

const UploadModule = ({fileProps, setFieldError, setFieldValue}) => {

const uploadModulesMutation = useMutation(api.uploadModule, {
    onSuccess: response => {
        setFieldValue('text', response?.data?.data?.url)
        toast.remove()
        toast.success(`Modules Uploaded Successfully.`)
    },
    onMutate: () => {
        toast.loading('Loading...')
    },
    onError: error =>  errorResponse(error)
    })

    const onFileChange = (event, setFieldError) => {
        const fileZipped = event.target.files[0] 
        if(!fileZipped?.name.endsWith('.zip')){
           setFieldError('file', 'Error! unprocessible file, only .zip file allowed')
        }
        else {
           setFieldValue('file', event.target.value)
           const formData = new FormData()
           formData.append('file', fileZipped)
           uploadModulesMutation.mutate(formData)
        }
    }
    
  return (
    <>
      { uploadModulesMutation.isLoading && <ResourceLoader />}
       <FormBuilder 
          onFileChange={ (event) => onFileChange(event, setFieldError)}
          {...fileProps} 
      />
    </>
  )
}

export default UploadModule