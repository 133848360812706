import toast from "react-hot-toast"

export const errorResponse = error => {
    toast.remove()
    if(error.response.status === 500) toast.error(error.response.statusText)
    else if(error.response.status === 400) toast.error(error?.response?.data?.message)
    else{
       if(error?.response?.data?.errors?.length > 1){
        error?.response?.data?.errors?.map( error => {
          toast?.error(error?.message)
        })
       }
       else toast.error(error?.response?.data?.errors[0]?.message)
    }
}