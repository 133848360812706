import React from 'react'
import { Link } from 'react-router-dom'
import EmailEditor from 'react-email-editor';
import { editorDefault } from './editor-default';

const CreateLandingPageTemplate = () => {

    const emailEditorRef = React.useRef(null);

    const exportHtml = (status) => {
         const unlayer = emailEditorRef.current?.editor;
         unlayer?.exportHtml((data) => {
           const { design, html } = data;
           sessionStorage.setItem('editor_html', html)
         });
     
         unlayer.saveDesign( design => {
           sessionStorage.setItem('editor_json', JSON.stringify(design))
         })
     };
   
     const onReady = (unlayer) => {
       // Editor is ready
       // You can load your template here;
       // The design json can be obtained by calling
       // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)
   
       // const templateJson = { DESIGN JSON GOES HERE };
       // unlayer.loadDesign(templateJson);
     };
   
     const loadTemplate = (editor) => {
       const unlayer = emailEditorRef.current?.editor;
        unlayer.loadDesign((editorDefault))
     }

  return (
    <div>
         <section className='bg-gray-1 h-[100vh] p-2 md:px-5'>
         <header className='flex justify-between items-center mt-4'>
              <Link to={-1} className='bi bi-arrow-left -mt-2'> Back </Link>
              <div>
                  <button className='text-xs py-3 px-12 text-white rounded-lg bg-customBlue'>
                      Save Template
                  </button>
              </div>
         </header>
         <div className='mt-5'>
            <EmailEditor  ref={emailEditorRef} onLoad={loadTemplate} onReady={onReady} />
         </div>
        </section>
    </div>
  )
}

export default CreateLandingPageTemplate