import React, {useState, useEffect} from 'react'
import { useQuery } from 'react-query';
import { api } from '../../../../../../../api/learningPath';
import Search from '../../../../../../../components/Inputs/Search';
import Table from '../../../../../../../components/table/Table';
import Pagination from '../../../../../../../components/pagination/Pagination';

const Overview = ({orgId}) => {

  const [perPage, setPerPage] = useState(4);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const { data: cohorts, isLoading, refetch } = useQuery(
    ["Cohorts", { id: orgId, search, perPage, currentPage }],
    ({ queryKey }) => {
      return api.getCohort(queryKey[1]);
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  useEffect( () => {
    refetch()
  }, [search, setSearch, perPage, currentPage] )

  let columns = React.useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index }) =>
          index +
          1 +
          ((cohorts?.meta?.page || 1) - 1) * (cohorts?.meta?.perPage || perPage ),
      },
      {
        title: "Name",
        selector: "cohort",
        key: "cohort",
        render: ({ item }) => {           
          return <span className="text-gray-900 text-xs">{item?.name}</span>;
        },
      },
      {
        title: "Learners",
        selector: "numberOfParticipants",
        key: "numberOfParticipants",
        render: ({ item }) => {           
          return <span className='text-gray-900 ml-3 text-xs'> {item} </span>;
        },
      },
      {
        title: "Status",
        selector: "status",
        key: "status",
        render: ({ item }) => {           
          return <span className={`${item === 'in progress' ? 'text-primary' : 'text-armyGreen'}`}>{item}</span>;
        },
      },
      {
        title: "Progress",
        selector: "progress",
        key: "progress",
        render: ({ item }) => {           
          return <span className="block w-[60px] bg-gray-300 rounded-full h-[15px]">
                      <span style={{width: item}} className={`h-full text-center text-[10px] ${
                          item !== 0 ? `text-white` : 'text-gray-900'
                      } bg-armyGreen block rounded-full ml-1`}>
                        {item}%
                      </span>
                 </span>;
        },
      },
      {
        title: "Date Added",
        selector: "cohort",
        key: "cohort",
        render: ({ item }) => {           
          return <span className="text-gray-900 text-xs">
                     {`${new Date(item?.createdAt).toLocaleDateString('en-ng')}`}
                 </span>;
        },
      },
      {
        title: "Date Updated",
        selector: "cohort",
        key: "cohort",
        render: ({ item }) => {           
          return <span className="text-gray-900 text-xs">
                   {`${new Date(item?.updatedAt).toLocaleDateString('en-ng')}`}
                 </span>;
        },
      },
    ],
    [1, 20, 20]
  );

  const tableProps = { isLoading: isLoading, columns, data: cohorts?.data }

  return (
    <div className='bg-white rounded-xl p-3'>
         <header className='flex justify-between items-center'>
            <h3 className='text-sm font-semibold'>
                Cohorts 
            </h3>
            <Search 
                  placeholder={'Search cohorts... e.g cohort for access bank'}
                  className={'mb-0 w-[340px]'}
                  onChange={ (e) => {
                    setSearch(e.target.value)
                  }}
            />
         </header>
        <section className='mt-3 w-[100%] overflow-auto clients-table'>
           <Table 
             style={{
              overflowX: 'auto',
              width: (window.innerWidth > 500) && window.innerWidth < 1200 ? '130%' : '123%' 
              }}
            headerClassName={'shadow'}
            {...tableProps}
           />
        </section>
        <section>
        <Pagination
              data={cohorts?.meta}
              perPage={perPage}
              pageNo={currentPage}
              setPageNo={setCurrentPage}
              setPerPage={setPerPage}
            />
        </section>
    </div>
  )
}

export default Overview