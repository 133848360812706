import React from "react";
import { Bar, } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, BarElement, } from "chart.js";

export const data = {
  labels: ["course a", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29"],
  datasets: [
    {
      label: "June Cohort",
      backgroundColor: "#0E316E",
      borderColor: "#0E316E",
      borderWidth: 1,
      hoverBackgroundColor: "#87AA4B",
      hoverBorderColor: "#87AA4B",
      data: [1000, 1170, 660, 1030, 1030, 1000, 220,100,30,1000,300],
    },     
    {
      label: "July Cohort",
      backgroundColor: "#F86624",
      borderColor: "#F86624",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(255,205,86,0.4)",
      hoverBorderColor: "rgba(255,205,86,1)",
      data: [200, 250, 300, 350,400,350,600,780,129,260,109],
    },
  ],
};

const StackedBarChart = ({ 
        barThickness = 10, chartData = data, horizontal = false, height='300px',
        withLegend = true, 
    }) => {

 const tooltipRef = React.useRef(null);
 Chart.register(CategoryScale, LinearScale, BarElement) 

 const options = {
  indexAxis: horizontal ? 'y' : 'x',
  scales: {
    x: { 
      stacked: true 
    },
    y: !horizontal && { 
      stacked: true, 
      ticks: {
        callback: (value) => {
          // Convert large numbers to more readable format (e.g., 10000 to 10K)
          if (value >= 1000) {
            return (value / 1000) + 'K';
          }
          return value;
        }
      }
    },
  },
  maintainAspectRatio: false, // Set to false to allow manual control of chart size
  responsive: true,
  plugins: {
    legend: {
      display: withLegend,
      position: 'top',
    },
  },
  barThickness: barThickness,  
};

  return (
    <div> 
    <Bar
      data={chartData}
      options={options}
      width="100%"
      height={height}    
      style={{zIndex: -1}} 
    />      
   </div>
  );
}

export default StackedBarChart
