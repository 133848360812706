import React from 'react'
import StarterPage from '../Login/components/StarterPage'

const LandingPage = () => {
  return (
    <div>
       <StarterPage 
          title='Get started by creating your branded landing page'
          navigation='/admin/clients/create-landing-page-template'
          btnText='Create Landing Page'
        />
    </div>
  )
}

export default LandingPage