import React from "react";
import useLearners from "../../../../../hooks/useLearners";
import EmptyData from "../../../../../components/EmptyData";

const RecentCompletion = () => {
  const { cohortAnalyticList } = useLearners();

  // console.log(cohortAnalyticList);

  return (
    <div className="border border-white shadow-lg p-3 bg-white rounded-md">
      <h1>Recent Completion</h1>
      <p className="text-gray-500 text-xs mb-5">Learners completion activity</p>
      {cohortAnalyticList?.data?.length ? (
        <div className="w-full mt-2">
          {cohortAnalyticList?.data?.map?.((item, index) => (
            <div className="border border-darkBlue mb-3 py-2 px-4 rounded-md flex items-center justify-between">
              <p className="text-sm">
                {index + 1}. {item?.participant?.user?.name}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <EmptyData text={"No data"} />
      )}
    </div>
  );
};

export default RecentCompletion;
