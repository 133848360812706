import React, { useState, useEffect } from "react";
import Header from "../../components/Layout/Header/Header";
import Registration from "./Registration";
import { Formik, Form, Field } from "formik";
import CompanyInfo from "./CompanyInfo";
import Stepper from "./components/Stepper";
import PasswordSection from "./PasswordSection";
import { signUpValidation } from "./utils/validation";
import { useMutation } from "react-query";
import { api } from "../../api";
import toast from "react-hot-toast";
import URL from "../../url/index";
import { useNavigate } from "react-router";

const RegistrationSteps = () => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);
  const [count, setCount] = useState(100);
  const [isActive, setIsActive] = useState(false);
  const url = window.location.origin;

  const registrationMutation = useMutation({
    mutationFn: api.register,
    onMutate: (values) => {
      toast.loading("Loading...");
      return { values }; // This will be accessible in onSuccess
    },
    onSuccess: (response, variables, context) => {
      toast.remove();
      toast.success(response?.message);
      // Access values from context
      sessionStorage.setItem("reg-email", context.values.email);
      navigate("/auth/verify-email");
    },
    onError: ({ response }) => {
      toast.remove();
      toast.error(response.data?.message);
    },
  });

  const initialValues = {
    fname: "",
    lname: "",
    email: "",
    phone_number: "",
    agreed: false,
    company_address: "",
    company_name: "",
    password: "",
    cpassword: "",
    role: "",
    domain: "",
    paymentType: "pre-paid",
  };

  const validate = (values) => signUpValidation(values);

  useEffect(() => {
    let intervalId;
    if (isActive) {
      intervalId = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount >= 0) {
            setCount(prevCount - 2);
          } else {
            setCount(100);
            setIsActive(false);
          }
        });
      }, 0.1);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isActive]);

  useEffect(() => {
    const stepper = document.querySelectorAll(
      'div[style="width: 33.3333%; display: table-cell; position: relative; padding-top: 24px;"]'
    );
    stepper.forEach((element, index) => {
      element.addEventListener("click", () => {
        setIsActive(true);
        setTimeout(() => setCurrentStep(index), 100);
      });
    });
  }, [currentStep]);

  const signUp = (values, actions) => {
    // Construct the payload
    const payload = {
      firstName: values?.fname,
      lastName: values?.lname,
      email: values?.email,
      organizationEmail: values?.email,
      password: values?.password,
      passwordConfirmation: values?.cpassword,
      organizationName: values?.company_name,
      organizationAddress: values?.company_address,
      domain: values?.role,
      phoneNumber: values?.phone_number,
      baseUrl: `${url}/auth/verify-email`,
      paymentType: values?.paymentType,
    };

    // Trigger the mutation with the payload and pass values to onMutate
    registrationMutation.mutate(payload, {
      onSuccess: () => {
        actions.setSubmitting(false);
      },
    });
  };

  return (
    <div>
      <Header />
      <Formik
        initialValues={initialValues}
        validate={validate}
        enableReinitialize={true}
        onSubmit={signUp}
      >
        {({ values, setFieldError, setFieldValue }) => (
          <Form>
            <section className="steps-wrapper mt-5 w-[90%] md:w-[80%] mx-auto">
              {currentStep === 0 && <Stepper currentStep={0} />}
              {currentStep === 1 && <Stepper currentStep={1} />}
              {currentStep === 2 && <Stepper currentStep={2} />}
              <div
                style={{ transform: `scale(${count / 100})` }}
                className="mt-3"
              >
                {currentStep === 0 && (
                  <Registration
                    setCurrentStep={setCurrentStep}
                    values={values}
                    setFieldError={setFieldError}
                    currentStep={currentStep}
                    setIsActive={setIsActive}
                    setFieldValue={setFieldValue}
                  />
                )}
                {currentStep === 1 && (
                  <CompanyInfo
                    values={values}
                    setCurrentStep={setCurrentStep}
                    setFieldError={setFieldError}
                    currentStep={currentStep}
                    setIsActive={setIsActive}
                    setFieldValue={setFieldValue}
                  />
                )}
                {currentStep === 2 && (
                  <PasswordSection
                    setCurrentStep={setCurrentStep}
                    setFieldError={setFieldError}
                    currentStep={currentStep}
                    setIsActive={setIsActive}
                    setFieldValue={setFieldValue}
                    values={values}
                    isLoading={registrationMutation.isLoading}
                  />
                )}
              </div>
            </section>
            <button type="submit" id="submit-btn" className={`hidden`}>
              Continue
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RegistrationSteps;
