import React, {useEffect} from 'react'
import Table from '../../../../../../../components/table/Table';
import Loader from '../../../../../../../components/loader/Loader';
import EmptyData from '../../../../../../../components/EmptyData';
import { formatCurrency } from '../../../site-admin/pages/summary/Summary';

const Invoices = ({ invoices, isLoading,}) => {

  const months = [
    'January','February','March','April','May','June',
    'July','August','September','October','November','December'
  ];

    let columns = React.useMemo(
        () => [
          {
            title: "ID",
            selector: null,
            key: "id",
            render: ({ index }) =>
              index + 1  
          },
          {
            title: "Month",
            selector: "month",
            key: "month",
            render: ({ item, row }) => {                      
                 return( 
                    <span className="">{months[item]}</span>
                 )
             },
          },
          // {
          //   title: "Year",
          //   selector: "year",
          //   key: "year",
          //   render: ({ item }) => {           
          //     return <span className="">{item}</span>;
          //   },
          // },

          {
            title: "Opening Credit",
            selector: "openingCreditBalance",
            key: "openingCreditBalance",
            render: ({ item }) => {           
              return <span className="">{formatCurrency(item)}</span>;
            },
          },
          {
            title: "Closing Credit",
            selector: "closingCreditBalance",
            key: "closingCreditBalance",
            render: ({ item }) => {           
              return <span className="">{formatCurrency(item)}</span>;
            },
          },
          // {
          //   title: "Number Of Top ups",
          //   selector: "numberOfTopUps",
          //   key: "numberOfTopUps",
          //   render: ({ item }) => {           
          //     return <span className="">{item}</span>;
          //   },
          // },
          {
            title: "Credit Used",
            selector: "creditUsed",
            key: "creditUsed", 
            render: ({ item = 500}) => {                      
               if(item < 1000){
                  return <span className="bg-red-100 rounded text-red-500 py-1 px-2 text-[11px]">
                             {item}
                          </span>;
               }
               else if(item < 10000 && item > 1000){
                 return <span className="bg-blue-100 rounded text-blue-500 py-1 px-2 text-[11px]">
                        {item}
                    </span>;
               }
               else return <span className="text-green-500 bg-green-100 rounded py-1 px-2 text-[11px]">
                      {item}
                 </span>;
            },
          },
          {
            title: "Payment",
            selector: "closingCreditBalance",
            key: "closingCreditBalance",
            render: ({ item }) => {           
              return <span className="">{formatCurrency(item)}</span>;
            },
          },
          {
            title: "Status",
            selector: "closingCreditBalance",
            key: "closingCreditBalance",
            render: ({ item }) => {           
              return <span className="">{formatCurrency(item)}</span>;
            },
          },
          
        
          {
            title: "Reciept",
            selector: null,
            key: null,      
            render: () => {           
              return <span className="bi bi-file-earmark-pdf ml-4 text-lg"></span>;
            }  
        },
        ],
        [1, 20, 20]
      );

  const tableProps = { isLoading, columns, data: invoices }

 if(isLoading) return <Loader />

  return (
    <div>   
        {
          invoices?.length ? 
            <Table 
             {...tableProps}
             headerClassName={'border-t border-gray-200'}
           />
           :
           <EmptyData
             text={'There is no available invoice for this year'}
           />
        }
    </div>
  )
}

export default Invoices