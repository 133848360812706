import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { HamburgerMenuIcon } from '@radix-ui/react-icons';
import { useNavigate } from 'react-router-dom';

const DropdownMenu_ = ({ 
  template, activeTemplate, setActiveTemplate, setIsCourse, setAfterCourses,
  setIsMandatory, setAfterAssessment, setJourneyId, setCourseId,
  openQModal, setOpenQModal, setOpenCustomizeFeedbackModal,
}) => {

  const navigate = useNavigate()

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className="ml-2 bi bi-three-dots-vertical" aria-label="Customise options">
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
          <DropdownMenu.Item
              className="DropdownMenuItem text-left hover:text-white text-gray-900"
              onClick={ () => {
                setActiveTemplate(template)
                setOpenQModal(true)
              } }
          >
             View Questions
          </DropdownMenu.Item>
          <DropdownMenu.Separator className="DropdownMenuSeparator" />
          <DropdownMenu.Item 
             className="DropdownMenuItem text-left hover:text-white text-gray-900"
             onClick={ () => '' }
          >
             View Response
          </DropdownMenu.Item>
          <DropdownMenu.Separator className="DropdownMenuSeparator" />
          <DropdownMenu.Item 
             className="DropdownMenuItem text-left hover:text-white text-gray-900"
             onClick={ () => {
               sessionStorage.setItem(
                'active-template',
                 JSON.stringify(activeTemplate)
               )
               navigate('/admin/settings/feedback/edit-feedback')
             } }
          >
             Edit Template
          </DropdownMenu.Item>
          <DropdownMenu.Separator className="DropdownMenuSeparator" />
          <DropdownMenu.Item 
             className="DropdownMenuItem text-left hover:text-white text-gray-900"
             onClick={ () => {
               setActiveTemplate(activeTemplate)
               setAfterAssessment(false)
               setCourseId(null)
               setAfterCourses(true)
               setIsCourse(true)
               setIsMandatory(true)
               setJourneyId(null)
               setOpenCustomizeFeedbackModal(true)
             } }
          >
             Add to course/learning path
          </DropdownMenu.Item>
          <DropdownMenu.Separator className="DropdownMenuSeparator hidden" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default DropdownMenu_;