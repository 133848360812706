import { Search } from "@material-ui/icons";
import createApiClient from "./api";

const client = createApiClient();
const formDataClient = createApiClient(true);

export const api = {
  getClients: ({ id, search, perPage, currentPage }) =>
    client
      .get(
        `admin/organizations?perPage=${perPage}${
          search ? `&search=${search}` : ""
        }&page=${currentPage}`
      )
      .then(({ data }) => data),

  getClients: ({id, search, perPage, currentPage}) => 
    client.get(`admin/organizations?perPage=${perPage}${ search ? `&search=${search}` : ''}&page=${currentPage}`)
   .then(({ data }) => data),

  createClient: (payload) => client.post(`admin/organizations`, payload).then(({ data }) => data),
 
  editClient: (payload) => client
    .patch(`admin/organizations/${payload?.id}`, payload?.data)
    .then(({ data }) => data),

  getClientSummary: (payload) => 
        client.get(`admin/organizations/${payload?.id}/${payload?.type}`).then(({ data }) => data),

  getEmailTemplates: ({ org_id, search, currentPage, perPage }) =>
    client
      .get(
        `/emails/${org_id}?search=${search}&page=${currentPage}&perPage=${perPage}`
      )
      .then(({ data }) => data),

  createEmailTemplates: (payload) =>
    client.post(`/emails`, payload).then(({ data }) => data),

  getLoginTemplate: (payload) =>
    client.get(`admin/organizations/${payload?.id}/settings/login`).then(({ data }) => data),

  createLoginTemplate: (payload) =>
    client.post(`admin/organizations/${payload?.id}/settings/login`, payload?.data).then(({ data }) => data),
};
