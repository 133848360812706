import React from "react";
import { ResponsivePie } from "@nivo/pie";
import useLearners from "../../../../../hooks/useLearners";
import EmptyData from "../../../../../components/EmptyData";

const CompletionStatus = () => {
  const { completionListStatus } = useLearners();

  const colors = ["#bbd8f6", "#ffe9cc", "#f9cfb4", "#ed6f1e", "#ccc"];

  const chartdata = completionListStatus?.data?.map?.((item, index) => {
    return {
      id: item?.name,
      label: item?.name,
      value: item?.count,
      color: colors[index],
    };
  });

  // console.log(completionListStatus?.data);

  return (
    <div className="border border-white shadow-lg p-3 bg-white rounded-md">
      <h1>Completion Status</h1>
      <p className="text-gray-500 text-xs mb-5">Learner's completion status</p>
      <div className="w-full p-2 h-[300px]">
        {completionListStatus?.data?.length ? (
          <ResponsivePie
            data={chartdata}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={{ datum: "data.color" }}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#333333"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor={{ from: "color" }}
            slicesLabelsSkipAngle={10}
            slicesLabelsTextColor="#333333"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            legends={[
              {
                anchor: "bottom",
                direction: "column",
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
        ) : (
          <EmptyData text={"No data"} />
        )}
      </div>
    </div>
  );
};

export default CompletionStatus;
