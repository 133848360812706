import React from 'react'
import Switch from 'react-switch'
import Button from '../../../../../../../../../components/Buttons/Button'
import { useMutation } from 'react-query'
import { api } from '../../../../../../../../../api/learningPath'
import toast from 'react-hot-toast'
import { errorResponse } from '../../../../../../../../../components/utils/errorResponse'

const CreateCohort = ({ 
             cohortName, setCohortName, status, setStatus,
              setCurrentStep, setEnableTeam, setCohortId,
              cohortId 
  }) => {

  const orgId = JSON.parse(sessionStorage.getItem('user'))?.org?.id

  const createCohortMutation = useMutation( 
    cohortId ? api.updateCohort :  api.createCohort , {
    onSuccess: response => {
        toast.remove()
        toast.success(`Cohort ${ cohortId ? 'Updated' : 'Created' } Successfully.`)
        setCohortId(response?.data?.id)
        if(!status) setCurrentStep(2)
        else setEnableTeam(true)
    },
    onMutate: () => {
      toast.loading('Loading...')
    },
    onError: error =>  errorResponse(error)
  })

  const createCohort = () => {
     if(!cohortName) toast.error('Error! Cohort Name Is Required')
      else {
         const payload = { id: orgId, data: { name: cohortName } }
         createCohortMutation.mutate( cohortId ? {...payload, cohortId} : payload )
      }
  }

  return (
    <>
        <div className={`w-[500px] mx-auto shadow-2xl p-4 rounded-lg h-[auto] bg-white mt-8`}>
              <header className='flex justify-between items-center'>
                   <div>
                      <h1 className='text-md font-semibold mt-1'>
                        Step 1 <span className='bi bi-arrow-right'></span> &nbsp;Create new cohort
                      </h1>
                      <p className='text-xs mb-2 text-gray-700'>
                         enter your cohort details
                      </p>
                   </div>
              </header>
             <div className='border-t border-gray-300 mt-3 pt-3'>
              <label className='text-xs'> Cohort Name </label>
                  <input 
                     className='input-md mt-1'
                     placeholder='Cohort Name....'
                     onChange={ (e) => setCohortName(e.target.value)  }
                     value={cohortName}
                   />  
                   <div className='mt-5 text-xs flex items-center'>
                     Enable Teams &nbsp;&nbsp;&nbsp;&nbsp;
                      <Switch
                          //value={enableAffiliate}
                          height={20}
                          width={40}
                          handleDiameter={18}
                          checked={status}
                          onChange={() =>
                            setStatus(!status)
                          }
                      />
                    </div> 
                  <div className='flex mt-5 justify-end'>
                      <Button 
                         width={'w-[160px]'} 
                        text={'Create Cohort'}
                        bgColor={'bg-customBlue'}
                        onClick={createCohort}
                      />  
                  </div>    
             </div>
         </div>
    </>
  )
}

export default CreateCohort