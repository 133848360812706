import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import useLearners from "../../../../hooks/useLearners";
import TopFive from "./components/TopFive";
import BottomFive from "./components/BottomFive";
import Completion from "./components/Completion";
import RecentCompletion from "./components/RecentCompletion";
import CompletionStatus from "./components/CompletionStatus";
import { LearnersContext } from "../../../../context/learnersContext";
import SummaryAnalytics from "./components/SummaryAnalytics";
import Header from "./components/Header";

const Analytics = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const {
    setSelectedCohort,
    selectedCohort,
    selectedCourse,
    selectedPath,
    setPageType,
    pageType,
  } = useContext(LearnersContext);

  const { filters, handleCohort, handleLearningPath, handleCourse } = useLearners();

  useEffect(() => {
    filters?.length && setSelectedCohort(filters[0]);
    setPageType("cohort");
  }, [filters]);

  // console.log(selectedCohort);

  return (
    <div className="mt-0 p-4">
      <Header />
      {/* <div className="w-full flex items-center justify-start">
        <button
          onClick={handleBack}
          className="px-5 border border-darkBlue bg-darkBlue"
        >
          <FontAwesomeIcon className="text-white" icon={faArrowLeft} />
        </button>
      </div> */}
      <div className="mb-10">
        <SummaryAnalytics status={pageType} />
      </div>
      <div className="flex-1 mt-5 flex items-center gap-2 justify-between">
        <select
          value={JSON.stringify(selectedCohort)}
          onChange={handleCohort}
          className="py-[11.5px] outline-none focus:ring-0 flex-1 text-sm rounded-md shadow-md px-2"
        >
          <option value={JSON.stringify({})}>All Co-horts</option>
          {filters?.map((filter, index) => (
            <option key={index} value={JSON.stringify(filter)}>
              {filter?.cohortName}
            </option>
          ))}
        </select>
        <select
          value={JSON.stringify(selectedPath)}
          onChange={handleLearningPath}
          className="py-[11.5px] outline-none focus:ring-0 flex-1 text-sm rounded-md shadow-md px-2"
        >
          <option value={JSON.stringify({})}>All Learning Paths</option>
          {selectedCohort?.learningPaths?.map((filter, index) => (
            <option key={index} value={JSON.stringify(filter)}>
              {filter?.learningPathName}
            </option>
          ))}
        </select>
        <select
          value={JSON.stringify(selectedCourse)}
          onChange={handleCourse}
          className="py-[11.5px] outline-none focus:ring-0 flex-1 text-sm rounded-md shadow-md px-2"
        >
          <option value={JSON.stringify({})}>All Courses</option>
          {selectedPath?.courses?.map((filter, index) => (
            <option key={index} value={JSON.stringify(filter)}>
              {filter?.courseName}
            </option>
          ))}
        </select>
      </div>
      <div className="mt-5 grid grid-cols-1 gap-5 h-[450px]">
        <Completion />
        {/* <TopFive /> */}
        {/* <BottomFive /> */}
      </div>
      <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-5">
        {/* <Completion /> */}
        <TopFive />
        <BottomFive />
      </div>
      <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-5">
        <RecentCompletion />
        <CompletionStatus />
      </div>
    </div>
  );
};

export default Analytics;
