import React from "react";
import img from "../assets/placeholder.png";
import useLearningPath from "../../../../../hooks/useLearningPath";

const Placeholder = ({handleSelectionModal}) => {
//   const { handleSelectionModal } = useLearningPath();

  return (
    <div className="w-full mt-7 flex items-center justify-center flex-col">
      <img src={img} className="w-[50%] h-[50%]" />
      <h1 className="mt-4 mb-2 text-customOrange text-2xl font-semibold">Create your first learning path</h1>
      <p>Guide your learners through a sequence of courses</p>
      <button
        className="mt-10 border border border-darkBlue rounded-md py-3 px-10 bg-darkBlue text-white text-sm"
        onClick={handleSelectionModal}
      >
        Create path
      </button>
    </div>
  );
};

export default Placeholder;
