import React from 'react';
import OverviewCard from '../../report-and-analysis/components/OverviewCard';
import { useQuery } from 'react-query';
import { dashboardApi } from '../../../../../api/dashboard';
import { formatCurrency } from '../../billing/site-admin/pages/summary/Summary';
import BadgeIcon from '../../../../../assets/Badge.png';
import BadgeIconOrange from '../../../../../assets/Bagde (5).png';
import BadgeIconGreen from '../../../../../assets/Badge (1).png';

const Summary = () => {

  const orgId = JSON.parse(sessionStorage.getItem('user'))?.org?.id
  const { data: dashboardSummary, isLoading } = useQuery(
    ["client dashboard summary"],
    () => { 
        return dashboardApi.getClientDashboardSummary({orgId});
    },
    {
        cacheTime: 0,
        staleTime: 0,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        select: (data) => data?.data,
  });
 const summary = (summary) => isLoading ? 0 : summary
 
 return (
    <>
        <header className='grid pt-3 grid-cols-1 md:grid-cols-4 gap-5'>
           <OverviewCard 
             title={'Active Cohort'}
             iconPath={BadgeIconGreen}
             py={'py-5'}
             total={formatCurrency(summary(dashboardSummary?.activeCohorts), false)}
             text={
              <p className='flex justify-between text-xs'>
                 <span>+{summary(dashboardSummary?.todayTotalCohorts)} today </span>
                 <span className='text-green-500'>
                   +{summary(dashboardSummary?.todayCohortsPercentage)} today 
                </span>
              </p>
             }
             headerTextSize='text-2xl'
           />
           <OverviewCard
             title={'Total Learners'}  
             total={formatCurrency(summary(dashboardSummary?.totalLearners), false)}
             text={
              <p className='flex justify-between text-xs'>
                 <span>+{summary(dashboardSummary?.todayTotalLearners)} today </span>
                 <span className='text-green-500'>
                   +{summary(dashboardSummary?.todayLearnersPercentage)} today 
                </span>
              </p>
             }
             iconPath={BadgeIconOrange}
             headerTextSize='text-2xl'
             py='py-5'
           />
           <OverviewCard
             title={'Active Learners'}
             total={formatCurrency(summary(dashboardSummary?.activeLearners),false)}
             iconPath={BadgeIcon}
             text={
                <p className='flex justify-between text-xs'>
                   <span>+{summary(dashboardSummary?.todayActiveLearners)} today </span>
                   <span className='text-green-500'>
                     +{summary(dashboardSummary?.todayActiveLearnersPercentage)} today 
                  </span>
                </p>
             }
             headerTextSize='text-2xl'
             py='py-5'
           />
           <OverviewCard
             title={'Credit Used'}  
             total={formatCurrency(summary(dashboardSummary?.creditUsed),false)}
             iconPath={BadgeIconOrange}
             text={
                <p className='flex justify-between text-xs'>
                   <span>+{formatCurrency(summary(dashboardSummary?.todayCreditUsed), false)} today </span>
                   <span className='text-green-500'>
                     +{summary(dashboardSummary?.todayCreditUsedPercentage)} today 
                  </span>
                </p>
             }
             headerTextSize='text-2xl'
             py='py-5'
           />
        </header>
    </>
  )
}

export default Summary