import React, {useState} from 'react';
import EmptyData from '../../../../../../../../../../../../components/EmptyData';
import Table from '../../../../../../../../../../../../components/table/Table';
import Loader from '../../../../../../../../../../../../components/loader/Loader';
 
const TransactionHistory = ({isLoading, transactions, perPage}) => {

    const months = [
        'January','February','March','April','May','June',
        'July','August','September','October','November','December'
      ];

    let columns = React.useMemo(
        () => [
          {
            title: "ID",
            selector: null,
            key: "id",
            render: ({ index }) =>
              index +
              1 +
              ((transactions?.meta?.page || 1) - 1) * (transactions?.meta?.perPage || perPage ),
          },
          {
            title: "Learner Name",
            selector: "",
            key: "",
            render: ({ row }) => {                      
                 return( 
                    <span>{row?.user?.name}</span>
                 )
             },
          },
          /*{
            title: "Learner Email",
            selector: "",
            key: "",
            render: ({ row }) => {                      
                 return( 
                    <span>{row?.user?.email}</span>
                 )
             },
          },*/
          {
            title: "Course",
            selector: "",
            key: "",
            render: ({ row }) => {                      
                 return( 
                    <span>{row?.course?.name}</span>
                 )
             },
          },
          {
            title: "Credit Cost",
            selector: "cost",
            key: "cost",
            render: ({item }) => {                      
                 return( 
                    <span className="">{item}</span>
                 )
             },
          },
          {
            title: "Month",
            selector: "month",
            key: "month",
            render: ({item }) => {                      
                 return( 
                    <span className="">{months[item]}</span>
                 )
             },
          },
          {
            title: "Year",
            selector: "year",
            key: "year",
            render: ({item }) => {                      
                 return( 
                    <span className="">{item}</span>
                 )
             },
          },
        ],
        [1, 20, 20]
      );
    
    const tableProps = { isLoading, columns, data: transactions?.data }

  if(isLoading) return <Loader />  

  return (
    <div>
       { 
        transactions?.data?.length ?
          <Table {...tableProps} />
          : 
          <EmptyData text={'There is no any transaction history found for this client.'} />
       }
    </div>
  )
}

export default TransactionHistory