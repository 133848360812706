import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { api } from "../../../../../api/learningPath";
import { Checkbox } from "./Components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import img_icon from "../assets/img_icon.png";
import Button from "../../../../../components/Buttons/Button";
import toast from "react-hot-toast";
import Loader from "../../../../../components/loader/Loader";

const Prerequisites = ({
  selectedPrerequisites,
  setSelectedPrerequisites,
  handleSettingsModal,
}) => {
  
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const current_path = JSON.parse(sessionStorage.getItem("active-path"));

  const user = JSON.parse(sessionStorage.getItem("user"));

  const { data: paths, isLoading } = useQuery(
    ["paths", { search, perPage, currentPage }],
    ({ queryKey }) => {
      return api.getLearningPath({ ...queryKey[1], org_id: user?.org?.id });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data?.data,
    }
  );

  const [isRequired, setIsRequired] = useState(true);

  const handleIsRequired = () => {
    setIsRequired(!isRequired);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleClick = (selected) => {
    return () => {
      setSelectedPrerequisites((prev) => {
        // Check if selected_course is already in selectedCourses
        const isSelected = prev.includes(selected);
        if (isSelected) {
          // If it's already selected, remove it
          return prev.filter((course) => course !== selected);
        } else {
          // If it's not selected, add it
          return [...prev, selected];
        }
      });
    };
  };

  const handleDeselect = () => {
    setSelectedPrerequisites([]);
  };

  const assignPrequisitesMutation = useMutation(api.assignPrerequisites, {
    onSuccess: (data) => {
      toast.remove();
      setTimeout(() => {
        handleSettingsModal();
      }, 100);
      toast.success(data?.message);
    },
    onError: ({ response }) => {
      toast.remove();
      toast.error(response?.data?.message);
    },
  });

  const handleSave = () => {
    if (isRequired && selectedPrerequisites.length < 1) {
      return toast.error("Please select paths you wish to add...");
    }
    toast.loading("Processing...");

    assignPrequisitesMutation.mutate({
      payload: {
        requiredJourneyIds: !isRequired
          ? []
          : selectedPrerequisites.map((item) => {
              return item?.id;
            }),
      },
      journey_id: current_path?.id,
      org_id: user?.org?.id,
    });
  };

  return (
    <div className="w-full p-3">
      <div
        className={`border ${
          isRequired ? "border-lightGray" : "border-customOrange"
        } flex items-center justify-start gap-3 rounded-md p-3`}
      >
        <Checkbox
          checked={!isRequired ? true : false}
          id={"not-required"}
          onChange={handleIsRequired}
        />
        <div>
          <h1 className="flex items-center justify-start text-left gap-1">
            No Prerequisites required.{" "}
            <span className="text-[10px] px-5 bg-slate-500 rounded-2xl text-white">
              Default
            </span>
          </h1>
          <p className="text-xs text-gray-500 text-left">
            Learners are not required to complete another learning path to take
            your learning path.
          </p>
        </div>
      </div>
      <div
        className={`border ${
          !isRequired ? "border-lightGray" : "border-customOrange"
        } mt-3 flex items-center justify-start gap-3 rounded-md p-3`}
      >
        <Checkbox
          checked={isRequired ? true : false}
          id={"required"}
          onChange={handleIsRequired}
        />
        <div>
          <h1 className="text-left">Prerequisites required.</h1>
          <p className="text-xs text-gray-500 text-left">
            Set which learning path learners are required to complete to take
            your learning path.
          </p>
        </div>
      </div>
      {isRequired && (
        <div className="w-full p-2 mt-3">
          <p className="text-sm text-left mb-1 mt-3">Select Learning Path </p>
          <input
            type={"search"}
            onChange={handleSearch}
            className="w-full duration-200 border border-lightGray rounded-md text-xs py-2 pl-2 focus:ring-0 outline-none focus:border-customOrange"
            placeholder="Search..."
          />
          {isLoading ? (
            <div className="w-full h-[300px]  mt-3 py-2 pr-2">
              <Loader />
            </div>
          ) : (
            <div className="w-full max-h-[300px] overflow-y-scroll mt-3 py-2 pr-2">
              {paths?.map((path, index) => (
                <div
                  key={index}
                  className={`border border-lightGray flex items-center justify-between w-full px-3 py-5 rounded-md mb-2`}
                >
                  <div className="text-sm flex items-center justify-start gap-2">
                    <button onClick={handleClick(path)}>
                      {selectedPrerequisites?.includes(path) ? (
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="text-xl font-light text-customOrange"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="text-xl font-light text-gray-400"
                        />
                      )}
                    </button>
                    <img
                      src={img_icon}
                      className="w-10 h-10"
                      alt={`img-icon ${index}`}
                    />
                    <p>{path?.name}</p>
                  </div>
                  <div className="text-xs capitalize flex items-center justify-end gap-2">
                    <p>{path?.status}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <div className="w-full flex items-center justify-between my-5">
        <Button
          text={"Deselect All"}
          bgColor={"bg-white"}
          textColor={"text-darkBlue"}
          width={"w-[120px]"}
          onClick={handleDeselect}
        />
        <button
          onClick={handleSave}
          className="w-[150px] flex items-center justify-center gap-2 bg-darkBlue text-white text-xs rounded-md py-3 px-5"
        >
          Save to Path{" "}
          {isRequired && selectedPrerequisites?.length > 0 && (
            <span className="bg-white text-darkBlue px-2 font-bold text-[9.5px] rounded-lg">
              {selectedPrerequisites?.length}
            </span>
          )}
        </button>
      </div>
    </div>
  );
};

export default Prerequisites;
