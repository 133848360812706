import React, { useState, useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Index from './pages/Index'
import CreateCourse from './pages/CreateCourse/CreateCourse'
import Loader from './../../../../components/loader/Loader';
import ViewCourses from './pages/ViewCourses/ViewCourses';
import { api } from '../../../../api/courses';
import { useQuery } from 'react-query';
import EditCourse from './pages/EditCourse/EditCourse';

const Courses = () => {

  const [perPage, setPerPage] = useState(8);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const { data: courses, isLoading, refetch } = useQuery(
    ["courses", { search, perPage, currentPage }],
    ({ queryKey }) => {
      return api.getCourses(queryKey[1]);
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  useEffect( () => {
    refetch()
  }, [search, setSearch, perPage, currentPage] )

  return (
          <Routes>  
            <Route path="/" element={
              isLoading ? <Loader /> :
                courses?.data?.length ? 
                     <Navigate to={'/admin/course/view'} />
                : <Index />  
              }
            />
             <Route 
                   path='/create-course' 
                   element={ <CreateCourse
                   refetch={refetch}
             /> } />
             <Route
                 path='/edit/:id' 
                 element={ <EditCourse refetch={refetch} /> } 
             />
             <Route  
              path='/view'
              element={<ViewCourses 
                        courses={courses} 
                        isLoading={isLoading}
                        search={search}
                        setSearch={setSearch}
                        perPage={perPage}
                        setPerPage={setPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        refetch={refetch}
                    /> }
            />
          </Routes>
  )
}

export default Courses