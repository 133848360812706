import React, { useEffect } from 'react'
import Button from '../../../../../../../../../components/Buttons/Button'
import UserSelect from '../../../components/UserSelect'
import Search from '../../../../../../../../../components/Inputs/Search'
import { v4 as uuid } from 'uuid'
import toast from 'react-hot-toast';
import * as XLSX from 'xlsx/xlsx.mjs'
import { useMutation } from 'react-query';
import { api } from '../../../../../../../../../api/learningPath'
import { errorResponse } from '../../../../../../../../../components/utils/errorResponse'
import EmptyData from '../../../../../../../../../components/EmptyData'

const AddLearnersToCohort = ({ 
  setCurrentStep, cohortId, learners, setLearners, defaultLearners, setDefaultLearners,
  checked, setChecked, duplicateLearners, setDuplicateLearners, invalidLearners,
  setInvalidLearners
}) => {

  const orgId = JSON.parse(sessionStorage.getItem('user'))?.org?.id

  const  addParticipantsMutation = useMutation(api.addLearnersToCohort, {
    onSuccess: response => {
        toast.remove()
        toast.success('Learners added to cohort successfully.')
        setCurrentStep(4)
    },
    onError: (error) => errorResponse(error),
    onMutate: () => {
      toast.loading('Loading...')
    }
  })

  const uploadLearners = event => {
     setInvalidLearners([])
     setDuplicateLearners([])
     const excelSheet = event.target.files[0] 
     if(excelSheet?.name?.endsWith('xlsx') || excelSheet?.name?.endsWith('csv')){
        const promise = new Promise( ( resolve , reject ) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(excelSheet);
            fileReader.onload = event => {
                const bufferArray = event.target.result;
                const workBook = XLSX.read(bufferArray, { type : 'buffer' });
                const workSheetName = workBook.SheetNames[0]
                const workSheet = workBook.Sheets[ workSheetName ]
                const dataArray = XLSX.utils.sheet_to_json( workSheet ) ;
                resolve(dataArray)
            }
            fileReader.onerror = error => {
                reject(error)
            }
        })
        promise.then( data => {
           //check if the data is in the rigth format
           const checkKey = (key) => data[0].hasOwnProperty(key)
           if(!checkKey('Name') || !checkKey('Email') || !checkKey('EmployeeId')){
               toast.error('Invalid excel template')                 
            }
           else {
               const learners = data?.length ? data?.map( learner => {
                  return { 
                     name: learner?.Name,
                     email: learner?.Email,
                     employeeId: learner?.EmployeeId ,
                     status: true
                     } 
                }) : []

                if(learners?.length){
                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex to validate email
                  const uniqueEmails = new Set();
                  const validLearners = [];
                  const invalidLearners = [];
                  const duplicateLearners = [];
                
                  learners.forEach(learner => {
                    const { email } = learner;
                    
                    if (!emailRegex.test(email)) {
                      // Invalid email
                      invalidLearners.push(learner);
                    } else if (uniqueEmails.has(email)) {
                      // Duplicate email
                      duplicateLearners.push(learner);
                    } else {
                      // Valid email
                      uniqueEmails.add(email);
                      validLearners.push(learner);
                    }
                  });
                  if(invalidLearners?.length){
                      setInvalidLearners(invalidLearners)
                      event.target.value = null 
                  }
                  else if(duplicateLearners?.length){
                      setDuplicateLearners(duplicateLearners)
                      event.target.value = null 
                  }
                  else {
                     setLearners(learners)
                     setDefaultLearners(learners)
                     event.target.value = null 
                  }
                }          
           }
        })
     }
     else toast.error('Error! please upload a valid xlsx file')
  }

  const updateLearners = (learner) => {
     const updatedLearners = (key) => [...key?.map( learner_ => {
         if(learner?.email === learner_?.email){
            return {...learner_, status: !learner_?.status }
         }   
         else return {...learner_}
       })]

     setLearners(updatedLearners(learners))
     setDefaultLearners(updatedLearners(defaultLearners))
  }

  const selectAll = () => {
     if(learners?.length){
         const isChecked = learners?.findIndex( learner => learner?.status === true )
         if(isChecked === -1) {
            setChecked(true)
            setLearners([...learners.map( learner => {
               return {...learner, status: true}
           } )])
         }
         else {
            setChecked(false)
            setLearners([...learners.map( learner => {
               return {...learner, status: false}
           } )])
         }
     }
  }

  const searchLearner = (event) => {
     const filteredLearners = learners.filter((learner) =>
        learner.email.toLowerCase().includes(event.target.value.toLowerCase())
     );
     if(event.target.value === '') setLearners(defaultLearners)
     else {
        setLearners(filteredLearners)
      }
  } 

  const addLearnersToCohort = () => {
    const participants = learners?.filter( learner => learner?.status === true )
    if(participants?.length){
      addParticipantsMutation.mutate({
        data: {participants}, cohortId, id: orgId
     })
    }
    else toast.error('Select learners to proceed.')
  }

  return (
    <>
       <div className={`w-[550px] mx-auto shadow-2xl p-4 rounded-lg h-[auto] bg-white mt-2`}>
              <header className='flex justify-between items-center'>
                   <div>
                      <h1 className='text-md font-semibold mt-1'>
                        Step 3 <span className='bi bi-arrow-right'></span> &nbsp;
                        Add Learners To Cohort
                      </h1>
                      <p className='text-xs mb-2  text-gray-700'>
                         add learners to your cohort. click to upload icon below to 
                         upload xlsx or csv or download sample to preview sample
                      </p>
                   </div>
                    <div className='flex'>
                    <a 
                      href="/assets/sample learners (1).xlsx" 
                      className='bi bi-download py-2 text-center font-bold -mt-8 w-[200px] rounded-lg px-4 bg-secondary text-xs text-white' 
                      download={'sample learners'}
                    >
                        &nbsp; Download Sample 
                      </a>
                    </div>
              </header>
             <div className='border-t border-gray-300 mt-3 pt-3'>
             <div>     
                <ul
                  className="mb-5 mt-2 flex list-none flex-row flex-wrap border-b-0 pl-0"
                  role="tablist"
                  data-te-nav-ref>
                  <li role="presentation">
                    <a
                      href="#tabs-home"
                      className="my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 py-1 text-[10px] font-medium uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
                      data-te-toggle="pill"
                      data-te-target="#tabs-home"
                      data-te-nav-active
                      role="tab"
                      aria-controls="tabs-home"
                      aria-selected="true"
                      >Bulk Upload Learners </a>
                  </li>
                </ul>
              <div className="mb-6">
                <div
                  className="hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                  id="tabs-home"
                  role="tabpanel"
                  aria-labelledby="tabs-home-tab"
                  data-te-tab-active
                >
                  <div className='w-full'>
                   <section className='flex justify-between'>
                     <div className='flex items-center'> 
                        <button
                            onClick={selectAll} 
                            className={`bi -mt-3 flex items-center ${ 
                                         checked ? 'text-primary' : 'text-gray-900'}
                                         bi-check-square text-lg `}
                            >
                              <span  className='text-gray-900 text-xs ml-1'> 
                                 select all 
                              </span>
                        </button>
                        <div className='ml-3 w-[330px]'>
                           <Search
                              onChange={searchLearner} 
                              //onBlur={ (e) => setLearners(defaultLearners) }
                           />
                        </div>
                     </div>
                     <div className='flex justify-end h-[40px]'>
                        <input 
                            onChange={uploadLearners} type="file"
                            id='upload-participants' className='hidden' 
                        />
                        <label  htmlFor='upload-participants' className='bi py-3 px-8 bi-cloud-upload ml-3 text-xs border border-gray-200 rounded-lg'>
                            &nbsp; 
                        </label>
                     </div>
                      </section>
                    <div className={`mt-2 w-[90%] ${learners?.length ? 'h-[400px]' : 'h-auto'} pr-5 overflow-y-auto`}>
                       {
                         learners?.length ? 
                          learners?.map( learner => {
                             return (
                                <UserSelect 
                                     name={learner?.email} 
                                     selected={learner?.status}
                                     onSelect={() => updateLearners(learner)}
                                />
                             )
                          } )
                         :
                         <>
                            {
                              (invalidLearners?.length || duplicateLearners?.length) ?
                               <>
                                 {
                                    invalidLearners?.length ? 
                                      invalidLearners?.map( learner => {
                                        return(
                                          <p key={uuid()} className='text-sm text-center text-red-500 mb-2'>
                                            Error! Invalid Email: &nbsp; {learner?.email}
                                          </p>
                                        )
                                      })
                                    : null
                                 }
                                 {
                                    duplicateLearners?.length ? 
                                      duplicateLearners?.map( learner => {
                                        return(
                                          <p key={uuid()} className='text-sm text-center text-red-500 mb-2'>
                                            Error! Duplicate Email: &nbsp; {learner?.email}
                                          </p>
                                        )
                                      })
                                    : null
                                 }
                               </>
                              :
                               <p className='text-center text-xs mt-12'>
                                    <EmptyData text={'Upload Learners and assign them to a group'} />
                               </p>
                            }
                         </>
                       }
                    </div>
                  </div>
                </div>
              </div>
             </div>
                  <div className='flex mt-5 justify-end'>
                      <Button 
                         width={'w-[160px]'} 
                        text={'Add Learners To Cohort'}
                        bgColor={'bg-customBlue'}
                        onClick={ addLearnersToCohort }
                      />  
                  </div>    
             </div>
         </div>
    </>
  )
}

export default AddLearnersToCohort